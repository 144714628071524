import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { FaPlus, Delete } from 'react-icons/fa';
import { Radio, Checkbox } from '@mui/material';



const QuestionBankTable = () => {
  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [questionsPerPage, setQuestionsPerPage] = useState(10);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState({
    id: 1,
    type: '',
    course: '',
    optionsCount: 4,
    question: '',
  });
const [options, setOptions] = useState(Array.from({ length: newQuestion.optionsCount }, () => ({ value: '', selected: false })));

  useEffect(() => {
    localStorage.setItem('questions', JSON.stringify(questions));
  }, [questions]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setQuestionsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const [selectedType, setSelectedType] = useState('');

  const handleOpenQuestionModal = () => {
    setOpenQuestionModal(true);
    setNewQuestion({
      id: questions.length + 1,
      type: selectedType, // Update with the selected type
      course: '',
      optionsCount: 4,
      question: '',
    });
    setOptions(Array.from({ length: 4 }, () => ({ value: '', selected: false })));
  };
  
  // Update the selected type when the user selects a type
  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSelectedType(type);
  };
  
  

  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
    setNewQuestion({
      id: questions.length + 1,
      type: '',
      course: '',
      optionsCount: 4,
      question: '',
    });
  };

  const handleOpenOptionsModal = () => {
    setOpenOptionsModal(true);
  };

  const handleCloseOptionsModal = () => {
    setOpenOptionsModal(false);
    setOptions([]);
  };

  const handleAddQuestion = () => {
    const confirmed = window.confirm('Are you sure you want to add this question?');
    if (confirmed) {
      let newQuestionData = { ...newQuestion };
      if (newQuestion.type === 'Multiple Choice Single Answer' || newQuestion.type === 'Multiple Choice Multiple Answer') {
        newQuestionData = { ...newQuestionData, options };
      }
      setQuestions([...questions, newQuestionData]);
      handleCloseQuestionModal(); // Close the "Add Question" dialog
    }
  };
  

  const handleDelete = (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this question?');
    if (confirmed) {
      const updatedQuestions = questions.filter((question) => question.id !== id);
      setQuestions(updatedQuestions);
    }
  };

  const handleOptionChange = (index, value) => {
    if (newQuestion.type === 'Multiple Choice Single Answer') {
      // For Single Answer, deselect all other options before selecting the current one
      const updatedOptions = options.map((option, i) => ({
        ...option,
        selected: i === index ? value : false,
      }));
      setOptions(updatedOptions);
    } else if (newQuestion.type === 'Multiple Choice Multiple Answer') {
      // For Multiple Answers, toggle the selection of the current option
      const updatedOptions = [...options];
      updatedOptions[index] = { ...updatedOptions[index], selected: value };
      setOptions(updatedOptions);
    }
  };
  
  
  const handleOptionTextChange = (index, value) => {
    const updatedOptions = [...options];
    if (updatedOptions[index]) {
      updatedOptions[index].value = value;
      setOptions(updatedOptions);
    }
  };
  
  

  const renderOptionsField = () => {
    const fields = [];
    for (let i = 0; i < newQuestion.optionsCount; i++) {
      fields.push(
        <div key={i}>
          <Checkbox
            checked={options[i] ? options[i].selected : false}
            onChange={(e) => handleOptionChange(i, e.target.checked)}
            value={options[i] ? options[i].value : ''}
          />
          <TextField
            label={`Option ${i + 1}`}
            value={options[i] ? options[i].value : ''}
            onChange={(e) => handleOptionTextChange(i, e.target.value)}
            fullWidth
            style={{ marginTop: '10px' }}
          />
        </div>
      );
    }
    return fields;
  };
  
  

  return (
    <div className="container" style={{ maxWidth: '1000px', marginTop: '52px' }}>
      <Dialog open={openQuestionModal} onClose={handleCloseQuestionModal}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <FormControl fullWidth style={{ marginTop: '12px' }}>
            <InputLabel>Course</InputLabel>
            <Select
              value={newQuestion.course}
              onChange={(e) => setNewQuestion({ ...newQuestion, course: e.target.value })}
              variant="outlined"
            >
              {dummyCourses.map((course) => (
                <MenuItem key={course} value={course}>
                  {course}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: '10px' }}>
            <InputLabel>Type</InputLabel>
            <Select
              value={newQuestion.type}
              onChange={(e) => setNewQuestion({ ...newQuestion, type: e.target.value })}
              variant="outlined"
            >
              {dummyTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(newQuestion.type === 'Multiple Choice Single Answer' || newQuestion.type === 'Multiple Choice Multiple Answer') && (
            <TextField
              label="Number of Options"
              type="number"
              value={newQuestion.optionsCount}
              onChange={(e) => setNewQuestion({ ...newQuestion, optionsCount: e.target.value })}
              fullWidth
              style={{ marginTop: '10px' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQuestionModal} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOpenOptionsModal} variant="contained" color="primary">
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openOptionsModal} onClose={handleCloseOptionsModal}>
        <DialogTitle>Add Question</DialogTitle>
        <DialogContent>
          <TextField
            label="Question"
            value={newQuestion.question}
            onChange={(e) => setNewQuestion({ ...newQuestion, question: e.target.value })}
            fullWidth
            style={{ marginTop: '10px' }}
          />
          {(newQuestion.type === 'Multiple Choice Single Answer' || newQuestion.type === 'Multiple Choice Multiple Answer') && renderOptionsField()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptionsModal} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddQuestion} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          style={{
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            float: 'left',
            fontSize: '16px',
          }}
        />
        <Button onClick={handleOpenQuestionModal} variant="contained" color="primary" style={{ float: 'left', marginLeft: '2%', marginTop: '52px' }}>
          Add Question
        </Button>
        <TableContainer sx={{ maxHeight: 'none' }}>
          <br />
          <Table aria-label="sticky table">
            <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0 }}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((question) => (
                <TableRow key={question.id}>
                  <TableCell>{question.id}</TableCell>
                  <TableCell>{question.question}</TableCell>
                  <TableCell>{question.type}</TableCell>
                  <TableCell>{question.course}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="secondary" onClick={() => handleDelete(question.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

const dummyCourses = [
  'Web Development',
  'React Fundamentals',
  'JavaScript Basics',
  'Node.js Essentials',
  'Database Management',
  'CSS Styling',
  'UI/UX Design',
  'Mobile App Development',
  'Python Programming',
  'Software Testing',
];
const dummyTypes = ['Multiple Choice Single Answer', 'Multiple Choice Multiple Answer', 'Short Answer', 'Long Answer', 'Short Answer with Image'];

export default QuestionBankTable;
