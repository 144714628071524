import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { Modal, Button } from 'react-bootstrap';
import axios from '../../Axiosinstance'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../../common';


const NewCourseForm = () => {
  const navigate = useNavigate();

  const [category, setCategory] = useState('');
  const [duration, setDuration] = useState('');
  const [fee, setFee] = useState('');
  const [clustercode, setClustercode] = useState('');
  const [qualificationcode, setQualificationcode] = useState('');
  const [coverImage, setCoverImage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const { courseid } = useParams();

  const [courseData, setCourseData] = useState({
    category: '',
    name: '',
    reference: '',
    duration: '',
    fee: '',
    clustercode: '',
    qualificationcode: '',
    requirement: '',
    outcome: '',
    description: '',
  });
  const [url, setUrl] = useState('');
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [description, setDescription] = useState(courseData.description);
  const [requirement, setRequirement] = useState(courseData.requirement);
  const [outcome, setOutcome] = useState(courseData.outcome);
  const showSnackbar = () => {
    setIsSnackbarOpen(true);
    setTimeout(() => {
      setIsSnackbarOpen(false);
    }, 1500);
  };

  const handleDurationChange = (e) => {
    setCourseData({ ...courseData, duration: e.target.value });
  };

  const handleFeeChange = (e) => {
    setCourseData({ ...courseData, fee: e.target.value });
  };

  const handleClusterCodeChange = (e) => {
    setCourseData({ ...courseData, clustercode: e.target.value });
  };

  const handleCodeChange = (e) => {
    setCourseData({ ...courseData, qualificationcode: e.target.value });
  };

  const handleNameChange = (e) => {
    setCourseData({ ...courseData, name: e.target.value });
  };

  const handleReferenceChange = (e) => {
    setCourseData({ ...courseData, reference: e.target.value });
  };


  const handleCategoryChange = (e) => {
    setCourseData({ ...courseData, category: e.target.value });
  };

  const handleDescriptionChange = (content) => {
    setCourseData({ ...courseData, description: content });
  };

  const handleRequirementChange = (content) => {
    setCourseData({ ...courseData, requirement: content });
  };

  const handleOutcomeChange = (content) => {
    setCourseData({ ...courseData, outcome: content });
  };





  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.value);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('categorys');
      if (response.status === 200) {
        setCategories(response.data); // Update the categories state with fetched data
      } else {
        console.error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Call fetchCategories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
  }, [courseid]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/course/${courseid}`);
        if (response.status === 200) {
          const {
            category,
            name,
            reference,
            duration,
            fee,
            clustercode,
            qualificationcode,
            description,
            outcome,
            requirement,
            url,
            categoryId
          } = response.data;
          setCourseData({
            category,
            name,
            reference,
            duration,
            fee,
            clustercode,
            qualificationcode,
            description,
            outcome,
            requirement,
            url,
            categoryId
          });
        } else {
          console.error('Failed to fetch course details');
        }
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchData();
  }, [courseid]);


  const handleSave = async () => {

  
    if (isNewImageUploaded) {
      try {
        setIsSnackbarOpen(true); // Show Snackbar with loading message
        const updatedCourseData = {
          ...courseData,
          url: url,
          description,
          requirement,
          outcome,
        };
  
        // Assuming image upload process here if isNewImageUploaded is true
  
        const response = await axios.put(`/course/${courseid}`, updatedCourseData);
        if (response.status === 200) {
          navigate('/AllCourse');
        } else {
          console.error('Failed to update course data');
        }
      } catch (error) {
        console.error('Error updating course data:', error);
      } finally {
        setIsSnackbarOpen(false); // Close the Snackbar when the update is complete
      }
    } else {
      // If no new image uploaded, proceed directly to saving without waiting for image upload
      try {
        setIsSnackbarOpen(true); // Show Snackbar with loading message
  
        const response = await axios.put(`/course/${courseid}`, courseData);
        if (response.status === 200) {
          navigate('/AllCourse');
        } else {
          console.error('Failed to update course data');
        }
      } catch (error) {
        console.error('Error updating course data:', error);
      } finally {
        setIsSnackbarOpen(false); // Close the Snackbar when the update is complete
      }
    }
  };
  





  const handleCancel = () => {
    console.log('Cancel clicked!');
    // Add your own custom logic here
  };



  const handleNext = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSkip = () => {
    setShowModal(false);
    console.log('Skip button clicked!');
    // Add your own custom logic here
  };

  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];

      // Check if a file is selected
      if (!file) {
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET); // Replace with your Cloudinary upload preset
      formData.append('public_id', 'LMS/courses/' + file.name);
      const response = await fetch(baseURL, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.secure_url !== '') {
        const uploadedFileUrl = data.secure_url;
        setUrl(uploadedFileUrl);
        setIsNewImageUploaded(true); // Set isNewImageUploaded to true
        // console.log(uploadedFileUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [formModified, setFormModified] = useState(false);


  useEffect(() => {
    const handleFormModification = () => {
      if (!formModified) {
        setFormModified(true);
      }
    };


    window.addEventListener('input', handleFormModification);


    return () => {
      window.removeEventListener('input', handleFormModification);
    };
  }, [formModified]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formModified) {
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };


    window.onbeforeunload = handleBeforeUnload;


    return () => {
      window.onbeforeunload = null;
    };
  }, [formModified]);





  return (
    <div className="container">
      <h1>Edit Course</h1>
      <form>
        <div className="form-group">
          <label htmlFor="category">Category:</label>
          <select
            className="form-control"
            id="category"
            value={courseData.category}
            onChange={handleCategoryChange}
          >
            <option value="">Select a category</option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.cat}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            value={courseData.name}
            onChange={handleNameChange}
            placeholder="Enter name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="reference">Reference Number:</label>
          <input
            type="text"
            className="form-control"
            id="reference"
            value={courseData.reference}
            onChange={handleReferenceChange}
            placeholder="Enter reference number"
          />
        </div>

        <div className="form-group">
          <label htmlFor="duration">Duration:</label>
          <input
            type="text"
            className="form-control"
            id="duration"
            value={courseData.duration}
            onChange={handleDurationChange}
            placeholder="Enter duration"
          />
        </div>
        <div className="form-group">
          <label htmlFor="fee">Fee:</label>
          <input
            type="text"
            className="form-control"
            id="fee"
            value={courseData.fee}
            onChange={handleFeeChange}
            placeholder="Enter fee"
          />
        </div>
        <div className="form-group">
          <label htmlFor="clusterCode">Cluster Code:</label>
          <input
            type="text"
            className="form-control"
            id="clusterCode"
            value={courseData.clustercode}
            onChange={handleClusterCodeChange}
            placeholder="Enter cluster code"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <ReactQuill
            value={courseData.description}
            onChange={handleDescriptionChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="requirement">Requirement:</label>
          <ReactQuill
            value={courseData.requirement}
            onChange={handleRequirementChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="outcome">Outcome:</label>
          <ReactQuill
            value={courseData.outcome}
            onChange={handleOutcomeChange}
          />
        </div>

        <br />
        <div className="mb-3">
          <label htmlFor="fileupload">Upload Images:</label>
          <input type="file" id="fileupload" onChange={handleImageUpload} />
        </div>

        {/* Display the uploaded image */}
        {(
          <div>
            <p>Uploaded Image:</p>
            <img style={{ width: '250px', height: '100px' }} src={courseData.url} alt="Uploaded" />
          </div>
        )}
        <Snackbar
          open={isSnackbarOpen}
          message="Updating course data..."
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <CircularProgress color="inherit" size={24} />
        </Snackbar>



        <div className="text-center">
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSave}
              style={{ backgroundColor: '#03396c' }}
            >
              Update
            </button>

            <Link
              to="/AllCourse"
              className="btn btn-primary"
              style={{ backgroundColor: '#03396c', color: 'white' }}
            >
              Cancel
            </Link>
          </div>


        </div>
      </form>
    </div>
  );
};

class ButtonComponent extends React.Component {


  render() {
    return (
      <div className="container">
        <Link
          to="/AllCourse"
          className="btn btn-primary"
          style={{ backgroundColor: '#03396c', color: 'white' }}
        >
          New Course
        </Link>
        <Link
          to="/AllCourse"
          className="btn btn-primary"
          style={{ backgroundColor: '#03396c', color: 'white' }}
        >
          All Course
        </Link>
      </div>
    );
  }
}

const Newcou = () => {
  return (
    <div>
      <Header />
      <ButtonComponent />
      <NewCourseForm />
    </div>
  );
};

export default Newcou;