import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form, Button , Modal } from 'react-bootstrap';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import axios from '../Axiosinstance'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function CreateQuizForm({ onFormSubmit }) {
  const navigate = useNavigate();
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedBatch, setSelectedBatch] = useState('');
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [duration, setDuration] = useState('');
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [errors, setErrors] = useState({});
  useEffect(() => {
    async function fetchBatches() {
      try {
        const response = await axios.get('batches');
        if (response.status === 200) {
          const data = response.data;
          setCourses(data.map(item => item.course));
          setBatches(data.map(item => item.batchcode));
        } else {
          console.error('Failed to fetch batches:', response.status);
        }
      } catch (error) {
        console.error('Error fetching batches:', error);
      }
    }


    fetchBatches();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();
  const formData = {
      course: selectedCourse,
      batch: selectedBatch,
      name: name,
      duration: parseInt(duration), // Convert duration to an integer if necessary
      start: startDate, // Map startDate to the 'start' field
      end_time: endDate, // Map endDate to the 'end_time' field
      description: editorContent, // Map editorContent to the 'description' field
    };
  try {
      const confirmation = window.confirm('Are you sure you want to add this assessment?');
      if (confirmation) {




        const response = await axios.post('assessment', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success('Assessment submitted successfully');
          // Reset form fields
          setSelectedCourse('');
          setSelectedBatch('');
          setDuration('');
          setName('');
          setStartDate('');
          setEndDate('');
          setEditorContent('');
     // Navigate to the "Allas" page
          navigate('/Allas');
          onFormSubmit();
        } else {
          console.error('Response status:', response.status);
          console.error('Response data:', response.data);
          throw new Error('Failed to submit quiz');
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit Assessment');
    }
  };


  const handleEditorChange = (value) => {
    setEditorContent(value);
  };




  const handleCancel = () => {
    onFormSubmit();
    };
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const handleCloseModal = () => setShowModal(false); // Function to close the modal
 
    const handleShowModal = () => setShowModal(true); // Function to show the modal


  const handleCanceled = () => {
    handleShowModal(); // Show modal when cancel button is clicked
  };






  const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1); // Set the maximum date to one year from now
  const maxDateFormatted = maxDate.toISOString().split('T')[0]; // Format the maximum date in YYYY-MM-DD format


  const [isFormDirty, setIsFormDirty] = useState(false);


  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };


    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);


  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },




  };


  return (
    <div className="container-fluid" >
      <br></br>
      <div style={styles.container}>
        <h4> New Assessment </h4>
        <hr style={{ marginTop: '1%' }} />


        <form onSubmit={handleSubmit} onChange={handleInputChange}
        >
          <div className="form-group">
            <label htmlFor="course">Course</label>
            <select
              className="form-control"
              id="course"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
              required
            >
              <option value="">Select a course</option>
              {courses.map((courseOption, index) => (
                <option key={index} value={courseOption}>
                  {courseOption}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="batch">Batch</label>
            <select
              className="form-control"
              id="batch"
              value={selectedBatch}
              onChange={(e) => setSelectedBatch(e.target.value)}
              required
            >
              <option value="">Select a batch</option>
              {batches.map((batchOption, index) => (
                <option key={index} value={batchOption}>
                  {batchOption}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Enter name"
              value={name}
              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces




                if (onlyLetters.test(inputValue) || inputValue === "") {
                  setName(inputValue);
                }
              }}
              required
            />
            {errors.name && (
              <div className="text-danger">{errors.name}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="duration">Duration (in minutes)</label>
            <input
              type="number"
              className="form-control"
              id="duration"
              placeholder="Enter duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input
              type="date"
              className="form-control"
              id="startDate"
              placeholder="Enter start date"
              min={currentDate}
              max={maxDateFormatted}
              onChange={(e) => setStartDate(e.target.value)}
              required/>
          </div>
          <div className="form-group">
            <label htmlFor="endDate">End Date</label>
            <input
              type="date"
              className="form-control"
              id="endDate"
              placeholder="Enter end date"
              min={currentDate}
              max={maxDateFormatted}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </div>
          <div className="instructions-container">
            <h6>--Instruction (Shown at the bottom of the Page)</h6>
            <ReactQuill
              value={editorContent}
              onChange={handleEditorChange}
              placeholder="Enter your text"
            />
          </div>
          <br />
          <button type="submit" className="primary" style={{ background:'red' ,
          fontSize: '15px' , color: 'white' , border: 'none' , padding: '8px 35px' ,
          borderRadius: '7px'  ,backgroundColor: 'green', fontSize: '15px', float: 'left' }} >    
           
            Submit
          </button>
         
          <button  type="Cancel" className="primary" onClick={handleCanceled} style={{
 
   fontSize: '15px' , color: 'white' , border: 'none' , padding: '8px 35px' ,  
   borderRadius: '7px'  ,backgroundColor: 'red', fontSize: '15px' , marginLeft: '2%'
        }}>
          Cancel
        </button>
        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} style={{backgroundColor:"grey"}}>
            No
          </Button>
          <Button
          style={{backgroundColor:"red"}}
            variant="danger"
            onClick={() => {
              handleCloseModal();
              // Place the logic to reset form fields or navigate away here
              onFormSubmit();
             
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
          <br />


        </form>
        <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />




      </div>


    </div>
  );
}


const New = ({ onFormSubmit }) => {
  return (
    <div>
    <CreateQuizForm onFormSubmit={onFormSubmit} />
    </div>
  );
}
export default New;



