import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../../components/Header';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaPlus } from 'react-icons/fa';
import axios from '../../Axiosinstance';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material'




const App = () => {
  const [tableData, setTableData] = useState([]);
  const [editedBlogPost, setEditedBlogPost] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);




  const [showPopup, setShowPopup] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredItems, setFilteredItems] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editedImage, setEditedImage] = useState(null);












  const handleEdit = (blogPost) => {
    setEditedBlogPost(blogPost);
    setOpenEditDialog(true);
  };
  const onEditTitleChange = (event) => {
    setEditedBlogPost({
      ...editedBlogPost,
      title: event.target.value,
    });
  };
  const onEditEditorChange = (content) => {
    setEditedBlogPost({
      ...editedBlogPost,
      editorContent: content,
    });
  };
 
   const [openDialog, setOpenDialog] = useState(false);




  const handleNewPost = () => {
    setOpenDialog(true);
  };




  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const updateBlogPost = async () => {
    if (!editedBlogPost.title || !editedBlogPost.editorContent) {
      toast.error('Please fill in all required fields.');
      return;
    }
  
    const confirmed = window.confirm('Are you sure you want to update the blog?');
    if (!confirmed) {
      return;
    }
  
    try {
      // Perform the update logic here using axios or your API request method
      await axios.put(`http://localhost:8080/blog/${editedBlogPost.id}`, editedBlogPost);
      console.log(`Blog post with ID ${editedBlogPost.id} updated successfully`);
  
      // Update the edited blog post in the tableData state
      const updatedData = tableData.map((item) => {
        if (item.id === editedBlogPost.id) {
          return editedBlogPost;
        }
        return item;
      });
      setTableData(updatedData);
  
      // Close the edit dialog after successful update
      setOpenEditDialog(false);
      toast.success('Post updated successfully');
    } catch (error) {
      console.error(`Error updating blog post with ID ${editedBlogPost.id}:`, error);
      toast.error('Error updating the post. Please try again.');
    }
  };
  
  
 
  const handleImageChange = (event) => {
    const imageFile = event.target.files[0];
    setEditedImage(imageFile);
  };




 
  const handleDelete = (id) => {
    // Confirm the deletion with the user (you can use a window.confirm dialog)
    const confirmDelete = window.confirm('Are you sure you want to delete this blog post?');
 
    if (confirmDelete) {
      // Call the deleteBlogPost function
      deleteBlogPost(id);
    }
  };
  const deleteBlogPost = async (id) => {
    try {
      await axios.delete(`blog/${id}`);
      console.log(`Blog post with ID ${id} deleted successfully`);
      const updatedData = tableData.filter(item => item.id !== id);
      setTableData(updatedData);
      toast.success('Post deleted successfully');


    } catch (error) {
      console.error(`Error deleting blog post with ID ${id}:`, error);
    }
  };


  const [editorContent, setEditorContent] = useState('');




  const handleEditorChange = (content) => {
    setEditorContent(content);
  };




  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get('blogs');
      setTableData(response.data); // Assuming the response contains an array of blog posts
      setFilteredItems(response.data); // Initially, set filtered items to all items
    } catch (error) {
      console.error('Error fetching blog posts:', error);
    }
  };




  const addBlogPost = async (data) => {
    try {
      const response = await axios.post('blog', data);
      console.log('Blog post added successfully:', response.data);
    } catch (error) {
      console.error('Error adding blog post:', error);
    }
  };
  const handleSave = async () => {
    const titleInput = document.querySelector('#title');
    const currentDate = new Date().toISOString();

    const newBlogPost = {
      title: titleInput.value,
      date: currentDate,
      post: editorContent,
    };

    if (!newBlogPost.title || !newBlogPost.post) {
      toast.error('Please fill in all required fields.');
      return;
    }


    try {


    
      const confirmed = window.confirm('Are you sure you want to save the blog?');
      if (!confirmed) {
        return; // If not confirmed, exit the function
      }
      // Send the data to the API
      await addBlogPost(newBlogPost);
 
      // Close the dialog upon successful save
      setOpenDialog(false);
 
      // Optionally, you can also reset the input fields or states here
      titleInput.value = '';
      setEditorContent('');
 
      // Fetch and display the updated list of blog posts
      fetchBlogPosts(); // Call the function to fetch blog posts
      toast.success('New Post created successfully');


    } catch (error) {
      console.error('Error adding blog post:', error);
    }
  };
 
  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('blogs');
        setTableData(response.data); // Assuming the response contains an array of blog posts
        setFilteredItems(response.data); // Initially, set filtered items to all items
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    };




    fetchBlogPosts();
  }, []);




  useEffect(() => {
    // Filter items based on search query
    const filtered = tableData.filter((item) =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredItems(filtered);
  }, [searchQuery, tableData]);




 
  const getTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - date;
   
    // Add 5 hours and 30 minutes to the time difference
    const offsetMilliseconds = 0;
    const adjustedTimeDifference = timeDifference + offsetMilliseconds;
 
    const seconds = Math.floor(adjustedTimeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);
 
    if (years > 0) {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    } else if (days > 0) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (hours > 0) {
      return `${hours} hr${hours === 1 ? '' : 's'} ago`;
    } else if (minutes > 0) {
      return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
    } else {
      return `${seconds} sec${seconds === 1 ? '' : 's'} ago`;
    }
  };
 
  return (
 
<div className="wider-container" style={{ width: '120%' ,  }}>
  <div className="container">
    <Paper sx={{ width: '150%', overflow: 'hidden' }}>
    <div className="row">
        <div className="col-md-6">
          <div className="input-group mb-4">
      <input
             type="text"
             className="form-control"
             placeholder="Search by Name"
             value={searchQuery}
             
             onChange={(e) => setSearchQuery(e.target.value)}
             
           />
           <Button
             onClick={handleNewPost}
              variant="contained"
              color="primary"
              startIcon={<FaPlus />}
              style={{
                marginRight: '-2%',              
                padding: '10px 26px',
                borderRadius: '4px',
                cursor: 'pointer',
               
              }}
            >
             New Post
            </Button>
            </div>
        </div>
      </div>
     
      <TableContainer sx={{ maxHeight: 'none' }}>
      <Table stickyHeader aria-label="sticky table">




        <TableHead>
          <TableRow>
            <TableCell align="center">S.No</TableCell>
            <TableCell align="center">Title</TableCell>
            <TableCell align="center"> Image</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        {filteredItems
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map((item,index) => (
       
  <TableRow key={item.id}>
              <TableCell style={{  textAlign: 'center' }} >{index + 1}</TableCell>
              <TableCell style={{  textAlign: 'center' }} >{item.title}</TableCell>
              <TableCell style={{  textAlign: 'center' }} >
                <img src={item.image} alt={item.title} />
              </TableCell>
              <TableCell style={{ textAlign: 'center' }}>{getTimeAgo(item.date)}</TableCell>
              <TableCell>
              <div className="action-buttons" style={{ textAlign: 'center' }}>
      <BsPencilFill style={{    marginRight: 10}}   onClick={() => handleEdit(item)}/>
         
      <DeleteRoundedIcon
                          style={{ color: 'red', marginLeft: 10}} onClick={() => handleDelete(item.id)}/>
          </div>
         </TableCell>




            </TableRow>
          ))}
          <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
  <DialogTitle>Edit Blog Post</DialogTitle>
  <DialogContent>
  <TextField
      type="text"
      placeholder="Title"
      value={editedBlogPost.title}
      onChange={onEditTitleChange}
      autoFocus
      margin="dense"
      id="title"
      fullWidth
      inputProps={{ maxLength: 20 }}
      helperText="20 characters only"
    />
    <ReactQuill
      value={editedBlogPost.editorContent}
      onChange={onEditEditorChange}
      placeholder="Enter your Text"
      style={{ fontSize: '16px' }}
      inputProps={{ maxLength: 20 }}
      helperText="20 characters only"
    />
    <input
      type="file"
      accept="image/*"
      onChange={handleImageChange}
      style={{ marginTop: '10px' }}
    />
    {editedImage && (
      <img
        src={URL.createObjectURL(editedImage)}
        alt="Edited Image"
        style={{ marginTop: '10px', maxWidth: '100px' }}
      />
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenEditDialog(false)}  variant='contained'  style={{
                         
                         padding: '10px 26px',
                         borderRadius: '4px',
                         cursor: 'pointer',
                       
                       }}>
      Close
    </Button>
    <Button onClick={updateBlogPost}  variant='contained'  style={{
                         
                         padding: '10px 26px',
                         borderRadius: '4px',
                         cursor: 'pointer',
                       
                       }}>
      Save
    </Button>
  </DialogActions>
</Dialog>
        </TableBody>
      </Table>




      </TableContainer>
      <TablePagination
            rowsPerPageOptions={[5,10, 25, 100]}
            component="div"
            count={tableData.length}
            rowsPerPage={itemsPerPage}
            page={currentPage - 1}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={(event) => {
              setCurrentPage(1);
              setItemsPerPage(event.target.value);
            }}
          />
      </Paper>
     
      <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>New Blog Post</DialogTitle>
  <DialogContent>
  <TextField
      autoFocus
      margin="dense"
      id="title"
      label="Title"
      type="text"
      fullWidth
      inputProps={{ maxLength: 20 }}
      helperText="20 characters only"
    />
    {/* Input field for image upload */}
    <input type="file" accept="image/*" style={{ marginBottom: '10px' }} />




    {/* Editor component */}
    <div style={{ fontWeight: 'bold', fontStyle: 'italic', marginBottom: '3%' }}>
      {/* Replace this with your preferred editor component */}
      <ReactQuill
        value={editorContent}
        onChange={handleEditorChange}
        placeholder="Enter your Text"
        style={{ fontSize: '16px' }}
        inputProps={{ maxLength: 20 }}
        helperText="50 characters only"
      />
    </div>
    <Button onClick={handleCloseDialog} variant='contained'  style={{
                         
                         padding: '10px 26px',
                         borderRadius: '4px',
                         cursor: 'pointer',
                       
                       }}>
               Close
             </Button>
             <Button onClick={handleSave} variant='contained'  style={{
                                   
                         padding: '10px 26px',
                         borderRadius: '4px',
                         cursor: 'pointer',
                         marginLeft: '2%'
                       
                       }}>
               Save
             </Button>
  </DialogContent>
 
  <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />


</Dialog>
 
    </div>
    </div>
  );
};




export default App;















