import React, { useState } from 'react';
import axios from '../Axiosinstance';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';






const RequestStudentForm = ({ closeModal }) => {








    const CLOUDINARY_URL = baseURL;
    const CLOUDINARY_UPLOAD = CLOUDINARY_UPLOAD_PRESET;








    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);








    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };








    const [formData, setFormData] = useState({
        fullname: '',
        nric: '',
        email: '',
        phone: '',
        gender: '',
        dob: '',
        password: '',
        address: '',
        postalcode: '',
        race: '',
        education: '',
        employmentstatus: '',
        salary: '',
        companyname: '',
        corporatecompanysponsored: '',
        designation: '',
        status: false,
        profileUrl: '',
        batch: '',
        batch_id: ''
    });




    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3; // Total number of steps in the wizard
    const nextStep = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        setCurrentStep((prevStep) => prevStep + 1);
    };




    const prevStep = (e) => {
        e.preventDefault();
        setCurrentStep((prevStep) => prevStep - 1);
    };







    const handleInputChange = (e) => {
        const { name, value } = e.target;








        if (name === 'gender') {
            setFormData({
                ...formData,
                gender: value
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };








    const handleSubmit = async (e) => {
        e.preventDefault();


        if (
            formData.fullname === '' ||
            formData.nric === '' ||
            formData.email === '' ||
            formData.phone === '' ||
            formData.gender === '' ||
            formData.dob === '' ||
            formData.password === '' ||
            formData.address === '' ||
            formData.postalcode === '' ||
            formData.race === '' ||
            formData.education === '' ||
            formData.employmentstatus === ''
        ) {
            // Handle validation error, alert the user, or perform necessary actions
            toast.error('Please fill in all required fields.', {
                position: toast.POSITION.BOTTOM_RIGHT,
            }); return;
        }


        // Check if it's the final step before submitting
        if (currentStep === totalSteps) {
            if (!isUploading) {
                try {
                    const formDataWithImage = new FormData();


                    // Append all form data fields
                    Object.keys(formData).forEach((key) => {
                        formDataWithImage.append(key, formData[key]);
                    });


                    if (selectedFile) {
                        // Append the file data if an image is selected
                        formDataWithImage.append('file', selectedFile);
                        formDataWithImage.append('upload_preset', CLOUDINARY_UPLOAD);
                        formDataWithImage.append('public_id', 'LMS/RequestStudent/' + selectedFile.name);


                        setIsUploading(true);
                        try {
                            const response = await fetch(CLOUDINARY_URL, {
                                method: 'POST',
                                body: formDataWithImage,
                            });
                            const data = await response.json();
                            if (data.secure_url !== '') {
                                const url = data.secure_url;
                                formDataWithImage.set('profileUrl', url);
                                setIsUploading(false);
                            }
                        } catch (err) {
                            console.error(err);
                            setIsUploading(false);
                        }
                    }


                    // Send formDataWithImage (with image and form data) to the server
                    const response = await axios.post('requeststudent', formDataWithImage);


                    // console.log('Data sent:', response.data);


                    // Clear the form
                    clearForm();
                    closeModal();
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        } else {
            // If it's not the final step, proceed to the next step
            nextStep();
        }
    };


















    const clearForm = () => {
        setFormData({
            fullname: '',
            nric: '',
            email: '',
            phone: '',
            gender: '',
            dob: '',
            password: '',
            address: '',
            postalcode: '',
            race: '',
            education: '',
            employmentstatus: '',
            salary: '',
            companyname: '',
            corporatecompanysponsored: '',
            designation: '',
            status: '',
            profileUrl: '',
            batch: '',
            batch_id: ''
        });








        setSelectedFile(null); // Clear the selected file
    };








    const formContainerStyle = {
        maxHeight: '800px',
        overflowY: 'auto',
        padding: '15px',
        maxWidth: '1000px', // Ensure it's maxWidth, not maxwidth
        width: '100%', // Ensures the content takes full width up to maxWidth
        margin: '0 auto' // Centers the container horizontally
    };


    const renderFormStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div >
                        {/* Section 1 Fields */}
                        <div className="row mb-3">
                            <label htmlFor="fullname" className="form-label">Full Name</label>
                            <input
                                type="text"
                                required
                                name="fullname"
                                value={formData.fullname}
                                maxLength={20}
                                title="20 Characters Only"
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
                                    const truncatedValue = inputValue.substring(0, 20); // Truncate to a maximum of 20 characters




                                    // Update the state with the truncated value
                                    handleInputChange({ target: { name: "fullname", value: truncatedValue } });
                                }}
                                className="form-control"
                            />




                            <label htmlFor="nric" className="form-label">NRIC</label>
                            <input
                                type="text"
                                required
                                name="nric"
                                value={formData.nric}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/[^A-Za-z0-9\s]/g, ''); // Allow alphanumeric characters and spaces
                                    const truncatedValue = inputValue.substring(0, 12); // Truncate to a maximum of 50 characters




                                    // Update the state with the truncated value
                                    handleInputChange({ target: { name: "nric", value: truncatedValue } });
                                }}
                                placeholder="NRIC"
                                autoComplete="off"
                                className="form-control"
                            />




                            <label htmlFor="email" className="form-label">Email</label>
                            <input type="email" required name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" className="form-control" autocomplete="off" />
                            <label htmlFor="phone" className="form-label">Phone</label>
                            <input
                                type="text"
                                required
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                placeholder="Phone"
                                className="form-control"
                                maxLength="8"
                                pattern="[0-9]{8}"
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}

                            />


                            <label htmlFor="gender" className="form-label">Gender</label>
                            <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                className="form-control"
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            <label htmlFor="dob" className="form-label">Date of Birth</label>
                            <input type="date" required name="dob" value={formData.dob} onChange={handleInputChange} placeholder="Date of Birth" className="form-control" />








                        </div>
                        <button type="button" className="btn btn-primary" onClick={nextStep}>
                            Next
                        </button>








                    </div>
                );
            case 2:
                return (
                    <div>








                        <div className="row mb-3">
                            <label htmlFor="password" className="form-label">Password</label>
                            <input
    type="password"
    required
    name="password"
    value={formData.password}
    onChange={handleInputChange}
    placeholder="Enter your password"
    className="form-control"
    autoComplete="off"
/>









                            <label htmlFor="address" className="form-label">Address</label>
                            <input type="text" required name="address" value={formData.address}
                                onChange={handleInputChange} placeholder="Address" className="form-control" />
                            <label htmlFor="postalcode" className="form-label">Postal Code</label>
                            <input
    type="number"
    required
    name="postalcode"
    value={formData.postalcode}
    onChange={(e) => {
        const inputValue = e.target.value.replace(/\D/g, ''); // Allow only digits
        const truncatedValue = inputValue.substring(0, 6); // Limit to a maximum of 6 digits

        // Update the state with the truncated value
        handleInputChange({ target: { name: "postalcode", value: truncatedValue } });
    }}
    placeholder="Postal Code"
    className="form-control"
/>





                            <label htmlFor="race" className="form-label">Race</label>
                            <input
                                type="text"
                                required
                                name="race"
                                value={formData.race}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
                                    const truncatedValue = inputValue.substring(0, 20); // Truncate to a maximum of 50 characters




                                    // Update the state with the truncated value
                                    handleInputChange({ target: { name: "race", value: truncatedValue } });
                                }}
                                className="form-control"
                            />




                            <label htmlFor="education" className="form-label">Education</label>
                            <input
                                type="text"
                                required
                                name="education"
                                value={formData.education}
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
                                    const truncatedValue = inputValue.substring(0, 20); // Truncate to a maximum of 50 characters




                                    // Update the state with the truncated value
                                    handleInputChange({ target: { name: "education", value: truncatedValue } });
                                }}
                                className="form-control"
                            />




                            <label htmlFor="employmentstatus" className="form-label">Employment Status</label>
                            <select
    required
    name="employmentstatus"
    value={formData.employmentstatus}
    onChange={handleInputChange}
    className="form-control"
>
    <option value="" disabled>Select Employment Status</option>
    <option value="employed">Employed</option>
    <option value="unemployed">Unemployed</option>
    <option value="student">Student</option>
</select>













                        </div>
                        <button className="btn btn-secondary" onClick={(e) => prevStep(e)}>
                            Previous
                        </button>








                        <button type="button" className="btn btn-primary" onClick={(e) => nextStep(e)}>
                            Next
                        </button>
















                    </div>
                );
            case 3:
                return (
                    <div>








                        <div className="row mb-3">
                            <label htmlFor="salary" className="form-label">Salary</label>
                            <input type="number" name="salary" value={formData.salary} onChange={handleInputChange} className="form-control" />
                            <label htmlFor="companyname" className="form-label">Company Name</label>
                            <input type="text" name="companyname" value={formData.companyname} onChange={handleInputChange} className="form-control" />
                            <label htmlFor="corporatecompanysponsored" className="form-label">Corporate Company Sponsored</label>
                            <input type="text" name="corporatecompanysponsored" value={formData.corporatecompanysponsored} onChange={handleInputChange} className="form-control" />








                            <label htmlFor="profileUrl" className="form-label">Profile URL</label>
                            <input type="file" name="file" onChange={handleFileChange}
                                className="form-control" />
                            {selectedFile && <div>Selected File: {selectedFile.name}</div>}








































                        </div>
                        <button className="btn btn-secondary" onClick={prevStep}>
                            Previous
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };




    return (
        <div className="containerw " style={formContainerStyle}>


            <div style={{ padding: '15px' }}>
                <form onSubmit={handleSubmit}>
                    {renderFormStep()}
                </form>
                <ToastContainer />
            </div>
        </div>
    );








};








export default RequestStudentForm;



