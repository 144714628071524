import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../components/Header'

const Creen = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [batches, setBatches] = useState([
    { id: 1, Room: 'B001', unit: '12', Block: 'A', Floor: '2', Street: '2nd block', Buliding:' aa', Post:'3423', WheeChairaction:'1', action: 'edit,delete' },
    { id: 2, Room: 'B701', unit: '22', Block: '1', Floor: '2', Street: '3rd blocklock', Buliding:' aa', Post:'3423', WheeChairaction:'1', action: 'edit,delete' },
    { id: 3, Room: 'B301', unit: '23', Block: '3', Floor: 'e', Street: 'S road', Buliding:' aa',Post:'3423', WheeChairaction:'1', action: 'edit,delete' },
    // Add more batches here
  ]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredBatches = batches.filter(
    (batch) =>
      batch.Room.toLowerCase().includes(search.toLowerCase()) &&
      (filter === '' || batch.unit === filter)
  );

  const handleDelete = (id) => {
    const updatedBatches = batches.filter((batch) => batch.id !== id);
    setBatches(updatedBatches);
  };

  const handlePrevious = () => {
    console.log('Previous clicked!');
    // Add your own custom logic here
  };

  const handleNext = () => {
    console.log('Next clicked!');
    // Add your own custom logic here
  };
  
  return (
    <div className="container-fluid">
      <h1 className="text-center">Course Run (Batches)</h1>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Room"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Room</th>
              <th>Unit</th>
              <th>Block</th>
              <th>Floor</th>
              <th>Street</th>
              <th>Building</th>
              <th>Post</th>   
              <th>Wheelchair Action</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredBatches.map((batch) => (
              <tr key={batch.id}>
                <td>
                  <a >
                    {batch.Room}
                  </a>
                </td>
                <td>
                  <a >
                    {batch.unit}
                  </a>
                </td>
                <td>{batch.Block}</td>
                <td>{batch.Floor}</td>
                <td>{batch.Street}</td>
                <td>{batch.Buliding}</td>
                <td>{batch.Post}</td>
                <td>{batch.WheeChairaction}</td>
                <td>
                  <div>
                    <Link to={`/Newbatches`} className="btn primary">
                      <BsPencilFill /> {/* Edit icon */}
                    </Link>
                    <button className="btn btn-dager" onClick={() => handleDelete(batch.id)}>
                      <BsTrashFill /> {/* Delete icon */}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="pagination">
        <button
          className="btn btn-primary"
          onClick={handlePrevious}
          style={{
            backgroundColor: '#03396c',
            color: '#fff',
            border: 'none',
            padding: '10px 50px',
            borderRadius: '4px',
            cursor: 'pointer',
            marginRight: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          Previous
        </button>
        <button
          className="btn btn-primary"
          onClick={handleNext}
          style={{
            backgroundColor: '#03396c',
            color: '#fff',
            border: 'none',
            padding: '8px 26px',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const ButtonComponent = () => {
  const handleButton1Click = () => {
    console.log('Button 1 clicked!');
    // Add your own custom logic here
  };

  const handleButton2Click = () => {
    console.log('Button 2 clicked!');
    // Add your own custom logic here
  };

  return (
    <div className="container">
      <Link to="/Allv" className="btn btn-primary" style={{ backgroundColor: '#03396c', color: 'white' }}>
        All Venue
      </Link>
      <Link to="/Editv" className="btn btn-success" style={{ backgroundColor: '#03396c', color: 'white' }}>
        New Venue
      </Link>
    </div>
  );
};

const All = () => {
  return (
    <div>
    <Header/>
      <ButtonComponent />
      <Creen />
    </div>
  );
};

export default All;
