import React from 'react';
import Header from '../components/Header'
import './Notification.css'; // Import your custom CSS styles
import  { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const Containerr = () => {
  return (
    <div className="containerw"  style={{marginLeft:'18%', }}>
      <div className="row">
        <div className="col-md-6">
          <div className="container-content">
            <p className="paragraph">Course:</p>
            <p className="paragraph">Course Date:</p>
            <p className="paragraph">Trainers:</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="container-content">
            <p className="paragraph">Batch:</p>
            <p className="paragraph">Student Enrolled:</p>
            <p className="paragraph">Assessors:</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ResponsiveTable = () => {
  const [studentName, setStudentName] = useState('');
  const [tableData, setTableData] = useState([]);

  // Set dummy values for studentName and tableData
  useState(() => {
    setStudentName('John Doe')
    

    setTableData([
      { name: 'Row 1' },
      { name: 'Row 2' },
      { name: 'Row 3' },
    ]);
  }, []);

  const handleDownload = (name) => {
    // Handle download logic
    console.log(`Download ${name}`);
  };

  return (
    <div className="table-responsive"  style={{ maxWidth: "1050px",marginLeft:'18%' }}>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>{studentName}</th>
            <th>C/NYC</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>{studentName}</td>
              <td>{row.name}</td>
              <td>
                <button onClick={() => handleDownload(row.name)} style={{ background: "skyblue" }}>
               <a href='/Update' style={{textDecoration:"none",color:'#03396c'}}>Update AR</a>   
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Manage = () => {
  return (
    <div style={{ backgroundColor: '#99bd9c', minHeight: '100vh' }}>
    
   
  <Containerr/>

  <ResponsiveTable/>

    </div>
  );
};

export default Manage ;
