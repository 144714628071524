import React, { useState, useEffect, useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Ad.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from '../Axiosinstance';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header'
import Loader from '../Loader';




const Tablerow = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
   const [hoveredStudentDetails, setHoveredStudentDetails] = useState(null);
  const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [loading, setLoading] = useState(true);


 


  useEffect(() => {
    axios.get('requeststudent')
      .then(response => {
        setStudents(response.data);
        setLoading(false); // Update loading state when data fetching is complete
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Update loading state in case of an error
      });
  }, []);








  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);






  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };




  const handleApprove = (id) => {
    setSelectedStudentId(id);
    setShowConfirmation(true);
  };








  const confirmApprove = () => {
    if (selectedStudentId) {
      axios.get(`requeststudents/${selectedStudentId}`)
        .then(response => {
          const studentData = response.data;
 
          // Update only the "status" field
          studentData.status = true;
 
          axios.put(`requeststudents/${selectedStudentId}`, studentData)
            .then(() => {
              // If the 'status' turns true, update the 'student' endpoint with the same data
              if (studentData.status === true) {
                const studentToUpdate = { ...studentData };
 
                axios.post('student', studentToUpdate)
                  .then(response => {
                    const sfid = response.data.id;
 
                    const userData = {
                      username: studentData.fullname,
                      password: studentData.password,
                      email: studentData.email,
                      usertype: 1, // Set the usertype as needed
                      profile_Url: studentData.profileUrl,
                      sfid: sfid // Assuming sfid comes from the 'student' response
                      // Other necessary fields
                    };
 
                    axios.post('user', userData)
                      .then(() => {
                        // Perform any additional actions or UI updates
 
                        // Fetch the updated list of students after approval
                        axios.get('requeststudent')
                          .then(response => {
                            setStudents(response.data); // Update the students state
 
                            // Show a success toast notification
                            toast.success('Student approved successfully', {
                              position: 'bottom-right',
                              autoClose: 3000, // Close the notification after 3 seconds
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                          })
                          .catch(error => {
                            console.error('Error fetching updated student list:', error);
                            // Handle error scenarios for fetching updated students
                          });
                      })
                      .catch(error => {
                        console.error('Error updating user data:', error);
                        // Handle error scenarios for 'user' endpoint
                      });
                  })
                  .catch(error => {
                    console.error('Error updating student data:', error);
                    // Handle error scenarios for 'student' endpoint
                  });
              }
            })
            .catch(error => {
              console.error('Error updating student data in requeststudents endpoint:', error);
              // Handle error scenarios for 'requeststudents' endpoint
            });
        })
        .catch(error => {
          console.error('Error fetching student data:', error);
          // Handle error scenarios for fetching student data
        });
    }
 
    setShowConfirmation(false);
    setSelectedStudentId(null);
  };
 






  const cancelApprove = () => {
    setShowConfirmation(false);
    setSelectedStudentId(null);
  };








  const handleDelete = (id) => {
    // Show a confirmation dialog or prompt the user for confirmation
    const confirmDelete = window.confirm("Are you sure you want to delete this student?");








    if (confirmDelete) {
      // Assuming axios is imported in your file
      axios.delete(`requeststudents/${id}`)
        .then(() => {
          // If the deletion is successful, remove the student from the list
          const updatedStudents = students.filter(student => student.id !== id);
          setStudents(updatedStudents);
        })
        .catch(error => {
          console.error('Error deleting student:', error);
          // Handle error scenarios (e.g., showing an error message)
        });
    }
  };


  const handleCancel = () => {
    setSelectedRow(null);
    setShowConfirmation(false);
  };




const handleHover = (studentDetails) => {
    setHoveredStudentDetails(studentDetails);
  };




  const handleLeave = () => {
    setHoveredStudentDetails(null);
  };




  return (
<div className="container-fluid"  style={{ maxWidth: '1200px'}}>
   
     <ToastContainer />
      {showConfirmation && (
        <div className="confirmation-overlay">
          <div className="confirmation-message">
            <p>Approving Student</p>
            <p>Are you sure to approve this student for this batch</p>
            <div className="confirmation-buttons">
              <button
                className=""
                onClick={confirmApprove} // Trigger the approve action








                style={{
                  backgroundColor: '#28a745',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
              >
                Approve
              </button>
              <button
                className=""
                onClick={cancelApprove} // Cancel the action
                style={{
                  backgroundColor: '#dc3545',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>








            </div>








          </div>
        </div>
      )}
      <h2 className="heading" align="center" style={{ color: '  #FFFFFF', fontSize: '28px' , marginTop: '35px' }}>New Registration</h2>
      {loading ? (
        <Loader /> // Display the Loader component while data is being fetched
      ) : (
        <React.Fragment>
      <TableContainer sx={{ maxHeight: 'none' }} component={Paper}>
        <Table >
          <TableHead>
            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
              <TableCell style={{ minWidth: 100 }}>S.No</TableCell>
              <TableCell style={{ minWidth: 170 }}>Name</TableCell>
              <TableCell style={{ minWidth: 150 }}>Phone No</TableCell>
              <TableCell style={{ minWidth: 120 }}>Batch</TableCell>
              <TableCell style={{ minWidth: 120 }}>Status</TableCell>
              <TableCell style={{ minWidth: 120 }}>Action</TableCell>
            </TableRow>
          </TableHead>
     
          <TableBody>
            {students
              .filter((student) => student.status === 'false')
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((student, index) => (
                <TableRow key={student.id}
                  onMouseEnter={() => handleHover(student)}
                  onMouseLeave={handleLeave}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell> <Tooltip title={hoveredStudentDetails ? (
                    <div>
                      <p>Name: {hoveredStudentDetails.fullname}</p>
                      <p>Email: {hoveredStudentDetails.email}</p>
                      <p>Phone: {hoveredStudentDetails.phone}</p>
                      <p>Address: {hoveredStudentDetails.address}</p>
                    </div>
                  ) : ''} arrow>
                    <span>{student.fullname}</span>
                  </Tooltip></TableCell>
                  <TableCell>{student.phone}</TableCell>
                  <TableCell><TableCell>
                    {student.batch ? student.batch : 'General'}
                  </TableCell>
                  </TableCell>
                  <TableCell>{student.status === 'false' ? 'Pending' : student.status}</TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={() => handleApprove(student.id)}
                        style={{ backgroundColor: '#28a745', color: '#fff' }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <div style={{ height: '50px', borderRight: '1px solid #ccc', margin: '0 10px' }}></div>
                      <IconButton
                        onClick={() => handleDelete(student.id)}
                        style={{ backgroundColor: '#dc3545', color: '#fff' }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={students.filter((student) => student.status === 'false').length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> </TableContainer>
    </React.Fragment>
      )}
    </div>
  );
};










const Batches = () => {
     
    return (
      <div >
        <Header />
        <div style={{ padding: '20px 20px'}}>


        <Tablerow />


        </div>
     
         
      </div>
    );
  };
 
  export default Batches;
 

