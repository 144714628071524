import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import axios from '../../Axiosinstance'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-bootstrap/Modal';








const API_URL = 'studentfeedback';
const API_URL1 = 'studentfeedbacks';




const General = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [Question, setQuestion] = useState('');
  const [Type, setType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newType, setNewType] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);








  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastStudent = currentPage * itemsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - itemsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);






  // useEffect for fetching student feedback
  useEffect(() => {
    const fetchStudentFeedback = async () => {
      try {
        const response = await axios.get(API_URL1);
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching student feedback:', error);
      }
    };




    fetchStudentFeedback();
  }, []);




  // useEffect for filtering students based on searchQuery and selectedType
  useEffect(() => {
    const filtered = students.filter(student => {
      const lowercaseQuery = searchQuery.toLowerCase();
      return (
        student.questions.toLowerCase().includes(lowercaseQuery) &&
        (selectedType === '' || student.type === parseInt(selectedType))
      );
    });




    setFilteredStudents(filtered);
  }, [searchQuery, students, selectedType]);




  // Functions for handling edit, delete, save changes, and create
  const handleEdit = (id) => {
    const student = students.find((student) => student.id === id);
    if (student) {
      setSelectedStudent(student);
      setQuestion(student.questions);
      setType(student.type !== undefined ? student.type.toString() : '');
    }
  };




  const handleCloseModal = () => {
    setSelectedStudent(null);
    setShowSuccessMessage(false);
    setQuestion('');
    setType('');
    setShowCreateModal(false);
    setNewQuestion('');
    setNewType('');
  };




  const handleDelete = async (id) => {
    try {
      const confirmed = window.confirm('Are you sure you want to delete the student feedback?');
      if (!confirmed) {
        return;
      }




      await axios.delete(`${API_URL}/${id}`);
      setStudents((prevStudents) => prevStudents.filter((student) => student.id !== id));




      // Show delete notification
      toast.success('Student feedback deleted successfully');
    } catch (error) {
      console.error('Error deleting student feedback:', error);
    }
  };




  const handleSaveChanges = async () => {
    try {
      const confirmed = window.confirm('Are you sure you want to update the general feedback?');
      if (!confirmed) {
        return; // If not confirmed, exit the function
      }
      const updatedStudent = {
        questions: Question,
        type: parseInt(Type),
      };




      await axios.put(`${API_URL}/${selectedStudent.id}`, updatedStudent);




      const updatedStudents = students.map((student) =>
        student.id === selectedStudent.id ? { ...student, questions: Question, type: parseInt(Type) } : student
      );




      setStudents(updatedStudents);
      setSelectedStudent(null);
      setShowSuccessMessage(true);




      setQuestion('');
      setType('');
      toast.success('general feedback updated successfully');


    } catch (error) {
      console.error('Error updating student feedback:', error);
    }
  };




  const handleCreate = async (e) => {
    e.preventDefault();




    if (newQuestion && newType) {
      const newStudent = {
        questions: newQuestion,
        type: parseInt(newType), // Convert the type to an integer
      };




      try {
        const confirmed = window.confirm('Are you sure you want to save the general feedback?');
        if (!confirmed) {
          return; // If not confirmed, exit the function
        }
        const response = await axios.post(API_URL, newStudent);
        const createdStudent = response.data;




        setStudents((prevStudents) => [...prevStudents, createdStudent]);
        setShowSuccessMessage(true);




        // Reset the form fields
        setNewQuestion('');
        setNewType('');
        setShowCreateModal(false);
        toast.success('general feedback created successfully');


      } catch (error) {
        console.error('Error creating student feedback:', error);
      }
    }
  };




  return (
    <div className="container" style={{ maxWidth: '1200px' }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>


        <div className="row">
          <div className="col-md-10">
            <div className="input-group mb-3">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control"
                style={{
                  padding: '10px 12px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  marginRight: '10px',
                  fontSize: '16px',
                  width: '150px', // Adjust the width as needed
                }}
              />
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="form-control ml-2"
                style={{
                  width: '150px', // Adjust the width as needed
                }}
              >
                <option value="">Select Type</option>
                <option value="2">checkbox</option>
                {/* <option value="1">Short Answer</option> */}
              </select>




              <Button


                onClick={() => setShowCreateModal(true)}
                color="primary"
                variant="contained"
                style={{
                  width: '150px', // Adjust the width as needed
                  // marginRight: '2rem'
                }}


              >
                Create New
              </Button>
            </div>
          </div>


        </div>








        <TableContainer sx={{ maxHeight: 'none' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Serial Number</TableCell>
                <TableCell align="center">Question</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentStudents.map((student, index) => (
                <TableRow key={student.id}>
                  <TableCell align="center">{indexOfFirstStudent + index + 1}</TableCell>
                  <TableCell align="center">{student.questions}</TableCell>
                  <TableCell align="center">{student.type === 1 ? 'Short answer' : 'Rating'}</TableCell>
                  <TableCell align="center">




                    <BsPencilFill style={{ marginRight: 10 }} onClick={() => handleEdit(student.id)} />


                    <DeleteRoundedIcon style={{ color: 'red', marginLeft: 10 }} onClick={() => handleDelete(student.id)} />




                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredStudents.length}
          rowsPerPage={itemsPerPage}
          page={currentPage - 1}
          onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
          onRowsPerPageChange={(event) => {
            setItemsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(1);
          }}
        />


        <Modal show={selectedStudent !== null} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit General</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSaveChanges}>
              <div className="form-group">
                <label htmlFor="question">Question</label>
                <input
                  type="text"
                  className="form-control"
                  id="question"
                  value={Question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>
              <div className="form-group  mb-2">
                <label htmlFor="type">Type</label>
                <select
                  className="form-control"
                  id="type"
                  value={Type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="">Select a type</option>
                  {/* <option value="1">Short answer</option> */}
                  <option value="2">checkbox</option>
                </select>
              </div>
            </form>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveChanges} color="primary">
              Save Changes
            </Button>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
          </Modal.Body>
        </Modal>


        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create general</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCreate}>
              <div className="form-group">
                <label htmlFor="newQuestion">Question</label>
                <input
                  type="text"
                  className="form-control"
                  id="newQuestion"
                  placeholder="Enter the question"
                  value={newQuestion}
                  onChange={(e) => setNewQuestion(e.target.value)}
                />
              </div>
              <div className="form-group  mb-2">
                <label htmlFor="newType">Type</label>
                <select
                  className="form-control"
                  id="newType"
                  value={newType}
                  onChange={(e) => setNewType(e.target.value)}
                >
                  <option value="">Select a type</option>
                  {/* <option value="1">Short answer</option> */}
                  <option value="2">checkbox</option>
                </select>
              </div>
            </form>
            <button class="btn btn-primary btn-sm" onClick={handleCloseModal} >
              Cancel
            </button>
            <button class="btn btn-primary btn-sm" onClick={handleCreate}>
              Create
            </button>
            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
          </Modal.Body>
        </Modal>
      </Paper>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />


    </div>
  );
};




export default General;

