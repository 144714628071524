import React from 'react';
import TeachHeader from './TeachHeader'
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    backgroundColor: '#abdeda',
    marginBottom: '20px',
  },
  logo: {
    fontSize: '34px',
    fontWeight: 'bold',
    marginRight: '20px',
  },
  headerList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
  },
  headerListItem: {
    marginLeft: '20px',
  },
  navigationDropdown: {
    width: 120,
    height: 50,
    display: 'flex',
    backgroundColor: '#abdeda',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0 10px',
  },
  navigationButton: {
    width: 120,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0 10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'lightblue',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  containerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paragraph: {
    margin: '0 10px',
  },
  containerr: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'lightblue',
    height: '100px', // Add the desired height value here
  },
  containerContentr: {
    display: 'flex',
    justifyContent: 'space-between', // Arrange the items with space between them
  },
  paragraphr: {
    margin: '0 250px',
  },
};

const Container = () => {
  return (
    <div className="containerw">
      <div className="row">
        <div className="col-md-4 mt-3">
          <div className="container-content">
            <p className="paragraph">Course:</p>
            <p className="paragraph">Course Date:</p>
            <p className="paragraph">Trainers:</p>
          </div>
        </div>

        <div className="col-md-4 mt-3">
          <div className="container-content">
            <p className="paragraph">Batch:</p>
            <p className="paragraph">Student Enrolled:</p>
            <p className="paragraph">Assessors:</p>
          </div>
        </div>
      </div>
    </div>
  );
};


const Myattandance = () => {
  const handleSignClick = () => {
    // Perform the sign action here
    window.open('/empty-page', '_blank');
  };

    return (
      <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
      <TeachHeader />
      <Container />
      <div style={{ marginTop: '20px', marginLeft: '18%', width: '54.5%' }}>
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%', // Adjusted width
          }}
        >
            <tbody>
              <tr>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  Trainer Name
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  AM:
                  <select style={{ width: '100%' }}>
                    <option>9:00</option>
                    <option>10:00</option>
                    <option>11:00</option>
                  </select>
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  PM:
                  <select style={{ width: '100%' }}>
                    <option>1:00</option>
                    <option>2:00</option>
                    <option>3:00</option>
                  </select>
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  Date:
                  <input type="date" style={{ width: '100%' }} />
                </td>
              </tr>
              <tr>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  Test Teacher
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px' }}>
                  <button onClick={handleSignClick}>
                    <FontAwesomeIcon icon={faPen} /> Sign
                  </button>
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px', color: 'blue' }}>
                  <button onClick={handleSignClick}>
                    <FontAwesomeIcon icon={faPen} /> Sign
                  </button>
                </td>
                <td style={{ border: '1px solid black', padding: '10px', minWidth: '150px', color: 'blue' }}>
                  <button>Submit</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
export default Myattandance;
