import React, { useState } from 'react'
import product5Image from './images/girl-laptop.png';
import Carousel from 'react-elastic-carousel';
import styled from 'styled-components';
// Import the product images
import laptopImage from './images/girl-laptop.png';
import product2Image from './images/girl-laptop.png';
import product3Image from './images/girl-laptop.png';
import product4Image from './images/girl-laptop.png';
import { IoArrowForward } from 'react-icons/io5';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Photo from './images/teacher-explaining.png';
import './Universities.css';
import SkillsDashboardImage from './images/SkillsDashboard_1-5x.png'
import Student from './images/student.jpg'
import InDemandSkills from './images/InDemandSkills_1_5x.png'
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,

  MDBCheckbox,

}
  from 'mdb-react-ui-kit';

  import { Grid, Card, CardContent, Typography, TextField, Checkbox, Button } from '@mui/material';


const Demonstrate = () => {
  return (
    <div className="your-new-component">
      <div className="left-content">
        <h2 style={{ fontSize: '16px' }}>SHOW ROI</h2>
        <h2>Demonstrate the value of learning</h2>
        <p>Use comprehensive metrics and insights to inform, measure, and assess your talent development strategy impact.</p>
        <ul className='custom-list '>
          <li>  Track real-time skill development and learning activity of everyone in your company</li>
          <li>  Gain industry-specific insights to understand the fast-changing skills landscape</li>
          <li>  See every employee’s proficiency in 117+ technology, data, and business skills</li>
          <li> Evaluate your skills against industry benchmarks using verified performance data</li>
        </ul>
        <p>With knowledge sharing and continued learning, we can increase productivity and keep delivering excellent client services that support our revenue goals.</p>
      </div>
      <div className="right-image">
        <img src={SkillsDashboardImage} alt="Skills Dashboard" />
      </div>
    </div>
  );
};

const Strengthen = () => {
  const componentStyles = {
    listStyle: 'none',
  };

  return (
    <div className="your-new-component">
      <div className="left-content">
        <h2>Strengthen critical skills across every team</h2>
        <p>Increase business impact and agility with in-demand tech, data, and leadership skills.</p>
        <ul className='custom-list '>
          <li>
            Use role-based assessments to identify skills gaps and advancement opportunities
          </li>
          <li>
            Advance skills in roles that drive rapid transformation for your company
          </li>
          <li>
            Enhance leadership skills to engage, navigate, and motivate employees amid change
          </li>
          <li>
            Learn and apply skills faster with hands-on projects and immersive learning
          </li>
        </ul>
        <p>
          What I’ve learned through impulz allows me to better guide and inform the professional data analysts on my team to solve tough business problems and accelerate the development of our digital capabilities.
        </p>
      </div>
      <div className="right-image">
        <img src={InDemandSkills} alt="InDemandSkills" />
      </div>
    </div>
  );
};

const Develop = () => {
  return (

    <div className="your-new-component2">

      <div className="right-image">
        <img src={Student} alt="Student" />
      </div>
      <div className="left-content">
        <h2 style={{ fontSize: '16px' }}>RETAIN TALENT</h2>
        <h2>Develop, retain, and advance your talent</h2>
        <p>Be the company people want to join, stay at, and find their next move with.</p>
        <ul className='custom-list '>
          <li>
            Provide high-quality career development, from entry-level to C-suite roles
          </li>
          <li>
            Use verified skill performance data to identify potential candidates for open roles
          </li>
          <li>
            Retrain employees whose roles are impacted by emerging technologies
          </li>
        </ul>
        <p>Training like this allows us to attract, develop, and retain the top talent that helps Adobe maintain its reputation as a technology leader.</p>
      </div>

    </div>
  );
};

const Form = () => {




  const [checked, setChecked] = useState(false);
  return (
    <div>
      <div className="your-new-component2" style={{ marginTop: '-7%'}}>
        <MDBContainer fluid className="p-4 ">
        <MDBRow className="align-items-center">
            <MDBCol md="6" className="text-center text-md-start d-flex flex-column ">
              <h1 className="my-5 display-3 fw-bold ls-tight px-3">
                Find out how Impulz <br />
                <span style={{ color: '#f48c06', fontSize: '34px' }} className="text">high-impact skill development</span>
              </h1>


              <h2 style={{ color: '#000' }}>Learn more about how you can:</h2>
              <ul className='custom-list '>
                <li>  Deliver on strategic business goals by building critical skills</li>
                <li>  Understand the full value of technology investments</li>
                <li> Build a data-driven, digitally-fluent workforce</li>
              </ul>
            </MDBCol>


            <MDBCol md="6">
            <Grid container justifyContent="center" mt={10}>
      <Grid item md={6}>




        <Card className="my-5 custom-card">
          <CardContent className="p-5">
           




          <Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
    <div className="custom-input">
      <Typography>First name</Typography>
      <TextField id="form1" variant="outlined" fullWidth />
    </div>
  </Grid>


  <Grid item xs={12} sm={6}>
    <div className="custom-input">
      <Typography>Last name</Typography>
      <TextField id="form2" variant="outlined" fullWidth />
    </div>
  </Grid>


  <Grid item xs={12} sm={6}>
    <div className="custom-input">
      <Typography>*Work Email Address:</Typography>
      <TextField id="form5" variant="outlined" fullWidth />
    </div>
  </Grid>


  <Grid item xs={12} sm={6}>
    <div className="custom-input">
      <Typography>*Phone Number:</Typography>
      <TextField id="form6" variant="outlined" fullWidth />
    </div>
  </Grid>


  <Grid item xs={12} sm={6}>
    <div className="custom-input">
      <Typography>Job Title</Typography>
      <TextField id="form7" variant="outlined" fullWidth />
    </div>
  </Grid>




      <Grid item xs={12} sm={6}>
        <div style={{ display: 'flex', alignItems: 'center',  marginTop: ''}}>
        <Checkbox id="flexCheckDefault" />
          <Typography variant="body1">Do you work for a government or nonprofit agency?</Typography>
         
        </div>
      </Grid>
 






             
            </Grid>
            <TextField id="form3" variant="outlined" fullWidth label="Country" className="custom-input mb-4" />
            <TextField id="form4" variant="outlined" fullWidth label="Which best describes your needs" className="custom-input mb-4" />
            <div className="text-start">
              <Typography>
                By submitting your info in the form above, you agree to our Terms of Use and Privacy Notice. We may use this info to contact you and/or use data from third parties to personalize your experience.
              </Typography>
              <Button variant="contained" color="primary" size="large" className="mb-4">
                Submit
              </Button>
            </div>
          </CardContent>
        </Card>
      </Grid>
      {/* <Grid item md={6}>
        <div data-mdb-input-init className="form-outline">
          <TextField id="form8Example2" variant="outlined" fullWidth label="Email address" />
        </div>
      </Grid> */}
    </Grid>
    </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    </div>
  )
}



const Talent = () => {
  return (
    <div>
      <div className="your-new-component2">
        <MDBContainer fluid className='p-4'>
          <MDBRow>
            <MDBCol md='6' className='text-start text-md-start d-flex flex-column justify-content-start align-items-start'>
              <h2 className='my-5 display-3 fw-bold ls-tight px-3'>
                Make talent your <br />
                <span className='text-primary'>competitive advantage</span>
              </h2>
              <div style={{ margin: '5px' }} >


                <ul className='custom-list '>
                  <li>Strengthen critical skills with content you can trust</li>
                  <li>Develop, retain, and advance critical talent</li>
                  <li>Lower training costs without sacrificing quality</li>
                  <li>Track and measure skills to demonstrate ROI</li>
                </ul>
                <br />
                <div className=''>
                  <Button color='primary' className='me-3 btn-lg'>
                    Contact Us
                  </Button>
                  <Button outline color='primary' className='position-relative btn-lg'>
                    Compare Plans
                    <IoArrowForward className='ms-2' />
                  </Button>
                </div>

                <br />
                <h3>Upskilling fewer than 125 employees?</h3>

                <h4 className='underline-link'>
                  Get Coursera for Teams
                  <BsBoxArrowUpRight className='ms-2' />
                </h4>


              </div>
            </MDBCol>
            {/* Right-side image */}
            <MDBCol md='6' className='d-flex justify-content-center align-items-center z-0'>
              <img src={Photo} alt='University' className='img-fluid' style={{ width: '80%', height: '80%', borderRadius: '10px' }} />
            </MDBCol>


          </MDBRow>
          <div className='black-box z-1 '>
            <div className='box-content'>
              <h4>24%</h4>
              <p>Reduction in training costs</p>
            </div>
            <div className='vertical-line'></div>
            <div className='box-content'>
              <h4>38%</h4>
              <p>Higher retention rates</p>
            </div>
            <div className='vertical-line'></div>
            <div className='box-content'>
              <h4>25%</h4>
              <p>More employee productivity</p>

            </div>
          </div>


        </MDBContainer>
      </div>

    </div>
  )
}




const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];


const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 250px;
`;


const ProductImage = styled.img`
  max-width: 200px; /* Reduce image width to 200px */
  max-height: 150px; /* Adjust as needed to maintain aspect ratio */
  object-fit: cover; /* Prevent distortion of images */
`;


const ProductContent = styled.div`
  text-align: center;
  padding: 10px;
`;


const Price = styled.p`
  color: #007bff; /* Set the color of the price */
  font-weight: bold;
`;


const Rating = styled.p`
  color: #28a745; /* Set the color of the rating */
  font-weight: bold;
`;


function ProductCard({ image, title, description, price, rating, link }) {
  return (
    <StyledProductCard>
      <ProductImage src={image} alt={title} />
      <ProductContent>
        <h3>{title}</h3>
        <p>{description}</p>
        <Price>Price: ${price}</Price>
        <Rating>Rating: {rating}</Rating>
        <a href={link}>Learn More</a>
      </ProductContent>
    </StyledProductCard>
  );
}


function Coarusel() {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Leverage Job-aligned curations</h1>
      <div className="App">
        <Carousel breakPoints={breakPoints}>
          <ProductCard
            image={laptopImage}
            title="Course 1"
            description="Learn Web Development"
            price={49.99}
            rating={4.5}
            link="/product/course1"
          />
          <ProductCard
            image={product2Image}
            title="Course 2"
            description="Master Data Science"
            price={59.99}
            rating={4.2}
            link="/product/course2"
          />
          <ProductCard
            image={product3Image}
            title="Course 3"
            description="Explore Digital Marketing"
            price={39.99}
            rating={4.8}
            link="/product/course3"
          />
          <ProductCard
            image={product4Image}
            title="Course 4"
            description="Learn Mobile App Development"
            price={54.99}
            rating={4.4}
            link="/product/course4"
          />
          <ProductCard
            image={product5Image}
            title="Course 5"
            description="Master UI/UX Design"
            price={44.99}
            rating={4.6}
            link="/product/course5"
          />
          {/* Add more ProductCard components for other courses */}
        </Carousel>
      </div>
    </>
  );
}


const Business = () => {
  return (
    <div style={{ marginTop: '100px' }}>
      <Strengthen />
   
      <Demonstrate />
      <Coarusel />
      <Talent />

      <Develop />
      <Form />

    </div>
  )
}

export default Business
