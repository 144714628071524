import React, { useState } from 'react';
import Header from '../../components/Header'

const NavigationBar = () => {
  
    return (
      <div className="navigation-buttons">
      
        <div className="navigation-buttons">
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
          </button>
          <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
          </button>
          <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
          </button>
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
          </button>
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
          </button>
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
          </button>
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
          </button>
          
          <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
          </button>
          <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
            <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
          </button>
        </div>
      </div>
    );
  };
  

const ImageUploader = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // Process the selected file as needed
    setSelectedImage(URL.createObjectURL(file));
  };

  return (
    <div>
     
      <input type="file" accept="image/*" onChange={handleImageChange} />
      {selectedImage && (
        <div>
          <h4>Selected Image:</h4>
          <img src={selectedImage} alt="Selected" />
        </div>
      )}
    </div>
  );
};

const GeneralSettingsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    logo: '',
    email: '',
    phone: '',
    server: '',
    organization: '',
    clientId: '',
    clientSecret: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogoSelection = (selectedLogo) => {
    setFormData((prevState) => ({
      ...prevState,
      logo: selectedLogo,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <div className="container" style={{ border: '1px solid #ccc', borderRadius: '18px'  }}>
      <h2>General Settings</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="companyName">Company Name:</label>
          <input
            type="text"
            className="form-control"
            id="companyName"
            name="companyName"
            placeholder="Enter your company name"
            value={formData.companyName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="logo">Logo:</label>
          <ImageUploader />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            name="phone"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="server">SSG Server:</label>
          <input
            type="text"
            className="form-control"
            id="server"
            name="server"
            placeholder="Enter SSG server"
            value={formData.server}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="organization">Organization:</label>
          <input
            type="text"
            className="form-control"
            id="organization"
            name="organization"
            placeholder="Enter organization"
            value={formData.organization}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientId">Client ID:</label>
          <input
            type="text"
            className="form-control"
            id="clientId"
            name="clientId"
            placeholder="Enter client ID"
            value={formData.clientId}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="clientSecret">Client Secret:</label>
          <input
            type="password"
            className="form-control"
            id="clientSecret"
            name="clientSecret"
            placeholder="Enter client secret"
            value={formData.clientSecret}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary" style={{ padding: '5px 3px', fontSize: '12px' }}>
  Save
</button>

      </form>
    </div>
  );
};



const SmtpConfig = () => {
    const [smtpHost, setSmtpHost] = useState('');
    const [smtpUsername, setSmtpUsername] = useState('');
    const [smtpPassword, setSmtpPassword] = useState('');
    const [smtpPort, setSmtpPort] = useState('');
  
    const handleSave = () => {
      // Save the SMTP configuration logic here
      console.log('SMTP configuration saved!');
    };
  
    return (
        <div className="container" style={{ border: '1px solid #ccc', borderRadius: '20px' }}>
        <h1>SMTP Configuration</h1>
        <form>
          <div className="form-group">
            <label htmlFor="smtpHost">SMTP Host</label>
            <input
              type="text"
              className="form-control"
              id="smtpHost"
              placeholder="Enter SMTP Host"
              value={smtpHost}
              onChange={(e) => setSmtpHost(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="smtpUsername">SMTP Username</label>
            <input
              type="text"
              className="form-control"
              id="smtpUsername"
              placeholder="Enter SMTP Username"
              value={smtpUsername}
              onChange={(e) => setSmtpUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="smtpPassword">SMTP Password</label>
            <input
              type="password"
              className="form-control"
              id="smtpPassword"
              placeholder="Enter SMTP Password"
              value={smtpPassword}
              onChange={(e) => setSmtpPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="smtpPort">SMTP Port</label>
            <input
              type="text"
              className="form-control"
              id="smtpPort"
              placeholder="Enter SMTP Port"
              value={smtpPort}
              onChange={(e) => setSmtpPort(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSave}
          >
            Save
          </button>
        </form>
      </div>
    );
  };
  
const Bar = () => {
  return (
    <div>
    <Header/>
      <NavigationBar />
   

<div className="container">
        <div className="row">
          <div className="col-md-6">
          <GeneralSettingsForm />
          </div>
          <div className="col-md-6">
          <SmtpConfig/>

          </div>
          <div className="col-md-6">
        
          </div>
        </div>
      </div>




    </div>
  );
};

export default Bar;
