import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from '../Axiosinstance';
import Header from '../components/Header';
import * as XLSX from 'xlsx';


const EditStudent = () => {
  const navigate = useNavigate();
  const { studentId } = useParams();
  const [isFormDirty, setIsFormDirty] = useState(false); 

  const [student, setStudent] = useState({
    fullname: '',
    email: '',
    phone: '',
    gender: '',
    dob: '',
    password: '',
    address: '',
    postalcode: '',
    race: '',
    eduction: '',
    employmentstatus: '',
    salary: '',
    companyname: '',
    corporatecompanysponsored: '',
    designation: '',
    nric:'',
    profileUrl:'',
  });

  useEffect(() => {
    loadStudent();
  }, []);

  const loadStudent = async () => {
    try {
      const response = await axios.get(`student/${studentId}`);
      setStudent(response.data);
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };

  const handleInputChange = (e) => {
    setStudent({ ...student, [e.target.name]: e.target.value });
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`student/${studentId}`, student);
      navigate('/studentlink');
    } catch (error) {
      console.error(error);
      // Handle error if needed
    }
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };


    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  return (
    <div className="container" style={{ maxWidth: '1000px'}}>
        <NavigationButtons />
      <h2>Edit Student</h2>
      <form onSubmit={handleSubmit} 
onChange={handleInputChange}
>
        <div className="form-group">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            id="fullName"
            name="fullname" // Change name to "fullname"
    className="form-control"
    value={student.fullname} // Change value to "student.fullname"
    onChange={handleInputChange} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={student.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="nric">NRIC</label>
          <input
            type="text"
            id="nric"
            name="nric"
            className="form-control"
            value={student.nric}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            className="form-control"
            value={student.phone}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            className="form-control"
            value={student.gender}
            onChange={handleInputChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="dob">Date of Birth</label>
          <input
            type="date"
            id="dob"
            name="dob"
            className="form-control"
            value={student.dob}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            value={student.password}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            className="form-control"
            value={student.address}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="postalcode">Postal Code</label>
          <input
            type="text"
            id="postalcode"
            name="postalcode"
            className="form-control"
            value={student.postalcode}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="race">Race</label>
          <input
            type="text"
            id="race"
            name="race"
            className="form-control"
            value={student.race}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="education">Education</label>
          <input
            type="text"
            id="education"
            name="eduction"
            className="form-control"
            value={student.eduction}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="employmentstatus">Employment Status</label>
          <input
            type="text"
            id="employmentstatus"
            name="employmentstatus"
            className="form-control"
            value={student.employmentstatus}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="salary">Salary</label>
          <input
            type="text"
            id="salary"
            name="salary"
            className="form-control"
            value={student.salary}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="companyname">Company Name</label>
          <input
            type="text"
            id="companyname"
            name="companyname"
            className="form-control"
            value={student.companyname}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="corporatecompanysponsored">Corporate Company Sponsored</label>
          <input
            type="text"
            id="corporatecompanysponsored"
            name="corporatecompanysponsored"
            className="form-control"
            value={student.corporatecompanysponsored}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="designation">Designation</label>
          <input
            type="text"
            id="designation"
            name="designation"
            className="form-control"
            value={student.designation}
            onChange={handleInputChange}
          />
        </div>
        <br/>
        <div style={{  display: 'flex' , alignContent: 'end' , justifyContent: 'flex-end'}}>
        <button type="submit" className="btn btn-primary">
          Update
        </button>
        <Link to="/studentlink" className="btn btn-secondary">
          Cancel
        </Link>
        </div>
      </form>
    </div>
  );
};



const NavigationButtons = () => {

  const [students, setStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [password, setPassword] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [pageSize, setPageSize] = useState(10);


  useEffect(() => {
    fetchStudents();
  }, []);


  const fetchStudents = async () => {
    try {
      const response = await axios.get('students');
      setStudents(response.data);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };
  


  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };


  const handleFilter = (e) => {
    setFilterGender(e.target.value);
  };


  const handleDelete = async (id) => {
    try {
      await axios.delete(`student/${id}`);
      setStudents((prevStudents) => prevStudents.filter((student) => student.id !== id));
    } catch (error) {
      console.error('Error deleting student:', error);
    }
  };
  


  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };


  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };


  const handleBulkDownload = () => {
    const filteredStudents = students.filter((student) => {
      if (filterGender) {
        return student.gender.toLowerCase() === filterGender.toLowerCase();
      }
      return true;
    });


    const searchFilteredStudents = filteredStudents.filter((student) => {
      const searchQueryLower = searchQuery.toLowerCase();
      return (
        student.id.toString().toLowerCase().includes(searchQueryLower) ||
        student.fullname.toLowerCase().includes(searchQueryLower) ||
        student.email.toLowerCase().includes(searchQueryLower) ||
        student.phone.toLowerCase().includes(searchQueryLower)
      );
    });


    const paginatedStudents = searchFilteredStudents.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );


    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(paginatedStudents);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });


    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'students.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const filteredStudents = students.filter((student) => {
    if (filterGender) {
      return student.gender.toLowerCase() === filterGender.toLowerCase();
    }
    return true;
  });


  const searchFilteredStudents = filteredStudents.filter((student) => {
    const searchQueryLower = searchQuery.toLowerCase();
    return (
      student.id.toString().toLowerCase().includes(searchQueryLower) ||
      student.fullname.toLowerCase().includes(searchQueryLower) ||
      student.email.toLowerCase().includes(searchQueryLower) ||
      student.phone.toLowerCase().includes(searchQueryLower)
    );
  });


  const paginatedStudents = searchFilteredStudents.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );

  return (
    <div className="navigation-buttons">
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <Link to="/Allstudent" style={{ textDecoration: 'none', color: 'white' }}>
          All Student
        </Link>
      </button>
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <Link to="/Newstudent" style={{ textDecoration: 'none', color: 'white' }}>
          New student
        </Link>
      </button>
      {/* <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <Link to="/Bulkupload" style={{ textDecoration: 'none', color: 'white' }}>
          Bulk upload
        </Link>
      </button> */}
     {/* <button  onClick={handleBulkDownload}
          style={{
            backgroundColor: '#03396c',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '4px',
            margin: '5px',
            textDecoration: 'none',
            cursor: 'pointer',
           
          }}>
            Bulk Download
          </button> */}
    </div>
  );
};

const editStudenth = () => {
  return (
    <div>
      <Header />
    
      <EditStudent />
    </div>
  );
};





export default editStudenth;
