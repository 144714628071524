import React, { useEffect, useState } from "react";
import axios from "./Axiosinstance";
import { Link, useParams, useNavigate } from "react-router-dom";
import logo from './All/images/logo1.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {logoImage} from './common'



export default function Home() {
  const [users, setUsers] = useState([]);
  const [loginPassword, setLoginPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showIncorrectAlert, setShowIncorrectAlert] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filterValue, setFilterValue] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [showPassword, setShowPassword] = useState(false);


  const { id } = useParams();
  const navigate = useNavigate();





  const loadUsers = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
    const userId = localStorage.getItem('userId');
    const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
  
    try {
      const response = await axios.get(`home/user/${userId}`, { headers });
      const userData = response.data;
  
      // Check if the user ID from local storage matches any user ID from the API response
      const matchedUser = userData;
      if (matchedUser) {
        if (matchedUser.usertype === 1) {
          try {
            navigate("/Dashboard");
            localStorage.setItem("usertype", matchedUser.usertype);
            localStorage.setItem("loggedInUserId", matchedUser.id);
            localStorage.setItem("studentteacherid", matchedUser.sfid);


           
            window.location.reload();
          } catch (error) {
            console.log("Error navigating to Dashboard:", error);
          }
        } else if (matchedUser.usertype === 2) {
          try {
            navigate("/Teacher");
            localStorage.setItem("usertype", matchedUser.usertype);
            localStorage.setItem("loggedInUserId", matchedUser.id);
            localStorage.setItem("studentteacherid", matchedUser.sfid);
           
            window.location.reload();
          } catch (error) {
            console.log("Error navigating to Dashboard:", error);
          }
        } else if (matchedUser.usertype === 3) {
          try {
            navigate("/Adhome");
            localStorage.setItem("usertype", matchedUser.usertype);
            localStorage.setItem("loggedInUserId", matchedUser.id);
          
            window.location.reload();
          } catch (error) {
            console.log("Error navigating to Adhome:", error);
          }
        }
        // console.log("User ID from local storage matches a user ID from the API:", matchedUser);
      } else {
        console.log("User ID from local storage does not match any user ID from the API");
      }
  
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  



  const handleLogin = async () => {
    // Display the spinner when attempting to log in
    setShowAlert(true);
    setShowIncorrectAlert(false);
  
    try {
      const response = await axios.post("auth/login", {
        email: loginEmail,
        password: loginPassword
      });
  
      const { jwtToken, userId, username,name } = response.data;
  
      // Save JWT token to local storage
      localStorage.setItem("jwtToken", jwtToken);
      localStorage.setItem("userId", userId);
      localStorage.setItem("username", username);
      localStorage.setItem("name", name);
  
      setShowAlert(false);
  
      // Redirect to "/Adhome" after successful login
   
  
      // Fetch user details after successful login
      loadUsers();
      // navigate("/Adhome");
    } catch (error) {
      console.error("Login error:", error);
      setShowAlert(false);
      setShowIncorrectAlert(true);
    }
  };
  

  


  const filteredUsers = users.filter((user) => {
    return (
      user.email.includes(searchInput) || user.username.includes(searchInput)
    );
  });


  const displayedUsers =
    filterValue === "All" ? filteredUsers : filteredUsers.slice(0, parseInt(filterValue));


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentUsers = displayedUsers.slice(startIndex, endIndex);


  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };



  const validateForm = () => {
    if (loginEmail.trim() === "" || loginPassword.trim() === "") {
      alert("Please enter email and password");
    } else {
      handleLogin();
    }
  };


  const styles = {


    row: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
    },
    column: {
      width: "100%",
      maxWidth: "100%",
    },
    card: {
      borderRadius: "10px",
      padding: "20px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      maxWidth: '150%',
      width: '140%',
      Marginright: '5%'
    },
    loginCard: {
      backgroundColor: "#fff",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: '15%'

    },
    logo: {

      width: "150px",
      justifyContent: "left",

    },
    successAlert: {
      backgroundColor: "#d4edda",
      color: "#155724",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "5px",
    },
    errorAlert: {
      backgroundColor: "#f8d7da",
      color: "#721c24",
      padding: "10px",
      marginBottom: "10px",
      borderRadius: "5px",
    },
    heading: {
      marginBottom: "20px",
    },
    formGroup: {
      marginBottom: "15px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    passwordInput: {
      display: "flex",
      alignItems: "center",
    },
    togglePasswordButton: {
      marginLeft: "-30px", // Adjust the margin to move the eye icon inside the password field
      padding: "5px 10px",
      borderRadius: "5px",
      backgroundColor: "#f0f0f0",
      border: "none",
      cursor: "pointer",
    },
    loginButton: {
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      padding: "10px 20px",
      cursor: "pointer",
      width: "100%",
    },
    forgotPasswordLink: {
      display: "block",
      marginTop: "10px",
      color: "#007bff",
      textDecoration: "none",
      alignItems: "center",
      fontSize: "11px"
    },
  };


  return (
    <div className="container-fluid d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div style={styles.row}>
        <div style={styles.column}>
          <div style={{ ...styles.card, ...styles.loginCard }}>
            {showAlert && (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}>
                <div className="spinner-grow text-success" role="status">
                  <span className="sr-only">...</span>
                </div>
              </div>
            )}


            {showIncorrectAlert && (
              <div style={styles.errorAlert}>Incorrect login credentials!</div>
            )}


            <div style={styles.logoContainer}>
              <img style={styles.logo} src={logoImage} alt="Logo" />
            </div>


            <form>
              <div style={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                  style={styles.input}
                />
              </div>
              <div style={styles.formGroup}>
                <label htmlFor="password">Password</label>
                <div style={styles.passwordInput}>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    style={{ ...styles.input, marginRight: '10px' }}
                  />
                  <button
                    onClick={togglePasswordVisibility}
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                    />
                  </button>
                </div>
              </div>
              <button
                type="button"
                onClick={validateForm}
                style={styles.loginButton}
              >
                Login
              </button>
              <Link to="/fp" style={styles.forgotPasswordLink}>
                Forgot Password
              </Link>
              <div style={{ textAlign: 'right', marginTop: '10px', fontSize: '9px', color: '#777' }}>
                Powered by LIDER Technology
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}