import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const App = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      title: 'Item 1',
      image: 'image1.jpg',
      createdAt: '2023-06-19',
    },
    {
      id: 2,
      title: 'Item 2',
      image: 'image2.jpg',
      createdAt: '2023-06-18',
    },
    // Add more items as needed
  ]);
  const [showPopup, setShowPopup] = useState(false);

  const handleEdit = (id) => {
    // Handle edit functionality here
    console.log(`Edit item with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Handle delete functionality here
    console.log(`Delete item with ID: ${id}`);
  };

  const handleNewPost = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (showPopup) {
      // Perform any necessary actions when the popup is shown
      console.log('Popup shown');
    }
  }, [showPopup]);

  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const handleSave = () => {
    // Save functionality here
    // ...

    setShowPopup(false); // Close the popup
  };

  
  return (
    <div className="container">
      <h5>Blog Posts</h5>
      <button style={{ float: 'right', background: '#03396c' ,color:'white'}} onClick={handleNewPost}>
        New Post
      </button>
      <Table responsive>
        <thead>
          <tr>
            <th>S. No.</th>
            <th>Title</th>
            <th>Image</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>
                <img src={item.image} alt={item.title} />
              </td>
              <td>{item.createdAt}</td>
              <td>
                <div className="action-buttons">
                  <button className="edit-button" onClick={() => handleEdit(item.id)}>
                    <BsPencilFill />
                  </button>
                  <button className="delete-button" onClick={() => handleDelete(item.id)}>
                    <BsTrashFill />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showPopup && (
  <div className="popup-container" style={{ border: '1px solid #ccc' }}>
    <div className="popup bg-white p-3 rounded">
      <h3>Blog post</h3>
      <input type="text" placeholder="Title" style={{ marginBottom: '10px' }} />
      <input type="file" accept="image/*" style={{ marginBottom: '10px' }} />
      <div style={{ fontWeight: 'bold', fontStyle: 'italic', textAlign: 'center' }}>
      <ReactQuill
            value={editorContent}
            onChange={handleEditorChange}
            placeholder="Enter your Text"
            style={{ fontSize: '16px' }} // Increase the size of the placeholder
          />
      </div>
      <button className="btn btn-primary" onClick={() => setShowPopup(false)}  style={{  background: '#03396c' }}>
        Close
      </button>
      <button className="btn btn-primary" onClick={handleSave}  style={{  background: '#03396c' }}>
            Save
          </button>
    </div>
  </div>
)}
    </div>
  );
};

const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
    
      <div className="navigation-buttons">
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
        </button>
        
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
        </button>
      </div>
    </div>
  );
};

const Allstudent = () => {
  return (
    <div>
      <Header />
      <NavigationButtons />
      <App />
    </div>
  );
};

export default Allstudent;
