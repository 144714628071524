import React, { useState } from "react";


function DownloadFilePage() {
  const [fileName, setFileName] = useState(""); // State to manage the file name
const[con, setCon] = useState("")
  const handleDownload = () => {
    // Implement logic to generate/download the file
    if (fileName) {
      // Creating a dummy text file for demonstration purposes
      const content = con;
      const element = document.createElement("a");
      const file = new Blob([content], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    } else {
      console.log("File name is required");
    }
  };


  const styles = {
    container: {
      maxWidth: "600px",
      margin: "0 auto",
      padding: "40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    heading: {
      textAlign: "center",
      marginTop: "0",
      marginBottom: "30px",
      color: "#333",
      fontSize: "28px",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    formGroup: {
      marginBottom: "20px",
    },
    label: {
      display: "block",
      marginBottom: "8px",
      color: "#555",
      fontWeight: "bold",
      fontSize: "18px",
    },
    input: {
      width: "100%",
      padding: "12px",
      fontSize: "16px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      outline: "none",
    },
    downloadButton: {
      // padding: "15px 25px",
      // fontSize: "18px",
      // color: "white",
      // textDecoration: "none",
      // borderRadius: "5px",
     
    },
    infoText: {
      fontSize: "16px",
      color: "#777",
      marginBottom: "20px",
    },
  };


  return (


    <div className="container-fluid" style={{ maxWidth: "1050px"  }}>
<br></br>
    <div style={styles.container}>
      <h2 style={styles.heading}>Download File</h2>
      <p style={styles.infoText}>
        Please enter a name for your file and click the "Download File" button
        to generate and download a text file.
      </p>
      <div style={styles.formGroup}>
        <label htmlFor="fileName" style={styles.label}>
          File Name:
        </label>
        <input
          type="text"
          id="fileName"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          placeholder="Enter file name"
          style={styles.input}
        />
      </div>


      <input type="text"   placeholder="Enter"
          style={styles.input} onChange={(e)=>setCon(e.target.value)}/>
<  br />
<  br />
      <button
        type="button"
        className="btn btn-primary"
       
        onClick={handleDownload}
      >
        Download File
      </button>
    </div>
    </div>
  );
}


export default DownloadFilePage;



