import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from '../components/Header';
 
 
import Facilitor from './Facilitor';
import Newfacilitators from './Newfacilitators';
 


const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
 


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const openModal = () => {
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div>
      <Header />
<br></br>
     
      <Tabs
  value={tabValue}
  onChange={handleChange} // Change from handleChangeTab to handleChange
  aria-label="Facilitator-tabs"
  variant="fullWidth"
>
            <Tab label="Facilitators" />
          <Tab label="NewFacilitator" />
       
          </Tabs>
     


      {/* Add a horizontal line separator */}


      <div>
        {tabValue === 0 && <Facilitor />}
        {tabValue === 1 && <Newfacilitators/>}
       
     
      </div>
    </div>
  );
};


export default New;





