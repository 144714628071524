import React from 'react';
import TeachHeader from './TeachHeader'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBook,
  faBell,

} from '@fortawesome/free-solid-svg-icons';

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    backgroundColor: '#abdeda',
    marginBottom: '20px',
  },
  logo: {
    fontSize: '34px',
    fontWeight: 'bold',
    marginRight: '20px',
  },
  headerList: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
  },
  headerListItem: {
    marginLeft: '20px',
  },
  navigationDropdown: {
    width: 120,
    height: 50,
    display: 'flex',
    backgroundColor: '#abdeda',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0 10px',
  },
  navigationButton: {
    width: 120,
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '16px',
    margin: '0 10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'lightblue',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  containerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paragraph: {
    margin: '0 10px',
  },
  containerr: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'lightblue',
    height: '100px', // Add the desired height value here
  },
  containerContentr: {
    display: 'flex',
    justifyContent: 'space-between', // Arrange the items with space between them
  },
  paragraphr: {
    margin: '0 250px',
  },


  tabulation: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // 4 equal columns
    gridTemplateRows: 'repeat(4, 1fr)', // 4 equal rows
    gap: '10px', // Gap between grid items
    margin: '20px', // Add some margin around the tabulation
  },
  tabulationItem: {
    backgroundColor: '#ffffff',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },

  table: {
    borderCollapse: 'collapse',
    width: '500px', // Adjust the width of the table
    height: '300px', // Adjust the height of the table
  },
  tableCell: {
    padding: '8px',
    textAlign: 'center',
    border: '1px solid black',
  },
};




const Container = () => {
    return (
        <div className="containerw">
        <div className="row">
          <div className="col-md-4 mt-4">
            <div className="container-content">
              <p className="paragraph">Course:</p>
              <p className="paragraph">Course Date:</p>
              <p className="paragraph">Trainers:</p>
            </div>
          </div>
  
          <div className="col-md-4  mt-4">
            <div className="container-content">
              <p className="paragraph">Batch:</p>
              <p className="paragraph">Student Enrolled:</p>
              <p className="paragraph">Assessors:</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  

const App = () => {
    const handleSignClick = () => {
        // Perform the sign action here
        window.open('/empty-page', '_blank');
      };
    
        
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/Arsc');
  };

  return (
    <div>
    <TeachHeader/>
    <Container />
    <div style={{...styles.tabulation , marginLeft: '18%', width: '55%', marginTop: '2%', textAlign: 'center'}}>
      {/* Column 1 */}
      <div style={styles.tabulationItem}></div>
      <div style={styles.tabulationItem}>student name</div>
      <div style={styles.tabulationItem}>Attendance</div>
      <div style={styles.tabulationItem}>Record</div>

      {/* Column 2 */}
      <div style={styles.tabulationItem}>**</div>
      <div style={styles.tabulationItem}>****</div>
      <div style={styles.tabulationItem}>****</div>
      <div style={styles.tabulationItem}>****</div>

      {/* Column 3 */}
      <div style={styles.tabulationItem}>**</div>
      <div style={styles.tabulationItem}>****</div>
      <div style={styles.tabulationItem}>****</div>
      <div style={styles.tabulationItem}>****</div>

      {/* Column 4 */}
    
   
    </div>
  </div>
);
};

export default App;
