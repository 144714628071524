import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Home from "./Home";

function Setp() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSetPassword = () => {
    if (newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        // Implement the logic for setting the new password here
        console.log("Password set successfully");
        alert("Password set successfully");
        navigate('/Course '); // Navigate to success page after setting the password
      } else {
        console.log("Passwords do not match");
        alert("Passwords do not match");
      }
    } else {
      console.log("Please fill in all fields");
      alert("Please fill in all fields");
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Set New Password</h2>
      <form style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="newPassword" style={styles.label}>
            New Password:
          </label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="confirmPassword" style={styles.label}>
            Confirm Password:
          </label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.buttonGroup}>
          <button type="button" style={styles.button} onClick={handleSetPassword}>
            Set Password
          </button>
        </div>
      </form>
    </div>
  );
}
const styles = {
  container: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "lightgreen",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    textAlign: "center",
    marginTop: "0",
    marginBottom: "20px",
    color: "#333",
  },
  form: {
    width: "100%",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    color: "#555",
    fontWeight: "bold",
  },
  input: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "3px",
    border: "1px solid #ddd",
    outline: "none",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    padding: "12px",
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.3s ease",
  },
};

export default Setp;
