import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Ad.css';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faBook, faUsers, faUser, faPlus, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import 'react-calendar/dist/Calendar.css';
import Footer from './Footer';
import Loader from '../Loader';

import axios from '../Axiosinstance';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@material-ui/core/Tooltip';

const Student = () => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const response = await axios.get('exisitingstudentrequests');
        const filteredRows = response.data.filter((record) => record.status === 'false');
        setRows(filteredRows);

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (rowIndex) => {
    const requestIdToDelete = rows[rowIndex].id;
    const isConfirmed = window.confirm('Are you sure you want to delete this student request?');

    if (isConfirmed) {
      axios.delete(`exisitingstudentrequest/${requestIdToDelete}`)
        .then(response => {
          console.log('Student request deleted successfully:', response.data);
          const updatedRows = rows.filter((row, index) => index !== rowIndex);
          setRows(updatedRows);
        })
        .catch(error => {
          console.error('Error deleting student request:', error);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const updateStatus = (rowIndex) => {
    const isConfirmed = window.confirm('Are you sure you want to update the status?');

    if (isConfirmed) {
      const updatedRows = [...rows];
      updatedRows[rowIndex].status = 'true';
      setRows(updatedRows);

      const updatedRow = updatedRows[rowIndex];
      axios.put(`exisitingstudentrequest/${updatedRow.id}`, {
        ...updatedRow,
        status: 'true'
      })
        .then(response => {
          console.log('Status updated successfully:', response.data);
        })
        .catch(error => {
          console.error('Error updating status:', error);
        });
    }
  };

  const renderStudentDetails = (student) => (
    <div>
      <p>Email: {student.email}</p>
      <p>Phone: {student.phone}</p>
      <p>Address: {student.address}</p>
      <p>Education: {student.education}</p>
    </div>
  );

  return (
    <div className="container-fluid" style={{ maxWidth: '1200px' }}>
      <h2 className="heading" align="center" style={{ color: '#FFFFFF', fontSize: '28px', marginTop: '35px' }}>
        Student/Course Request
      </h2>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TableContainer sx={{ maxHeight: 'none' }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Batch</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Tooltip title={row.studentName ? renderStudentDetails(row) : ''} arrow>
                            <span>{row.studentName || 'Unknown'}</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{row.course}</TableCell>
                        <TableCell>{row.courseId}</TableCell>
                        <TableCell>{row.status === 'false' ? 'Pending' : row.status}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => updateStatus(index)}
                            style={{ backgroundColor: '#28a745', color: '#fff' }}
                          >
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(index)}
                            style={{
                              borderRight: '1px solid #ccc',
                              margin: '0 10px',
                              backgroundColor: '#dc3545',
                              color: '#fff'
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}
    </div>
  );
};

const Batches = () => {
  return (
    <div>
      <Header />
      <div style={{ padding: '20px 20px' }}>
        <Student />
      </div>
    </div>
  );
};

export default Batches;
