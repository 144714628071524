import React, { useState, useRef, useEffect } from 'react';
import axios from '../Axiosinstance';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import PasswordForm from './passwordform';
import "../login/otp.css";

const OTPInput = ({ email }) => {
  const inputsRef = useRef([]);
  const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
  const [otpVerified, setOtpVerified] = useState(false);
  const [minutes, setMinutes] = useState(4);
  const [seconds, setSeconds] = useState(59);
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (index, event) => {
    const { value } = event.target;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && !otpValues[index]) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleValidateClick = async () => {
    try {
      setLoading(true);
      const enteredOtp = otpValues.join('');
      const isNotEmpty = otpValues.every(value => value.trim() !== ''); // Check if all inputs are not empty
      if (!isNotEmpty) {
        setIsValid(false);
        return;
      }
      setIsValid(true);
      const response = await axios.post('/verify-otp', {
        email,
        enteredOtp
      });
      if (response.status === 200) {
        setOtpVerified(true);
      } else if (response.status === 400) {
        toast.error('Invalid OTP');
      } else {
        toast.error('Invalid OTP');
      }
    } catch (error) {
      toast.error('Invalid OTP');
      // console.error('Error validating OTP:', error);
      // Handle error cases if necessary
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendotp = async () => {
    try {
      // Make POST request to request OTP
      const response = await axios.post('https://newtonimpulzserver.df.r.appspot.com/request-otp', { email });
      // Check response and decide whether to reset countdown
      if (response.status === 200) {
        // Reset countdown to 4 minutes and 59 seconds
        setMinutes(4);
        setSeconds(59);
        console.log("Resend OTP successful");
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      // Handle error cases if necessary
    }
  };

  return (
    <div>
      <ToastContainer />
      {!otpVerified ? (
        <>
          <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Enter One-Time Password</h2>
          <p style={{ textAlign: 'center', marginBottom: '20px' }}>A code has been sent to <strong>{email}</strong></p>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            {otpValues.map((value, index) => (
              <input
                key={index}
                ref={(el) => (inputsRef.current[index] = el)}
                style={{ width: '40px', height: '40px', margin: '0 5px', textAlign: 'center', borderRadius: '5px', border: '1px solid #ccc', fontSize: '18px' }}
                type="number"
                maxLength="1"
                value={value}
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            ))}
          </div>
          {!isValid && (
            <p style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }}>Please enter OTP </p>
          )}
          <div className='countdown-text '>
            <p style={{ fontSize: '15px' }}>
              Time remaining:{" "}
              <span style={{ fontWeight: 600 }}>
                {minutes < 10 ? `0${minutes}` : minutes} :
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </p>
            <button disabled={seconds > 0 || minutes > 0}
              style={{ color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630", marginBottom: '12px' }}
              onClick={resendotp}
            >
              Resend OTP
            </button>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button
              style={{ width: '150px', height: '40px', fontSize: '16px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '20px', cursor: 'pointer' }}
              onClick={handleValidateClick}
              disabled={loading}
            >
              {loading ? 'Validating...' : 'Validate'}
            </button>
          </div>
        </>
      ) : (
        <PasswordForm email={email} />
      )}
    </div>
  );
};

export default OTPInput;
