import logo from './logonew.png';
export const CLOUDINARY_UPLOAD_PRESET = 'vldqgwk8';
export const baseURL = 'https://api.cloudinary.com/v1_1/dyjwwi8ea/raw/upload';


export   const Username = 'cb589ea3-9b05-44f8-b7c2-de71d128cb0b';
export const Password = '469efb6fc6d285934e1f';
export const baseURLdyte = 'https://api.dyte.io/v2';
export const baseURLdyteMeeting ='https://app.dyte.io/v2';


export const logoImage =logo
// export const logoImage ="https://lider-impulz.vercel.app/static/media/logo1.775c2acf99492d8c08fd.png"