import React, { useState, useEffect } from 'react';
import Head from './Headstu';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from '../Axiosinstance'; // Import Axios
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PDFWritten() {
    const { cid: batchId, bid: courseId } = useParams();
    const [assessmentData, setAssessmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]); // Define selectedFiles state as an array
    const [uploadedFileUrls, setUploadedFileUrls] = useState([]); // Define uploadedFileUrls state as an array
    const studentId = localStorage.getItem("studentteacherid");
    const [templateIds, setTemplateIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [modalError, setModalError] = useState(null);
    const [uploadingStates, setUploadingStates] = useState([]); // Array to manage loading state for each upload button
    const [confirmationMessage, setConfirmationMessage] = useState(null); // State to manage confirmation message

    useEffect(() => {
        axios.get(`templates/course/${courseId}?type=Written%20PDF`)
            .then(response => {
                setAssessmentData(response.data);
                const ids = response.data.map(item => item.id); // Extract templateIds from response.data
                setTemplateIds(ids);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [courseId]);

    const handleGetStudentData = async (assessmentId) => {
        try {
            const response = await axios.get(`pdfwritten/search?studentId=${studentId}&batchId=${batchId}&courseId=${courseId}&templateId=${assessmentId}`);
            const fetchedPdfUrl = response.data;
            if (fetchedPdfUrl) {
                setPdfUrl(fetchedPdfUrl);
                setShowModal(true);
                setModalError(null);
            } else {
                setPdfUrl(null);
                setShowModal(true); // Open modal even if URL is not fetched (to show message)
                setModalError("No assessment uploaded by the student.");
            }
        } catch (error) {
            setPdfUrl(null); // Reset pdfUrl if API call fails
            setShowModal(true); // Open modal to show error message
            setModalError("No assessment uploaded by the student.");
        }
    };

    const handleFileChange = (event, index) => {
        const files = [...selectedFiles];
        files[index] = event.target.files[0];
        setSelectedFiles(files);
    };

    const uploadFile = async (index) => {
        const uploadingStatesCopy = [...uploadingStates];
        uploadingStatesCopy[index] = true; // Set loading state for the clicked upload button
        setUploadingStates(uploadingStatesCopy); // Update loading state array
        const selectedFile = selectedFiles[index];
        if (!selectedFile) {
            toast.error('Please select a file before uploading.');
            setUploadingStates(prevState => {
                const newState = [...prevState];
                newState[index] = false; // Reset loading state for the clicked upload button
                return newState;
            });
            return;
        }

        const timestamp = Date.now();
        const fileExtension = selectedFile.name.split('.').pop();
        const fileNameWithTimestamp = `${timestamp}.${fileExtension}`;

        const formData = new FormData();
        formData.append('file', selectedFile, fileNameWithTimestamp);
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        formData.append('public_id', `LMS/PDFAssessment/${fileNameWithTimestamp}`);

        try {
            const response = await fetch(baseURL, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.secure_url !== '') {
                const fileUrl = data.secure_url;
                const updatedUrls = [...uploadedFileUrls];
                updatedUrls[index] = fileUrl;
                setUploadedFileUrls(updatedUrls);
                updateAssessmentData(index, fileUrl);
                setConfirmationMessage('PDF uploaded successfully.'); // Set success message
                console.log('Uploaded File URL:', fileUrl);
                saveUploadedFileData(fileUrl, templateIds[index]);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setUploadingStates(prevState => {
                const newState = [...prevState];
                newState[index] = false; // Reset loading state for the clicked upload button
                return newState;
            });
        }
    };

    const updateAssessmentData = (index, fileUrl) => {
        setAssessmentData(prevData => {
            const updatedData = [...prevData];
            updatedData[index].uploadedFileUrl = fileUrl;
            return updatedData;
        });
    };

    const saveUploadedFileData = async (fileUrl, templateId) => {
        try {
            await axios.post('/pdfwritten', {
                url: fileUrl,
                studentId: studentId,
                courseId: courseId,
                batchId: batchId,
                templateId: templateId
            });
            console.log('Uploaded file data saved successfully');
            // You can add further actions or notifications upon successful save
        } catch (error) {
            console.error('Error saving uploaded file data:', error);
            // You can handle error cases here, such as displaying an error message
        }
    };

    const openModal = (url) => {
        setPdfUrl(url);
        setShowModal(true);
    };

    useEffect(() => {
        if (confirmationMessage) {
            toast.success(confirmationMessage);
            setConfirmationMessage(null);
        }
    }, [confirmationMessage]);

    const handleviewPDF = (url) => {
        if (url) {
            setPdfUrl(url);
            setShowModal(true);
            setModalError(null);
        } else {
            setPdfUrl(null);
            setShowModal(true); // Open modal even if URL is not fetched (to show message)
            setModalError("No assessment uploaded by the student.");
        }
    };
    return (
        <div style={{ textAlign: 'center', marginTop: '80px' }}>
            <Head />
            <ToastContainer />
            {assessmentData.map((assessment, index) => (
                <div key={index} style={{ maxWidth: '1000px', margin: '20px auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: '#ffffff', padding: '20px' }}>
                    <h2>PDF Assessment {index + 1} Details</h2>
                    <h3>Learning Outcome: {assessment.learningOutcome}</h3>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Questions</th>
                                <th scope="col">Criteria</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {assessment.questLabels && assessment.criteria && Object.keys(assessment.questLabels).map(key => (
                                <tr key={key}>
                                    <td>{assessment.questLabels[key]}</td>
                                    <td>{assessment.criteria[key]}</td>
                                </tr>
                            ))} */}
                            <tr>
                                <td>  <Button onClick={() => handleviewPDF(assessment.pdfLabels.url)} variant="contained" color="secondary">click here to see the Questions</Button></td>
                                {assessment.criteria && Object.keys(assessment.criteria).map(key => (
                                    <td>{assessment.criteria[key]}</td>
                                ))}
                                {/* <td>  <Button onClick={() => handleviewPDF(assessment.pdfLabels.url)} variant="contained" color="secondary">View</Button></td> */}

                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <input type="file" accept=".pdf" onChange={(event) => handleFileChange(event, index)} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Button onClick={() => uploadFile(index)} variant="contained" color="primary">
                        {uploadingStates[index] ? <CircularProgress size={24} /> : 'Upload File'}
                    </Button>
                    <Button onClick={() => handleGetStudentData(assessment.id)} variant="contained" color="secondary">View</Button>

                </div>
            ))}
            <Dialog open={showModal} onClose={() => setShowModal(false)} fullScreen>
                <DialogTitle>Assessment PDF</DialogTitle>
                <DialogContent>
                    {pdfUrl ? (
                        <iframe src={pdfUrl} style={{ width: '100%', height: '100vh' }} title="PDF Viewer" />
                    ) : (
                        <p>{modalError}</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default PDFWritten;
