import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../../components/Header";


const Screen = ({ batches, setBatches }) => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [currentDate] = useState(new Date().toLocaleDateString()); // Get current date as a string

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredBatches = batches.filter(
    (batch) =>
      batch.code.toLowerCase().includes(search.toLowerCase()) &&
      (filter === '' || batch.course === filter)
  );

  const handlePrevious = () => {
    console.log('Previous clicked!');
    // Add your own custom logic here
  };

  const handleNext = () => {
    console.log('Next clicked!');
    // Add your own custom logic here
  };

  const handleDelete = (id) => {
    const updatedBatches = batches.filter((batch) => batch.id !== id);
    setBatches(updatedBatches);
  };

  const handleEdit = (batch) => {
    setSelectedBatch(batch);
    setShowPopup(true);
  };

  const handleNameChange = (e) => {
    setSelectedBatch({ ...selectedBatch, code: e.target.value });
  };

  const handleCourseChange = (e) => {
    setSelectedBatch({ ...selectedBatch, course: e.target.value });
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setSelectedBatch(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log(`Submitted name: ${selectedBatch.code}`);
    console.log(`Submitted course: ${selectedBatch.course}`);

    if (selectedBatch.id) {
      // Edit existing batch
      const updatedBatches = batches.map((batch) =>
        batch.id === selectedBatch.id ? selectedBatch : batch
      );
      setBatches(updatedBatches);
    } else {
      // Add new batch
      const newBatchId = Math.max(...batches.map((batch) => batch.id)) + 1;
      const newBatch = { ...selectedBatch, id: newBatchId, startingDate: '' };
      setBatches([...batches, newBatch]);
    }

    handlePopupClose();
  };

  return (
    <div className="container-fluid">
   

      <div className="row">
        <div className="col-md-4 offset-md-2" style={{marginLeft:'20%'}}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search by code"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
      <table className="table table-striped" style={{ width: '60%' ,marginLeft:'20%', border: '1px solid #ccc', borderRadius: '2px', borderWidth: '1px 5px'   }}>
          <thead>
            <tr>
              <th>Question</th>
              <th>Type</th>
              <th>Course</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredBatches.map((batch) => (
              <tr key={batch.id}>
                <td>{batch.code}</td>
                <td>{batch.course}</td>
                <td>{currentDate}</td>
                <td>
                  <div>
                   
                    <button className="btn btn-dan" onClick={() => handleDelete(batch.id)}>
                      <BsTrashFill /> {/* Delete icon */}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    <div>
      
    </div>
    <div> <Button batches={batches} setBatches={setBatches} 

/></div>
     
    
      <div className="" style={{ display: 'flex', justifyContent: 'flex-end' }}>
 
      <button
        className=""
        onClick={handlePrevious}
        style={{
          backgroundColor: '#03396c',
          color: '#fff',
          marginRight:'2%',
          border: 'none',
          padding: '8px 40px', // Adjusted padding
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '14px', // Adjusted font size
        }}
      >
        Previous
      </button>
    
      <button
        className=""
        onClick={handleNext}
        style={{
          backgroundColor: '#03396c',
          color: '#fff',
          border: 'none',
          marginRight:'18%',
          padding: '8px 40px', // Adjusted padding
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '14px', // Adjusted font size
          marginLeft: '10px', // Add some margin between the buttons
        }}
      >
        Next
      </button>
    </div>

      {showPopup && (
        <div className="position-fixed top-50 start-50 translate-middle">
          <div className="popup-message bg-light p-4">
            <h2 className="mb-3">{selectedBatch ? 'Edit batch' : 'New category'}</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label">Name:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter name"
                  value={selectedBatch ? selectedBatch.code : ''}
                  onChange={handleNameChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Course:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter course"
                  value={selectedBatch ? selectedBatch.course : ''}
                  onChange={handleCourseChange}
                  required
                />
              </div>
              <div className="text-end">
                <div className="button-container">
                  <button className="btn btn-secondary me-2" onClick={handlePopupClose} style={{ padding: '10px 35px' }}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit" style={{ padding: '10px 35px' }}>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const Button = ({ batches, setBatches }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [course, setCourse] = useState('');

  const handleClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setName('');
    setCourse('');
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCourseChange = (e) => {
    setCourse(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log(`Submitted name: ${name}`);
    console.log(`Submitted course: ${course}`);

    const newBatchId = Math.max(...batches.map((batch) => batch.id)) + 1;
    const newBatch = {
      id: newBatchId,
      code: name,
      course: course,
      startingDate: '',
      action: 'edit,delete',
    };

    setBatches([...batches, newBatch]);

    handlePopupClose();
  };

  return (
    <div className="container" style={{ translate: '-94%'}}>
      <button className="btn btn-primary position-fixed top-60 end-0 m-3" onClick={handleClick} style={{ background: '#03396c',marginRight:'-2%',padding:'6px 50px'}}>
        Add
      </button>
      {showPopup && (
        <div className="position-fixed top-50 start-50 translate-middle">
          <div className="popup-message bg-light p-4">
            <h2 className="mb-3">New batch</h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label className="form-label">Question Type</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Type"
                  value={name}
                  onChange={handleNameChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Question</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter course"
                  value={course}
                  onChange={handleCourseChange}
                  required
                />
              </div>
              <div className="text-end">
  <div className="button-container">
    <button
      className="btn btn-secondary me-2"
      onClick={handlePopupClose}
      style={{ background: '#03396c', color: 'white', border: 'none',padding:'10px 70px' }}
    >
      Cancel
    </button>
    <button
      className="btn btn-primary"
      type="submit"
      style={{ background: '#03396c', color: 'white', border: 'none',padding:'10px 70px' }}
    >
      Submit
    </button>
  </div>
</div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const Hi = ({ batches, setBatches }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <div className="container"  style={{marginLeft:'50% ' ,width:'20%'}}>
   
      <select value={selectedOption} onChange={handleOptionChange} className="form-select">
        <option value="">Select a course</option>
        <option value="Course 1">Course 1</option>
        <option value="Course 2">Course 2</option>
        <option value="Course 3">Course 3</option>
      </select>
      <p> {selectedOption}</p>
    </div>
  );
};

const Batches = () => {
  const [batches, setBatches] = useState([
    { id: 1, code: 'CT4', course: 'SHORT', startingDate: 'mINTAIN DISPLAY', action: 'edit,delete' },
    { id: 2, code: 'CT6', course: 'SHORT', startingDate: '27-6-2017', action: 'edit,delete' },
    { id: 3, code: 'CT7', course: 'SHORT', startingDate: '22-6-2017', action: 'edit,delete' },
    // Add more batches here
  ]);

  return (
    <div >
      <Header />
     
      <Hi/>
      <Screen batches={batches} setBatches={setBatches} />
  
    </div>
  );
};

export default Batches;
