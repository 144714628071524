import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../../components/Header';
import Modal from 'react-bootstrap/Modal';
import axios from '../../Axiosinstance'
import Pagination from 'react-bootstrap/Pagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, FormControl, InputLabel, Button } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const ITEMS_PER_PAGE = 10;


const App = () => {
  const [students, setStudents] = useState([]);


  const [selectedStudent, setSelectedStudent] = useState(null);
  const [Question, setQuestion] = useState('');
  const [Type, setType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newType, setNewType] = useState('');
  const [batchCodes, setBatchCodes] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [feedbackData, setFeedbackData] = useState([]);
  const [selectedBatchFeedback, setSelectedBatchFeedback] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);


  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);


  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = selectedBatchFeedback.slice(indexOfFirstItem, indexOfLastItem);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
 
    // Filter feedback based on search query
    const filteredFeedback = selectedBatchFeedback.filter((feedback) =>
      feedback.questions.toLowerCase().includes(query.toLowerCase())
    );
 
    // Update the displayed feedback
    setSelectedBatchFeedback(filteredFeedback);
  };
 


  useEffect(() => {
    axios.get('batches')
      .then(response => {
        setBatchCodes(response.data);
      })
      .catch(error => {
        console.error('Error fetching batch codes:', error);
      });
 
    axios.get('studentfeedbacks')
      .then(response => {
        setFeedbackData(response.data);
      })
      .catch(error => {
        console.error('Error fetching feedback data:', error);
      });
  }, []);
 




const handleEdit = (id) => {
  const student = selectedBatchFeedback.find((feedback) => feedback.id === id);
  const confirmed = window.confirm('Are you sure you want to update the course feedback?');
  if (!confirmed) {
    return; // If not confirmed, exit the function
  }
  if (student) {
    setSelectedStudent(student);
    setQuestion(student.questions);
    setType(student.type);
    setEditModalVisible(true);
  }
};


const handleUpdate = (e) => {
  e.preventDefault();


  if (Question && Type && selectedStudent && selectedStudent.id) {
    const updatedStudent = {
      questions: Question,
      type: parseInt(Type),
      batchId: selectedBatchId,
    };


    axios.put(`studentfeedback/${selectedStudent.id}`, updatedStudent)
      .then(response => {
        // Update local state to reflect the changes
        const updatedFeedback = selectedBatchFeedback.map(feedback => {
          if (feedback.id === selectedStudent.id) {
            return { ...feedback, questions: Question, type: parseInt(Type) };
          }
          return feedback;
        });
        setSelectedBatchFeedback(updatedFeedback);
        handleCloseModal(); // Close and clear the modal
        setEditModalVisible(false); // Hide the edit modal
        toast.success('course feedback updated successfully');


      })
      .catch(error => {
        console.error('Error updating feedback data:', error);
        // Handle error condition if needed
      });
  }
};






  const handleCloseModal = () => {
    setSelectedStudent(null);
    setShowSuccessMessage(false);
    setQuestion('');
    setType('');
    setShowCreateModal(false);
    setNewQuestion('');
    setNewType('');
  };


  const handleDelete = (id) => {
    const confirmed = window.confirm('Are you sure you want to delete the course feedback?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
    axios.delete(`studentfeedback/${id}`)
      .then(response => {
        // Update local state to remove the deleted feedback
        setSelectedBatchFeedback(prevFeedback => prevFeedback.filter(feedback => feedback.id !== id));
        toast.success('course feedback deleted successfully');


      })
      .catch(error => {
        console.error('Error deleting feedback data:', error);
        // Handle error condition if needed
      });
  };
 


  const handleSaveChanges = () => {
    const updatedStudents = students.map((student) =>
      student.id === selectedStudent.id
        ? {
            ...student,
            Question: Question,
            Type: Type,
          }
        : student
    );


    setStudents(updatedStudents);
    setSelectedStudent(null);
    setShowSuccessMessage(true);


    // Reset the form fields
    setQuestion('');
    setType('');
  };


 


 


  const handleCreate = (e) => {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to save the course feedback?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
 
    if (newQuestion && newType && selectedBatchId) {
      const newStudent = {
        questions: newQuestion,
        type: parseInt(newType),
        batchId: selectedBatchId,
      };
 
      axios.post('studentfeedback', newStudent)
        .then(response => {
          // Update local state to include the newly added feedback
          setSelectedBatchFeedback(prevFeedback => [...prevFeedback, response.data]);
          setShowSuccessMessage(true);
          setNewQuestion('');
          setNewType('');
          setShowCreateModal(false);
          toast.success('course feedback created successfully');


        })
        .catch(error => {
          console.error('Error storing feedback data:', error);
          // Handle error condition if needed
        });
    }
  };
 


  return (
    <div className="containerw" style={{  maxWidth: "870px", borderRadius: '2px', marginTop:'30px' }}>
       <br/>   <Link to="/Allcourse">
        <Button variant="contained" color="primary">
          All Courses
        </Button>
      </Link>

      <br/>   <br/> 
     <h2>Trainer Form</h2>


     <br/>


     <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
 
 
  <div style={{ marginRight: '10px', flex: 1 }}>
    <TextField
      label="Search by Question"
      value={searchQuery}
      onChange={handleSearch}
      variant="outlined"
      fullWidth
    />
  </div>
  <div style={{ flex: 1 }}>
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="batchSelectLabel">Select Batch</InputLabel>
      <Select
        labelId="batchSelectLabel"
        id="batchSelect"
        value={selectedBatch}
        onChange={(e) => {
          setSelectedBatch(e.target.value);
          const batch = batchCodes.find(batch => batch.batchcode === e.target.value);
          if (batch) {
            setSelectedBatchId(batch.id);
            const batchFeedback = feedbackData.filter(feedback => feedback.batchId === batch.id);
            setSelectedBatchFeedback(batchFeedback);
          } else {
            setSelectedBatchId(null);
            setSelectedBatchFeedback([]);
          }
        }}
        label="Select Batch"
      >
        <MenuItem value="">
          <em>Select a batch</em>
        </MenuItem>
        {batchCodes.map(batch => (
          <MenuItem key={batch.id} value={batch.batchcode}>
            {batch.batchcode}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </div>
  <div  style={{ flex: 1 , marginLeft:'10px'}}>
  {selectedBatch && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowCreateModal(true)}
            style={{ fontSize: '15px', height:'55px' }}
          >
           < FaPlus/> Create
          </Button>
        )}
        </div>
</div>


 
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Question</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentItems.map((feedback, index) => (
              <TableRow key={feedback.id}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{feedback.questions}</TableCell>
                <TableCell align="center">{feedback.type === 2 ? 'Short answer' : 'Rating'}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(feedback.id)}>
                    <BsTrashFill />
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(feedback.id)}>
                    <BsPencilFill />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end' }}>
       


        <Pagination className="mt-3">
          <Pagination.Prev
            onClick={() => {
              if (currentPage > 1) {
                paginate(currentPage - 1);
              }
            }}
          />
          {Array.from({ length: Math.ceil(selectedBatchFeedback.length / ITEMS_PER_PAGE) }).map((_, index) => (
            <Pagination.Item key={index} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => {
              if (currentPage < Math.ceil(selectedBatchFeedback.length / ITEMS_PER_PAGE)) {
                paginate(currentPage + 1);
              }
            }}
          />
        </Pagination>
      </div>


    <Modal show={editModalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdate}>
            <div className="form-group">
              <label htmlFor="editQuestion">Question</label>
              <input
                type="text"
                className="form-control"
                id="editQuestion"
                value={Question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="editType">Type</label>
              <select
                className="form-control"
                id="editType"
                value={Type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select a type</option>
                <option value="1">Rating</option>
                <option value="2">Short answer</option>
              </select>
            </div>
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        </Modal.Body>
      </Modal>


      <Modal show={showCreateModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreate}>
            <div className="form-group">
              <label htmlFor="newQuestion">Question</label>
              <input
                type="text"
                className="form-control"
                id="newQuestion"
                placeholder="Enter the question"
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="newType">Type</label>
              <select
  className="form-control"
  id="newType"
  value={newType}
  onChange={(e) => setNewType(e.target.value)} // Use e.target.value directly
>
  <option value="">Select a type</option>
  <option value="1">checkbox</option>
  <option value="2">Short answer</option>


</select>
            </div>
            <button type="submit" style={{padding:'3px 10px'}}>
              Create
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />


   
    </div>
  );
};






const Allstudent = () => {
  return (
    <div>
      <Header />
      <div >
     
      </div>
      <div classname= "col-md-4 mb-1 py-1 ">
      <App />
      </div>
    </div>
  );
};


export default Allstudent;

