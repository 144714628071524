import React ,{useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import  Asat from  './Asat';
import Bill from './Bill';
import Header from '../components/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';






const columns = [
  { id: 'Batch', label: 'Batch', minWidth: 170 },
  { id: 'Date', label: 'Date', minWidth: 100 },
  {
    id: 'Course',
    label: 'Course',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Trainer',
    label: 'Trainer',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'NoofCompentent',
    label: 'No of Compentent',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'NoofNotYetCompentent',
    label: 'No of Not Yet Compentent',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'PassingPercentage',
    label: 'Passing Percentage',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];




function createData(Batch,Date,Course,Trainer,NoofCompentent,NoofNotYetCompentent,PassingPercentage) {
   
  return { Batch,Date,Course,Trainer,NoofCompentent,NoofNotYetCompentent,PassingPercentage };
}














const rows = [
  createData('Batch1', '21-07-2023', 'java', 'ram',2,12,25),
  createData('Batch2', '09-04-2023', 'reactjs', 'moorthy',2,12,25),
  createData('Batch3', '11-02-2023', 'python', 'raju',2,12,25),
  createData('Batch4', '16-05-2023', 'dotnet', 'sheela',2,12,25),
  createData('Batch5', '23-09-2023', 'angular', 'suganya',2,12,25),
  // Add more rows as needed
];




const StickyHeadTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState('');


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const filteredRows = rows
    .filter((row) => {
      const values = Object.values(row).map((value) =>
        typeof value === 'string' ? value.toLowerCase() : value.toString()
      );
      return values.some((value) => value.includes(searchQuery.toLowerCase()));
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const handleDownload = () => {
      // Implement the logic for downloading table content
      console.log('Download clicked');
    };
 
    const handlePrint = () => {
      // Implement the logic for printing table content
      console.log('Print clicked');
    };


  return (
    <div className="container" style={{ maxWidth: '1000px', marginTop: '-20px' }}>
     
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <Input
          type="text"
          placeholder="Search by Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
          }}
        />
        <div>
          <IconButton onClick={handleDownload}>
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <PrintIcon />
          </IconButton>
        </div>
      </div>
      <TableContainer
    sx={{
      overflowX: 'auto',
      maxWidth: '100%',
      '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
        overflowX: 'unset', // Enable horizontal scroll
      },
    }}
  >      
<Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
  rowsPerPageOptions={[10, 25, 100]}
  component="div"
  count={rows.length}
  rowsPerPage={rowsPerPage}
  labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${rows.length}`}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>


      </Paper>
    </div>
  );
};




const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const openModal = () => {
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
  };


  const navigateToAcadamic = () => {
    setTabValue(0);
  };
  const navigateToAsat = () => {
    setTabValue(1);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div>
      <Header />
      <br />
      <div className="d-flex justify-content-center">


      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="General-tabs"
        variant="fullWidth"
        className="d-md-flex justify-content-md-center my-4"
        sx={{
          borderRadius: 10,
          display: 'flex',
          justifyContent: 'center',
          "& button": {
            borderRadius: 10,
            color: "#555",
            margin: 'auto', // Center the tabs horizontally
            display: 'block', // Necessary for margin: auto to work
           
            alignItems: 'center', // Center the text vertically
            justifyContent: 'center',
          },
          "& button:hover": {
            backgroundColor: "#eee",
          },
          "& button:active": {
            backgroundColor: "#ddd",
          },
          "& button.Mui-selected": {
            backgroundColor: "#007bff",
            color: "#fff",
          },
          "& .MuiTabs-indicator": {
            display: 'none', // Remove the underline
          },
         // Adjust width for mobile view
         ...(isMobile && {
          flexDirection: 'column', // Stack tabs vertically
          "& button": {
            width: '100%', // Set tabs to full width on mobile
          },
        }),
      }}
    >
        <Tab label="Academic Report"  sx={{ minWidth: isMobile ? '50%' : '390px' }} />
        <Tab label="Assessment Attrition Report"  sx={{ minWidth: isMobile ? '50%' : '390px' }} />
        <Tab label="Bill Report"  sx={{ minWidth: isMobile ? '50%' : '390px' }}/>
      </Tabs>
      </div>
      <br></br>
      <div>
        {tabValue === 0 && <StickyHeadTable />}
        {tabValue === 1 && <Asat onCourseCreated={navigateToAcadamic} />}
        {tabValue === 2 && <Bill onCourseCreated={navigateToAsat} />}
      </div>
    </div>
  );
 
};


export default New;


 




 














