import React, { useState } from "react";
import logo from '../All/images/logo.png';
import OTPInput from './Otp'; // Import the OTPInput component
import axios from '../Axiosinstance';
import { logoImage } from '../common'

export default function Home() {
  const [showOTPInput, setShowOTPInput] = useState(false); // State to manage OTPInput visibility
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false); // State to manage loading state of the button
  const [error, setError] = useState('');

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      height: "50%",
    },
    card: {
      borderRadius: "25px",
      padding: "20px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
      width: '100%',
      height: '50vh'
    },
    loginCard: {
      backgroundColor: "#fff",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      marginTop: '15%'
    },
    logo: {
      marginTop: '-2%',
      width: "100px",
      justifyContent: "left",
    },
    formGroup: {
      marginBottom: "15px",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    Codebutton: {
      backgroundColor: "#007bff",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      padding: "10px 20px",
      cursor: "pointer",
      width: "100%",
    },
  };

  const handleGetCodeClick = async () => { if (!email) {
    setError('Please enter your email');
    return;
  }
  if (!validateEmail(email)) {
    setError('Please enter a valid email address');
    return;
  }
    try {
      setLoading(true); // Start loading
      // Make POST request to request OTP
      const response = await axios.post('request-otp', { email });
      // Check response and decide whether to show OTP input
      if (response.status === 200) {
        setShowOTPInput(true); // Show OTPInput when OTP request is successful
        // You can also handle other cases like showing error messages if needed
        console.log("success");
      }
    } catch (error) {
      console.error('Error requesting OTP:', error);
      // Handle error cases if necessary
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
    }
  };

  const validateEmail = (email) => {
    // Regular expression for validating email format
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  
  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <div style={styles.column}>
          <div style={{ ...styles.card, ...styles.loginCard }}>
            <div style={styles.logoContainer}>
              <img style={styles.logo} src={logoImage} alt="Logo" />
            </div>
            {showOTPInput ? (
              <OTPInput email={email} />

            ) : (
              <form className="mt-5">
                <div style={styles.formGroup}>
        <label htmlFor="email">Forgot Password</label>
        <input
          type="text"
          placeholder="Enter Email ID"
          id="email"
          style={styles.input}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(''); // Clear error when input changes
          }}
          value={email}
          required
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      <button
        type="button"
        style={styles.Codebutton}
        onClick={handleGetCodeClick}
        disabled={loading}
      >
        {loading ? "Loading..." : "Get Code"}
      </button>
                <div style={{ textAlign: 'right', marginTop: '10px', fontSize: '9px', color: '#777' }}>
                  Powered by LIDER Technology
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
