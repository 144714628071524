import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import axios from '../Axiosinstance';
import { Last } from '../All/Homepage';
import Header from '../StudentLogin/Headstu';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common';
import { ToastContainer, toast } from 'react-toastify';

function CourseDetail() {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [courseOverview, setCourseOverview] = useState('');

  const loggedInUserId = localStorage.getItem('loggedInUserId');
  const studentteacherid = localStorage.getItem('studentteacherid');

  
  

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(`course/${id}`);
        setCourse(response.data); // Assuming the data is an object
      } catch (error) {
        console.error('Error fetching course details:', error);
      }
    };

    fetchCourseDetails();
  }, [id]);

  
 

  
  const handleEnrollmentConfirmation = () => {
    const logId = loggedInUserId;
    const courseId = id;
    const courseName = course.name;
    const status = 'Pending';
    const message = `Are you sure you want to enroll in ${courseName}?`;
    const overview = `Course Overview:\n${course.description}`;

    setConfirmationMessage(message);
    setCourseOverview(overview);
    setShowDialog(true);
  };
  const handleConfirmEnrollment = () => {
    // Your existing logic...

    // Show toast notification on successful enrollment
    toast.success('Successfully enrolled', {
      position: toast.POSITION.BOTTOM_RIGHT,

    });
    handleCloseDialog();
  };


  const handleCloseDialog = async () => {
    setShowDialog(false);

    try {
      const response = await axios.post('exisitingstudentrequest', {
        studentId: loggedInUserId,
        logId: studentteacherid,
        courseId: id,
        course: course.name,
        status: false,
        enroll: 'true'
      });

      if (response.status === 200) {
        console.log('Enrollment request successful:', response.data);
        // You can add further logic or handle success accordingly
      } else {
        console.error('Enrollment request failed:', response.data);
        // Handle failure if needed
      }
    } catch (error) {
      console.error('Error sending enrollment request:', error);
      // Handle error if needed
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
      <Typography variant="h4" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
        {course.name}
      </Typography>

      <Card>

        <CardMedia
          component="img"
          alt={course.name}
          height="440"
          image={course.url}
          style={{ width: '100%', objectFit: 'cover' }}
        />
        <CardContent>



          <Typography variant="h5" component="div" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Category: {course.category}
          </Typography>
          <Typography variant="h5" component="div" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Duration: {course.duration} 
          </Typography>

          <Typography variant="h5" component="div" style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            Fee: {course.fee}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleEnrollmentConfirmation}>
           Enroll Now 
          </Button>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div" style={{ marginBottom: '10px', fontWeight: 'normal' }}
            dangerouslySetInnerHTML={{ __html: course.description }}
          />

<h5>Outcome:</h5>
       <Typography
  variant="body2"
  color="text.secondary"
  component="div"
  style={{ marginBottom: '10px', fontWeight: 'normal', fontSize: '15px' }}
  dangerouslySetInnerHTML={{ __html: ` ${course.outcome}` }}
/>
<h5>Requirement:</h5>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div" style={{ marginBottom: '10px', fontWeight: 'normal' }}
            dangerouslySetInnerHTML={{
              __html: ` ${course.requirement}`,
            }}
          />
          <Button variant="contained" color="primary" onClick={handleEnrollmentConfirmation}>
            Enroll Now
          </Button>
        </CardContent>
      </Card>

      <Dialog open={showDialog} onClose={handleCloseDialog} PaperProps={{ style: { padding: '20px', minWidth: '300px' } }}>
        <DialogTitle>Enrollment Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmationMessage}</DialogContentText>
          <DialogContentText dangerouslySetInnerHTML={{ __html: courseOverview }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={handleConfirmEnrollment} color="primary" variant="contained">
          Confirm Enrollment
          </Button>
        </DialogActions>
      </Dialog>
      {/* Toast container for notifications */}
      <ToastContainer position="bottom-right" />
    </div>
  );
}

function coures() {
  return (
    <div>
      <Header />
   <br/>   <br/>
      <CourseDetail />
      <Last />
    </div>
  );
}

export default coures;