import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Courseei from './Courseei';
import axios from '../Axiosinstance';
import Head from '../components/Header'


function App() {
  const [selectedBatch, setSelectedBatch] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentNames, setStudentNames] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [netFee, setNetFee] = useState('');
  const [submitNotification, setSubmitNotification] = useState(null);




  const navigate = useNavigate(); // Initialize navigate




  // Fetch batch data using Axios
  const fetchBatchData = async () => {
    try {
      const response = await axios.get('batches');
      const batches = response.data.map((batch) => batch.batchcode); // Modify to extract batchcode
      setBatchData(batches);
    } catch (error) {
      console.error('Error fetching batch data:', error);
    }
  };




  // Fetch student names using Axios
  const fetchStudentNames = async () => {
    try {
      const response = await axios.get('students');
      const names = response.data.map((student) => student.fullname);
      setStudentNames(names);
    } catch (error) {
      console.error('Error fetching student names:', error);
    }
  };




  // Fetch batch and student data when the component mounts
  useEffect(() => {
    fetchBatchData();
    fetchStudentNames();
  }, []);




  // Function to navigate to Courseei.jsx
  const navigateToCourseei = () => {
    navigate('/Sswcommon'); // Use navigate to navigate to /Courseei
  };




  // Modified handleSubmit function to only handle form submission
  const handleSubmit = async (e) => {
    const confirmed = window.confirm('Are you sure you want to save the sfc payment?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
    e.preventDefault();




    const formData = {
      batch: selectedBatch,
      student: selectedStudent,
      fee: parseInt(netFee),
      document: null,
    };




    try {
      const response = await axios.post('sfcpay', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });




      if (response.status === 201 || response.status === 200) {
        alert('sfcpay submitted successfully');
        // Show submit notification
        setSubmitNotification('sfcpay submitted successfully');
        // Navigate to Courseei.jsx
        navigateToCourseei();
      } else {
        console.error('Response status:', response.status);
        console.error('Response data:', response.data);
        throw new Error('Failed to submit sfcpay');
      }
    } catch (error) {
      console.error(error);
    }
  };


  const [isFormDirty, setIsFormDirty] = useState(false);




  const handleInputChange = () => {
      if (!isFormDirty) {
        setIsFormDirty(true);
      }
    };
 
 
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (isFormDirty) {
          event.preventDefault();
          event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
        }
      };
 
 
      window.addEventListener('beforeunload', handleBeforeUnload);
 
 
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [isFormDirty]);
 
  return (
    <><Head/>
    <div className="App">
     
      <header className="text-center mt-3">
        <h1>SFC Payment</h1>
      </header>
      <div className="container-fluid mt-5">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="shadow p-3 mb-5 bg-body rounded">
              <form onSubmit={handleSubmit} onChange={handleInputChange}>
                <h4 className="mb-4">New SFC Payment</h4>
                <div className="mb-3">
                  <label htmlFor="batchSelect" className="form-label">
                    Select Batch
                  </label>
                  <select
  required
  className="form-select"
  id="batchSelect"
  value={selectedBatch}
  onChange={(e) => setSelectedBatch(e.target.value)}
>
  <option value="">Select a Batch</option>
  {batchData.map((batchCode, index) => (
    <option key={index} value={batchCode}>
      {batchCode}
    </option>
  ))}
</select>
                </div>
                <div className="mb-3">
                  <label htmlFor="studentSelect" className="form-label">
                    Select Student
                  </label>
                  <select
                  required
                    className="form-select"
                    id="studentSelect"
                    value={selectedStudent}
                    onChange={(e) => setSelectedStudent(e.target.value)}
                  >
                    <option value="">Select a Student</option>
                    {studentNames.map((studentName, index) => (
                      <option key={index} value={studentName}>
                        {studentName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="netFee">Net Fee</label>
                  <input
                    type="number"
                    id="netFee"
                    className="form-control"
                    value={netFee}
                    onChange={(e) => setNetFee(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="supportDocument">Support Document</label>
                  <input
                    type="file"
                    id="supportDocument"
                    className="form-control"
                    accept=".pdf,.doc,.docx"
                  />
                </div>
                <button
  type="submit"
  className="btn btn-primary"
  style={{
   
    padding: '10px', // Increase padding for better touch interaction on mobile
    fontSize: '1rem', // Adjust font size for mobile
    borderRadius: '5px', // Add border radius for a softer look
    boxSizing: 'border-box', // Ensure padding and border are included in the width
  }}
>
  Submit
</button>

                {submitNotification && (
                  <p className="mt-3 text-success">{submitNotification}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
}




export default App;















