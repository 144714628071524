import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const MyFilePickerComponent = () => {
  const onDrop = useCallback((acceptedFiles, event) => {
    // Combine dropped files and selected files from the gallery
    const files = acceptedFiles.concat(Array.from(event.target.files));

    // Handle the files here (e.g., upload them)
    console.log(files);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`dropzone ${isDragActive ? 'drag-active' : ''}`}
    >
      <input {...getInputProps({ accept: 'image/*' })} />
      {isDragActive ? (
        <p>Drop the files here...</p>
      ) : (
        <div>
          <h2>Click here to select the file</h2>
          <button style={{ width: '100px', height: '50px' }}>
            <p>Select File</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default MyFilePickerComponent;
