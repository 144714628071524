import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeachHeader from './TeachHeader';

const styles = {
  tableCell: { padding: '8px', border: '1px solid #ccc' },
  searchButton: { cursor: 'pointer', marginLeft: '10px' },
  navigationButton: { padding: '10px', marginLeft: '10px' },
  lightBlueButton: { backgroundColor: '#0396c4', color: 'white' },
};

const Container = () => { 
  return (
    <div className="responsive" style={{marginRight:'54px'}}>
      <div className="row" style={{marginRight:'54px'}}>
        <div className="col-md-6"  >
          <div className="container-content" >
            <p className="paragraph">Course <span style={{ marginLeft:'38px'}}>:</span></p>
            <p className="paragraph">Course Date <span style={{}}>:</span></p>
            <p className="paragraph">Trainers <span style={{ marginLeft:'32px'}}>:</span></p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="container-content"  >
            <p className="paragraph">Batch <span style={{ marginLeft:'78px'}}>:</span></p>
            <p className="paragraph">Student Enrolled <span style={{}}>:</span></p>
            <p className="paragraph">Assessors<span style={{ marginLeft:'54px'}}>:</span></p>
          </div>
        </div>
      </div>
    </div>




  );
};

const RQ = () => {
  const data = [
    { studentName: 'John Doe', attempted: 3, status: 'Completed', result: 'Pass', action: 'q' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },
    { studentName: 'Jane Smith', attempted: 2, status: 'Incomplete', result: 'Fail', action: 'w' },

    { studentName: 'Bob Johnson', attempted: 1, status: 'Incomplete', result: 'Pending', action: 'e' },
    // Add more data objects here
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setCurrentPage(0);
  };

  return (
    <div>
      <TeachHeader />
      <div className="containerw" style={{ maxWidth: '1200px' }}>
        <br></br>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Container />
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              marginRight: '10px',
              fontSize: '16px',
            }}
          />
          {/* Table */}
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Attempted</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Result</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
                  : data
                ).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.studentName}</TableCell>
                    <TableCell>{item.attempted}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{item.result}</TableCell>
                    <TableCell>{item.action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[ 5,10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RQ;
