import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from '../components/Header';


import Academic from './Acadamic';
import Asat from './Asat';
import Bill from './Bill';




const New = () => {
    const [tabValue, setTabValue] = useState(0);
    const [showModal, setShowModal] = useState(false);





    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };




    const openModal = () => {
        setShowModal(true);
    };




    const closeModal = () => {
        setShowModal(false);
    };




    return (
        <div>
            <Header />
            <br></br>

            <Tabs
                value={tabValue}
                onChange={handleChange} // Change from handleChangeTab to handleChange
                aria-label="report-tabs"
                variant="fullWidth"
            >
                <Tab label="Academic Report" />
                <Tab label="Assessment Attrition Report" />
                <Tab label="Bill Report" />
            </Tabs>


            <div>
                {tabValue === 0 && <Academic />}
                {tabValue === 1 && <Asat />}

                {tabValue === 2 && <Bill />}
            </div>
        </div>
    );
};




export default New;

