import React, { useState } from 'react';
import Header from '../components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddNewForm = () => {
  const [branch, setBranch] = useState('');
  const [student, setStudent] = useState('');
  const [netFee, setNetFee] = useState('');
  const [supperDocument, setSupperDocument] = useState('');

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleStudentChange = (event) => {
    setStudent(event.target.value);
  };

  const handleNetFeeChange = (event) => {
    setNetFee(event.target.value);
  };

  const handleSupperDocumentChange = (event) => {
    setSupperDocument(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const handleCancel = () => {
    setBranch('');
    setStudent('');
    setNetFee('');
    setSupperDocument('');
  };

  return (
    <div className="container">
      <h2 style={{ marginTop: '20px', fontWeight: 'bold' }}>Add New</h2>
      <form onSubmit={handleSubmit} style={{ border: '1px solid #ddd', padding: '20px', margin: '2px' }}>
        <div className="form-group">
          <label htmlFor="branch">Select Branch:</label>
          <select
            className="form-control"
            id="branch"
            value={branch}
            onChange={handleBranchChange}
          >
            <option value="">Select</option>
            <option value="branch1">Branch 1</option>
            <option value="branch2">Branch 2</option>
            <option value="branch3">Branch 3</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="student">Select Student:</label>
          <select
            className="form-control"
            id="student"
            value={student}
            onChange={handleStudentChange}
          >
            <option value="">Select</option>
            <option value="student1">Student 1</option>
            <option value="student2">Student 2</option>
            <option value="student3">Student 3</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="netFee">Net Fee:</label>
          <input
            type="text"
            className="form-control"
            id="netFee"
            placeholder="Enter Net Fee"
            value={netFee}
            onChange={handleNetFeeChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="supperDocument">Supper Document:</label>
          <input
            type="file"
            id="supperDocument"
            value={supperDocument}
            onChange={handleSupperDocumentChange}
          />
        </div>

        <div className="row mt-4">
          <div className="col-md-6"></div>
          <div className="col-md-6 d-flex justify-content-end">
            <button type="button" className="btn btn-secondary mr-2" onClick={handleCancel} style={{ backgroundColor: '#d9534f', border: '1px solid #ddd', fontWeight: 'bold' }}>
              Cancel
            </button>
            <button type="submit" className="btn btn-primary btn-sm w-50" style={{ backgroundColor: '#03396c', border: '1px solid #ddd', fontWeight: 'bold' }}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const Nfc = () => {
  return (
    <div>
      <Header />
      <AddNewForm />
    </div>
  );
};

export default Nfc;