import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TeachHeader from './TeachHeader'
import { Link, useParams } from 'react-router-dom';
import axios from '../Axiosinstance'
import { TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';


// const styles = {
//   header: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     padding: '10px 20px',
//     backgroundColor: '#abdeda',
//     marginBottom: '20px',
//   },
//   logo: {
//     fontSize: '34px',
//     fontWeight: 'bold',
//     marginRight: '20px',
//   },
//   headerList: {
//     display: 'flex',
//     listStyle: 'none',
//     padding: 0,
//   },
//   headerListItem: {
//     marginLeft: '20px',
//   },
//   navigationDropdown: {
//     width: 120,
//     height: 50,
//     display: 'flex',
//     backgroundColor: '#abdeda',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textDecoration: 'none',
//     color: 'black',
//     fontWeight: 'bold',
//     fontSize: '16px',
//     margin: '0 10px',
//   },
//   navigationButton: {
//     width: 120,
//     height: 50,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     textDecoration: 'none',
//     color: 'white',
//     fontWeight: 'bold',
//     backgroundColor: '#03396c',
//     fontSize: '16px',
//     margin: '0 10px',
//     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//   },





// };






const Container = () => {
  const { batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);
  const [students, setStudents] = useState([]);


  useEffect(() => {
    // Fetch batch details
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      }
    };


    const fetchStudents = async () => {
      try {
        const response = await axios.get('students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };


    fetchBatchDetails();
    fetchStudents();
  }, [batchId]);


  if (!batchDetails) {
    return <p>Loading...</p>;
  }
  return (
    <div className="containerw mx-auto" style={{ marginTop: '5%', marginLeft: '1%' }}>
      <div className="row">
        <div className="col-md-4" >
          <div className="container-content">
            <p className="paragraph">Course:{batchDetails.course}</p>
            <p className="paragraph">Course Date:{batchDetails.date}</p>
            <p className="paragraph">Trainers:{batchDetails.trainers}</p>
          </div>
        </div>


        <div className="col-md-4">
          <div className="container-content">
            <p className="paragraph">Batch:{batchDetails.batchcode}</p>
            <p className="paragraph">Student Enrolled:{batchDetails.course}</p>
            <p className="paragraph">Assessors:{batchDetails.assessors}</p>
          </div>
        </div>
      </div>
    </div>
  );
};




const App = () => {
  const { batchId } = useParams();
  const [data, setData] = useState([]);
  const [sIdArray, setSIdArray] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // 'asc' or 'desc'
  const [studentsPerPage, setStudentsPerPage] = useState(5); // Default to 5 students per page
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredStudentDetails, setFilteredStudentDetails] = useState([]);


  useEffect(() => {
    // Fetch data from the 'manages' endpoint using Axios
    axios
      .get(`manages`)
      .then((response) => {
        const result = response.data;
        const filteredData = result.filter((item) => item.batch_id == batchId);
        setData(filteredData);

        // Extract sId values and store them in an array
        const sIds = filteredData.map((item) => item.sId);
        setSIdArray(sIds);

        // Fetch student details based on sId values
        fetchStudentDetails(sIds);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [batchId]);


  useEffect(() => {
    const filteredStudents = studentDetails.filter((student) => {
      return (
        student.fullname?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.phone?.toString().includes(searchQuery) ||
        student.gender?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.address?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.race?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredStudentDetails(filteredStudents);
  }, [searchQuery, studentDetails]);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update search query immediately
  };



  // Utility function to compare two arrays
  function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }



  const fetchStudentDetails = async (sIds) => {
    // Convert sIds to numbers and store them in an array
    const sIdNumbers = sIds.map((sId) => Number(sId));

    try {
      // Fetch student details based on sIdNumbers using Axios
      const response = await axios.get('students');
      const studentData = response.data;

      // Filter student data based on matching IDs
      const filteredStudents = studentData.filter((student) =>
        sIdNumbers.includes(student.id)

      );

      setStudentDetails(filteredStudents);
      // console.log(filteredStudents)
    } catch (error) {
      console.error('Error fetching student details:', error);
    }
  };







  const handleSort = (column) => {
    if (column === sortedColumn) {
      // Toggle sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set a new column for sorting
      setSortedColumn(column);
      setSortDirection('asc');
    }
  };


  const sortedStudentDetails = [...studentDetails];


  if (sortedColumn) {
    // Sort the data based on the sorted column and direction
    sortedStudentDetails.sort((a, b) => {
      const aValue = a[sortedColumn];
      const bValue = b[sortedColumn];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        // Compare as strings if both values are strings
        if (sortDirection === 'asc') {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      } else {
        // Handle other data types (e.g., numbers)
        if (sortDirection === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      }
    });
  }
  const renderSortArrow = (column) => {
    if (column === sortedColumn) {
      return sortDirection === 'asc' ? '↑' : '↓';
    }
    return null;
  };


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handlePerPageChange = (event) => {
    const perPage = parseInt(event.target.value, 10);
    setStudentsPerPage(perPage);
    setCurrentPage(1);
  };





  const startIndex = (currentPage - 1) * studentsPerPage;
  const endIndex = startIndex + studentsPerPage;
  const studentsToDisplay = filteredStudentDetails.slice(startIndex, endIndex);


  return (
    <div >
      <TeachHeader />
      <div>
        <div className="containerw mx-auto" style={{ maxWidth: '1200px' }}>
          <Container />
          {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}

          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              float: 'left',
              fontSize: '16px',
            }}
          />
          <TableContainer>
            <Table striped bordered hover responsive>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>DOB</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Postal Code</TableCell>
                  <TableCell>Race</TableCell>
                  <TableCell>Education</TableCell>
                  <TableCell>Employment Status</TableCell>
                  {/* <TableCell>Salary</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>WTS</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {studentsToDisplay.map((student, index) => (
                  <TableRow key={index}>
                    <TableCell >{student.fullname}</TableCell>
                    <TableCell >{student.id}</TableCell>
                    <TableCell >{student.email}</TableCell>
                    <TableCell >{student.phone}</TableCell>
                    <TableCell >{student.gender}</TableCell>
                    <TableCell >{student.dob}</TableCell>
                    <TableCell >{student.address}</TableCell>
                    <TableCell >{student.postalcode}</TableCell>
                    <TableCell >{student.race}</TableCell>
                    <TableCell >{student.eduction}</TableCell>
                    <TableCell >{student.employmentstatus}</TableCell>
                    {/* <TableCell >{student.salary}</TableCell>
                    <TableCell >{student.companyname}</TableCell>

                    <TableCell >{student.designation}</TableCell>
                    <TableCell >{student.corporatecompanysponsored}</TableCell> */}


                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

    </div>
  );
};






export default App;



