import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import axios from '../../Axiosinstance';
import Header from '../../components/Header';
import NewCourseForm from '../../Resource/Course/Newcou';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../../common';
import Loader from '../../Loader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';




const categories = [




];




const columns = [
  { id: 'name', label: 'Course Name', minWidth: 170 },
  { id: 'image', label: 'Image', minWidth: 170 },
  { id: 'reference', label: 'Reference', minWidth: 170 },
  { id: 'duration', label: 'Duration', minWidth: 170 },
  { id: 'fee', label: 'Fee', minWidth: 170 },
  { id: 'contents', label: 'Contents', minWidth: 170 },
  { id: 'feedback', label: 'Feedback', minWidth: 170 },
  { id: 'arTemplates', label: 'AR Template', minWidth: 170 },
  { id: 'action', label: 'Action', minWidth: 170 },
];








const AllCourse = () => {
  const [courses, setCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [coursesPerPage, setCoursesPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteCourseId, setDeleteCourseId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [editedCourse, setEditedCourse] = useState({
    courseName: '',
    description: '',
    instructor: '',
    duration: '',
    category: '', // Initialize 'category' with an empty string or default value
    reference: '',
    categoryId: '',
  });

  const jwtToken = localStorage.getItem('jwtToken');

  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [editedCourseImage, setEditedCourseImage] = useState(null);
  const [editedCourseImageUrl, setEditedCourseImageUrl] = useState(editedCourse ? editedCourse.url : '');
  const [isNewImageUploaded, setIsNewImageUploaded] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Fetch courses when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get('courses', { headers });
        setCourses(response.data.reverse());
        setLoading(false); // Set loading to false when data is received
      } catch (error) {
        console.error('Error fetching course data:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    };


    fetchData();
  }, []);










  useEffect(() => {
    const filtered = courses.filter((course) =>
      course.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredCourses(filtered);
  }, [searchQuery, courses, currentPage, pageSize]);


  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };


  const handleDelete = async (id) => {
    setCourseToDelete(id);
    setShowConfirmationModal(true);
  };




  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`course/${courseToDelete}`, { headers });




      if (response.status === 200) {
        const updatedCourses = courses.filter((course) => course.id !== courseToDelete);
        setCourses(updatedCourses);
        toast.success('Course deleted successfully');
      } else {
        console.error('Failed to delete Course');
      }
    } catch (error) {
      console.error('Error deleting course:', error);
    } finally {
      setShowConfirmationModal(false);
    }
  };




  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setCourseToDelete(null);
  };








  const fetchCategories = async () => {
    try {
      const response = await axios.get('categorys', { headers });
      if (response.status === 200 || 201) {
        setCategories(response.data); // Update the categories state with fetched data
      } else {
        console.error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };




  // Call fetchCategories when the component mounts
  useEffect(() => {
    fetchCategories();
  }, []);










  const openEditModal = (course) => {
    setEditedCourse({
      ...course,
      category: course.category,
      // Make sure this is the correct property name
    });
    setEditedCourse(course);
    setEditedCourseImage(null);
    setEditedCourseImageUrl(course.url);
    setEditModalOpen(true);
  };


  const closeEditModal = () => {
    setEditModalOpen(false);
  };




  const handleSaveEdit = async (e) => {
    e.preventDefault();
  
    try {
      // Check if any required fields are empty
      if (
        !editedCourse.category ||
        !editedCourse.name ||
        !editedCourse.reference ||
        !editedCourse.duration ||
        !editedCourse.qualificationcode ||
        !editedCourse.clustercode ||
        !editedCourse.description ||
        !editedCourse.requirement ||
        !editedCourse.outcome ||
        !editedCourse.fee ||
        !editedCourse.url
      ) {
        toast.error('Please fill in all required fields.');
        return;
      }
  
      // Confirmation dialog for updating course
      const confirmation = window.confirm('Are you sure you want to update this course?');
  
      if (confirmation) {
        // Prepare updated course data
        const selectedCategory = categories.find(cat => cat.name === editedCourse.category);
        const updatedCourseData = {
          ...editedCourse,
          url: editedCourseImageUrl,
          categoryId: selectedCategory ? selectedCategory.id : '',
          // ... other fields
        };
  
        // Send updated course data to the server
        const response = await axios.put(`course/${editedCourse.id}`, updatedCourseData, { headers });
  
        if (response.status === 200) {
          // Update courses state with the updated data
          const updatedCourses = courses.map((course) =>
            course.id === editedCourse.id ? updatedCourseData : course
          );
          setCourses(updatedCourses);
          closeEditModal();
          toast.success('Course updated successfully');
        } else {
          console.error('Failed to update Course');
        }
      } else {
        // Handle cancellation or do nothing
      }
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };
  








  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];


      // Check if a file is selected
      if (!file) {
        return;
      }


      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET); // Replace with your Cloudinary upload preset
      formData.append('public_id', 'LMS/courses/' + file.name);
      const response = await fetch(baseURL, {
        method: 'POST',
        body: formData,
      });


      const data = await response.json();


      if (data.secure_url !== '') {
        const uploadedFileUrl = data.secure_url;
        setEditedCourseImageUrl(uploadedFileUrl);
        setIsNewImageUploaded(true);
      }
    } catch (err) {
      console.error(err);
    }
  };






  return (
    <div>
      {loading ? (
        <Loader /> // Show loader while waiting for API response
      ) : (
        <div className="container" style={{ maxWidth: '1200px' }}>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <input
              type="text"
              placeholder="Search by Name"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: '8px 12px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                display: 'flex',
                fontSize: '16px',
              }}
            />



            <TableContainer
              sx={{
                overflowX: 'auto',
                maxWidth: '100%',
                '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                  overflowX: 'unset', // Enable horizontal scroll
                },
              }}
            >
              <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1, height: '60px' }}>
                    <tr>
                      {columns.map(column => (
                        <th
                          key={column.id}
                          align="left"
                          style={{
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            fontWeight: 'normal',
                            whiteSpace: 'nowrap',
                            fontSize: '15px',
                            textAlign: 'start' // or 'left'
                          }}
                        >
                          {column.label}
                        </th>

                      ))}
                    </tr>
                  </TableHead>




                  <TableBody>
                    {filteredCourses
                      .slice(
                        (currentPage - 1) * coursesPerPage,
                        currentPage * coursesPerPage
                      )
                      .map(course => (
                        <TableRow key={course.id}>
                          <TableCell>{course.name}</TableCell>
                          <TableCell>
                            <img src={course.url} alt={course.name} width="100" height="100" />
                          </TableCell>
                          <TableCell>{course.reference}</TableCell>
                          <TableCell>{course.duration}</TableCell>
                          <TableCell>{course.fee}</TableCell>
                          <TableCell>{course.contents}<Link to={`/Managec/${course.id}`} style={{ textDecoration: 'none' }}>
                            Manage
                          </Link></TableCell>
                          <TableCell>


                            <a href="/Stuendent" style={{ textDecoration: 'none' }}>
                              {course.feedback}
                            </a>
                            <a href="/Coursefeedback" style={{ textDecoration: 'none' }}>
                              Trainer Form
                            </a>
                          </TableCell>
                          <TableCell>

                            <a href={`/Setup/${course.id}`} style={{ textDecoration: 'none' }}>
                              {course.templates}templates
                            </a>

                          </TableCell>
                          <TableCell>
                            {/* <Link to={`/Editc/${course.id}`} className="btn btn-primary">
                      <BsPencilFill />
                    </Link> */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                            <BsPencilFill style={{ marginRight: 10 }} onClick={() => openEditModal(course)} />
                            <DeleteRoundedIcon
                              style={{ color: 'red', marginLeft: 10 }}
                              onClick={() => handleDelete(course.id)}
                            />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredCourses.length}
              labelDisplayedRows={({ from, to }) => `${from}-${to} of ${currentPage}`}
              rowsPerPage={coursesPerPage}
              page={currentPage - 1}
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
              onRowsPerPageChange={event => {
                setCurrentPage(1);
                setCoursesPerPage(event.target.value);
              }}
            />
          </Paper>
        </div>
      )}







      <Dialog open={editModalOpen} onClose={closeEditModal} >
        <DialogTitle>Edit Course (Newton International College)</DialogTitle>




        <DialogContent onSubmit={handleSaveEdit}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <InputLabel>Category*</InputLabel>
            <Select
              name="category"
              value={editedCourse.category}
              onChange={(e) =>
                setEditedCourse({
                  ...editedCourse,
                  category: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              required


            >
              <MenuItem value="">Select Category*</MenuItem>
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.name}>
                  {cat.name}
                </MenuItem>
              ))}
            </Select>








            <TextField
              label="Course Name"
              name="name"
              value={editedCourse.name}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
                const truncatedValue = inputValue.substring(0, 40); // Limit the input to 25 characters
                setEditedCourse({
                  ...editedCourse,
                  name: truncatedValue,
                });
              }}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Reference Number"
              name="reference"
              value={editedCourse ? editedCourse.reference : ''}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '').substring(0, 8); // Allow only digits and limit to 10 characters
                setEditedCourse({
                  ...editedCourse,
                  reference: inputValue,
                });
              }}
              fullWidth
              margin="normal"
              required
            />


            <TextField
              label="Duration"
              name="duration"
              type="number" // Set the type attribute to "number"
              value={editedCourse ? editedCourse.duration : ''}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/[^\d:,-]/g, '').substring(0, 20);
                // Allow only digits and limit to 2 characters
                setEditedCourse({
                  ...editedCourse,
                  duration: inputValue, // Update with the sanitized input value
                });
              }}
              fullWidth
              margin="normal"
              required
            />










            <TextField
              label="qualificationcode"
              name="qualificationcode"
              type="text"
              value={editedCourse ? editedCourse.qualificationcode : ''}
              onChange={(e) => {
                // const inputValue = e.target.value.replace(/\D/g, '').substring(0, 40); // Allow only digits and limit to 2 characters


                setEditedCourse({
                  ...editedCourse,
                  qualificationcode: e.target.value,
                })
              }}

              fullWidth
              margin="normal"
              required

            />

            <TextField
              label="Registration Fee"
              name="clustercode"
              type="number"
              value={editedCourse ? editedCourse.clustercode : ''}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '').substring(0, 8); // Allow only digits and limit to 2 characters


                setEditedCourse({
                  ...editedCourse,
                  clustercode: inputValue
                })
              }}

              fullWidth
              margin="normal"
              required

            />




            <label>Description*</label>
            <ReactQuill
              modules={{
                // ... (your toolbar configuration)
              }}
              placeholder="Type your description here..."
              value={editedCourse ? editedCourse.description : ''}
              onChange={(content) =>
                setEditedCourse((prevCourse) => ({
                  ...prevCourse,
                  description: content,
                }))
              }
              fullWidth
              margin="normal"
              required

            />
            <label>Requirement*</label>
            <ReactQuill
              modules={{
                // ... (your toolbar configuration)
              }}
              placeholder="Type your requirements here..."
              value={editedCourse ? editedCourse.requirement : ''}
              onChange={(content) =>
                setEditedCourse((prevCourse) => ({
                  ...prevCourse,
                  requirement: content,
                }))
              }
              fullWidth
              margin="normal"
              required

            />
            <label>Outcome*</label>
            <ReactQuill
              modules={{
                // ... (your toolbar configuration)
              }}
              placeholder="Type your outcome here..."
              value={editedCourse ? editedCourse.outcome : ''}
              onChange={(content) =>
                setEditedCourse((prevCourse) => ({
                  ...prevCourse,
                  outcome: content,
                }))
              }
              fullWidth
              margin="normal"
              required


            />

            <TextField
              label="Fee"
              required
              name="fee"
              type="number"
              value={editedCourse ? editedCourse.fee || '' : ''}
              onChange={(e) => {
                const inputValue = e.target.value.replace(/\D/g, '').substring(0, 10);


                setEditedCourse({
                  ...editedCourse,
                  fee: inputValue,
                })
              }}
              fullWidth
              margin="normal"
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}

            />

          </div>
          <div className="form-group">
            <label htmlFor="fileupload">Upload Image:</label>
            <input type="file" id="fileupload" onChange={handleImageUpload} />
          </div>

          {/* Display the uploaded image */}
          <div>
            <p>Image*</p>
            {isNewImageUploaded ? (
              <img style={{ width: '150px', height: '100px' }} src={editedCourseImageUrl} alt="Uploaded" />
            ) : (
              <img style={{ width: '150px', height: '100px' }} src={editedCourse ? editedCourse.url : ''} alt="Existing" />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEdit} variant="contained" color="primary" style={{ backgroundColor: 'green', color: 'white' }}>
            Update
          </Button>
          <Button onClick={closeEditModal} variant="contained" color="secondary" style={{ backgroundColor: 'red', color: 'white' }}>
            Cancel
          </Button>

        </DialogActions>

      </Dialog>

      <Modal
     
       centered
        show={showConfirmationModal}
        onHide={handleCancelDelete}
        // style={{
        //   display: 'flex',
        //   flexDirection:'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   // paddingTop: '400px',
        // }}
      >
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this course?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelDelete} style={{ backgroundColor: 'green', color: 'white' }}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white' }}>
              Delete
            </Button>
          </Modal.Footer>
        </div>
      </Modal>




      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
    </div>


  );
};



const AllCourseTab = () => {
  const [tabValue, setTabValue] = useState(0);




  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };




  const navigateToAllCourses = () => {
    // Set the tab value to navigate to the "AllCourse" tab
    setTabValue(0);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div>
      <Header />
      <br />
      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="all-course-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
              "& span": {
                fontWeight: 'bold', // Make the font bold
              },
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="All Courses" sx={{ minWidth: isMobile ? '50%' : '620px' }} />
          <Tab label="New Course" sx={{ minWidth: isMobile ? '50%' : '600px' }} />
        </Tabs>
      </div>
      <br />
      <div >
        {tabValue === 1 && <NewCourseForm onCourseCreated={navigateToAllCourses} />}
        {tabValue === 0 && <AllCourse />}
      </div>
    </div>
  );

};


export default AllCourseTab;
