import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';


  
const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
    
      <div className="navigation-buttons">
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
        </button>
        
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
        </button>
      </div>
    </div>
  );
};



const Activity = () => {
  return (
    <div>
      <Header />
      <NavigationButtons />

    </div>
  );
};

export default Activity;
