import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';

const D = () => {
    return (
      <div className="navigation-buttons" style={{position:'absolute', marginRight:'20px'}}>
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: 'white' }}>Clear cache</a>
        </button>
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: 'white' }}>Migrate Db</a>
        </button>
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/' style={{ textDecoration: 'none', color: 'white' }}>Backup Db</a>
        </button>
      
      </div>
    );
  };

  const NavigationButtons = () => {
    return (
        <div className="navigation-buttons">
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
       </button>
       <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
       </button>
       <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
       </button>
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
       </button>
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
       </button>
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
       </button>
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
       </button>
      
       <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
       </button>
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
         <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
       </button>
    
      
      </div>
    );
  };
  
  
  const Payment = () => {
    return (
      <div>
        <Header />
        <NavigationButtons />
        <D/>
       
      </div>
    );
  };
  
  export default Payment;
  