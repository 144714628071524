import React, { useState } from "react";

function CourseCatagories() {
  const [email, setEmail] = useState("");

  const handleSendPassword = () => {
    if (email) {
      console.log(`Send password reset link to ${email}`);
    } else {
      console.log("Email is required");
    }
  };

  const styles = {
    container: {
      maxWidth: "400px",
      margin: "0 auto",
      padding: "20px",
      border: "1px solid #ddd",
      borderRadius: "5px",
      backgroundColor: "lightgreen",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "80vh",
      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },
    heading: {
      textAlign: "center",
      marginTop: "0",
      marginBottom: "20px",
      color: "#333",
      fontSize: "24px",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    form: {
      width: "100%",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      color: "#555",
      fontWeight: "bold",
    },
    input: {
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      borderRadius: "3px",
      border: "1px solid #ddd",
      outline: "none",
    },
    buttonGroup: {
      display: "flex",
      justifyContent: "flex-end",
    },
    sendPasswordLink: {
      padding: "12px",
      fontSize: "16px",
      color: "white",
      textDecoration: "none",
      borderRadius: "5px",
      cursor: "pointer",
      backgroundColor: "black", // Change background color to black
      transition: "background-color 0.3s ease",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Reset Password</h2>
      <form style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>
            Email:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.input}
          />
        </div>
        <div style={styles.buttonGroup}>
          <a
            href="/Otp"
            style={styles.sendPasswordLink}
            onClick={handleSendPassword}
          >
            Send Password Link
          </a>
        </div>
      </form>
    </div>
  );
}

export default CourseCatagories;
