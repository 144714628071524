import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Header from '../components/Header';
import Notification from './Notification';
import Manage from './Manage';
import Attend from './Attend';
import A from './A';
import Invoice from './Invoice';
import Downloads from './Downloads';
import Container from './Container';
import axios from '../Axiosinstance';
import {  useParams } from 'react-router-dom';
import Recordings from './Recordings';

function Managebatch() {
  const [tabValue, setTabValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const { batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('activeTab', newValue); 
  };

  const handleDropdownChange = (event) => {
    setTabValue(event.target.value);
    localStorage.setItem('activeTab', event.target.value);
  };

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch batch details
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      }
    };

    fetchBatchDetails();
  }, [batchId]);


  useEffect(() => {
    // Retrieve active tab index from localStorage on page load
    const storedTab = localStorage.getItem('activeTab');
    if (storedTab !== null) {
      setTabValue(parseInt(storedTab)); // Parse storedTab to integer
    }
  }, []);
  
  if (!batchDetails) {
    return <p>Loading...</p>;
  }


  return (
    <div>
      <div>
        <Header />
        <br />
      </div>


      {isMobileView ? (
        <div>
          <Select value={tabValue} onChange={handleDropdownChange} displayEmpty>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            <MenuItem value={0}>Manage Student</MenuItem>
            <MenuItem value={1}>Notification</MenuItem>
            <MenuItem value={2}>Attendance</MenuItem>
            <MenuItem value={3}>Recordings</MenuItem>
            <MenuItem value={4}>Assessment</MenuItem>
            {/* <MenuItem value={5}>Invoice</MenuItem> */}
            {/* <MenuItem value={6}>Download</MenuItem> */}
          </Select>


          {/* Wrap the Select and Container in a container div with styling */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
           
          </div>


          <div>
          <Container batchDetails={batchDetails} />
            {tabValue === 0 && <Manage  batchDetails={batchDetails}  />}
            {tabValue === 1 && <Notification />}
            {tabValue === 2 && <Attend />}
            {tabValue === 4 && <A  batchDetails={batchDetails}/>}

            {tabValue === 3 && <Recordings />}
     
            {/* {tabValue === 5 && <Invoice />} */}
            {/* {tabValue === 6 && <Downloads />} */}
          </div>
        </div>
      ) : (
        <div style={{  alignItems: 'center' , marginTop: '-1%', padding: '0% 15%'}}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="Manage-tabs"
           variant="fullWidth"
          sx={{
              "& button": {
                borderRadius: 10,
                color: "#555",
                margin: '30px 5px',  // Center the tabs horizontally
             
           fontSize:'12px',
              
             
              },
              "& button:hover": {
                backgroundColor: "#eee",
              },
              "& button:active": {
                backgroundColor: "#ddd",
              },
              "& button.Mui-selected": {
                backgroundColor: "#007bff",
                color: "#fff",
              },
              "& .MuiTabs-indicator": {
                display: 'none', // Remove the underline
              },
            }}
          >
                       
            <Tab label="Manage Student" />
            <Tab label="Notification" />
            <Tab label="Attendance" />
            <Tab label="Recordings" />
            <Tab label="Assessment" />
            {/* <Tab label="Invoice" /> */}
            {/* <Tab label="Download" /> */}
          </Tabs>


          {/* Wrap the Tabs and Container in a container div with styling */}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
         
         
          <Container batchDetails={batchDetails} />
            {tabValue === 0 && <Manage  batchDetails={batchDetails}  />}
            {tabValue === 1 && <Notification />}
            {tabValue === 2 && <Attend />}
            {tabValue === 4 && <A  batchDetails={batchDetails}/>}
            {tabValue === 3 && <Recordings />}
            {/* {tabValue === 5 && <Invoice />} */}
            {/* {tabValue === 6 && <Downloads />} */}
     
          </div>
        </div>
      )}
    </div>
  );
}


export default Managebatch;



