import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Header from './Headstu';
import axios from '../Axiosinstance'
import { useParams } from 'react-router-dom';
import { Table, Button, Typography, Box, Paper, Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTrash } from 'react-icons/fa';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePowerpoint } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon you want to use




const extractYouTubeVideoId = (url) => {
  // Extract the video ID from the YouTube URL
  const videoId = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|embed\/watch\?feature=player_embedded&v=))([^&\n?#]+)/);
  return videoId ? videoId[1] : null;
};

const Key = () => {
  const { id } = useParams();
  const [curriculumData, setCurriculumData] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);
  const [selectedContentUrl, setSelectedContentUrl] = useState(null);

  useEffect(() => {
    axios.get('uploadcurriculums')
      .then((response) => {
        setCurriculumData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  const filteredData = curriculumData.filter(
    (curriculum) => curriculum.course_id === parseInt(id, 10)
  );





  const displayContent = (content) => {
    setSelectedContent(content);
    setSelectedContentType(content.type);
    setSelectedContentUrl(content.fileUrl);
  };



  return (
    <div className='containerw' style={{ marginTop: '3%' }}>
      <Box mt={2} maxWidth="1200px">
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={12} md={3}>
              <Box p={3}>
                <Typography variant="h5">
                  <FontAwesomeIcon icon={faFilePowerpoint} style={{ marginRight: '8px' }} />
                  Course Content
                </Typography>
                <br />
                <Table>
                  <tbody>
                    {filteredData.map((curriculum, index) => (
                      <React.Fragment key={curriculum.id}>
                        <tr
                          onClick={() => displayContent(curriculum)}
                          style={{
                            cursor: 'pointer',
                            background: selectedContent && selectedContent.id === curriculum.id ? '#c1c7c7' : '',
                            lineHeight: '2',
                            marginBottom: '8px',
                            borderRadius: '10px',
                            transition: 'background 0.3s ease-in-out',
                            boxShadow: '0 4px 4px rgba(0.3, 0, 0, 0.3)',
                            padding: '12px'
                          }}
                        >
                          <td style={{ fontSize: '18px' }}>{curriculum.sectionName}</td>
                        </tr>
                        {index !== filteredData.length - 1 && <br />} {/* Add <br> except after the last element */}
                      </React.Fragment>
                    ))}
                  </tbody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box p={3} ml={2}>
                {selectedContent && (
                  <div>
                    {selectedContent.type === 'pdf' ? (
                      <div>
                        <iframe src={selectedContent.fileUrl + "#toolbar=0"} width="800" height="800"></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'ppt' || selectedContent.type === 'docx' ? (
                      <div>
                        <iframe
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            selectedContent.fileUrl
                          )}`}
                          width="800"
                          height="500"
                        ></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'video' ? (
                      <div>
                        <video width="800" height="400" controls>
                          <source src={selectedContent.fileUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        {renderDownloadButton(selectedContent, 'video')}
                      </div>
                    ) : selectedContent.type === 'audio' ? (
                      <div>
                        <audio controls>
                          <source src={selectedContent.fileUrl} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                        {renderDownloadButton(selectedContent, 'audio')}
                      </div>
                    ) : selectedContent.type === 'text' ? (
                      <div>
                        {/* Render HTML content with formatting */}
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: selectedContent.fileUrl }} />
                        {renderDownloadButton(selectedContent, 'text')}
                      </div>
                    ) : (
                      <div>
                        <iframe
                          src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                            selectedContent.fileUrl
                          )}`}
                          title="YouTube Video"
                          width="800"
                          height="400"
                        ></iframe>
                        {/* YouTube link - No download button */}
                      </div>

                    )}


                  </div>
                )}


              </Box>
              <Button variant="contained" color="error" style={{ float: 'right', marginBottom: '5%', marginRight: '-40%' }}  >
                <a href='/Dashboard' style={{ textDecoration: 'none', color: 'white' }}  >  Back  </a>
              </Button>
            </Grid>

          </Grid>

        </Paper>
      </Box>


    </div>
  );
};

const Managec = () => {
  return (
    <div >
      <Header />

      <div>
        <Key />
      </div>

    </div>
  );
};

export default Managec;

function renderDownloadButton(content, type) {
  // Check if the content type is not a YouTube link and type is defined
  if (type && type !== 'youtube') {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownload(content.fileUrl, type)}>
        Download {type.toUpperCase()}
      </Button>
    );
  }

  // No download button for YouTube links or if type is undefined
  return null;
}

function handleDownload(fileUrl, type) {
  // Implement download logic here
  if (type === 'text') {
    downloadTextFile(fileUrl, 'download.txt');
  } else if (type === 'pdf' || type === 'ppt') {
    downloadFile(fileUrl, `download.${type}`);
  } else {
    window.location.href = fileUrl; // This is a simple example; improve based on your requirements
  }
}


function downloadTextFile(htmlContent, fileName) {
  const element = document.createElement('a');

  // Convert HTML content to formatted plain text
  const plainTextContent = convertHtmlToPlainText(htmlContent);

  const file = new Blob([plainTextContent], { type: 'text/plain' });

  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
}

function convertHtmlToPlainText(htmlContent) {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  const textContent = doc.body.textContent || '';
  return decodeHtmlEntities(textContent);
}

function decodeHtmlEntities(text) {
  const element = document.createElement('div');
  element.innerHTML = text;
  return element.innerText;
}

function downloadFile(fileUrl, fileName) {
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const element = document.createElement('a');
      const file = new Blob([blob], { type: 'application/octet-stream' });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}



