import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../Axiosinstance';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common'
import { Modal, Button, Form } from 'react-bootstrap';


const NewStudent = ({ onFormSubmit }) => {
  const navigate = useNavigate();



  const CLOUDINARY_URL = baseURL;
  const CLOUDINARY_UPLOAD = CLOUDINARY_UPLOAD_PRESET;


  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const defaultDate = '2000-01-01';
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [nric, setNric] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState(defaultDate);
  const [password, setPassword] = useState('');
  const [address, setAddress] = useState('');
  const [postalcode, setPostalcode] = useState('');
  const [race, setRace] = useState('');
  const [eduction, setEducation] = useState('');
  const [employmentstatus, setEmploymentStatus] = useState('');
  const [salary, setSalary] = useState('');
  const [companyname, setCompanyName] = useState('');
  const [corporatecompanysponsored, setCorporateSponsored] = useState('');
  const [designation, setDesignation] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({ name: '', race: '' });
  const [studentId, setStudentId] = useState('')

  const genderOptions = ["Male", "Female", "Other"];


  const handleFormClose = () => {
    navigate('/Allstudent'); // Navigate to the desired page or close the form
  };




  const today = new Date();
  const minDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());


  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isUploading) {
      try {
        const formData = {
          // Define your form data fields here
          fullname,
          email,
          nric,
          phone,
          gender,
          dob,
          password,
          address,
          postalcode,
          race,
          eduction,
          employmentstatus,
          salary,
          companyname,
          corporatecompanysponsored,
          designation,
          profileUrl,
          studentId,
        };

        const formDataWithImage = new FormData();

        // Append all form data fields
        Object.keys(formData).forEach((key) => {
          formDataWithImage.append(key, formData[key]);
        });

        if (selectedFile) {
          const timestamp = Date.now(); // Get current timestamp
          const fileExtension = selectedFile.name.split('.').pop(); // Get the file extension
          const fileNameWithTimestamp = `${timestamp}.${fileExtension}`;
          // Append the file data if an image is selected
          formDataWithImage.append('file', selectedFile, fileNameWithTimestamp); // Use the updated file name
          formDataWithImage.append('upload_preset', CLOUDINARY_UPLOAD);
          formDataWithImage.append('public_id', 'LMS/Student/' + fileNameWithTimestamp); // Also update the public_id

          setIsUploading(true);


          try {
            const response = await fetch(CLOUDINARY_URL, {
              method: 'POST',
              body: formDataWithImage,
            });
            const data = await response.json();
            if (data.secure_url !== '') {
              const url = data.secure_url;
              formDataWithImage.set('profileUrl', url);
              setProfileUrl(url);
              setIsUploading(false);

              // Continue with student registration using the updated profile URL
              const studentResponse = await axios.post('student', {
                fullname,
                email,
                nric,
                phone,
                gender,
                dob,
                password,
                address,
                postalcode,
                race,
                eduction,
                employmentstatus,
                salary,
                companyname,
                corporatecompanysponsored,
                designation,
                profileUrl: url, // Set the updated profile URL
                studentId,
              });

              const studentIdi = studentResponse.data.id;

              await axios.post('auth/create-user', {
                username: fullname,
                name: fullname,

                password,
                email,
                usertype: 1,
                sfid: studentIdi,
                profile_Url: url,
              });

              // Clear the form fields
              setFullname('');
              setEmail('');
              setPhone('');
              setGender('');
              setDob('');
              setPassword('');
              setAddress('');
              setPostalcode('');
              setRace('');
              setEducation('');
              setEmploymentStatus('');
              setSalary('');
              setCompanyName('');
              setCorporateSponsored('');
              setDesignation('');
              setNric('');
              setStudentId('');

              // Navigate to the desired page
              onFormSubmit();
            }
          } catch (err) {
            console.error(err);
            setIsUploading(false);
          }
        } else {
          // If no image is selected
          // Continue without uploading an image
          const studentsResponse = await axios.get('students');
          const existingStudents = studentsResponse.data;

          const emailExists = existingStudents.some((student) => student.email === email);

          if (emailExists) {
            setShowAlert(true);
          } else {
            const studentFullname = fullname;

            const studentResponse = await axios.post('student', {
              fullname: studentFullname,
              email,
              nric: 65465455465,
              phone: 60541545,
              gender,
              dob,
              password,
              address ,
              postalcode,
              race,
              eduction,
              employmentstatus,
              salary,
              companyname,
              corporatecompanysponsored,
              designation,
              profileUrl,
              studentId,
            });

            const studentIdi = studentResponse.data.id;


            await axios.post('auth/create-user', {
              username: studentFullname,
              name: studentFullname,

              password,
              email,
              usertype: 1,
              sfid: studentIdi,
              profile_Url: profileUrl,
            });

            // Clear the form fields
            setFullname('');
            setEmail('');
            setPhone('');
            setGender('');
            setDob('');
            setPassword('');
            setAddress('');
            setPostalcode('');
            setRace('');
            setEducation('');
            setEmploymentStatus('');
            setSalary('');
            setCompanyName('');
            setCorporateSponsored('');
            setDesignation('');
            setNric('');
            setStudentId('');


            // Navigate to the desired page
            onFormSubmit();
          }
        }
      } catch (error) {
        console.log('Error occurred while adding student:', error);
      }
    }
  };


  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };


  const handleFormCancel = () => {
    setFullname('');
    setEmail('');
    setPhone('');
    setGender('');
    setDob('');
    setPassword('');
    setAddress('');
    setPostalcode('');
    setRace('');
    setEducation('');
    setEmploymentStatus('');
    setSalary('');
    setCompanyName('');
    setCorporateSponsored('');
    setDesignation('');
    setNric('')
    setStudentId('');
    onFormSubmit();
  };


  const [isFormDirty, setIsFormDirty] = useState(false);




  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };




  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };




    window.addEventListener('beforeunload', handleBeforeUnload);




    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);

  const [isFocused, setIsFocused] = useState(false);



  return (


    <div className="containerw mx-auto" style={{
      maxWidth: '1200px', background: '#ffffff', padding: '20px',
      boxShadow: '0px 0px 2px 0px #000000', borderRadius: '10px', marginTop: '1%'
    }}>





      <h4 >New Student (Newton International College)</h4>
      <hr style={{ marginTop: '1%' }} />
      {showAlert && (
        <div className="alert alert-danger" role="alert">
          Student with the same email already exists!
        </div>
      )}
      <form onSubmit={handleSubmit}


        onChange={handleInputChange}
        style={{ display: 'flex', flexDirection: 'row' }} >
        <div style={{ marginRight: '20px', flex: 1 }}>
          <div className="form-grup">
            <label htmlFor="name">Name*</label>
            <input
              type="text"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              id="fullname"
              className="form-control"
              value={fullname}

              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z\s]+$/;

                // Ensure the input does not exceed 25 characters and only contains letters and spaces
                if (inputValue.length <= 25 && (onlyLetters.test(inputValue) || inputValue === "")) {
                  setFullname(inputValue);
                }
              }}
              required
            />




          </div>

          <div className="form-group">
            <label htmlFor="studentId">Student ID</label>
            <input
              type="text"
              id="studentId"
              name="studentId"
              value={studentId} // Step 2: Use studentId state for input value
              onChange={(e) => setStudentId(e.target.value)} // Step 3: Update studentId state
              className="form-control"
              style={{ /* Styles */ }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              id="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="nric">NRIC*</label>
            <input
              type="text"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              id="nric"
              className="form-control"
              value={nric}
              onChange={(e) => {
                // Ensure the input does not exceed 12 characters
                const inputValue = e.target.value.slice(0, 9);
                setNric(inputValue);
              }}
              required
            />

          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone*</label>
            <input
              type="tel"  // Use "tel" type for phone numbers
              id="phone"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              className="form-control"
              value={phone}
              onChange={(e) => {
                const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                const formattedText = inputText.substring(0, 8); // Limit to 12 digits
                setPhone(formattedText);
              }}
              required
            />
          </div>


          <div className="form-group">
            <label htmlFor="gender">Gender*</label>
            <select
              id="gender"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              className="form-control"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              {genderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth*</label>
            <input
              type="date"
              id="dob"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              className={`form-control`}
              value={dob}
              onChange={(e) => setDob(e.target.value)}
              max={formatDate(new Date())} // Set the max attribute to today's date
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              id="password"
              className="form-control"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address*</label>
            <input
              type="text"
              id="address"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              className="form-control"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="col-md-8">
            <label htmlFor="profileUrl" className="form-label">Profile image</label>
            <input type="file" name="file" onChange={handleFileChange}
              className="form-control" style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
            />
            {selectedFile && <div>Selected File: {selectedFile.name}</div>}


          </div>
        </div>
        <div style={{ flex: 1 }}>


          <div className="form-group">
            <label htmlFor="postalcode">Postal Code*</label>
            <input
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              type="text"
              id="postalcode"
              className="form-control"
              value={postalcode}
              onChange={(e) => {
                const inputText = e.target.value.substring(0, 6); // Limit to 10 characters
                setPostalcode(inputText);
              }}
              required
            />

          </div>
          <div className="form-group">
            <label htmlFor="race">Race*</label>
            <input
              type="text"
              id="race"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}

              className="form-control"
              value={race}
              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces

                if (onlyLetters.test(inputValue) || inputValue === "") {
                  setRace(inputValue);
                }
              }}
              required
            />
            {errors.race && (
              <div className="text-danger">{errors.race}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="education">Education* </label>
            <input
              type="text"
              id="education"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              className="form-control"
              value={eduction}
              onChange={(e) => {
                const inputValue = e.target.value.substring(0, 30); // Limit to 30 characters
                setEducation(inputValue);
              }}
              required
            />


          </div>
          <div className="form-group">
            <label htmlFor="employmentstatus">Employment Status*</label>


            <select
              id="employmentstatus"
              style={{
                width: '100%',
                height: '42px',
                borderRadius: '6px',
                border: `1px solid ${isFocused ? '#007bff' : '#ced4da'}`,
                fontSize: '14px',
                padding: '8px',
                backgroundColor: '#ffffff',
                color: '#495057',
                outline: 'none',
                boxShadow: isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
              }}
              className=""
              value={employmentstatus}
              onChange={(e) => setEmploymentStatus(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              required
            >
              <option value="">Select Employment Status</option>
              <option value="employed">Employed</option>
              <option value="unemployed">Unemployed</option>
              <option value="student">Student</option>
            </select>



          </div>
          <div className="form-group">
            <label htmlFor="salary">Salary</label>
            <input
              type="number"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              id="salary"
              className="form-control"
              value={salary}
              onChange={(e) => {
                const inputValue = e.target.value.substring(0, 10); // Limit to 10 characters
                setSalary(inputValue);
              }}

            />


          </div>
          <div className="form-group">
            <label htmlFor="companyname">Company Name</label>
            <input
              type="text"
              id="companyname"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              className="form-control"
              value={companyname}
              onChange={(e) => {
                const inputValue = e.target.value.substring(0, 30); // Limit to 30 characters
                setCompanyName(inputValue);
              }}

            />

          </div>
          <div className="form-group">
            <label htmlFor="corporatecompanysponsored">Corporate Company Sponsored</label>
            <input
              type="text"
              id="corporatecompanysponsored"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              className="form-control"
              value={corporatecompanysponsored}
              onChange={(e) => {
                const inputValue = e.target.value.substring(0, 30); // Limit to 30 characters
                setCorporateSponsored(inputValue);
              }}

            />

          </div>
          <div className="form-group">
            <label htmlFor="designation">Designation</label>
            <input
              type="text"
              id="designation"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              className="form-control"
              value={designation}
              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces

                if (onlyLetters.test(inputValue) || inputValue === "") {
                  const truncatedValue = inputValue.substring(0, 25); // Limit to 25 characters
                  setDesignation(truncatedValue);
                }
              }}

            />

          </div>

          <br />
          <div className="form-group" style={{ display: 'flex', alignContent: 'end', justifyContent: 'flex-end' }}>
            {selectedFile && isUploading ? (
              <button type="submit" className="btn btn-primary" disabled>
                ....
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  backgroundColor: 'green',
                  fontSize: '15px',
                  width: '100%', // Set button width to 100% for mobile view
                  maxWidth: '200px', // Optionally set a max-width to prevent it from stretching too wide

                  padding: '10px', // Adjust the padding to reduce the gap
                }}
              >
                Submit
              </button>


            )}
            {/* <Link className="btn btn-secondary" to="/Allstudent">Cancel</Link> */}
            <Button type="button" variant="dager" className="btn-cacel" onClick={handleFormCancel} style={{
              backgroundColor: 'red',
              fontSize: '15px',
              width: '100%', // Set button width to 100% for mobile view
              maxWidth: '200px', // Optionally set a max-width to prevent it from stretching too wide

            }}>
              Cancel
            </Button>
            {/* <button type="button" className="btn btn-danger" onClick={handleFormClose}>
          Close
        </button> */}


          </div>
        </div>
      </form>

    </div>

  );
};












// const NavigationButtons = () => {
//   const [showPopUp, setShowPopUp] = useState(false);




//   const handleBulkUploadClick = () => {
//     setShowPopUp(true);
//   };




//   const closePopUp = () => {
//     setShowPopUp(false);
//   };








// return (
// <div className="navigation-buttons" style={{ translate: '26%' }}>
//   <button
//     style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 35px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer', marginRight: '5%', marginBottom: '2%' }}
//   >
//     <a href='/Allstudent' style={{ textDecoration: 'none', color: 'white' }}>All Student</a>
//   </button>
//   <button
//     style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 35px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer', marginRight: '5%', marginBottom: '2%' }}
//   >
//     <a href='/Newstudent' style={{ textDecoration: 'none', color: 'white' }}>New student</a>
//   </button>
{/* <button
        style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer', marginRight: '4%', marginBottom: '2%' }}
        onClick={handleBulkUploadClick}
      >
        Bulk upload
      </button>
      <button
        style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer', marginBottom: '2%' }}
      >
        <a href='/settings' style={{ textDecoration: 'none', color: 'white' }}>Download Sample</a>
      </button> */}
{/* Pop-up message
      {showPopUp && (
        <div className="pop-up">
          <div className="pop-up-content">
            <h3>Bulk Upload</h3>
            <input type="file" accept="image/*" />
            <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-primary">Save</button>
              <button className="btn btn-secondary" onClick={closePopUp}>Close</button>
            </div>
          </div>
        </div>
      )} */}


//        </div>
//   );
// }




const NewStudenth = ({ onFormSubmit }) => {
  return (
    <div>

      {/* <NavigationButtons /> */}
      <NewStudent onFormSubmit={onFormSubmit} />
    </div>
  );
};








export default NewStudenth;









