import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../../components/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const App = () => {
  const [students, setStudents] = useState([
    { id: 1, Question: 'What is your name', Type: 'Short answer' },
    { id: 2, Question: 'What is your name', Type: 'Rating' },
    // Add more student data as needed
  ]);

  const [selectedStudent, setSelectedStudent] = useState(null); // To track the selected student for editing
  const [Question, setQuestion] = useState('');
  const [Type, setType] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newType, setNewType] = useState('');

  const handleEdit = (id) => {
    const student = students.find((student) => student.id === id);
    if (student) {
      setSelectedStudent(student);
      setQuestion(student.Question);
      setType(student.Type);
    }
  };

  const handleCloseModal = () => {
    setSelectedStudent(null);
    setShowSuccessMessage(false);
    setQuestion('');
    setType('');
    setShowCreateModal(false);
    setNewQuestion('');
    setNewType('');
  };

  const handleDelete = (id) => {
    setStudents((prevStudents) => prevStudents.filter((student) => student.id !== id));
  };

  const handleSaveChanges = () => {
    const updatedStudents = students.map((student) =>
      student.id === selectedStudent.id
        ? {
            ...student,
            Question: Question,
            Type: Type,
          }
        : student
    );

    setStudents(updatedStudents);
    setSelectedStudent(null);
    setShowSuccessMessage(true);

    // Reset the form fields
    setQuestion('');
    setType('');
  };
  const handlePrevious = () => {
    // Code to handle previous pagination
    console.log('Previous clicked!');
    // Add your own custom logic here
  };

  const handleNext = () => {
    // Code to handle next pagination
    console.log('Next clicked!');
    // Add your own custom logic here
  };
  

  const handleCreate = (e) => {
    e.preventDefault();

    if (newQuestion && newType) {
      const newStudent = {
        id: students.length + 1,
        Question: newQuestion,
        Type: newType,
      };

      setStudents((prevStudents) => [...prevStudents, newStudent]);
      setShowSuccessMessage(true);

      // Reset the form fields
      setNewQuestion('');
      setNewType('');
      setShowCreateModal(false);
    }
  };

  return (
    <div className="container">
      <button
        style={{
          float: 'right',
          backgroundColor: '#03396c',
          color: 'white',
        }}
        onClick={() => setShowCreateModal(true)}
      >
        Create
      </button>
     
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Question</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.id}>
              <td>{student.id}</td>
              <td>{student.Question}</td>
              <td>{student.Type}</td>
              <td>
                <button className="btn btn-sm btn-danger" onClick={() => handleDelete(student.id)}>
                  <BsTrashFill />
                </button>
                <button className="btn btn-sm btn-primary" onClick={() => handleEdit(student.id)}>
                  <BsPencilFill />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination" style={{ display: 'flex', justifyContent: 'flex-end' }}>
  {/* Pagination buttons */}

  <button
  className="btn btn-primary"
  onClick={handlePrevious}
  style={{
    backgroundColor: '#03396c',
    color: '#fff',
    border: 'none',
    padding: '10px 50px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginRight: '10px',
  }}
>
  Previous
</button>

<button
  className="btn btn-primary"
  onClick={handleNext}
  style={{
    backgroundColor: '#03396c',
    color: '#fff',
    border: 'none',
    padding: '10px 26px',
    borderRadius: '4px',
    cursor: 'pointer',
  }}
>
  Next
</button>




    </div>

      <Modal show={selectedStudent !== null} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveChanges}>
            <div className="form-group">
              <label htmlFor="question">Question</label>
              <input
                type="text"
                className="form-control"
                id="question"
                value={Question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type</label>
              <input
                type="text"
                className="form-control"
                id="type"
                value={Type}
                onChange={(e) => setType(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showCreateModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Create Student</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <form onSubmit={handleCreate}>
      <div className="form-group">
        <label htmlFor="newQuestion">Question</label>
        <input
          type="text"
          className="form-control"
          id="newQuestion"
          placeholder="Enter the question"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          required // Make this field required
        />
      </div>
      <div className="form-group">
        <label htmlFor="newType">Type</label>
        <select
          className="form-control"
          id="newType"
          value={newType}
          onChange={(e) => setNewType(e.target.value)}
          required // Make this field required
        >
          <option value="">Select a type</option>
          <option value="Short answer">Short answer</option>
          <option value="Rating">checkbox</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <button type="submit" style={{ padding: '3px 10px' }}>
        Create
      </button>
    </form>
  </Modal.Body>
</Modal>

   
    </div>
  );
};

const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <a href="/Feedback" style={{ textDecoration: 'none', color: 'white' }}>
    Student Feedback
        </a>
      </button>
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <a href="/Traine1r" style={{ textDecoration: 'none', color: 'white' }}>
        Trainer Feedback
        </a>
      </button>
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <a href="/Gen" style={{ textDecoration: 'none', color: 'white' }}>
      General Question
        </a>
      </button>
      <button
        style={{
          backgroundColor: '#03396c',
          color: 'white',
          border: 'none',
          padding: '10px 20px',
          borderRadius: '4px',
          margin: '5px',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <a href="/Coursep" style={{ textDecoration: 'none', color: 'white' }}>
        Couse Specfic
        </a>
      </button>
    </div>
  );
};

const Allstudent = () => {
  return (
    <div>
      <Header />
      <NavigationButtons />
      <App />
    </div>
  );
};

export default Allstudent;
