import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-quill/dist/quill.snow.css';
















import ResponsiveTable from './Invoice1';
import App from './Invoice2';
import InvoiceForm from './Invoice3';
import Header from '../../components/Header'












const Invoicesetting = () => {
  return (
    <div>
    <Header />
    <div>
      <Col className="justify-content-center" style={{ marginTop: '-1%' }}>
        <div>
          <InvoiceForm />
          <App />
        </div>
        <div  style={{ marginTop: '1%' }} >
          <ResponsiveTable />
        </div>
      </Col>
    </div>
  </div>
  );
};
















export default Invoicesetting;















