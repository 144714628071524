import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from '../components/Header';


import Allstudent from './Allstudent';
import Newstudent from './Newstudent';



const News = () => {
    const [tabValue, setTabValue] = useState(0);



    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };




    return (
        <div>
            <Header />
            <br></br>

            <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="Student-tabs"
                variant="fullWidth"
            >
                <Tab label="All Student" />
                <Tab label="New Student" />

            </Tabs>





            <div>
                {tabValue === 0 && <Allstudent />}
                {tabValue === 1 && <Newstudent />}


            </div>
        </div>
    );
};


export default News;





