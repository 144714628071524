import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Header from '../components/Header';
import Headstu from '../StudentLogin/Headstu';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import   "./Chat.css";
import { FaPhone, FaEnvelope } from 'react-icons/fa';




const ContactForm = () => {
  return (
    // <div className="container ">
      <div className="row" style={{height:'60vh', marginTop:'10%'}}>
        <div className="col-md-3" style={{ background: '#ff9b00', padding: '4%', borderTopLeftRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' }}>
        <div className="contact-info" style={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
            <img src="https://image.ibb.co/kUASdV/contact-image.png" alt="Contact" />
            <h2>Contact Us</h2>
            <h4>We would love to hear from you!</h4>
          </div>
        </div>
        <div className="col-md-9" style={{ background: '#f8f9fa', padding: '3%', borderRadius: '0.5rem', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <div className="contact-container">
      <h2>Contact Information</h2>
      <div className="card-group">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title ">Address</h5>
            <p className="card-text">
              LIDER Technology Solutions Ptd Ltd<br />
              No: 381, TN Road, SP Kovil Street,<br />
              Thiruvottiyur, Chennai-600019
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Phone</h5>
            <p className="card-text">
              <FaPhone className="icon" /> <a href="tel:+6585005636">+65 85005636</a>
            </p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Email</h5>
            <p className="card-text">
              <FaEnvelope className="icon" /> <a href="mailto:impulz.lms@gmail.com">impulz.lms@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
</div>

     
    // </div>
  );
};










const NewbatchPage = () => {
  return (
    <div>
      <Headstu />
      <div className="container-xl px-4 mt-4">
        {/* Rest of the code... */}
        {/* Add the content you want to show below the Header component */}
        <ContactForm />
      </div>
    </div>
  );
};


export default NewbatchPage;



