import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../Axiosinstance';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, Typography, Button } from '@material-ui/core';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../../common';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import NewCourseForm from './AllCourse'
import './NewCourses.css'


const NewCourseFor = ({ onCourseCreated }) => {
  const navigate = useNavigate();




  const [category, setCategory] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [name, setName] = useState('');
  const [reference, setReference] = useState('');
  const [duration, setDuration] = useState('');
  const [qualificationcode, setQualificationCode] = useState('');
  const [clustercode, setClusterCode] = useState('');
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState('');
  const [requirement, setRequirement] = useState('');
  const [outcome, setOutcome] = useState('');
  const [fee, setFee] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [url, setUrl] = useState('');
  const [errors, setErrors] = useState({});
  const [isFormReady, setIsFormReady] = useState(false);


  const [isFocused, setIsFocused] = useState(false);

  const jwtToken = localStorage.getItem('jwtToken');
  
  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};




  const validateForm = () => {
    let isValid = true;
    const newErrors = {};




    // Validate 'category' field
    if (!category) {
      newErrors.category = "Category is required.";
      isValid = false;
    }




    // Validate 'name' field
    if (!name) {
      newErrors.name = "Name is required.";
      isValid = false;
    }
    if (!reference) {
      newErrors.reference = "reference is required.";
      isValid = false;
    }
    if (!duration) {
      newErrors.duration = "duration is required.";
      isValid = false;
    }
    if (!qualificationcode) {
      newErrors.qualificationcode = "qualification code is required";
      isValid = false;
    }
    if (!clustercode) {
      newErrors.clustercode = "cluster code is required.";
      isValid = false;
    }
    if (!categories) {
      newErrors.categories = "categories is required.";
      isValid = false;
    }
    if (!description) {
      newErrors.description = "description is required.";
      isValid = false;
    }
    if (!requirement) {
      newErrors.requirement = "requirement is required.";
      isValid = false;
    }
    if (!fee) {
      newErrors.fee = "fee is required.";
      isValid = false;
    }
    if (!outcome) {
      newErrors.outcome = "outcome is required.";
      isValid = false;
    }




    // Optional validation for image upload
    // if (selectedFiles.length === 0) {
    //   newErrors.image = 'Image is required.';
    //   isValid = false;
    // }




    // Validate other fields similarly...




    // Set the errors state with new error messages
    setErrors(newErrors);




    return isValid;
  };




  useEffect(() => {
    fetchCategories();
  }, []);




  const fetchCategories = () => {
    axios
      .get('categorys', { headers })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };




  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);




    // Find the selected category object from the categories array
    const selectedCategoryObject = categories.find((category) => category.name === selectedCategory);




    if (selectedCategoryObject) {
      // Set the categoryId based on the selected category's id
      setCategoryId(selectedCategoryObject.id);
    } else {
      // Handle the case when the selected category is not found in the categories array
      setCategoryId(''); // You can set it to an appropriate default value or handle it differently
    }




    // Clear the error message when the user interacts with the field
    if (errors.category) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        category: '',
      }));
    }
  };








  const formContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  };








  const handleNameChange = (e) => {
    setName(e.target.value);




    if (errors.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: '',
      }));
    }
  };




  const handleReferenceChange = (e) => {
    setReference(e.target.value);
    if (errors.reference) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        reference: '',
      }));
    }
  };







  const handleDurationChange = (e) => {
    const value = e.target.value;
    setDuration(value);

    // Validate the range
    if (value < 1 || value > 60) {
      setErrors({ duration: 'Duration must be between 1 and 60' });
    } else {
      setErrors({ duration: '' });
    }
  };


  const handleQualificationCodeChange = (e) => {
    setQualificationCode(e.target.value);
    if (errors.qualificationcode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        qualificationcode: '',
      }));
    }
  };




  const handleClusterCodeChange = (e) => {
    setClusterCode(e.target.value);
    if (errors.clustercode) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        clustercode: '',
      }));
    }
  };




  const handleDescriptionChange = (content) => {
    setDescription(content);
    if (errors.description) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: '',
      }));
    }
  };




  const handleRequirementChange = (content) => {
    setRequirement(content);
    if (errors.requirement) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        requirement: '',
      }));
    }
  };




  const handleOutcomeChange = (content) => {
    setOutcome(content);
    if (errors.outcome) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        outcome: '',
      }));
    }
  };




  const handleFeeChange = (e) => {
    setFee(e.target.value);
    if (errors.fee) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fee: '',
      }));
    }
  };




  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];




      // Check if a file is selected
      if (!file) {
        return;
      }




      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET); 
      formData.append('public_id', 'LMS/courses/' + file.name);
      const response = await fetch(baseURL, {
        method: 'POST',
        body: formData,
      });




      const data = await response.json();




      if (data.secure_url !== '') {
        const uploadedFileUrl = data.secure_url;
        setUrl(uploadedFileUrl);
        setIsFormReady(true);
        // console.log(uploadedFileUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };












  const handleSubmit = () => {




    if (!validateForm() || !isFormReady) {
      return; // If the form is not valid, don't submit
    }




    const formData = {
      category,
      categoryId,
      name,
      reference,
      duration,
      qualificationcode,
      clustercode,
      description,
      requirement,
      outcome,
      fee,
      url,
    };












    axios
      .post('course', formData, { headers })
      .then((response) => {
        toast.success('Course created successfully', {
          autoClose: 3000, // Close the toast after 3 seconds
        });








        setCategory('');
        setCategoryId('');
        setName('');
        setReference('');
        setDuration('');
        setQualificationCode('');
        setClusterCode('');
        setDescription('');
        setRequirement('');
        setOutcome('');
        setFee('');
        setUrl('');
        setErrors({});
        setIsFormReady(false);




        // console.log('Data stored successfully!', response.data);
        onCourseCreated();
      })
      .catch((error) => {
        console.error('Error storing data:', error);




        // Handle the error related to the Axios POST request here
      });

  };




  const [formModified, setFormModified] = useState(false);








  useEffect(() => {
    const handleFormModification = () => {
      if (!formModified) {
        setFormModified(true);
      }
    };








    window.addEventListener('input', handleFormModification);








    return () => {
      window.removeEventListener('input', handleFormModification);
    };
  }, [formModified]);








  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formModified) {
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };








    window.onbeforeunload = handleBeforeUnload;








    return () => {
      window.onbeforeunload = null;
    };
  }, [formModified]);








  const handleNavigate = () => {
    onCourseCreated();
  };


  const handleRemoveImage = () => {
    setUrl('');
    setIsFormReady(false);
  };




  return (
    <>
      <div className="containerw mx-auto" style={{
        maxWidth: '1200px', background: '#ffffff', padding: '20px',
        boxShadow: '0px 0px 2px 0px #000000', borderRadius: '10px'
      }}>
        <h4> New Courses (Newton International College)</h4>
        <hr />
        <form style={formContainerStyle}>






          <div style={{ marginRight: '20px', flex: '1', minWidth: '300px' }}>


          <div className="form-group">
  <label htmlFor="name">Name*</label>
  <input
    type="text"
    className="form-control"
    id="name"
    style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
    onChange={(e) => {
      // Remove the regular expression that filters out non-alphabetic characters
      const inputValue = e.target.value;
      const truncatedValue = inputValue.substring(0, 150); // Truncate to a maximum of 50 characters

      // Update the state with the truncated value
      handleNameChange({ target: { value: truncatedValue } });
    }}
    value={name}
  />
  {errors.name && (
    <div className="text-danger">{errors.name}</div>
  )}
</div>



            <div className="form-group">
              <label htmlFor="category">Category*</label>
              <select
                className="form-control"
                id="category"
                onChange={handleCategoryChange}
                value={category}
                required
                style={{
                  width: '100%',
                  height: '42px',
                  borderRadius: '6px',
                  border: `1px solid ${isFocused ? '#007bff' : '#ced4da'}`,
                  fontSize: '14px',
                  padding: '8px',
                  backgroundColor: '#ffffff',
                  color: '#495057',
                  outline: 'none',
                  boxShadow: isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
                }}


              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>


              {errors.category && (
                <div className="text-danger">{errors.category}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="reference">Reference*</label>
              <input
                type="text"
                className="form-control"
                id="reference"
                onChange={(e) => {
                  const inputValue = e.target.value.substring(0, 80); // Allow only digits and limit to 8 characters


                  // Update the state with the modified value
                  handleReferenceChange({ target: { value: inputValue } });
                }}
                value={reference}
                required
                style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              />
              {errors.reference && (
                <div className="text-danger">{errors.reference}</div>
              )}
            </div>


            <div className="form-group">
              <label htmlFor="duration">Course Duration*</label>
              <input
                type="text"
                className="form-control"
                id="duration"
                onChange={(e) => {
                  const inputValue = e.target.value.substring(0, 200); // Allow only digits and limit to 8 characters


                  // Update the state with the modified value
                  handleDurationChange({ target: { value: inputValue } });
                }}
                value={duration}
                required
                // onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              />
              {errors.duration && (
                <div className="text-danger">{errors.duration}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="qualificationcode">Days & Time</label>
              <input
                type="text"
                className="form-control"
                id="qualificationcode"
                onChange={(e) => {
                  const inputValue = e.target.value.substring(0, 80); // Allow only digits and limit to 8 characters


                  // Update the state with the modified value
                  handleQualificationCodeChange({ target: { value: inputValue } });
                }}
                value={qualificationcode}
                required
                // onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              />
              {errors.qualificationcode && (
                <div className="text-danger">{errors.qualificationcode}</div>
              )}
            </div>



            <div className="form-group">
              <label htmlFor="clustercode">Registration Fee*</label>
              <input
                type="text"
                className="form-control"
                id="clustercode"
                onChange={(e) => {
                  const inputValue = e.target.value.substring(0, 80); // Allow only digits and limit to 8 characters


                  // Update the state with the modified value
                  handleClusterCodeChange({ target: { value: inputValue } });
                }}
                value={clustercode}
                required
                // onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              />
              {errors.clustercode && (
                <div className="text-danger">{errors.clustercode}</div>
              )}
            </div>

            <br />
            <div className="mb-3">
              <label htmlFor="fileupload">Upload Images*</label>
              <input type="file" id="fileupload" onChange={handleImageUpload} accept="image/*" />
            </div>

            
            {url && (
              <div style={{ position: 'relative' }}>
                <p>Uploaded Image:</p>
                <img style={{ width: '250px', height: '100px' }} src={url} alt="Uploaded" />
                <div
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    cursor: 'pointer',
                  }}
                  onClick={handleRemoveImage}
                  required
                >
                  <strong>X</strong>
                </div>
              </div>
            )}


          </div>
          <div style={{ flex: 1 }}>
            <div className="form-group">
              <label htmlFor="fee">Course Fee*</label>
              <input
                type="text" // Change type to text to allow custom input logic
                className="form-control"
                id="fee"
                onChange={(e) => {
                  const inputValue = e.target.value.substring(0, 80); // Allow only digits and limit to 8 characters


                  // Update the state with the modified value
                  handleFeeChange({ target: { value: inputValue } });
                }}
                value={fee}
                required
                style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              />
                 <div className="form-group">
              <label htmlFor="description">Entry Requirements*</label>
              <ReactQuill
                value={description}
                onChange={handleDescriptionChange}
                required
              />
              {errors.description && (
                <div className="text-danger">{errors.description}</div>
              )}
            </div>
              {errors.fee && (
                <div className="text-danger">{errors.fee}</div>
              )}
            </div>



   
            <div className="form-group">
              <label htmlFor="requirement">Graduation requirement*</label>
              <ReactQuill
                value={requirement}
                onChange={handleRequirementChange}
                required


              />
              {errors.requirement && (
                <div className="text-danger">{errors.requirement}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="outcome">Teacher and Students ratio*</label>
              <ReactQuill
                value={outcome}
                required
                onChange={handleOutcomeChange}
              />
              {errors.outcome && (
                <div className="text-danger">{errors.outcome}</div>
              )}
            </div>
            <br />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                className={`btn ${isFormReady ? 'btn-success' : 'btn-primary'} me-3`}
                onClick={handleSubmit}
                disabled={!isFormReady}
                style={{
                  borderRadius: '5px',
                  boxShadow: '0px 0px 5px 0px #888888',
                  backgroundColor: isFormReady ? 'green' : 'white',
                  color: isFormReady ? 'white' : 'black',
                }}
              >
                Submit
              </Button>
              <Button
                className="btn btn-danger"
                onClick={handleNavigate}
                style={{
                  borderRadius: '5px',
                  boxShadow: '0px 0px 5px 0px #888888',
                  backgroundColor: 'red',
                  color: 'white',
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
        <ToastContainer />




      </div></>
  );
};




export default NewCourseFor;



