import React from 'react'
import { Link } from "react-router-dom";
import logoImage from "./lider.png";

export default function Footer() {
  return (
    <div className="container">
     <Link className="navbar-brand" to="/login">
    <img
  src={logoImage}
      alt="Logo"
      className="logo-image"
      style={{
        width: '170px',
        height: '90px',
        marginLeft: '-450px',
        marginTop: '-30px',
        paddingTop: '5px',
        // Add any other inline styles you want
      }}
    />
  </Link>
</div>
  )
}
