import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {logoImage} from '../common';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBook, faGraduationCap, faQuestionCircle, faUser, faComment, faDesktop, faSignOutAlt, faChevronDown } from "@fortawesome/free-solid-svg-icons"; // Import FontAwesome icons
import axios from '../Axiosinstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap'; 

 




const Header = () => {
  const [isResourcesOpen, setResourcesOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const toggleResources = () => {
    setResourcesOpen(!isResourcesOpen);
    setHelpOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening resources dropdown
  };




  const toggleHelp = () => {
    setHelpOpen(!isHelpOpen);
    setResourcesOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening help dropdown
  };




  const toggleAccount = () => {
    setAccountOpen(!isAccountOpen);
    setResourcesOpen(false);
    setHelpOpen(false); // Close resources and help dropdowns when opening account dropdown
  };





  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const handleLogout = () => {
    // Remove user data from local storage
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("usertype");
    localStorage.removeItem("studentteacherid");
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');

    document.title = "LMS-impluz";

    // Navigate to the login page
    // navigate("/");

    openModal();
  };

  

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios.get(`home/user/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            // alert(response.status);
            const data = response.data;
            localStorage.setItem("studentteacherid", data.sfid);
            document.title = `impluz - ${data.name}`;
            setStudentName(data.name);
            setProfileUrl(data.profile_Url);
          }

          else {
            throw new Error("Failed to fetch sfid");
          }
        })
        .catch((error) => {
          console.error(error.response);
          if (error == "AxiosError: Network Error")
            handleLogout()
          // alert(error)
          // Handle any errors that occur during the API call
        });
    }
  }, []);


  const toggleHamburger = () => {
    setHamburgerActive((prevState) => !prevState);
    setNavActive((prevState) => !prevState);
  };

  const toggleNav = () => {
    console.log('Toggling navigation menu');
    setNavOpen(!isNavOpen);
  };


  return (
    <div className={`App ${isNavOpen ? 'opened' : ''}`}>
      <nav>
        <div className="logo">
          <Link to='/adhome'>
            <img
              id='imglogo'
              src={logoImage}
              alt="logoImage"
            />
          </Link>
        </div>
        <button className={`btn-hamburger ${isNavOpen ? 'opened' : ''}`} onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`nav-menu ${isNavOpen ? 'opened' : ''}`}>
          <li className="nav-item home-item">
            <Link className="nav-link home-link" to="/Teacher">
              <FontAwesomeIcon icon={faHome} /> HOME
            </Link>
          </li>
          {/* <li className="nav-item home-item">
            <Link className="nav-link course-link" to="/Teacher">
              <FontAwesomeIcon icon={faBook} /> COURSE
            </Link>
          </li> */}
          <li className="nav-item home-item">
            <Link className="nav-link notification-link" to="/Notifications">
              <FontAwesomeIcon icon={faGraduationCap} /> NOTIFICATION
            </Link>
          </li>
          {/* <li className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle help-link"
              to="/services"
              id="helpDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faQuestionCircle} /> HELP
            </Link>
            <div className="dropdown-menu" aria-labelledby="helpDropdown">
              <Link className="dropdown-item" to="/Tvcontact">
                <FontAwesomeIcon icon={faUser} /> Contact us
              </Link>
              <Link className="dropdown-item" to="/services/option3">
                <FontAwesomeIcon icon={faComment} /> Live chat
              </Link>
              <Link className="dropdown-item" to="/services/option3">
                <FontAwesomeIcon icon={faDesktop} /> Remote Desktop
              </Link>
            </div>
          </li> */}
          <li className="nav-item dropdown" >
            <Link
              className="nav-link dropdown-toggle my-account-link"
              to="/services"
              id="profileDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FontAwesomeIcon icon={faUser} /> {studentName} <FontAwesomeIcon icon={faChevronDown} />
            </Link>
            <div className="dropdown-menu mx-auto" aria-labelledby="profileDropdown">
              <Link className="dropdown-item" to="/TProfile">
                <FontAwesomeIcon icon={faUser} /> Profile
              </Link>
              <Link className="dropdown-item" to="/TeacherChat">
               <FontAwesomeIcon icon={faUser} /> Contact us
              </Link>
              <Link className="dropdown-item" to="/login" onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} /> Log out
              </Link>
              
            </div>
          </li>
        </ul>
      </nav>
      <div className="container">
        {/* Content of your page goes here */}
      </div>
      <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
        <Modal.Body>
          <p>Your session has been closed.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { navigate("/login") }}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


export default Header;





