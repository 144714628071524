import React, { useState, useEffect } from 'react';
import axios from '../../Axiosinstance';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  Snackbar,


} from '@material-ui/core';
import { useParams, Link } from 'react-router-dom';
import { TableContainer } from '@material-ui/core';
import Header from '../../components/Header'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
  // Add your custom styles here
  addButton: {
    margin: theme.spacing(2),
  },
  tableContainer: {
    margin: 'auto', // Center the table
    display: 'flex',
    justifyContent: 'center',
  },
}));


const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dyjwwi8ea/raw/upload';
const CLOUDINARY_UPLOAD_PRESET = 'vldqgwk8';

const TemplateForm = ({ open, handleClose, addTemplate }) => {

  const { id } = useParams();
  const classes = useStyles();

  const jwtToken = localStorage.getItem('jwtToken');

  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};

  const initialFormData = {
    type: '',
    courseId: id,
    learningOutcome: '',
    questLabels: { '1': '' },
    criteria: { '1': '' },
    pdfFile: null,
    pdfLabels:{'':''}

  };


  const [errors, setErrors] = useState({
    type: '',
    learningOutcome: '',
    questLabels: {},
    criteria: {},
    pdfFile: '',
    pdfLabels:{}

  });



  const [formData, setFormData] = useState(initialFormData);


  const showToast = (message, type = 'success') => {
    toast(message, {
      type,
      position: 'bottom-right',
    });
  };


  const handleInputChange = (e) => {
    const { name, value, files, } = e.target;
      if (name === 'pdfFile') {
      // If the input is a file input, update pdfFile separately
      setFormData((prevData) => ({
        ...prevData,
        pdfFile: files[0] 
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };


  const handleQuestLabelChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      questLabels: { ...prevData.questLabels, [key]: value },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      questLabels: { ...prevErrors.questLabels, [key]: '' },
    }));
  };

  const handleCriteriaChange = (e, key) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      criteria: { ...prevData.criteria, [key]: value },
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      criteria: { ...prevErrors.criteria, [key]: '' },
    }));
  };



  const handleAddSet = () => {
    const newSetKey = Object.keys(formData.criteria).length + 1;


    setFormData((prevData) => ({
      ...prevData,
      questLabels: { ...prevData.questLabels, [newSetKey]: '' },
      criteria: { ...prevData.criteria, [newSetKey]: '' },
    }));
  };


  const handleSave = () => {
    const { type, learningOutcome, questLabels, criteria } = formData;
    const newErrors = {};

    if (type.trim() === '') {
      newErrors.type = 'Please select a Type';
    }

    if (learningOutcome.trim() === '') {
      newErrors.learningOutcome = 'Please fill in Learning Outcome';
    }

    // Validate Quest Labels
    Object.entries(questLabels).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors.questLabels = { ...newErrors.questLabels, [key]: 'Please fill in Quest Label' };
      }
    });

    // Validate Criteria
    Object.entries(criteria).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors.criteria = { ...newErrors.criteria, [key]: 'Please fill in Criteria' };
      }
    });

    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showToast('Please check for errors in the form', 'error');
      return;
    }

    addTemplate(formData);
    showToast('Template added successfully');
    setFormData(initialFormData); // Reset the form
    handleClose();
  };

  


  const handleSavePDF = async () => {
    const { type, learningOutcome,criteria, pdfFile } = formData;
    const newErrors = {};
  
    if (type.trim() === '') {
      newErrors.type = 'Please select a Type';
    }
  
    if (learningOutcome.trim() === '') {
      newErrors.learningOutcome = 'Please fill in Learning Outcome';
    }
  
    if (!pdfFile) {
      newErrors.pdfFile = 'Please upload a PDF file';
    }

    // Validate Criteria
    Object.entries(criteria).forEach(([key, value]) => {
      if (value.trim() === '') {
        newErrors.criteria = { ...newErrors.criteria, [key]: 'Please fill in Criteria' };
      }
    });
  
    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showToast('Please check for errors in the form', 'error');
      return;
    }
  
    try {
      // Upload PDF file to Cloudinary
      const formData1 = new FormData();
      formData1.append('file', pdfFile);
      formData1.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      formData1.append('public_id', 'LMS/PDF_assessment/' + pdfFile.name);
  
      const response = await fetch(CLOUDINARY_URL, {
        method: 'POST',
        body: formData1,
      });
  
      const data = await response.json();
      if (data.secure_url) {
        const uploadedFileUrl = data.secure_url;
  
        // Update formData with the uploaded file URL
        const updatedFormData = {
          ...formData,
          pdfLabels: { url: uploadedFileUrl },
        };
  
        // Call the function to save the form data
        console.log(uploadedFileUrl);
        await addTemplate(updatedFormData);
        showToast('Template added successfully');
        setFormData(initialFormData); // Reset the form
        handleClose();
      } else {
        showToast('Failed to upload PDF', 'error');
      }
    } catch (err) {
      console.error(err);
      showToast('An error occurred during upload', 'error');
    }
  };
  

  



  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Template</DialogTitle>
      <DialogContent>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel>Type *</InputLabel>
          <Select
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            required
            error={!!errors.type}
          >
            <MenuItem value="Written Assessment">Written Assessment</MenuItem>
            <MenuItem value="Role Play">Role Play</MenuItem>
            <MenuItem value="Oral Assessment">Oral Assessment</MenuItem>
            <MenuItem value="Written PDF">Written PDF</MenuItem> {/* Add Written PDF option */}
          </Select>
        </FormControl>


        <TextField
          label="Learning Outcome"
          name="learningOutcome"
          value={formData.learningOutcome}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
          required
          error={!!errors.type}
        />

        {formData.type === 'Written PDF' ? ( // Conditionally render file upload option
          <FormControl fullWidth error={!!errors.pdfFile}>
            <>
             <label>
              Criteria:
              <ul>
                {Object.entries(formData.criteria).map(([key, value]) => (
                  <li key={key}>
                    <TextField
                      type="text"
                      name={key}
                      value={value}
                      onChange={(e) => handleCriteriaChange(e, key)}
                      required
                      error={!!errors.criteria[key]}
                      helperText={errors.criteria[key]}
                    />
                  </li>
                ))}
              </ul>
            </label>
            </>
            <>
            {/* <InputLabel>Upload PDF *</InputLabel> */}
            <input type="file" accept=".pdf" name="pdfFile" onChange={handleInputChange} />
            {errors.pdfFile && <span>{errors.pdfFile}</span>}
            </>
          </FormControl>
        ) : (
          <>
            <label>
              Quest Labels:
              <ul>
                {Object.entries(formData.questLabels).map(([key, value]) => (
                  <li key={key}>
                    <TextField
                      type="text"
                      name={key}
                      value={value}
                      onChange={(e) => handleQuestLabelChange(e, key)}
                      required
                      error={!!errors.questLabels[key]}
                      helperText={errors.questLabels[key]}
                    />
                  </li>
                ))}
              </ul>
            </label>


            <label>
              Criteria:
              <ul>
                {Object.entries(formData.criteria).map(([key, value]) => (
                  <li key={key}>
                    <TextField
                      type="text"
                      name={key}
                      value={value}
                      onChange={(e) => handleCriteriaChange(e, key)}
                      required
                      error={!!errors.criteria[key]}
                      helperText={errors.criteria[key]}
                    />
                  </li>
                ))}
              </ul>
            </label>


            <Button type="button" onClick={handleAddSet} variant="outlined">
              + Add Set
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={formData.type === 'Written PDF' ? handleSavePDF : handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const TemplateList = () => {
  const classes = useStyles();
  const [templates, setTemplates] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);


  const jwtToken = localStorage.getItem('jwtToken');

  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
  const { id } = useParams();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('templates', { headers });
        const filteredTemplates = response.data.filter((template) => template.courseId === id);
        setTemplates(filteredTemplates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    fetchData();
  }, [id]);


  const handleOpenDialog = () => {
    setDialogOpen(true);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };


  const handleAddTemplate = async (newTemplate) => {
    try {
      console.log(newTemplate)
      const response = await axios.post('template', newTemplate, { headers });
      // console.log('Response from server:', response.data);
      setTemplates((prevTemplates) => [...prevTemplates, response.data]);

      handleCloseDialog();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };


  const handleDeleteTemplate = async (templateId) => {
    setDeleteConfirmationOpen(true);
    setSelectedTemplateId(templateId);
  };


  const handleDeleteConfirmation = async () => {
    try {
      await axios.delete(`template/${selectedTemplateId}`, { headers });
      setTemplates((prevTemplates) => prevTemplates.filter((template) => template.id !== selectedTemplateId));
      setSnackbarOpen(true);
      showToast('Template deleted successfully'); // Show delete success toast
    } catch (error) {
      console.error('Error deleting template:', error);
    } finally {
      setDeleteConfirmationOpen(false);
      setSelectedTemplateId(null);
    }
  };


  const handleDeleteCancel = () => {
    setDeleteConfirmationOpen(false);
    setSelectedTemplateId(null);
  };


  const showToast = (message, type = 'success') => {
    toast(message, {
      type,
      position: 'bottom-right', // Set position to bottom-right
    });
  };


  return (
    <div>
      <Header />
      <br />

      <div className='containerw mx-auto' style={{ maxWidth: '1200px' }}>



        <Grid item xs={12}>
          <Link to="/Allcourse">
            <Button variant="contained" color="primary">
              All Courses
            </Button>
          </Link>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            onClick={handleOpenDialog}
          >
            Add New Template
          </Button>
          <TemplateForm open={isDialogOpen} handleClose={handleCloseDialog} addTemplate={handleAddTemplate} />


          <Paper>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4} style={{ textAlign: 'center' }}>
                      <h2>Templates</h2>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Learning Outcome</TableCell>
                    <TableCell>Criteria</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((template) => (
                    <TableRow key={template.id}>
                      <TableCell>{template.type}</TableCell>
                      <TableCell>{template.learningOutcome}</TableCell>
                      <TableCell>
                        {Object.entries(template.questLabels).map(([key, value]) => (
                          <div key={key}>
                            {value} {template.criteria[key]}
                            <hr />
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleDeleteTemplate(template.id)} aria-label="delete">
                          <BsTrashFill style={{ color: 'red' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </div>

      <ToastContainer position="bottom-right" /> {/* Set default position to bottom-right */}
      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this template?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmation} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default TemplateList;





