import React from 'react';
import Header from '../components/Header'
import './Notification.css'; // Import your custom CSS styles
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams } from 'react-router-dom';
import axios, {logout} from '../Axiosinstance';
import Container from './Container';
import { toast, ToastContainer } from 'react-toastify';




const CourseContainer = () => {
  const { batchId } = useParams();
  const [courseName, setCourseName] = useState('');
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [selectedAssessors, setSelectedAssessors] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [batchInfo, setBatchInfo] = useState({ batchcode: '', course: '', trainers: [], assessors: [] });
  const [assessorId, setAssessorId] = useState(null);
  const [facilitatorId, setFacilitatorId] = useState(null);
  const [selectedAssessorIds, setSelectedAssessorIds] = useState([]);
  const [selectedTrainerIds, setSelectedTrainerIds] = useState([]);
  const [allTrainers, setAllTrainers] = useState([]);
  const [allAssessors, setAllAssessors] = useState([]);
  const [combinedIds, setCombinedIds] = useState([]);
  const [combinedNames, setCombinedNames] = useState([]);


  const [selectedFacilitatorsNames, setSelectedFacilitatorsNames] = useState([]);
  const [errors, setErrors] = useState({
    trainers: '',
    assessors: '',
    subject: '',
    message: '',
  });


  useEffect(() => {
    const fetchAllFacilitators = async () => {
      try {
        const response = await axios.get('facilitators');
        if (response.status !== 200) {
          throw new Error('Failed to fetch facilitators');
        }
        const facilitators = response.data;




        // Filter the facilitators to find all trainers and assessors
        const trainers = facilitators.filter((facilitator) => batchInfo.trainers.includes(facilitator.name));
        const assessors = facilitators.filter((facilitator) => batchInfo.assessors.includes(facilitator.name));


        // Extract all trainer and assessor IDs and names
        const trainerIds = trainers.map((trainer) => trainer.id);
        const trainerNames = trainers.map((trainer) => trainer.name);

        const assessorIds = assessors.map((assessor) => assessor.id);
        const assessorNames = assessors.map((assessor) => assessor.name);

        // Combine all trainer and assessor IDs and names into single variables
        const combinedIds = [...trainerIds, ...assessorIds];
        const combinedNames = [...trainerNames, ...assessorNames];

        // Store all trainers and assessors in separate states
        setAllTrainers(
          trainerIds.map((id, index) => ({
            id,
            name: trainerNames[index],
          }))
        );
        setAllAssessors(
          assessorIds.map((id, index) => ({
            id,
            name: assessorNames[index],
          }))
        );

        // Store combined IDs and names in separate variables
        setCombinedIds(combinedIds);
        setCombinedNames(combinedNames);
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };

    fetchAllFacilitators();
  }, [batchInfo.trainers, batchInfo.assessors]);


  useEffect(() => {
    // Fetch batch information using the batchId from the URL
    const fetchBatchInfo = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        if (response.status !== 200) {
          throw new Error('Failed to fetch batch information');
        }
        const data = response.data;
        setBatchInfo({
          batchcode: data.batchcode,
          course: data.course,
          trainers: data.trainers,
          assessors: data.assessors,
        });
      } catch (error) {
        console.error(error);
        // Handle error if needed
      }
    };


    fetchBatchInfo();
  }, [batchId]);


  const handleTrainerSelection = (e) => {
    const trainer = e.target.value;
    const trainerId = e.target.id; // Use the ID from the checkbox

    // Update the selected trainers and their IDs
    if (selectedTrainers.includes(trainer)) {
      setSelectedTrainers(selectedTrainers.filter((item) => item !== trainer));
      setSelectedTrainerIds(selectedTrainerIds.filter((id) => id !== trainerId));
    } else {
      setSelectedTrainers([...selectedTrainers, trainer]);
      setSelectedTrainerIds([...selectedTrainerIds, trainerId]);
    }


    // Update the trainer ID state
    setFacilitatorId(trainerId);
    setErrors((prevErrors) => ({
      ...prevErrors,
      trainers: '',
    }));
  };

  const handleAssessorSelection = (e) => {
    const assessor = e.target.value;
    const assessorId = e.target.id; // Use the ID from the checkbox


    // Update the selected assessors and their IDs
    if (selectedAssessors.includes(assessor)) {
      setSelectedAssessors(selectedAssessors.filter((item) => item !== assessor));
      setSelectedAssessorIds(selectedAssessorIds.filter((id) => id !== assessorId));
    } else {
      setSelectedAssessors([...selectedAssessors, assessor]);
      setSelectedAssessorIds([...selectedAssessorIds, assessorId]);
    }


    // Update the assessor ID state
    setAssessorId(assessorId);
    setErrors((prevErrors) => ({
      ...prevErrors,
      assessors: '',
    }));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      subject: '',
    }));
  };



  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      message: '',
    }));
  };



  const handleClick = async () => {
    setErrors({
      trainers: '',
      assessors: '',
      subject: '',
      message: '',
    });
 
    // Field validation
    let hasErrors = false;
 
    // if (selectedTrainers.length === 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     trainers: 'Please select at least one trainer',
    //   }));
    //   hasErrors = true;
    // }
 
    // if (selectedAssessors.length === 0) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     assessors: 'Please select at least one assessor',
    //   }));
    //   hasErrors = true;
    // }
 
    if (!subject.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subject: 'Please enter a subject',
      }));
      hasErrors = true;
    }
 
    if (!message.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: 'Please enter a message',
      }));
      hasErrors = true;
    }
 
    // If there are errors, return without sending the request
    if (hasErrors) {
      return;
    }








    // Get the selected trainer and assessor IDs, subject, and message
    const selectedTrainerIds = batchInfo.trainers
      .filter((trainer) => selectedTrainers.includes(trainer))
      .map((trainer) => trainer.match(/\((\d+)\)/)[1]);

    const selectedAssessorIds = batchInfo.assessors
      .filter((assessor) => selectedAssessors.includes(assessor))
      .map((assessor) => assessor.match(/\((\d+)\)/)[1]);

    const combinedIds = [...selectedTrainerIds, ...selectedAssessorIds];


    try {
      const response = await axios.post('notification', {
        teacherIds: combinedIds,
        message,
        subject,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (response.status !== 200) {
        throw new Error('Failed to store data in the database');
      }

      // Reset the form after a successful API call
      setCourseName('');
      setSelectedTrainers([]);
      setSelectedTrainerIds([]);
      setSelectedAssessors([]);
      setSelectedAssessorIds([]);
      setSubject('');
      setMessage('');


      toast.success('Notification sent successfully');
      setErrors({
        trainers: '',
        assessors: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      console.error('Error storing data in the database:', error);
      // Handle error if needed
      toast.error('Failed to send notification');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Extract selected trainer and assessor IDs
    const selectedTrainerIds = batchInfo.trainers
      .filter((trainer) => selectedTrainers.includes(trainer))
      .map((trainer) => trainer.match(/\((\d+)\)/)[1]);


    const selectedAssessorIds = batchInfo.assessors
      .filter((assessor) => selectedAssessors.includes(assessor))
      .map((assessor) => assessor.match(/\((\d+)\)/)[1]);


    // Combine trainer and assessor IDs into a single array
    const combinedIds = [...selectedTrainerIds, ...selectedAssessorIds];

    // Display combined IDs in the console
    // console.log("Combined IDs:", combinedIds);


    // Reset other form fields
    setSubject('');
    setMessage('');
  };

  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
     
    }
  };

  return (

    <div>

     <div className="container-fluid" style={{ maxWidth: "1050px"  , marginTop: '1%'}}>

     <div style={styles.container}>


     <h2  style={{ display: 'flex'  , justifyContent: 'center'}}  >Notification </h2>
     < br />
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Trainers</label>
              {batchInfo.trainers.map((trainer) => (
               
                <div className="form-check" key={trainer}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={trainer}
                    value={trainer}
                    checked={selectedTrainers.includes(trainer)}
                    onChange={handleTrainerSelection}
                  />
                  <label htmlFor={trainer} className="form-check-label">
                    {trainer.match(/^([^\(]+)/)[1]}
                  </label>
                </div>
              ))}
              <span style={{ color: 'red' }} className="error-message">{errors.trainers}</span>

            </div>
            <div className="form-group">
              <label>Assessors</label>
              {batchInfo.assessors.map((assessor) => (
                <div className="form-check" key={assessor}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={assessor}
                    value={assessor}
                    checked={selectedAssessors.includes(assessor)}
                    onChange={handleAssessorSelection}
                  />
                  <label htmlFor={assessor} className="form-check-label">
                  {assessor.match(/^([^\(]+)/)[1]}
                  </label>
                </div>
              ))}
              <span style={{ color: 'red' }} className="error-message">{errors.assessors}</span>


            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                placeholder="Enter subject"
                value={subject}
                onChange={handleSubjectChange}
              />
              <span style={{ color: 'red' }} className="error-message">{errors.subject}</span>

            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                className="form-control"
                id="message"
                rows="3"
                placeholder="Enter your message"
                value={message}
                onChange={handleMessageChange}
              ></textarea>
              <span style={{ color: 'red' }} className="error-message">{errors.message}</span>

            </div>
            <br></br>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleClick}
              style={{ backgroundColor: 'green',  padding: '7px', maxWidth: '100px',  width: '100%',  fontSize: '12px'  }} 
            >
              Send
            </button>

          </form>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

const Manage = () => {
  return (
    <div >


      <div>
        <CourseContainer />
      </div>
    </div>




  );
};
export default Manage;


