import React, { useState } from 'react';
import { Tabs, Tab, Container, Typography, Box, Divider } from '@mui/material';
import Header from '../components/Header';
import All from './All';
import New from './New';

const News = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <Header />
      <Box mt={3}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="Venue-tabs" variant="fullWidth">
          <Tab label="All Venues" />
          <Tab label="New Venue" />
        </Tabs>
      </Box>
      <Divider />

      <Box mt={3}>
        {tabValue === 0 && (
          <div>
            <Typography variant="h5" gutterBottom>
              All Venues
            </Typography>
            <All />
          </div>
        )}

        {tabValue === 1 && (
          <div>
            <Typography variant="h5" gutterBottom>
              New Venue
            </Typography>
            <New />
          </div>
        )}
      </Box>
    </Container>
  );
};

export default News;
