import React from 'react'
import Individual from './Individual'
const Universities = () => {
  return (
    <div>
    <Individual/>
    </div>
  )
}

export default Universities
