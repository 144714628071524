import React from 'react';
import './Manage.css';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';





 




const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};


const Container = ({ batchDetails }) => {
  const [showAllDates, setShowAllDates] = useState(false);

 
  if (!batchDetails) {
    return <p>Loading...</p>;
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-us', { month: 'short' });                                                                                                                                                                                                                                              
    const year = date.getFullYear();
    const ordinalDay = getOrdinalSuffix(day);


    return `${ordinalDay} ${month} ${year}`;
  };

  const formattedDates = batchDetails.days.map(day => formatDate(day.batchDate));

  const visibleDates = showAllDates ? formattedDates : formattedDates.slice(0, 2);



  return (
    <div className="container-fluid" style={{maxWidth:'1050px' , marginTop: '-1%'  , fontSize: '8%' }}>
 
    <table className="table" style={{ margin: 'auto' }}>
      <tbody>
        <tr>
          <td colSpan="2" className="col-md-6">
            <div className="container-content" style={{ justifyContent: 'space-between' }}>
              <p className="paragraph">Course<span  style={{ marginLeft: '50px' }}>:</span> {batchDetails.course.split('(')[0]}</p>
              <div className="course-container">
      {batchDetails.days.length > 0 && (
        <div className="course-details">
          <p className="paragraph">Course Dates<span style={{ marginLeft: '8px' }}>:</span> {visibleDates.join(', ')}</p>
          {!showAllDates && <button className="see-all-btn" onClick={() => setShowAllDates(true)}>See More</button>}
          {showAllDates && <button className="see-less-btn" onClick={() => setShowAllDates(false)}>See Less</button>}
        </div>
      )}
    </div>              
    <p className="paragraph">Trainers<span  style={{ marginLeft: '47px' }}>:</span>  {batchDetails.trainers.map(trainer => trainer.split('(')[0]).join(', ')}</p>
            </div>
          </td>
          <td colSpan="2" className="col-md-6">
            <div className="container-content">
              <p className="paragraph">Batch <span  style={{ marginLeft: '85px' }}>:</span> {batchDetails.batchcode}</p>
              <p className="paragraph">Student Enrolled<span  style={{ marginLeft: '10px' }}>:</span> {batchDetails.intakesize}</p>
              <p className="paragraph">Assessors<span  style={{ marginLeft: '60px' }}>:</span> {batchDetails.assessors.map(assessor => assessor.split('(')[0]).join(', ')}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
   
  );
};


export default Container;

