const NavigationButtons = () => {
    return (
    

  <div className="navigation-buttons" style={{ justifyContent: 'center' , marginTop: '1%'}} >
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 10px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Genee' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
  </button>
  <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Roasr' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
  </button>
  <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='Invoicesett' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
  </button>
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 5px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Feedback' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
  </button>
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 40px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Blo' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
  </button>
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 10px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Activ' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
  </button>
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Aa' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
  </button>
  <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Paym' style={{ textDecoration: 'none', color: 'white' }}>Payment</a>
  </button>
  <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
    <a href='/Mainta' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
  </button>
</div>
  );
};




  

  const 

   Hed = () => {
    return (
      <div>
     <NavigationButtons/>
      </div>
    );
  };
  
  export default Hed;