import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Button } from '@mui/material';




const LogTable = () => {
  const [logs, setLogs] = useState([
    {
      logname: 'Log 1',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 2',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 3',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 4',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 5',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 6',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 7',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 8',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',




    },
    {
      logname: 'Log 9',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
     
    },
    {
      logname: 'Log 10',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 11',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 11',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 11',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    {
      logname: 'Log 11',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
   








  ]);




  const [currentPage, setCurrentPage] = useState(0);
  const logsPerPage = 10;




  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };




  const currentLogs = logs.slice(currentPage * logsPerPage, currentPage * logsPerPage + logsPerPage);




  return (
   
    <div className="wider-container" style={{ width: '150%' , translate: '-15%'  }}>


       <Paper sx={{ width: '100%', overflow: 'hidden' }}>
       <TableContainer sx={{ maxHeight: 'none' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Log Name</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Charge</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentLogs.map((log, index) => (
              <TableRow key={index}>
                <TableCell>{log.logname}</TableCell>
                <TableCell>{log.action}</TableCell>
                <TableCell>{log.user}</TableCell>
                <TableCell>{log.date}</TableCell>
                <TableCell>{log.time}</TableCell>
                <TableCell>{log.charge}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={logs.length}
        rowsPerPage={logsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
      />
    </Paper>
    </div>
  );
};




const Activity = () => {
  return (
    <div>
      <LogTable />
    </div>
  );
};




export default Activity;













