import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button
} from '@mui/material';

const App = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Initial rows per page

  // Sample data as an array of objects
  const data = [
    { course: 'Data 1', batch: 23, date: '3-12-2022', students: 'Student(1)' },
    { course: 'Data 2', batch: 25, date: '4-12-2022', students: 'Student(1)' },
    { course: 'Data 3', batch: 23, date: '7-12-2022', students: 'Student(1)' },
    { course: 'Data 4', batch: 23, date: '4-12-2022', students: 'Student(1)' },
    { course: 'Data 5', batch: 26, date: '8-12-2022', students: 'Student(1)' },
    { course: 'Data 6', batch: 23, date: '5-12-2022', students: 'Student(1)' },
    { course: 'Data 7', batch: 23, date: '11-12-2022', students: 'Student(1)' },
    { course: 'Data 8', batch: 28, date: '17-12-2022', students: 'Student(1)' },
    { course: 'Data 9', batch: 29, date: '30-12-2022', students: 'Student(1)' },
    { course: 'Data 10', batch: 23, date: '23-12-2022', students: 'Student(1)' },
    { course: 'Data 11', batch: 22, date: '12-12-2022', students: 'Student(1)' },
    { course: 'Data 12', batch: 23, date: '23-12-2022', students: 'Student(1)' },
    // Add more data objects here
  ];

  // Calculate pagination
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setCurrentPage(1); // Reset to first page when changing rows per page
  };

  return (
    <div className="containerw" style={{ maxWidth: '1200px' }}>
      <br></br>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <input
          type="text"
          placeholder="Search..."
          style={{
            padding: '8px 12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            marginRight: '10px',
            fontSize: '16px',
          }}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Course</TableCell>
                <TableCell>Batch</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Student On</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentRows.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.course}</TableCell>
                  <TableCell>{item.batch}</TableCell>
                  <TableCell>{item.date}</TableCell>
                  <TableCell>
  <Button
    variant="contained"
    color="primary"
    href="/Vieww"
  >
    View all
  </Button>
</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={({ from, to }) => `${from}-${to} of ${currentPage}`}

          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default App;
