import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
  CardContent,
  Button,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import axios from '../Axiosinstance';
import Header from './Headstu';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';


function Courses() {
  const [categories, setCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const StudentId = localStorage.getItem('studentteacherid');
  const [existingStudent, setExistingStudent] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [courseOverview, setCourseOverview] = useState('');
  const loggedInUserId = localStorage.getItem('loggedInUserId');
  const [selectedCourse, setSelectedCourse] = useState(null); // State to store the selected course
  const [loading, setLoading] = useState(true);


  const studname = localStorage.getItem('name');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get('categorys');
        setCategories(categoriesResponse.data);

        const coursesResponse = await axios.get('courses');
        setCourses(coursesResponse.data);

        const existingStudentResponse = await axios.get('exisitingstudentrequests');
        setExistingStudent(existingStudentResponse.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, []); // Empty dependency array to run only once when the component mounts

  useEffect(() => {
    if (existingStudent.length > 0 && StudentId) {
      // Find all matched courseIds for the given student
      const matchedCourseIds = existingStudent
        .filter((student) => student.studentId === parseInt(StudentId))
        .map((student) => student.courseId);

      // Filter out courses with the matchedCourseIds
      const filteredCourses = courses.filter((course) => !matchedCourseIds.includes(course.id));
      setCourses(filteredCourses);
    }
  }, [StudentId, existingStudent]);





  useEffect(() => {
    if (!loading) {
      // Filter courses based on selected category
      if (selectedCategory) {
        const filtered = courses.filter((course) => course.category === selectedCategory);
        setFilteredCourses(filtered);
      } else {
        // If no category is selected, display all courses
        setFilteredCourses(courses);
      }
    }
  }, [selectedCategory, courses, loading]);


  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  const isEnrolledOrPending = (courseId) => {
    // Check if the user is already enrolled or has a pending request for the course
    return existingStudent.some(
      (student) => student.studentId === loggedInUserId && student.courseId === courseId
    );
  };




  const handleEnrollmentConfirmation = (selectedCourse) => {
    const courseId = selectedCourse.id;
    const courseName = selectedCourse.name;

    const isAlreadyEnrolledOrPending = isEnrolledOrPending(courseId);

    if (isAlreadyEnrolledOrPending) {
      // If the user is already enrolled or has a pending request, show a message or take action
      console.log('User is already enrolled or has a pending request for this course');
      // You may want to display a message to the user here
    } else {
      // Proceed with enrollment confirmation dialog
      setConfirmationMessage(`Are you sure you want to enroll in ${courseName}?`);
      setCourseOverview(`Course Overview:\n${selectedCourse.description}`);
      setSelectedCourse(selectedCourse);
      setShowDialog(true);

    }
  };

  const handleCloseDialog = async () => {
    setShowDialog(false);

    try {
      if (selectedCourse) {
        const response = await axios.post('exisitingstudentrequest', {
          studentId: StudentId,
          logId: loggedInUserId,
          courseId: selectedCourse.id,
          course: selectedCourse.name,
          studentName:studname,
          status: 'false',
          enroll: 'true'
        });

        if (response.status === 200) {
          console.log('Enrollment request successful:', response.data);
          // Add logic for successful enrollment
        } else {
          console.error('Enrollment request failed:', response.data);
          // Handle failure if needed
        }
      }
      toast.success('Course enrolled successfully');
      window.location.reload();

    } catch (error) {
      console.error('Error sending enrollment request:', error);
      // Handle error if needed
    }
  };
  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between', // Align buttons to left and right
    maxWidth: '300px', // Adjust width as needed
    margin: '0 auto', // Center the container
  };

  const truncateString = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str;
    }
    return str.substring(0, maxLength) + '...';
  };
  

  return (
    <div style={{ padding: '20px' }}>

      {loading && <Loader />}
      {!loading && (
        <>
          <div
            className="fixed-top"
            style={{
              maxWidth: '600px',
              margin: '0 auto',
              marginTop: '100px',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                background: '#fff',
                width: '100%',
                maxWidth: '800px',
                margin: '0 auto',
              }}
            >
              <FormControl fullWidth>
                <InputLabel>Select Category</InputLabel>
                <Select value={selectedCategory} onChange={handleCategoryChange}>
                  <MenuItem value="">All Categories</MenuItem>
                  {categories.map((category, index) => (
                    <MenuItem key={index} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>


          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginTop: '80px',
            }}
          >
            {filteredCourses.map((course, index) => (
              <Card
                key={index}
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  margin: '20px',
                  border: 'solid 0px black',
                  boxShadow: '4px 8px 16px rgba(0.2, 0, 0, 0.2)',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '8px 8px 0 0',
                  }}
                >

                  <img
                    src={course.url ? course.url : 'https://blog.coursify.me/wp-content/uploads/2018/08/plan-your-online-course.jpg'}
                    alt={course.name}
                    style={{
                      width: '100%',
                      maxHeight: '200px',
                      objectFit: 'cover',
                      borderRadius: '8px 8px 0 0', }}
                    loading="lazy"

                  />


                </div>


                <CardContent>
                <Grid item xs={12} style={{ textAlign: 'right' }}>
    <div style={containerStyle}>
      {isEnrolledOrPending(course.id) ? (
        <Typography variant="body2" style={{ marginRight: '10px', color: 'red' }}>
          Already Enrolled
        </Typography>
      ) : (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnrollmentConfirmation(course)}
          >
            Enroll Now
          </Button>
          <Link
            to={`/course/${course.id}`}
          
            rel="noopener noreferrer"
          >
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: '10px' }}
            >
              Read more
            </Button>
          </Link>
        </>
      )}
    </div>
  </Grid>
  <br />
                <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
  <Typography variant="h8" style={{ fontWeight: 'bold' }}>
    Course: {truncateString(course.name, 30)}
  </Typography>
</Grid>


  <Grid item xs={12}>
    <Grid container spacing={2}>
      
    <Grid item xs={12} md={4}>
  <Typography variant="h10">Fee: {truncateString(course.fee, 20)}</Typography>
</Grid>

    </Grid>
  </Grid>

</Grid>


                </CardContent>
              </Card>
            ))}
          </div>


          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>Enrollment Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {confirmationMessage}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleCloseDialog} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
        </>
      )}
    </div>
  );
}


function coures() {
  return (
    <div>
      <Header />
      <Courses />
    </div>
  );
}


export default coures;



