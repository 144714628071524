import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";


function Home() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    if (username && password) {
      if (username === "1234" && password === "123") {
        navigate("./Adhome");
      } else {
        console.log("Invalid username or password");
      }
    } else {
      console.log("Username and password are mandatory");
    }
  };

  const handleForgotPassword = () => {
    console.log("Forgot password clicked");
  };

  return (
    <div className="container my-5 mx-auto" >
      <h2 className="text-center mb-4 text-primary">Teacher Login</h2>
      <form className="p-4 my-4">
        <div className="form-group">
          <label htmlFor="username" className="form-label">
            Username:
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
            placeholder="Enter your username"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="form-label">
            Password:
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
            placeholder="Enter your password"
          />
        </div>
        <div>
          <button
            type="button"
            className="btn btn-primary btn-lg btn-block"
            onClick={handleLogin}
          >
            Login
          </button>
          <a
            href="/Fp"
            className="btn btn-link btn-block"
            onClick={handleForgotPassword}
          >
            Forgot Password
          </a>
        </div>
      </form>
    </div>
  );
}

export default Home;
