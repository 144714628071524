import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


const Payment = () => {
    return (
      <div className="navigation-buttons" style={{ transform: 'translateX(20%)' , marginBottom: ' 2%',  }} >
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Mainta' style={{ textDecoration: 'none', color: 'white' }}>Clear cache</a>
        </button>
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Mainta' style={{ textDecoration: 'none', color: 'white' }}>Migrate Db</a>
        </button>
        <button style={{ backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Mainta' style={{ textDecoration: 'none', color: 'white' }}>Backup Db</a>
        </button>
      
      </div>
    );
  };



  
  export default Payment;
  