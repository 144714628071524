import React, { useEffect, useState } from 'react';
import axios from '../Axiosinstance';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Loader from '../Loader'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as XLSX from 'xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import 'bootstrap/dist/css/bootstrap.min.css';
import { baseURLdyte, Password, Username } from '../common'

const BatchDetails = () => {
  const { batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [matchingSessions, setMatchingSessions] = useState([]);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of sessions per page

  // Calculate the indexes for the current page
  const indexOfLastSession = (page + 1) * rowsPerPage;
  const indexOfFirstSession = indexOfLastSession - rowsPerPage;
  const currentSessions = matchingSessions.slice(indexOfFirstSession, indexOfLastSession);

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchDetails();
  }, [batchId]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const orgId = Username;
        const apiKey = Password;
        const authHeader = `Basic ${btoa(`${orgId}:${apiKey}`)}`;

        const sessionsResponse = await axios.get(`${baseURLdyte}/sessions`, {
          headers: {
            Authorization: authHeader,
          },
        });

        const sessions = sessionsResponse.data.data.sessions;

        if (batchDetails && sessions) {
          const url = batchDetails.onlineclasslink
          const match = url.match(/id=([a-f\d-]+)/i);

          if (match && match[1]) {
            const meetingId = match[1];

            const matchingSessions = sessions.filter(
              session => session.meta.room_name === meetingId
            );

            setMatchingSessions(matchingSessions);
          } else {
            console.warn('Meeting ID not found in onlineclasslink');
          }
        }
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchSessions();
  }, [batchDetails]);

  const handleSessionButtonClick = async (sessionId) => {
    setSelectedSessionId(sessionId);
    setShowModal(true);

    try {
      const orgId = Username;
      const apiKey = Password;
      const authHeader = `Basic ${btoa(`${orgId}:${apiKey}`)}`;

      const response = await axios.get(`${baseURLdyte}/sessions/${sessionId}/participants`, {
        headers: {
          Authorization: authHeader,
        },
      });

      const participantsData = response.data.data.participants;
      setSessionDetails(participantsData);
    } catch (error) {
      console.error('Error fetching session details:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const convertSecondsToMinutes = (seconds) => {
    return (seconds / 60).toFixed(2); // Round to 2 decimal places
  };

  const downloadSessionDetailsToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Combine batch and session details into a single sheet
    const sheetData = [

      {
        'Course': batchDetails.course,
        'Student Enrolled': batchDetails.intakesize,
        'Batch': batchDetails.batchcode,
        'Trainers': batchDetails.trainers.join(', '),
        'Course Dates': formatDate(batchDetails.days[0].batchDate),
        'Assessors': batchDetails.assessors.join(', ')
      },
      {}, 
     
      ...matchingBatches.map((batch, index) => {
        const participantDetails = sessionDetails.find(participant => participant.custom_participant_id === batch.sId);
        return {
          'S.no': index + 1,
          'Display Name': batch.sName,
          'Joined At': participantDetails ? formatDate(participantDetails.joined_at) : 'Absent',
          'Left At': participantDetails ? formatDate(participantDetails.left_at) : 'N/A',
          'Duration': participantDetails ? convertSecondsToMinutes(participantDetails.duration) + ' hrs' : 'N/A'
        };
      }),
    ];

    const worksheet = XLSX.utils.json_to_sheet(sheetData);

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Session Details');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = `${selectedSessionId ? selectedSessionId : 'batch'}_session_details.xlsx`;

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };




  const renderSessionTable = () => {
    let validRowIndex = 0; // Counter for valid rows

    return (
      <div className="container-responsive mt-4" style={{ width: '1200px' }}>
        {matchingSessions.length > 0 && (
          <Table striped bordered hover responsive>
            <TableHead>
              <TableRow>
                <TableCell>S.no</TableCell>
                <TableCell>Meeting Name</TableCell>
                <TableCell>Total Participants</TableCell>
                <TableCell>Minutes Consumed</TableCell>
                <TableCell>Starting At</TableCell>
                <TableCell>Ending At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentSessions.map((session, index) => (
                // Check if total_participants is not 0
                session.total_participants !== 0 && (
                  <TableRow key={session.id}>
                    <TableCell>{++validRowIndex}</TableCell> {/* Increment validRowIndex for each valid row */}
                    <TableCell>{session.meeting_display_name}</TableCell>
                    <TableCell>{session.total_participants}</TableCell>

                    <TableCell>{convertSecondsToMinutes(session.minutes_consumed)} hrs</TableCell>
                    <TableCell>{formatDate(session.started_at)}</TableCell>
                    <TableCell>{formatDate(session.ended_at)}</TableCell>
                    <TableCell>
                      <button className='btn btn-primary' style={{ padding: '7px', maxWidth: '100px', width: '100%', fontSize: '12px' }}
                        type='Submit' onClick={() => handleSessionButtonClick(session.id)}>
                        View 
                      </button>
                    </TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        )}
        <TablePagination
          component="div"
          count={matchingSessions.filter(session => session.total_participants !== 0).length} // Adjust count based on valid rows
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    );
  };




  const renderSessionDetailsTable = () => (
    <div>
      {sessionDetails && (
        <Table aria-label="sticky table" style={{ tableLayout: 'fixed', minWidth: '600px' }}>
          <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              <TableCell style={{ width: '160px' }}>S.no</TableCell>
              <TableCell>Meeting Name</TableCell>
              <TableCell>Joined At</TableCell>
              <TableCell>Left At</TableCell>
              <TableCell>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionDetails.map((participant, index) => (
              <TableRow key={participant.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{participant.display_name}</TableCell>
                <TableCell>{formatDate(participant.joined_at)}</TableCell>
                <TableCell>{formatDate(participant.left_at)}</TableCell>
                <TableCell>{convertSecondsToMinutes(participant.duration)} hrs</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );

  const [matchingBatches, setMatchingBatches] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('manages');
        const filteredBatches = response.data.filter(batch => batch.batch_id.toString() === batchId);
        setMatchingBatches(filteredBatches);
        console.log(filteredBatches);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, [batchId]);


  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <TableContainer sx={{ maxHeight: 'none' }}>
          {renderSessionTable()}
        </TableContainer>
      )}

      <Modal show={showModal} onHide={handleCloseModal} centered size="fullscreen">
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '30px' }}>Session Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sessionDetails && (
            <div>
              <Table aria-label="sticky table" style={{ tableLayout: 'fixed', minWidth: '600px' }}>
                <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    <TableCell style={{ width: '160px' }}>S.no</TableCell>
                    <TableCell>sName</TableCell>
                    <TableCell>Joined At</TableCell>
                    <TableCell>Left At</TableCell>
                    <TableCell>Duration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {matchingBatches.map((batch, index) => {
                  const participantDetails = sessionDetails.find(participant => participant.custom_participant_id === batch.sId);
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{batch.sName}</TableCell>
                      <TableCell>{participantDetails ? formatDate(participantDetails.joined_at) : 'Absent'}</TableCell>
                      <TableCell>{participantDetails ? formatDate(participantDetails.left_at) : 'N/A'}</TableCell>
                      <TableCell>{participantDetails ? convertSecondsToMinutes(participantDetails.duration) + ' hrs' : 'N/A'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              </Table>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>

          <button className='primary' onClick={handleCloseModal}
            style={{
              background: 'grey',
              fontSize: '17px',
              color: 'white',
              border: 'none',
              padding: '8px 40px',
              borderRadius: '7px'
            }}
          >

            Close
          </button>
          <button
            className='primary'

            startIcon={<SaveAltIcon />}
            onClick={downloadSessionDetailsToExcel}
            style={{
              background: 'green',
              fontSize: '17px',
              color: 'white',
              border: 'none',
              padding: '8px 30px',
              borderRadius: '7px'
            }}
          >
            Download
          </button>

        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default BatchDetails;
