import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Header from './TeachHeader';
import axios from '../Axiosinstance'
import { useParams } from 'react-router-dom';
import { Table, Button, Typography, Box, Paper, Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTrash } from 'react-icons/fa';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const App = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] = useState({ id: '', name: '' });
  const [selectedType, setSelectedType] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState([]);
  const { id } = useParams();
  const [selectedFileType, setSelectedFileType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedPublicPreview, setSelectedPublicPreview] = useState('');
  const [selectedFor, setSelectedFor] = useState('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const [uploadStatus, setUploadStatus] = useState('');

  const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dyjwwi8ea/raw/upload';
  const CLOUDINARY_UPLOAD_PRESET = 'vldqgwk8';

  // const handleUpload = () => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
  //     formData.append('public_id', 'LMS/curriculum/' + file.name);

  //     fetch(CLOUDINARY_URL, {
  //       method: 'POST',
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.secure_url !== '') {
  //           const uploadedFileUrl = data.secure_url;
  //           // console.log('File uploaded successfully:', uploadedFileUrl); // take the uploadedFileUrl and assign to i mention place
  //           setUploadStatus('File uploaded successfully.');
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setUploadStatus('An error occurred during upload.');
  //       });
  //   } else {
  //     setUploadStatus('Please select a file before uploading.');
  //   }
  // };





  const handleNewButtonClick = () => {
    setShowPopup(true);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleSavePopup = (e) => {
    e.preventDefault();
    const newCurriculumName = document.getElementById('curriculumInput').value;

    const pathSegments = window.location.pathname.split('/');
    const courseid = pathSegments[pathSegments.length - 1];
    const curriculumData = {
      name: newCurriculumName,
      courseid: parseInt(courseid),
      createdAt: new Date().toISOString(),
    };

    axios.post('curriculum', curriculumData)
      .then((response) => {
        // console.log('Curriculum saved successfully:', response.data);

        // After successfully saving, fetch the latest curriculum data
        axios.get('curriculum')
          .then((response) => {
            const filteredData = response.data.filter(curriculum => curriculum.courseid === parseInt(id));
            setCurriculumData(filteredData);
          })
          .catch((error) => {
            console.error('Error fetching curriculum data:', error);
          });
      })
      .catch((error) => {
        console.error('Error saving curriculum:', error);
      });

    setShowPopup(false); // Close the popup after saving
  };



  useEffect(() => {
    // Fetch curriculum data from the API
    axios.get('curriculum')
      .then((response) => {
        // Filter the curriculum data based on the ID
        const filteredData = response.data.filter(curriculum => curriculum.courseid === parseInt(id));
        // Update the state with the filtered curriculum data

        setCurriculumData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching curriculum data:', error);
      });
  }, [id]);

  const handleFileChange = (e) => {
    const fileToUpload = e.target.files[0];

    if (fileToUpload) {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      formData.append('public_id', 'LMS/curriculum/' + fileToUpload.name);

      fetch(CLOUDINARY_URL, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.secure_url !== '') {
            const uploadedFileUrl = data.secure_url;
            setUploadedFileUrl(uploadedFileUrl);
            setIsUploading(false);
            // console.log('File uploaded successfully:', uploadedFileUrl);
          }
        })
        .catch((err) => {
          console.error(err);
          setUploadStatus('An error occurred during upload');
          setIsUploading(false);
        });

      // Set the selected file type based on the accepted formats
      const acceptTypes = getAcceptTypes();
      for (const type in validFileTypes) {
        if (acceptTypes.includes(validFileTypes[type])) {
          setSelectedFileType(type);
          break;
        }
      }
    }
  };





  const handleSelectChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleSubmitButtonHover = (e) => {
    e.target.style.backgroundColor = '#28a745';
  };

  const handleSubmitButtonLeave = (e) => {
    e.target.style.backgroundColor = 'green';
  };

  const handleDownload = () => {
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        link.click();
        URL.revokeObjectURL(link.href);
      });
    }
  };

  const handleOpenPdfModal = () => {
    setShowPdfModal(true);
  };

  const handleClosePdfModal = () => {
    setShowPdfModal(false);
  };

  const handleDelete = (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this curriculum?");

    if (isConfirmed) {
      axios.delete(`curriculum/${id}`)
        .then(response => {
          if (response.status === 200) {
            const updatedCourses = curriculumData.filter((curriculum) => curriculum.id !== id);
            setCurriculumData(updatedCourses);
          }
        })
        .catch(error => {
          console.error('Error deleting course:', error);
          // You can log the error or display an error message here.
        });
    }
  };

  const validFileTypes = {
    ppt: '.ppt, .pptx',
    pdf: '.pdf',
    audio: '.mp3',
    video: '.mp4, .mov, .avi', // Add more video file extensions if needed
  };

  const getAcceptTypes = () => {
    if (selectedFileType === 'ppt') {
      return validFileTypes.ppt;
    } else if (selectedFileType === 'pdf') {
      return validFileTypes.pdf;
    } else if (selectedFileType === 'video') {
      return validFileTypes.video;
    }
    else if (selectedFileType === 'audio') {
      return validFileTypes.audio;
    } else {
      return '';
    }
  };

  const handleInputChange = (e) => {
    setSelectedFileType(e.target.value);
  };



  const renderInputField = () => {
    if (selectedFileType === 'youtube link') {
      return (
        <div className="mb-3">
          <label htmlFor="youtubeLink" className="form-label">
            Paste YouTube Link
          </label>
          <input
            type="text"
            id="youtubeLink"
            className="form-control"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      );
    } else if (selectedFileType === 'text') {
      return (
        <div className="mb-3">
          <label htmlFor="textInput" className="form-label">
            Enter Text
          </label>
          <ReactQuill
            value={inputValue}
            onChange={setInputValue}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                ['link'],
                [{ 'align': [] }],
              ],
            }}
            formats={[
              'header',
              'font',
              'list',
              'bullet',
              'bold',
              'italic',
              'underline',
              'link',
              'align',
            ]}
          />
        </div>
      );
    }
    return null;
  };

  const handleFormSubmit = async (e) => {
    const confirmed = window.confirm('Are you sure you want to upload the curriculam?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
    e.preventDefault();

    // Get form data from the state or directly from the form fields
    const sectionName = document.getElementById('name').value;
    const selectCurriculum = selectedCurriculum.name;
    const forAccess = document.getElementById('for').value;
    const type = selectedFileType;
    const publicReview = selectedPublicPreview === 'Type 1';
    const curriculum_id = selectedCurriculum.id;
    const course_id = id;
    let fileUrl = '';

    if (selectedFileType === 'youtube link') {
      // If the selected file type is "Youtube Link", use the inputValue as the fileUrl
      fileUrl = inputValue;
    } else if (selectedFileType === 'text') {
      // If the selected file type is "Text", use the inputValue as the fileUrl
      fileUrl = inputValue;
    } else {
      // If it's not "Youtube Link" or "Text", use the uploaded file's URL
      fileUrl = uploadedFileUrl;
    }

    // Prepare the data object
    const formData = {
      sectionName,
      selectCurriculum,
      for_access: forAccess,
      type,
      publicReview,
      curriculum_id,
      course_id,
      fileUrl,
    };

    try {
      
      // Send a POST request to the API endpoint
      const response = await axios.post('uploadcurriculum', formData);

      // Handle the response, for example, show a success message to the user
      console.log('Form submitted successfully!', response.data);

      // Reset the form fields if needed
      // document.getElementById('name').value = '';
      // setSelectedCurriculum('');
      // document.getElementById('for').value = '';
      // setSelectedType('');
      document.getElementById('name').value = '';
      setSelectedCurriculum({ id: '', name: '' });
      document.getElementById('for').value = '';
      setSelectedFileType('');
      setInputValue(''); // Clear the input field for YouTube link or text

      // Clear the uploaded file field and reset the upload status
      setUploadedFileUrl('');
      setUploadStatus('');

      // Clear the "For Public Preview" and "For" select fields
      setSelectedPublicPreview('');
      setSelectedFor('');
    } catch (error) {
      // Handle errors, for example, show an error message to the user
      console.error('Error submitting the form:', error);
    }
  };


  return (
    <div>
      <div className="containerw mx-auto mt-5" style={{ maxWidth: '1000px' }}>
        <Box
          sx={{
            backgroundColor: '#fff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '20px',
            borderRadius: '8px',
          }}
        >

          <div className="mb-4">
            <h3>New Curriculum</h3>
            <Button onClick={handleNewButtonClick} variant="primary" style={{ background: '#03396c',color:'white' }} >
              <span className="me-2">
                <i className="bi bi-file-earmark-plus"></i>
              </span>
              New
            </Button>
          </div>
          {showPopup && (
            <div className=" ">
              {/* <h3 className="mb-3">New Curriculum</h3> */}
              <div className="input-group" style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '16px' }}>
                <input
                  type="text"
                  id="curriculumInput"
                  placeholder="Enter curriculum name"
                  className="form-control"
                  style={{ marginRight: '8px' }}
                />
                <div className="d-flex flex-column">
                  <Button onClick={handleSavePopup} variant="success" style={{ background: '#03396c', marginBottom: '8px' }}>
                    Save
                  </Button>
                  <Button onClick={handleClosePopup} variant="secondary">
                    Cancel
                  </Button>
                </div>
              </div>

            </div>
          )}

          <div className="responsive">

            <h3>Curriculum List</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  {/* <th>Course ID</th>
              <th>Created At</th> */}
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {curriculumData.map((curriculum) => (
                  <tr key={curriculum.id}>
                    {/* <td>{curriculum.id}</td> */}
                    <td>{curriculum.name}</td>
                    {/* <td>{curriculum.courseid}</td>
                <td>{curriculum.createdAt}</td> */}
                    {/* <td>   <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(curriculum.id)}
                  >
                    <BsTrashFill />
                  </button> </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>


          <div className=" ">
            <h3 className="mb-3">Upload Curriculum</h3>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                Section Name
                </label>
                <input type="text" id="name" className="form-control" placeholder="Enter name" />
              </div>
              <div className="mb-3">
                <label htmlFor="curriculum" className="form-label">
                  Select Curriculum
                </label>
                <select
                  id="curriculum"
                  className="form-select"
                  value={selectedCurriculum.id}
                  onChange={(e) => {
                    const curriculumName = e.target.options[e.target.selectedIndex].text;
                    setSelectedCurriculum({ id: e.target.value, name: curriculumName });
                  }}
                >
                  <option value="">Select a curriculum</option>
                  {curriculumData.map((curriculum) => (
                    <option key={curriculum.id} value={curriculum.id}>
                      {curriculum.name}
                    </option>
                  ))}
                </select>


              </div>

              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="publicPreview" className="form-label">
                    For Public Preview
                  </label>
                  <select
                    id="publicPreview"
                    className="form-select"
                    value={selectedPublicPreview}
                    onChange={(e) => setSelectedPublicPreview(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Type 1">Yes</option>
                    <option value="Type 2">No</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="for" className="form-label">
                    For
                  </label>
                  <select
                    id="for"
                    className="form-select"
                    value={selectedFor}
                    onChange={(e) => setSelectedFor(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Student">Student</option>
                    <option value="Trainer">Trainer</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  id="type"
                  className="form-select"
                  value={selectedFileType}
                  onChange={(e) => {
                    setSelectedFileType(e.target.value);
                  }}
                >
                  <option value="">Select a type</option>
                  <option value="ppt">PPT</option>
                  <option value="pdf">PDF</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                  <option value="youtube link">Youtube Link</option>
                  <option value="text">Text</option>
                  {/* Add other options as needed */}
                </select>
              </div>

              {/* {selectedFileType && (
        <div className="mb-3">
          <label htmlFor="file" className="form-label">
            Choose {selectedFileType.toUpperCase()} file
          </label>
          <input
            type="file"
            id="file"
            className="form-control"
            accept={getAcceptTypes()}
            onChange={handleFileChange}
          />
        </div>
      )} */}

              {renderInputField()}

              {selectedFileType && selectedFileType !== 'youtube link' && selectedFileType !== 'text' && (


                <div className="mb-3">
                  <label htmlFor="file" className="form-label">
                    Choose {selectedFileType.toUpperCase()} file
                  </label>
                  <input
                    type="file"
                    id="file"
                    className="form-control"
                    accept={getAcceptTypes()}
                    onChange={handleFileChange}
                  />
                  {isUploading && <p>Uploading...</p>}
                </div>




              )}


              <button
                className="btn btn-success btn-sm"
                onMouseOver={handleSubmitButtonHover}
                onMouseLeave={handleSubmitButtonLeave}
                type="submit"
                style={{
                  backgroundColor: '#03396c',
                  color: 'white',
                  border: 'none',
                  padding: '8px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                disabled={isUploading || !selectedFileType}
              >
                Submit
              </button>




            </form>
          </div>
        </Box>
      </div>
    </div>
  );
};

const extractYouTubeVideoId = (url) => {
  // Extract the video ID from the YouTube URL
  const videoId = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|embed\/watch\?feature=player_embedded&v=))([^&\n?#]+)/);
  return videoId ? videoId[1] : null;
};

const Key = () => {
  const { id } = useParams();
  const [curriculumData, setCurriculumData] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);
  const [selectedContentUrl, setSelectedContentUrl] = useState(null);
  
  useEffect(() => {
    axios.get('uploadcurriculums')
      .then((response) => {
        setCurriculumData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }, []);

  const filteredData = curriculumData.filter(
    (curriculum) => curriculum.course_id === parseInt(id, 10)
  );



  const confirmDelete = (curriculum) => {
    if (window.confirm('Are you sure you want to delete this curriculum?')) {
      axios
        .delete(`uploadcurriculum/${curriculum.id}`)
        .then(() => {
          alert('Curriculum deleted successfully');
        })
        .catch((error) => {
          alert('Error deleting curriculum: ' + error);
        });
    }
  };

  const displayContent = (content) => {
    setSelectedContent(content);
    setSelectedContentType(content.type);
    setSelectedContentUrl(content.fileUrl);
  };
  


  return (
    <div className='containerw mx-auto' style={{ maxWidth: '1050px' }}>
      <Box
          sx={{
            // backgroundColor: '#fff',
            // boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '20px',
            borderRadius: '8px',
            // maxWidth: '1000px' 
          }}
        >
        <Paper elevation={3}>
          <Grid container>
            <Grid item xs={3}>
              <Box p={3}>
                <Typography variant="h5">Curriculum</Typography>
                <Table striped bordered hover>
                  <tbody>
                    {filteredData.map((curriculum) => (
                      <tr
                        key={curriculum.id}
                        onClick={() => displayContent(curriculum)}
                        style={{
                          cursor: 'pointer',
                          background: selectedContent && selectedContent.id === curriculum.id ? '#0789A9' : '',
                          lineHeight: '1.5', // Adjust the line height to reduce space
                          marginBottom: '5px', // Add margin to reduce space
                          borderRadius: '50px'
                        }}
                      >
                        <td style={{ fontSize: '20px' }}>{curriculum.sectionName}</td>
                        <td>
                          <FaTrash
                            style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }}
                            onClick={() => confirmDelete(curriculum)}
                          />
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </Table>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={3} ml={2}>
                {selectedContent && (
                  <div>
                    {selectedContent.type === 'pdf' ? (
                      <div>
                        <iframe src={selectedContent.fileUrl} width="700" height="400"></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'ppt' || selectedContent.type === 'docx' ? (
                      <div>
                        <iframe
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            selectedContent.fileUrl
                          )}`}
                          width="700"
                          height="400"
                        ></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'video' ? (
                      <div>
                        <video width="700" height="400" controls>
                          <source src={selectedContent.fileUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        {renderDownloadButton(selectedContent, 'video')}
                      </div>
                    ) : selectedContent.type === 'audio' ? (
                      <div>
                        <audio controls>
                          <source src={selectedContent.fileUrl} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                        {renderDownloadButton(selectedContent, 'audio')}
                      </div>
                    ) : selectedContent.type === 'text' ? (
                      <div>
                        {/* Render HTML content with formatting */}
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: selectedContent.fileUrl }} />
                        {renderDownloadButton(selectedContent, 'text')}
                      </div>
                    ) : (
                      <div>
                        <iframe
                          src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                            selectedContent.fileUrl
                          )}`}
                          title="YouTube Video"
                          width="700"
                          height="400"
                        ></iframe>
                        {/* YouTube link - No download button */}
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
};

const Managec = () => {
  return (
    <div >
      <Header />
      <App />
      <div>
        <Key />
      </div>

    </div>
  );
};

export default Managec;

function renderDownloadButton(content, type) {
  // Check if the content type is not a YouTube link and type is defined
  if (type && type !== 'youtube') {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownload(content.fileUrl, type)}>
        Download {type.toUpperCase()}
      </Button>
    );
  }

  // No download button for YouTube links or if type is undefined
  return null;
}

function handleDownload(fileUrl, type) {
  // Implement download logic here
  if (type === 'text') {
    downloadTextFile(fileUrl, 'download.txt');
  } else if (type === 'pdf' || type === 'ppt') {
    downloadFile(fileUrl, `download.${type}`);
  } else {
    window.location.href = fileUrl; // This is a simple example; improve based on your requirements
  }
}


function downloadTextFile(htmlContent, fileName) {
  const element = document.createElement('a');

  // Convert HTML content to formatted plain text
  const plainTextContent = convertHtmlToPlainText(htmlContent);

  const file = new Blob([plainTextContent], { type: 'text/plain' });

  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
}

function convertHtmlToPlainText(htmlContent) {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  const textContent = doc.body.textContent || '';
  return decodeHtmlEntities(textContent);
}

function decodeHtmlEntities(text) {
  const element = document.createElement('div');
  element.innerHTML = text;
  return element.innerText;
}

function downloadFile(fileUrl, fileName) {
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const element = document.createElement('a');
      const file = new Blob([blob], { type: 'application/octet-stream' });

      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}