import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import { Form, Button } from 'react-bootstrap';

const Sree = () => {
  const [formData, setFormData] = useState({
    roomNo: '',
    unit: '',
    block: '',
    floor: '',
    street: '',
    building: '',
    postalCode: '',
    wheelchairAccess: false,
    additionalOption: false,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form data submitted:', formData);
  };

  const handleCancel = () => {
    setFormData({
      roomNo: '',
      unit: '',
      block: '',
      floor: '',
      street: '',
      building: '',
      postalCode: '',
      wheelchairAccess: false,
      additionalOption: false,
    });
  };

  const handleChange = (event) => {
    const { id, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: newValue,
    }));
  };

  return (
    <div className="container">
      <h2>New Venue</h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="roomNo">
          <Form.Label>Room No</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Room No"
            required
            value={formData.roomNo}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="unit">
          <Form.Label>Unit</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Unit"
            required
            value={formData.unit}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="block">
          <Form.Label>Block</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Block"
            required
            value={formData.block}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="floor">
          <Form.Label>Floor</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Floor"
            required
            value={formData.floor}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="street">
          <Form.Label>Street</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Street"
            required
            value={formData.street}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="building">
          <Form.Label>Building</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Building"
            required
            value={formData.building}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="postalCode">
          <Form.Label>Postal Code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Postal Code"
            required
            value={formData.postalCode}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="additionalOption">
          <Form.Check
            type="checkbox"
            label="wheelchairAccess"
            checked={formData.additionalOption}
            onChange={handleChange}
          />
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button variant="secondary" className="mr-2" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" className="btn-submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

const ButtonComponent = () => {
  const handleButton1Click = () => {
    console.log('Button 1 clicked!');
    // Add your own custom logic here
  };

  const handleButton2Click = () => {
    console.log('Button 2 clicked!');
    // Add your own custom logic here
  };

  return (
    <div className="container">
      <Link
        to="/Allv"
        className="btn btn-primary"
        style={{ backgroundColor: '#03396c', color: 'white', minWidth: '100px', marginRight: '10px' }}
      >
        All Venue
      </Link>
      <Link
        to="/Editv"
        className="btn btn-success"
        style={{ backgroundColor: '#03396c', color: 'white', minWidth: '100px' }}
      >
        New Venue
      </Link>
    </div>
  );
};

const New = () => {
  return (
    <div>
      <Header />
      <ButtonComponent />
      <Sree />
    </div>
  );
};

export default New;
