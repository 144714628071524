import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';

const LogTable = () => {
  const [logs, setLogs] = useState([
    {
      logname: 'Log 1',
      action: 'Action 1',
      user: 'User 1',
      date: '2023-06-01',
      time: '10:00 AM',
      charge: '$10',
    },
    // ... add more log objects here ...
  ]);

  // Function to add a new log
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10; // Number of logs to display per page

  // Calculate the index of the last log on the current page
  const lastIndex = currentPage * logsPerPage;
  // Calculate the index of the first log on the current page
  const firstIndex = lastIndex - logsPerPage;
  // Slice the logs array to get the logs for the current page
  const currentLogs = logs.slice(firstIndex, lastIndex);

  // Function to go to the next page
  const nextPage = () => {
    if (currentPage < Math.ceil(logs.length / logsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to go to the previous page
  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container">
      <Table className="table">
        <thead>
          <tr>
            <th>Log Name</th>
            <th>Action</th>
            <th>User</th>
            <th>Date</th>
            <th>Time</th>
            <th>Charge</th>
          </tr>
        </thead>
        <tbody>
          {currentLogs.map((log, index) => (
            <tr key={index}>
              <td>{log.logname}</td>
              <td>{log.action}</td>
              <td>{log.user}</td>
              <td>{log.date}</td>
              <td>{log.time}</td>
              <td>{log.charge}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination">
        <button
          style={{ background: '#03396c', color: 'white', marginRight: '2px' }}
          onClick={previousPage} // Call previousPage function on button click
        >
          Previous
        </button>
        <span>Page {currentPage}</span>
        <button
          style={{ background: '#03396c', color: 'white' }}
          onClick={nextPage} // Call nextPage function on button click
        >
          Next
        </button>
        <span>of {Math.ceil(logs.length / logsPerPage)}</span>
      </div>
    </div>
  );
};

  
const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
    
      <div className="navigation-buttons">
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
        </button>
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
        </button>
        
        <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
        </button>
        <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
          <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
        </button>
      </div>
    </div>
  );
};




const Activity = () => {
  return (
    <div>
      <Header />
      <NavigationButtons />
  <LogTable/>
    </div>
  );
};

export default Activity;
