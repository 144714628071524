import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Heading  from './Heading';
import { FaPlus } from 'react-icons/fa';

const App = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      title: 'Item 1',
      image: 'image1.jpg',
      createdAt: '2023-06-19',
    },
    {
      id: 2,
      title: 'Item 2',
      image: 'image2.jpg',
      createdAt: '2023-06-18',
    },
    // Add more items as needed
  ]);
  const [showPopup, setShowPopup] = useState(false);

  const handleEdit = (id) => {
    // Handle edit functionality here
    console.log(`Edit item with ID: ${id}`);
  };

  const handleDelete = (id) => {
    // Handle delete functionality here
    console.log(`Delete item with ID: ${id}`);
  };

  const handleNewPost = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (showPopup) {
      // Perform any necessary actions when the popup is shown
      console.log('Popup shown');
    }
  }, [showPopup]);

  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const handleSave = () => {
    // Save functionality here
    // ...

    setShowPopup(false); // Close the popup
  };

  
  return (
    <div className="container">
      <h2>Blog Posts</h2>
      
      <Table style={{ maxWidth: "3050px" ,  border: '1px solid #ccc', borderRadius: '2px', borderWidth: '1px 5px'  }}>
        <thead>
          <tr>
            <th style={{  textAlign: 'center' }} >S. No.</th>
            <th style={{  textAlign: 'center' }} >Title</th>
            <th style={{  textAlign: 'center' }} >Image</th>
            <th style={{  textAlign: 'center' }} >Created At</th>
            <th style={{  textAlign: 'center' }} >Action</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={item.id}>
              <td style={{  textAlign: 'center' }} >{index + 1}</td>
              <td style={{  textAlign: 'center' }} >{item.title}</td>
              <td style={{  textAlign: 'center' }} >
                <img src={item.image} alt={item.title} />
              </td>
              <td style={{  textAlign: 'center' }}>{item.createdAt}</td>
              <td>
                <div className="action-buttons" style={{  textAlign: 'center' }}>
                  <button className="btn btn-sm btn-danger" onClick={() => handleEdit(item.id)}>
                    <BsPencilFill />
                  </button>
                  <button className="btn btn-sm btn-danger" onClick={() => handleDelete(item.id)}>
                    <BsTrashFill />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <button style={{  backgroundColor: '#03396c',
    color: '#fff',
    border: 'none',
    padding: '8px 25px',
    borderRadius: '4px',
    cursor: 'pointer',
    
    marginLeft: '7px',
   
    }} onClick={handleNewPost}>
        <FaPlus /> New Post 
      </button>
      {showPopup && (
  <div className="popup-container" style={{ border: '1px solid #ccc' }}>
    <div className="popup bg-white p-3 rounded">
      <h3>Blog post</h3>
      <input type="text" placeholder="Title" style={{ marginBottom: '10px' }} />
      <input type="file" accept="image/*" style={{ marginBottom: '10px' }} />
      <div style={{ fontWeight: 'bold', fontStyle: 'italic' , marginBottom: '3%'}}>
      <ReactQuill
            value={editorContent}
            onChange={handleEditorChange}
            placeholder="Enter your Text"
            style={{ fontSize: '16px' }} // Increase the size of the placeholder
          />
      </div>
      <button className="btn btn-primary" onClick={() => setShowPopup(false)}  style={{  background: '#03396c' ,  padding: '8px 25px'  }}>
        Close
      </button>
      <button className="btn btn-primary" onClick={handleSave}  style={{  background: '#03396c' ,  padding: '8px 25px'}}>
            Save
          </button>
    </div>
  </div>
)}
    </div>
  );
};



const Allstudent = () => {
  return (
    <div>
      <Header />
      <Heading/>
      <App />
    </div>
  );
};

export default Allstudent;
