import React, { useEffect, useState, useRef } from 'react';
import { faDollar, faFilePowerpoint, faLaptop, faThumbsUp ,faGraduationCap} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headstu from './Headstu';
import axios from '../Axiosinstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import jsPDF from 'jspdf';
import cert from '../All/images/Cert.png'; // Ensure this path is correct

import { useReactToPrint } from 'react-to-print';

import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import { baseURLdyte, Password, Username, baseURLdyteMeeting } from '../common'
import Meeting from '../StudentLogin/Meeting'
import { Link, useNavigate, Routes, Route } from 'react-router-dom';
import './Dashboard.css'
import { faVideo, faComment, faEdit, faBook } from '@fortawesome/free-solid-svg-icons';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1400px',
    marginTop: '3%',
  },
  card: {
    marginBottom: '16px',
    border: '1px solid #ccc',
  },
  cardImage: {
    height: '100px',
    width: '100%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signatureCanvas: {
    width: 500,
    height: 300,
  },
}));


function extractNamesFromArray(arr) {
  return arr.map((item) => {
    const match = item.match(/^([^(]+)/);
    return match ? match[0].trim() : '';
  });
}


const Man = () => {
  const [batchIds, setBatchIds] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const [fetchedCourseData, setFetchedCourseData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [courseId, setCourseId] = useState([])
  const classes = useStyles();
  const navigate = useNavigate();
  const [certificateStatuses, setCertificateStatuses] = useState({});


  const extractId = (str) => {
    const match = str.match(/\((\d+)\)/);
    return match ? match[1] : '';
  };


  useEffect(() => {
    axios.get('manages')
      .then((response) => {
        const data = response.data;


        const studentteacherid = localStorage.getItem('studentteacherid');


        const batchIdArray = [];


        data.forEach((item) => {
          const sId = item.sId;
          if (sId === studentteacherid) {
            batchIdArray.push(item.batch_id);
          }
        });


        setBatchIds(batchIdArray);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);


  useEffect(() => {
    const fetchBatchIds = async () => {
      try {
        setIsLoading(true);
        const batchDataPromises = batchIds.map(async (batchId) => {
          const response = await axios.get(`batch/${batchId}`);
          return response.data;
        });


        const fetchedBatchData = await Promise.all(batchDataPromises);


        const updatedBatchData = fetchedBatchData.reduce((acc, batch) => {
          const trainers = extractNamesFromArray(batch.trainers);
          const assessors = extractNamesFromArray(batch.assessors);


          acc.push({ ...batch, trainers, assessors });


          return acc;
        }, []);


        setBatchData(updatedBatchData);
      } catch (error) {
        console.error('Error fetching batch data:', error);
      } finally {
        // Set loading state to false after fetching data (success or failure)
        setIsLoading(false);
      }
    };


    if (batchIds.length > 0) {
      fetchBatchIds();
    }
  }, [batchIds]);


  const courseNames = [];

  batchData.forEach((course) => {
    const courseParts = course.course.split('(');
    // console.log(batchData)

    if (courseParts.length > 2) {
      const extractedValue = courseParts[2].slice(0, -1);
      courseNames.push(extractedValue);
    } else if (courseParts.length > 1) {
      const extractedValue = courseParts[1].slice(0, -1);
      courseNames.push(extractedValue);
    } else {
      courseNames.push('0');
    }
  });



  // console.log(courseNames);


  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const courseDataPromises = courseNames.map(async (courseName) => {
          if (courseName !== '0') {
            const response = await axios.get(`course/${courseName}`);
            setCourseId(response.data.id)
// console.log(response.data)
            return response.data.url;
          } else {
            return null;
          }
        });


        const fetchedCourseData = await Promise.all(courseDataPromises);


        // console.log(fetchedCourseData);


        setFetchedCourseData(fetchedCourseData);


      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };


    if (courseNames.length > 0 && !fetchedCourseData.length) {
      fetchCourseData();
    }
  }, [courseNames, fetchedCourseData]);


  const signatureRef = useRef();


  const handleShowModal = (index) => {
    setSelectedIndex(index);
    setShowModal(true);
    // console.log('Modal Opened:', showModal);
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedIndex(null);
    // console.log('Modal Closed:', showModal);
  };




  const clearSignature = () => {
    signatureRef.current.clear();
  };






  const saveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    // Handle the signature data (e.g., send it to the server)

    toast.success('Save successfully!', {
      position: toast.POSITION.BOTTOM_RIGHT,
    }); // Close the modal after saving the signature
    handleCloseModal();
  };

  const extractMeetingId = (link) => {
    const match = link.match(/id=([a-z0-9-]+)&/i);
    return match ? match[1] : link.split('?id=')[1].split('&')[0];
  };

  const addParticipantToMeeting = async (meetingId) => {
    try {
      // const basicAuth = btoa('cb589ea3-9b05-44f8-b7c2-de71d128cb0b:469efb6fc6d285934e1f');

      const basicAuth = btoa(`${Username}:${Password}`);
      // const orgId = 'cb589ea3-9b05-44f8-b7c2-de71d128cb0b';
      // const apiKey = '469efb6fc6d285934e1f';
      // const basicAuth = `Basic ${btoa(`${orgId}:${apiKey}`)}`;


      const loggedInUserId = localStorage.getItem('userId');
      const userDetailsResponse = await axios.get(`home/user/${loggedInUserId}`);

      const userDetails = userDetailsResponse.data;

      const response = await axios.post(
        `${baseURLdyte}/meetings/${meetingId}/participants`,
        {
          name: userDetails.name || "student",
          picture: userDetails.profile_Url || "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg",
          preset_name: 'group_call_participant',
          custom_participant_id: userDetails.sfid.toString(),
        },
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const updatedAuthTokenLink = `${baseURLdyteMeeting}/meeting?id=${meetingId}&authToken=${response.data.data.token}`;
      window.open(updatedAuthTokenLink, '_blank');
    }
    catch (error) {
      console.error('Error adding participant:', error);
    }
  };
  const [showAllDates, setShowAllDates] = useState(false);


  const containerRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
  });

 
  const handleDownload = (batchname) => {
    const doc = new jsPDF('landscape'); // Set orientation to landscape
  
    // Add certificate image to the PDF
    const imgWidth = 297; // Width of the A4 size page in mm (landscape)
    const imgHeight = 210; // Height of the A4 size page in mm (landscape)
    doc.addImage(cert, 'PNG', 0, 0, imgWidth, imgHeight);
  
    // Fetch name from localStorage
    const studentName = localStorage.getItem('name') ; // Default name if not found
  
    
    // Calculate the text dimensions and position to center it
  // Convert name and batch to uppercase
  const nameText = studentName.toUpperCase();
  const batchText = batchname.toUpperCase();
    const nameFontSize = 19; // Set font size for name
    const batchFontSize = 15; // Set font size for batch
    const nameTextWidth = doc.getStringUnitWidth(nameText) * nameFontSize / doc.internal.scaleFactor;
    const batchTextWidth = doc.getStringUnitWidth(batchText) * batchFontSize / doc.internal.scaleFactor;
    const textHeight = nameFontSize * 1.5; // Assuming line height is 1.5 times font size
    const textX = (imgWidth - nameTextWidth) / 2;
    const batchTextX = (imgWidth - batchTextWidth) / 2;
  
    // Adjust the vertical position
    const textY = (imgHeight / 2) - 20; // Move text higher up
    const batchTextY = textY + 30; // Ensure batch text appears below the name text with some spacing
  
    // Add centered text to the PDF
    doc.setFont("Helvetica", "bold"); // Set font weight for name
    doc.setFontSize(nameFontSize);
    doc.text(nameText, textX, textY); // Student name
  
    doc.setFontSize(batchFontSize); // Set font size for batch
    doc.text(batchText, batchTextX, batchTextY); // Batch name
  
    // Save the PDF
    doc.save(`${studentName}_certificate.pdf`);
  };
  
  
  const fetchCertificateStatus = async (batchId, sId) => {
    try {
      const response = await axios.get(`/manage/certificate`, {
        params: { batchId, sId }
      });
      return response.data.includes('true');
    } catch (error) {
      console.error('Error fetching certificate status:', error);
      return false;
    }
  };
  
  useEffect(() => {
    const fetchStatuses = async () => {
      const sId = localStorage.getItem('studentteacherid');
      const statuses = {};
  
      for (const batch of batchData) {
        const status = await fetchCertificateStatus(batch.id, sId);
        statuses[batch.id] = status;
      }
  
      setCertificateStatuses(statuses);
    };
  
    if (batchData.length > 0) {
      fetchStatuses();
    }
  }, [batchData]);
  
  
  
  

  return (
    <Container className={classes.container}>
      <ToastContainer position="bottom-right" />
      <Routes>
        <Route path="/meeting/:meetingId" element={<Meeting />} />
        <Route
          path="/"
          element={
            isLoading ? (
              <Loader />
            ) : (
              <div>
                {batchData.map((course, index) => (
                  <Card key={index} className={classes.card} style={{ marginBottom: '20px' }}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} md={2}>
                        {fetchedCourseData[index] ? (
                          <img
                            className="card-img-top img-responsive p-0"
                            height=""
                            style={{ height: '120px', maxWidth: '100%' }}
                            src={fetchedCourseData[index]}
                            alt="Course cover"
                          />
                        ) : (
                          <div>No Image Available</div>
                        )}
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <CardContent>
                          <Typography variant="h5" gutterBottom>
                            Course: {course.course.split('(')[0]}
                          </Typography>
                          <Typography variant="h6" gutterBottom>
                            Batch: {course.batchcode}
                          </Typography>
                          <Typography variant="body1">
                            Trainer: {course.trainers.map(trainer => trainer)}, {course.assessors.map(assessor => assessor)}
                          </Typography>
                          <div className="course-details">
                            <Typography variant="subtitle1">Start Date: {course.days[0].batchDate}</Typography>
                            {showAllDates && (
                              <Typography variant="body2">All Dates: {course.days.map(day => day.batchDate).join(', ')}</Typography>
                            )}
                            {!showAllDates && (
                              <button className="see-all-btn" onClick={() => setShowAllDates(true)}>
                                See All
                              </button>
                            )}
                            {showAllDates && (
                              <button className="see-less-btn" onClick={() => setShowAllDates(false)}>
                                See Less
                              </button>
                            )}
                          </div>
                        </CardContent>
                      </Grid>
                      <Grid item xs={12}>


                        <div className="actions-container" style={{ display: 'flex', justifyContent: 'center', marginBottom: '2%', flexWrap: 'wrap' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px', marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faVideo} />} // Adding Font Awesome icon to the button
                            onClick={() => {
                              const meetingId = extractMeetingId(course.onlineclasslink);
                              if (meetingId) {
                                addParticipantToMeeting(meetingId);
                                // navigate(`/meeting/${meetingId}`);
                              }
                            }}
                          >
                            Join Meeting
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px', marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faComment} />} // Adding Font Awesome icon to the button
                            href={`/FeedbackStudent/${course.id}`}
                          >
                            Feedback
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px', marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faFilePowerpoint} />}
                            href={`/content/${extractId(course.course)}`}
                          >
                            Course Content
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px', marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faBook} />} // Adding Font Awesome icon to the button
                            component={Link} // Using Link component for navigation
                            to={`/written/${courseId}/${course.id}`}
                          >
                            Written Assessment
                          </Button>

                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '10px', marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faBook} />} // Adding Font Awesome icon to the button
                            component={Link} // Using Link component for navigation
                            to={`/PDFwritten/${courseId}/${course.id}`}
                          >
                            PDF Assessment
                          </Button>

                          {certificateStatuses[course.id] && (
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faGraduationCap} />}
                            onClick={() => handleDownload(course.course.split('(')[0])}
                          >
                            Certificate
                          </Button>
                        )}
                          {/* <Button
                            variant="contained"
                            color="primary"
                            style={{ marginBottom: '10px', width: '45%', maxWidth: '180px' }}
                            startIcon={<FontAwesomeIcon icon={faEdit} />} // Adding Font Awesome icon to the button
                            onClick={() => handleShowModal(index)}
                          >
                            Sign Here
                          </Button>

                          {showModal && (
                            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                              <Modal.Header closeButton>
                                <Modal.Title>Sign Here</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <SignatureCanvas
                                  ref={signatureRef}
                                  canvasProps={{ width: 500, height: 300, className: 'signature-canvas' }}
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="contained" color="default" onClick={handleCloseModal} style={{ marginRight: '8px' }}>
                                  Close
                                </Button>
                                <Button variant="contained" color="error" onClick={clearSignature} style={{ marginRight: '8px' }} >
                                  Clear
                                </Button>
                                <Button variant="contained" color="primary" onClick={saveSignature}>
                                  Save
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )} */}
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                ))}
              </div>

            )
          }
        />


      </Routes>
    </Container>


  );
};

const Manage = () => {
  return (
    <div>
      <Headstu />


      <Man />
    </div>
  );
};
export default Manage










