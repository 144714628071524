import React, { useState, useEffect } from 'react'; import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import TeachHeader from './TeachHeader'
import axios from '../Axiosinstance'
import './Teacher.css';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  // TextField,
  // InputAdornment,
  // Box,
  // ButtonGroup
} from '@mui/material';
import Loader from '../Loader';
import Online from './Online';
// import Assessment from './Assement';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// import { faSearch, faFilePowerpoint, faLaptop, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast, ToastContainer } from 'react-toastify';




const RQ = () => {


  const [batchesData, setBatchesData] = useState([]);
  const [matchingBatches, setMatchingBatches] = useState([]);

  const [sorting, setSorting] = useState({ column: '', direction: 'asc' });
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [BatchIDsPerPage, setBatchIDsPerPage] = useState(5);
  // const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [showAllDates, setShowAllDates] = useState(false); // State to track whether to show all dates or only the first two

  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredbatches, setFilteredbatches] = useState(0);
  // const [matchingBatchIDs, setMatchingBatchIDs] = useState([]);
  // const [sortedMatchingBatches, setSortedMatchingBatches] = useState([]);
  // const calculateCountForBatch = (batchId, managesData) => {
  //   return managesData.filter((manage) => manage.batch_id === batchId).length;
  // };


  // const pageCount = Math.ceil(matchingBatchIDs.length / rowsPerPage);
  // const activePage = currentPage;





  useEffect(() => {
    fetchData();
  }, [itemsPerPage, currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const studentTeacherId = localStorage.getItem('studentteacherid');
      const Nameid = localStorage.getItem('name');
      const response = await axios.get('batch/byTrainersAndAssessors', {
        params: {
          id: Nameid+"("+studentTeacherId+")",
          size: itemsPerPage,
          page: currentPage,
          searchCriteria: search // Pass search term as a parameter
        }
      });

      setBatchesData(response.data.content.reverse());
      console.log((response.data.content.reverse()));
      setFilteredbatches(response.data.totalElements); 
      setLoading(false);
      console.log(response.data.content.reverse());
    } catch (error) {
      console.error('Error fetching batches:', error);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   axios.get('batches')
  //     .then((response) => {
  //       setBatchesData(response.data.reverse());
  //       handleSort('course', 'asc');
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       setLoading(false)
  //     });
  // }, []);


  // useEffect(() => {
  //   axios.get('manages')
  //     .then((response) => {
  //       const managesData = response.data.reverse();
  //       const batchDataWithCounts = batchesData.map((batch) => {
  //         const batchId = batch.id;
  //         const count = calculateCountForBatch(batchId, managesData);
  //         return { ...batch, count };
  //       });
  //       setMatchingBatches(batchDataWithCounts);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching manages data:', error);
  //     });
  // }, [batchesData]);


  // const handleSort = (column) => {
  //   setSorting((prevSorting) => {
  //     if (prevSorting.column === column) {
  //       return {
  //         column,
  //         direction: prevSorting.direction === 'asc' ? 'desc' : 'asc',
  //       };
  //     } else {
  //       return {
  //         column,
  //         direction: 'asc', // Set the default direction when a new column is clicked
  //       };
  //     }
  //   });
  // };


  const sortData = (data, column, direction) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      const valueA = a[column] ? a[column].toLowerCase() : '';
      const valueB = b[column] ? b[column].toLowerCase() : '';
      if (direction === 'asc') {
        return valueA.localeCompare(valueB);
      } else {
        return valueB.localeCompare(valueA);
      }
    });
    return sortedData;
  };




  // const extractId = (str) => {
  //   const match = str.match(/\((\d+)\)/);
  //   return match ? match[1] : '';
  // };


  // useEffect(() => {
  //   const matchingBatches = batchesData.filter((batch) => {
  //     const trainerIds = batch.trainers.map(extractId);
  //     const assessorIds = batch.assessors.map(extractId);
  //     return trainerIds.includes(studentTeacherId) || assessorIds.includes(studentTeacherId);
  //   });


  //   const batchIDs = matchingBatches.map((batch) => batch.id);
  //   setMatchingBatchIDs(batchIDs);


  //   // console.log(batchIDs)
  //   setMatchingBatches(matchingBatches);
  //   matchingBatches.forEach((batch) => {
  //     // console.log('Matching Batch ID:', batch.id);
  //     // console.log('Batch Details:', batch);
  //   });
  // }, [batchesData, studentTeacherId]);


  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const batchesToDisplay = sortData(matchingBatches, sorting.column, sorting.direction)
    .slice(startIndex, endIndex);


  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    // Check if search term is not null or empty before fetching data
    setCurrentPage(0);
    if (search.trim() !== '') {
      fetchData();
    } else {
      toast.error('Please enter a search term');
    }
  };
  const handleResetClick = () => {
    // setCurrentPage(0);
    setSearch('');
    // console.log("hi"+search)
  };

  useEffect(() => {
    if (search === '') {
      setCurrentPage(0);
      fetchData();
    }
  }, [search]);


  // const handleRowsPerPageChange = (event) => {
  //   setRowsPerPage(parseInt(event.target.value));
  //   setCurrentPage(1);
  // };


  // const totalPages = Math.ceil(matchingBatchIDs.length / rowsPerPage);


  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };



  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-us', { month: 'short' }).replace(/^\w/, (c) => c.toUpperCase());
    const year = date.getFullYear();
    return `${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  if (loading) {
    return <Loader />; // Display loader while data is being fetched
  }



  const toggleShowDates = (batchId) => {
    setShowAllDates(prevState => ({
      ...prevState,
      [batchId]: !prevState[batchId]
    }));
  };

  return (

    <div className="container" style={{ maxWidth: '1200px' }}>
      <br></br>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
          <input
            type="text"
            placeholder="Search by Batchcode"
            value={search}
            onChange={handleSearchChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchClick();
              }
            }}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              flex: '1',
              fontSize: '16px',
            }}
          />
          <button className='btn btn-primary'
            onClick={handleSearchClick}
            style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>
            Search</button>
          <button className='btn btn-primary' onClick={handleResetClick} style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>reset</button>
        </div>

        <TableContainer sx={{ maxHeight: 540 }}>

          <Table aria-label="sticky table">
            <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
              {/* <TableRow > */}
              {/* <TableCell className="col-2" onClick={() => handleSort('course')} style={{ fontSize: '15px', fontWeight: 'bold' }}> */}
              {/* Course */}
              {/* {sorting.column === 'course' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'course' && sorting.direction === 'desc' && <span>▼</span>} */}
              {/* </TableCell> */}
              {/* <TableCell className="col-2" onClick={() => handleSort('attendance')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Attendance
                  {sorting.column === 'attendance' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'attendance' && sorting.direction === 'desc' && <span>▼</span>}
                </TableCell> */}
              {/* <TableCell className="col-2" onClick={() => handleSort('date')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Date */}
              {/* {sorting.column === 'date' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'date' && sorting.direction === 'desc' && <span>▼</span>} */}
              {/* </TableCell> */}
              {/* <TableCell className="col-2" onClick={() => handleSort('content1')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Student
                  {sorting.column === 'content1' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'content1' && sorting.direction === 'desc' && <span>▼</span>}
                </TableCell> */}
              {/* <TableCell className="col-1" onClick={() => handleSort('student')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Content */}
              {/* {sorting.column === 'student' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'student' && sorting.direction === 'desc' && <span>▼</span>} */}
              {/* </TableCell> */}
              {/* <TableCell className="col-2" onClick={() => handleSort('content2')} style={{ fontSize: '18px' }}>Content
                  {sorting.column === 'content2' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'content2' && sorting.direction === 'desc' && <span>▼</span>}
                </TableCell> */}
              {/* <TableCell className="col-1" onClick={() => handleSort('assessments')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Assessments */}
              {/* {sorting.column === 'assessments' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'assessments' && sorting.direction === 'desc' && <span>▼</span>} */}
              {/* </TableCell> */}
              {/* <TableCell className="col-2" onClick={() => handleSort('reports')} style={{ fontSize: '15px', fontWeight: 'bold' }}>Reports */}
              {/* {sorting.column === 'reports' && sorting.direction === 'asc' && <span>▲</span>}
                  {sorting.column === 'reports' && sorting.direction === 'desc' && <span>▼</span>} */}
              {/* </TableCell> */}
              {/* </TableRow> */}

              <TableRow >
                <TableCell className="col-2">
                  Course
                </TableCell>
                <TableCell className="col-2">
                  Date
                </TableCell>
                <TableCell className="col-1">
                  Content
                </TableCell>
                <TableCell className="col-1">
                  Assessments
                </TableCell>
                <TableCell className="col-2">
                  Reports
                </TableCell>
              </TableRow>

            </TableHead>

            <TableBody>
              {batchesData.map((batch) => (
                <TableRow key={batch.id}>
                  <TableCell>{batch.course}</TableCell>
                  <TableCell style={{ minWidth: 120, borderRight: '1px solid #ccc' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            <li>
                              {batch.days.slice(0, showAllDates[batch.id] ? undefined : 3).map((day, index) => (
                                <span key={index}>{getFormattedDate(day.batchDate)}{index !== batch.days.length - 1 && ', '}</span>
                              ))}

                              {/* Button to toggle between showing all dates and only the first two */}
                            </li>
                          </ul>
                        </div>
                        {batch.days.length > 3 && (
                          // <button className="toggle-button" onClick={toggleShowAllDates}>
                          //   {showAllDates ? 'See Less' : 'See More'}
                          // </button>
                          <span
                            onClick={() => toggleShowDates(batch.id)}
                            style={{
                              cursor: 'pointer',
                              color: 'blue',
                              textAlign: 'center', // Align text to center
                              marginTop: '5px', // Add some top margin for spacing
                            }}
                          >
                            {showAllDates[batch.id] ? 'See Less' : 'See More'}
                          </span>
                        )}
                      </TableCell>                  <TableCell>
                    <Button href={`./Managee/${batch.courseId}`} variant="contained" color="primary">
                      Manage
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button href={`./View/${batch.id}`} variant="outlined" color="primary">
                      View Assessments
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button href={`./Student/${batch.id}`} variant="contained" color="primary">
                      Student Report
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredbatches}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => setCurrentPage(newPage)}
          // labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
          onRowsPerPageChange={event => {
            setItemsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
      </Paper>



    </div>

  );
};



const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToTeacher = () => {
    setTabValue(0);
  };
  const navigateToOnline = () => {
    setTabValue(1);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <TeachHeader />
      <br></br>
      <div className="d-flex justify-content-center" style={{ marginTop: '1%' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Assessment-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
              minWidth: '400px'
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none',
            },
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="My Course" />
          <Tab label="Online Course" />
          {/* <Tab label="My Assessment" /> */}
        </Tabs>
      </div>
      <div>
        {tabValue === 0 && <RQ />}
        {tabValue === 1 && <Online onCourseCreated={navigateToTeacher} />}
        {/* {tabValue === 2 && <Assessment onCourseCreated={navigateToOnline} />} */}
      </div>
    </div>
  );
};


export default New;


