import React from 'react';
import Header from '../components/Header';
import './Invoice.css';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import axios from '../Axiosinstance';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableContainer } from '@mui/material'; // Import necessary components from Material-UI
import Paper from '@mui/material/Paper';


const Container = () => {
  const { batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);
  const [students, setStudents] = useState([]); // State to store student details




  useEffect(() => {
    // Fetch batch details
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      }
    };


    const fetchStudents = async () => {
      try {
        const response = await axios.get('students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };


    fetchBatchDetails();
    fetchStudents();
  }, [batchId]);




  if (!batchDetails) {
    return <p>Loading...</p>;
  }
  return (
    <div className="container-fluid" >
      <div className="row" style={{ maxWidth: '1200px' }}>
        <div className="col-md-4" style={{ marginLeft: '10px' }}>
          <div className="container-content">
            <p className="paragraph">Course:{batchDetails.course}</p>
            <p className="paragraph">Course Date:{batchDetails.date}</p>
            <p className="paragraph">Trainers:{batchDetails.trainers}</p>
          </div>
        </div>




        <div className="col-md-4" style={{ marginLeft: '10px' }}>
          <div className="container-content">
            <p className="paragraph">Batch:{batchDetails.batchcode}</p>
            <p className="paragraph">Student Enrolled:{batchDetails.course}</p>
            <p className="paragraph">Assessors:{batchDetails.assessors}</p>
          </div>
        </div>
      </div>
    </div>
  );
};




const Green = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [batches, setBatches] = useState([
    { id: 1, code: 'SANJEEV', Phone: '321157444', Registered: '2-6-2023', manage: 'Pending', action: 'delete' },
    { id: 2, code: 'SUGIR', Phone: '454777447', Registered: '21-6-2023', manage: 'Pending', action: 'delete' },
    { id: 3, code: 'SUBASH', Phone: '4774777', Registered: '25-6-2023', manage: 'Pending', action: 'delete' },
    { id: 4, code: 'SARAH', Phone: '999888777', Registered: '5-7-2023', manage: 'Pending', action: 'delete' },
    { id: 5, code: 'SAMUEL', Phone: '123456789', Registered: '12-7-2023', manage: 'Pending', action: 'delete' },
    { id: 6, code: 'SHARON', Phone: '987654321', Registered: '18-7-2023', manage: 'Pending', action: 'delete' },
    { id: 7, code: 'STEVEN', Phone: '111222333', Registered: '25-7-2023', manage: 'Pending', action: 'delete' },
    { id: 8, code: 'SOPHIE', Phone: '444555666', Registered: '4-8-2023', manage: 'Pending', action: 'delete' },
    { id: 9, code: 'SEBASTIAN', Phone: '777888999', Registered: '14-8-2023', manage: 'Pending', action: 'delete' },
    { id: 10, code: 'SABRINA', Phone: '666777888', Registered: '20-8-2023', manage: 'Pending', action: 'delete' },
    { id: 2, code: 'SUGIR', Phone: '454777447', Registered: '21-6-2023', manage: 'Pending', action: 'delete' },
    { id: 3, code: 'SUBASH', Phone: '4774777', Registered: '25-6-2023', manage: 'Pending', action: 'delete' },
    { id: 4, code: 'SARAH', Phone: '999888777', Registered: '5-7-2023', manage: 'Pending', action: 'delete' },




  ]);


  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(null);






  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };




  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };




  const handleGenerateInvoice = (id) => {
    setSelectedBatchId(id);
    setShowInvoiceModal(true);
  };




  const filteredBatches = batches.filter(
    (batch) =>
      batch.code.toLowerCase().includes(search.toLowerCase()) &&
      (filter === '' || batch.ssgWSQ === filter)
  );




  const handleStatusChange = (id) => {
    const updatedBatches = batches.map((batch) => {
      if (batch.id === id) {
        if (batch.manage === 'Pending') {
          return { ...batch, manage: 'Approved' };
        } else {
          return { ...batch, manage: 'Pending' };
        }
      }
      return batch;
    });
    setBatches(updatedBatches);
  };




  const handleModalClose = () => {
    setShowInvoiceModal(false);
  };


  const handleModalSubmit = () => {
    // Perform the submit action here
    console.log('Invoice submitted for batch with ID:', selectedBatchId);
    setShowInvoiceModal(false);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [batchesPerPage, setBatchesPerPage] = useState(10);


  return (
    <div className="container-fluid" style={{ maxWidth: "1050px" }}>
      <br></br>


   
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <div className="row" style={{ marginTop: '1%'}}>
            <div className="col-md-2">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by code"
                  value={search}
                  onChange={handleSearchChange}
                />
              </div>
            </div>


            <div className="col-md-2">
              <div className="form-group" style={{ marginLeft: '5px' }}>
                <select className="form-control" value={filter} onChange={handleFilterChange}>
                  <option value="">All</option>
                  <option value="ssgWSQ">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
              </div>
            </div>
          </div>
          <br />
          <div className="table-responsive">
            <TableContainer sx={{ maxHeight: 'none' }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell >Batch Code</TableCell >


                    <TableCell >Registered on</TableCell >
                    <TableCell >Manage</TableCell >
                    <TableCell >Action</TableCell >
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBatches
                    .slice((currentPage - 1) * batchesPerPage, currentPage * batchesPerPage)
                    .map((batch) => (
                      <TableRow key={batch.id}>
                        <TableCell>{batch.code}</TableCell>
                        <TableCell>{batch.Registered}</TableCell>
                        <TableCell>
                          <button
                            className={`btn btn-primary${batch.manage === 'Pending' ? ' btn-sm' : ''} w-5 text-wrap`}
                            onClick={() => handleStatusChange(batch.id)}
                            style={{ fontSize: '12px', justifyContent: 'center', padding: '10px 40px', marginLeft: '-10%' }}
                          >
                            {batch.manage}
                          </button>








                        </TableCell>
                        <TableCell>
                          <button
                            className={`btn btn-primary ${window.innerWidth <= 767 ? 'btn-sm' : ''}`}


                            style={{ fontSize: window.innerWidth <= 767 ? '12px' : 'inherit', padding: '12px 20px' ,  marginLeft: '-10%' }}
                          >
                            Generate Invoice
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>


              </Table>
              <TablePagination
                rowsPerPageOptions={[5 , 10, 25]}
                component="div"
                count={filteredBatches.length}
                rowsPerPage={batchesPerPage}
                page={currentPage - 1}
               
labelDisplayedRows={({ from, to }) => `${from}-${to} of ${currentPage}`}


                onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
                onRowsPerPageChange={(event) => {
                  setCurrentPage(1);
                  setBatchesPerPage(event.target.value);
                }}
              />
            </TableContainer>
          </div>


        </Paper>


     


      {showInvoiceModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Generate Invoice</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleModalClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" >
                <h6>Courses Fees:</h6>
                <input type="text" className="form-control" placeholder="Enter course fees" />
                <h6>Invoice Date:</h6>
                <input type="date" className="form-control" placeholder="Select invoice date" />
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="checkbox1" />
                  <label className="form-check-label" htmlFor="checkbox1">
                    Option 1
                  </label>
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="checkbox2" />
                  <label className="form-check-label" htmlFor="checkbox2">
                    Option 2
                  </label>
                </div>
                <div className="form-check">
                  <input type="checkbox" className="form-check-input" id="checkbox3" />
                  <label className="form-check-label" htmlFor="checkbox3">
                    Option 3
                  </label>
                </div>
              </div>
              <p style={{ marginLeft: '4%' }}>To add more items</p>
              <Link to='/Invoicesetting' style={{ textDecoration: "none", marginLeft: '4%' }}>Click here</Link>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" style={{ backgroundColor: '#03396c' }} onClick={handleModalClose}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" style={{ backgroundColor: '#03396c' }} onClick={handleModalSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
  );
};




export default Green;





