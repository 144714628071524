import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios,{logout} from '../Axiosinstance';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Newfacilitators from './Newfacilitators'
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Loader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'id', label: 'NRIC ID', minWidth: 100 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'phone', label: 'Phone', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];




const FacilitatorsComponent = () => {
  const [facilitators, setFacilitators] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [facilitatorsPerPage, setFacilitatorsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredFacilitators, setFilteredFacilitators] = useState([]);
  const [editedFacilitator, setEditedFacilitator] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('facilitators')
      .then((response) => {
        const data = response.data.reverse();
        setFacilitators(data);
        setFilteredFacilitators(data);
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch((error) => {
    
        
        console.error('Error fetching data:', error);
  
        setLoading(false); // Set loading to false in case of an error
      });
  }, []);











  useEffect(() => {
    const filtered = facilitators.filter((facilitator) =>
      facilitator.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFacilitators(filtered);
  }, [searchQuery, facilitators, currentPage, pageSize]);






  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };




  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setRowToDelete(null);
  };




  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`facilitator/${rowToDelete}`);


      if (response.status === 200) {
        // Assuming you have a state variable named 'facilitators'
        const updatedFacilitators = facilitators.filter((facilitator) => facilitator.id !== rowToDelete);
        setFacilitators(updatedFacilitators);
        toast.success('Facilitator deleted successfully');
      } else {
       
        console.error('Failed to delete facilitator');
      }
    } catch (error) {
     
      console.error('Error deleting facilitator:', error);
    } finally {
      setShowConfirmationModal(false);
      setRowToDelete(null);
    }
  };






  const handleDelete = (id) => {
    setRowToDelete(id);
    setShowConfirmationModal(true);
  };








  const openEditModal = (facilitator) => {
    setEditedFacilitator(facilitator);
    setEditModalOpen(true);

    // Fetch all users
    axios.get('users')
      .then((response) => {
        const userData = response.data;

        // Find the matching user based on sfid
        const matchingUser = userData.find(user => user.sfid === facilitator.sfid);

        // if (matchingUser) {
        //   console.log(`User ID: ${matchingUser.userId}`);
        //   // You can display the user id in your UI as needed
        // }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  };





  const closeEditModal = () => {
    setEditModalOpen(false);
  };




  const handleSaveEdit = async (e) => {
    e.preventDefault(); // Prevent the default form submission





    try {
      if (

        !editedFacilitator.name ||
        !editedFacilitator.usertype ||
        !editedFacilitator.email ||
        !editedFacilitator.phone ||
        !editedFacilitator.educational_level ||
        !editedFacilitator.qualification ||
        !editedFacilitator.domain ||
        !editedFacilitator.gender ||
        !editedFacilitator.password


      ) {
        toast.error('Please fill in all required fields.');
        return;
      }


      const confirmation = window.confirm('Are you sure you want to update this facilitator?');

      if (confirmation) {

        const response = await axios.put(`facilitator/${editedFacilitator.id}`, editedFacilitator);

        if (response.status === 200) {
          // Map over facilitators and update the edited facilitator
          const updatedFacilitators = facilitators.map((facilitator) =>
            facilitator.id === editedFacilitator.id ? editedFacilitator : facilitator
          );

          // Update the state with the new array of facilitators
          setFacilitators(updatedFacilitators);

          closeEditModal();
          toast.success('Facilitator updated successfully');
        } else {
          console.error('Failed to update facilitator');
          navigate('/');
          logout()
        }
      }
    } catch (error) {
      navigate('/');
      logout()
      console.error('Error updating facilitator:', error);
    }
  };



  return (
    <div>
      <div className="container" style={{ maxWidth: '1200px' }}>
        {loading ? (
          <Loader /> // Show loader while data is being fetched
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <input
              type="text"
              placeholder="Search by Name"
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: '8px 12px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                display: 'flex',
                fontSize: '16px',
              }}
            />
            <TableContainer
              sx={{
                overflowX: 'auto',
                maxWidth: '100%',
                '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                  overflowX: 'unset', // Enable horizontal scroll
                },
              }}
            >

              <div style={{ maxHeight: '600px', overflowY: 'auto' }}>

              <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>

                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFacilitators
                      .slice((currentPage - 1) * facilitatorsPerPage, currentPage * facilitatorsPerPage)
                      .map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.nric}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.phone}</TableCell>
                          <TableCell>
                            <BsPencilFill style={{ marginRight: 10 }} onClick={() => openEditModal(row)} />
                            <DeleteRoundedIcon
                              style={{ color: 'red', marginLeft: 10 }}
                              onClick={() => handleDelete(row.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={facilitators.length}
              rowsPerPage={facilitatorsPerPage}
              labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}


              page={currentPage - 1}
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
              onRowsPerPageChange={(event) => {
                setCurrentPage(1);
                setFacilitatorsPerPage(event.target.value);
              }}
            />
          </Paper>
        )}

      </div>




      <Dialog open={editModalOpen} onClose={closeEditModal}>
        <DialogTitle>Edit Facilitator (Newton International College)</DialogTitle>




        <DialogContent onSubmit={handleSaveEdit}>
          <div >


            <label>Salutation</label>
            <select
              className="form-control"
              name="salutation"
              value={editedFacilitator ? editedFacilitator.salutation : ''}

              onChange={(e) =>
                setEditedFacilitator({
                  ...editedFacilitator,
                  salutation: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              style={{ width: '100%', height: '58px', borderRadius: '6px', border: '1px solid #ced4da' }}

            >
              <option value="">Select Salutation</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Miss">Miss</option>
            </select>
          </div>

          <TextField
            label="Name"
            name="name"
            value={editedFacilitator ? editedFacilitator.name : ''}

            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
              const truncatedValue = inputValue.substring(0, 25); // Limit the input to 25 characters}
              setEditedFacilitator({
                ...editedFacilitator,
                name: truncatedValue,
              })
            }}


            fullWidth
            margin="normal"
            required

          />





          <div className="form-group">
            <label>User Type</label>
            <select
              className="form-control"
              name="usertype"
              style={{ width: '100%', height: '58px', borderRadius: '6px', border: '1px solid #ced4da' }}
              value={editedFacilitator ? editedFacilitator.usertype || '' : ''}
              onChange={(e) =>
                setEditedFacilitator({
                  ...editedFacilitator,
                  usertype: e.target.value,
                })
              }
              fullWidth
              margin="normal"
            >
              <option value="">Select User Type</option>
              <option value="new">New</option>
              <option value="existing">Existing</option>
            </select>
          </div>


          <TextField
            label="NRIC ID"
            name="nric"
            type="text" // Change type to "text" for alphanumeric input
            value={editedFacilitator ? editedFacilitator.nric : ''}

            onChange={(e) => {
              const inputValue = e.target.value;


              // Allow only alphanumeric characters and limit the length to 12 characters
              const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '').substring(0, 12);


              setEditedFacilitator({
                ...editedFacilitator,
                nric: sanitizedValue,
              });
            }}
            fullWidth
            margin="normal"
            required

          />




          <TextField
            label="Email"
            name="email"
            value={editedFacilitator ? editedFacilitator.email : ''}

            onChange={(e) =>

              setEditedFacilitator({
                ...editedFacilitator,
                email: e.target.value,
              })
            }

            fullWidth
            margin="normal"
            required



          />

          <TextField
            label="Phone"
            name="phone"
            value={editedFacilitator ? editedFacilitator.phone : ''}

            onChange={(e) => {
              // Remove non-digit characters
              const cleanedValue = e.target.value.replace(/\D/g, '');

              // Accept only the first 10 digits
              const truncatedValue = cleanedValue.slice(0, 8);

              setEditedFacilitator({
                ...editedFacilitator,
                phone: truncatedValue,
              });
            }}
            fullWidth
            margin="normal"
            required
            inputProps={{
              pattern: "^[0-9]{8}$",
            }}


          />
          <TextField
            label="Educational Level"
            name="educational_level"
            value={editedFacilitator ? editedFacilitator.educational_level : ''}

            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
              const truncatedValue = inputValue.substring(0, 20);
              setEditedFacilitator({
                ...editedFacilitator,
                educational_level: inputValue,
              })
            }}
            fullWidth
            margin="normal"
            required

          />

          <TextField
            label="Qualification"
            name="qualification"
            value={editedFacilitator ? editedFacilitator.qualification : ''}

            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
              const truncatedValue = inputValue.substring(0, 20);
              setEditedFacilitator({
                ...editedFacilitator,
                qualification: truncatedValue,
              })
            }}
            fullWidth
            margin="normal"
            required

          />

          <TextField
            label="Domain"
            name="domain"
            value={editedFacilitator ? editedFacilitator.domain : ''}

            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
              const truncatedValue = inputValue.substring(0, 20);
              setEditedFacilitator({
                ...editedFacilitator,
                domain: truncatedValue,
              })
            }}
            fullWidth
            margin="normal"
            required


          />





          <div className="form-group">
            <label>Gender</label>
            <select
              className="form-control"
              name="gender"
              value={editedFacilitator ? editedFacilitator.gender : ''}

              onChange={(e) =>
                setEditedFacilitator({
                  ...editedFacilitator,
                  gender: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              style={{ width: '100%', height: '58px', borderRadius: '6px', border: '1px solid #ced4da' }}
              required
              error={editedFacilitator.gender === ""}
              helperText={editedFacilitator.gender === "" ? "Please enter a race" : ""}




            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="others">Others</option>
            </select>
          </div>
          <TextField
            label="Password"
            name="password"
            value={editedFacilitator ? editedFacilitator.password : ''}

            onChange={(e) =>

              setEditedFacilitator({
                ...editedFacilitator,
                password: e.target.value,
              })
            }
            fullWidth
            margin="normal"
            required

          />

        </DialogContent>

        <DialogActions>
          <Button onClick={handleSaveEdit} variant="contained" color="primary" style={{ backgroundColor: 'green', color: 'white' }}>
            Update
          </Button>
          <Button onClick={closeEditModal} variant="contained" color="secondary" style={{ backgroundColor: 'red', color: 'white' }}>
            Cancel
          </Button>
        </DialogActions>




      </Dialog>



      <Modal
        show={showConfirmationModal}
        onHide={handleCancelDelete}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20%', // Adjust this value to move the modal down
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this course?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete} color="secondary" style={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete} color="secondary" style={{ backgroundColor: 'red', color: 'white' }}>

            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
    </div>



  );
};






const New = () => {
  const [tabValue, setTabValue] = useState(0);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };




  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Header />
      <br />


      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Facilitator-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none',
            },

            ...(isMobile && {
              flexDirection: 'column',
              "& button": {
                width: '100%',
              },
            }),
          }}
        >
          <Tab label="Facilitators" sx={{ minWidth: isMobile ? '50%' : '580px' }} />
          <Tab label="New Facilitator" sx={{ minWidth: isMobile ? '50%' : '580px' }} />
        </Tabs>


      </div>
      <br />
      <div>
        {tabValue === 0 && <FacilitatorsComponent />}
        {tabValue === 1 && <Newfacilitators onFormSubmit={() => handleTabChange(0)} />}
      </div>
    </div>
  );


};


export default New;

















