import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


import Header from '../components/Header';
import axios from '../Axiosinstance';
import { Button } from '@mui/material'




const Form = () => {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [courses, setCourses] = useState([]);




  useEffect(() => {
    axios.get('batches')
      .then(response => {
        const courseData = response.data.map(item => ({ id: item.id, name: item.course }));
        setCourses(courseData);
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });




  }, []);


  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected Course:', selectedCourse);
  };




  const styles = {
    container: {
      maxWidth: "500px",
      margin: "0 auto",
      padding: "40px 40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
     
    },


    formGroup: {
      marginBottom: "20px",
    }
  };




  return (
    <div className="container-fluid" style={{ maxWidth: "1050px"  }}>
<br></br>
    <div style={styles.container}>
       
        <form onSubmit={handleSubmit}>
        <h2 >Grant Calculator-Base Scheme</h2>


        <div className="form-group">
          <label htmlFor="course">Select Course:</label>
          <select id="course" value={selectedCourse}
          className='form-control'
          onChange={handleCourseChange}>
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}


          </select>
          </div>
        < br />
          <Button type="submit" variant="contained"
            color="primary"
            >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};






const New = () => {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [courses, setCourses] = useState([]);
  const [students, setStudents] = useState([]);


  useEffect(() => {
    axios.get('batches')
      .then(response => {
        const courseData = response.data.map(item => ({ id: item.id, name: item.course }));
        setCourses(courseData);
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });


    axios.get('students')
      .then(response => {
        const studentData = response.data.map(item => item.fullname);
        setStudents(studentData);
      })
      .catch(error => {
        console.error('Error fetching students:', error);
      });
  }, []);


  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };


  const handleStudentChange = (event) => {
    setSelectedStudent(event.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected Course:', selectedCourse);
    console.log('Selected Student:', selectedStudent);
  };




  const styles = {
    container: {
      maxWidth: "500px",
      margin: "0 auto",
      padding: "40px 40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
     
    },


    formGroup: {
      marginBottom: "20px",
    }
  };


  return (
    <div className="container-fluid" style={{ maxWidth: "1050px"  }}>
<br></br>
    <div style={styles.container}>
        <form onSubmit={handleSubmit}>
          <h2> Grant Calculator-Base Scheme</h2>


       
          <div className="form-group">
          <label htmlFor="course">Select Course:</label>
          <select id="course"
          className='form-control'
          value={selectedCourse}
           onChange={handleCourseChange}>
            <option value="">Select a course</option>
            {courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.name}
              </option>
            ))}
          </select>
          </div>


          <div className="form-group">
          <label htmlFor="student">Select Student:</label>
          <select id="student" value={selectedStudent}
         
          className='form-control'
          onChange={handleStudentChange} style={{ marginTop: '10' }}>
            <option value="">Select a student</option>
            {students.map((student) => (
              <option key={student} value={student}>
                {student}
              </option>
            ))}
          </select>
          </div>
           < br />
          <Button type="submit" variant="contained"
            color="primary"
            >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};




const All = () => {


  return (
    <div>
   
        <Header />
     
     
            <Form />
   
       
            <New />
         
       
     
    </div>
  );
};


export default All;

