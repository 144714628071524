import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import TeachHeader from './TeachHeader';
import './Tprofile'
import axios from '../Axiosinstance';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common';






const Newbatch = () => {
  const [userData, setUserData] = useState({
    id: '',
    username: '',
    password: '',
    email: '',
    usertype: 0,
    sfid: 0,
    profile_Url: '',
    name: ''
  });
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');

  useEffect(() => {
    // Retrieve the id from local storage
    const id = localStorage.getItem('userId');

    if (id) {
      // Make an API request to get user data
      axios.get(`/home/user/${id}`)
        .then((response) => {
          const userDataResponse = response.data;
          // Update the state with the response data
          setUserData(userDataResponse);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    // Specify the folder in the public_id property
    formData.append('public_id', 'LMS/profile/' + file.name);

    try {
      const response = await fetch(baseURL, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.secure_url !== '') {
        const url = data.secure_url;
        setUploadedFileUrl(url);
        console.log(url)
        localStorage.setItem('passportUrl', url);

        // Update the user's profile URL in the database
        axios
          .put(`/home/user/${userData.userId}`, {
            ...userData,
            profile_Url: url,
          })
          .then((response) => {
            // Handle success, e.g., show a success message
            // console.log('User details updated:', response.data);
          })
          .catch((error) => {
            // Handle errors, e.g., show an error message
            console.error('Error updating user details:', error);
          });
      }
    } catch (err) {
      console.error(err);
    }
  };



  // Function to handle saving changes
  const handleSaveChanges = () => {
    // Make a PUT request to update user details
    axios.put(`/user/${userData.id}`, userData)
      .then((response) => {
        // Handle success, e.g., show a success message
        // console.log('User details updated:', response.data);
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error('Error updating user details:', error);
      });
  };

  return (
    <div className="new-batch" >
      <h2 style={{ fontSize: '2em', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
        {/* <Link className="nav-link active ms-0" to="/profile"> */}
        Profile
        {/* </Link> */}
      </h2>
      <div className="container-xl  px-4 mt-2" style={{ display: 'flex', justifyContent: 'center' }}>


        <hr className="mt-0 mb-4" />

        <div className="row">

          <div className="col-xl-8">
            <div className="card mb-4">
              <div className="card-header">Account Details</div>
              <div className="card-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="inputUsername" className="form-label">
                      name
                    </label>
                    <input
                      className="form-control"
                      id="inputUsername"
                      name="name"
                      type="text"
                      placeholder="Enter your name"
                      value={userData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Email
                    </label>
                    <input
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputPassword" className="form-label">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="inputPassword"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      value={userData.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-xl-4">
                    <div>
                      <input type="file" id="fileupload" onChange={handleFileUpload} /><br />
                      {userData.profile_Url && ( // Check if profile_Url is available
                        <div>
                          <p>Profile Image:</p>
                          <img style={{ width: '250px', height: '150px' }} src={userData.profile_Url} alt="Profile" />
                        </div>
                      )}
                    </div>
                  </div>
                  <br />
                  <button
                    className="btn btn-primary btn-lg" // Adjust button size as needed (e.g., btn-lg, btn-sm)
                    type="button"
                    onClick={handleSaveChanges}
                    style={{ maxWidth: '200px', margin: '0 auto' }} // Responsive width and centering
                  >
                    Save Changes
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


const NewbatchPage = () => {
  return (
    <div>
      <TeachHeader />
      <div className="container-xl px-4 mt-4">

        <Newbatch />
      </div>
    </div>
  );
};


export default NewbatchPage;



