import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../components/Header';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from '../Axiosinstance';
import Box from '@mui/material/Box';

import Pagination from 'react-bootstrap/Pagination';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus } from 'react-icons/fa';




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const NestedModal = ({ showNestedModal, handleCloseNestedModal, detailedFeedback }) => {
    return (
        <Modal show={showNestedModal} onHide={handleCloseNestedModal} dialogClassName="modal-fullscreen">
            <Modal.Header closeButton>
                <Modal.Title>Feedback Answers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {detailedFeedback && detailedFeedback.length > 0 && ( // Add a check for detailedFeedback
                    <div>
                        <h5>Student: {detailedFeedback[0].studentName}</h5> {/* Access studentName from the first element */}
                        <Table>
                            <thead>
                                <tr>
                                    <th>Question</th>
                                    <th>Answer</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detailedFeedback[0].questions.map((question, index) => (
                                    <tr key={index}>
                                        <td>{question}</td>
                                        <td>{detailedFeedback[0].answers[index]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
                <Button onClick={handleCloseNestedModal}>Close </Button>
            </Modal.Body>
        </Modal>
    );
};



const App = () => {
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [batchCodes, setBatchCodes] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedBatchId, setSelectedBatchId] = useState(null);
    const [feedbackData, setFeedbackData] = useState([]);
    const [selectedBatchFeedback, setSelectedBatchFeedback] = useState([]);
    const [fetchedFeedback, setFetchedFeedback] = useState([]);
    const [feedbackId, setFeedbackId] = useState('');
    const [studentId, setStudentId] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showNestedModal, setShowNestedModal] = useState(false);

    const [moduleName, setModuleName] = useState('');
    const [dateTaken, setDateTaken] = useState('');
    const [lectureName, setLectureName] = useState('');

    const [detailedFeedback, setDetailedFeedback] = useState([]);
    const [selectedFeedback, setSelectedFeedback] = useState([]);


    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        const filteredFeedback = selectedBatchFeedback.filter((feedback) =>
            feedback.questions.toLowerCase().includes(query.toLowerCase())
        );

        setSelectedBatchFeedback(filteredFeedback);
    };

    useEffect(() => {
        axios.get('batches')
            .then(response => {
                setBatchCodes(response.data);
            })
            .catch(error => {
                console.error('Error fetching batch codes:', error);
            });
    }, []);

    const handleCloseModal = () => {
        setShowCreateModal(false);

        setModuleName('');
        setDateTaken('');
        setLectureName('');
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
    };


    const handleCloseNestedModal = () => {
        setShowNestedModal(false);
    };


    //for delete created feedback
    const handleDelete = (id) => {
        const confirmed = window.confirm('Are you sure you want to delete the course feedback?');
        if (!confirmed) {
            return;
        }
        axios.delete(`generalfeedbackcreate/${id}`)
            .then(response => {
                setFetchedFeedback(prevFeedback => prevFeedback.filter(feedback => feedback.id !== id));
                toast.success('Feedback deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting feedback data:', error);
            });
    };

    useEffect(() => {
        if (selectedBatchId) {
            fetchFeedbackByBatch(selectedBatchId);
        }
    }, [selectedBatchId]);

    const handleCreate = (e) => {
        e.preventDefault();
        const confirmed = window.confirm('Are you sure you want to save the course feedback?');
        if (!confirmed) {
            return;
        }

        const feedbackQuestions = [
            "The module was interesting and suitable.",
            "I am overall satisfied with this module.",
            "I am satisfied with the standard and quality of student support services.",
            "I am satisfied with the school’s physical facility, E-learning resources, Infrastructure and other facilities (Classroom, Aircon, Wi-Fi, access etc.)",
            "The communication provided for this module such as the module information, timetable, assessment and results were given in a timely and effective manner.",
            "How satisfied are you with your recruitment agent?",
            "How do you rate our teaching facilities' resources and its adequacy, accessibility and quality (E.g: PowerPoint Slides, Videos, Software Simulation and practical kit)?",
            "How do you rate our learning resources and did they meet your needs and expectations? (Google Classroom, course Notes, assessment materials)",
            "How do you rate NIC’s environment?",
            "The lecturer is well-organized and prepared for the class.",
            "The lecturer expressed clear expectations of my learning and performance in this class.",
            "The lecturer is clear in giving directions and explaining what is expected of assignments and/or examinations.",
            "The lecturer inspires me with his/her knowledge in the module.",
            "I am satisfied with the standard and quality of teaching.",
            "I am satisfied with my pre-course counselling experience when I first joined the school.",
            "The assessment mode (Examination and/or Coursework) allocated is efficient and appropriate.",
            "The assessments allow me to gauge my own competency at regular intervals.",
            "I am satisfied with the assessment methods and frequency.",
            "The holistic development activities provided by the NIC such as (career progression seminars, Cricket Match, Carrom matches, E-celebrations, etc.) are satisfactory and helpful.",
            "NIC applies innovative methods for students' overall education experience such as online application form, Online Progress Reports, Zoom Class, Google Classroom, etc."
        ];

        const feedbackData = {
            questions: feedbackQuestions,
            batchId: selectedBatchId,
            moduleName,
            dateTaken,
            lectureName
        };

        axios.post('generalfeedbackcreate/', feedbackData)
            .then(response => {
                setShowCreateModal(false);
                toast.success('Course feedback created successfully');
                fetchFeedbackByBatch(selectedBatchId); // Fetch the updated feedback list after creation
                setModuleName('');
                setDateTaken('');
                setLectureName('');
            })
            .catch(error => {
                console.error('Error creating feedback data:', error);
            });
    };

    useEffect(() => {
        if (selectedBatchId) {
            axios.get(`generalfeedbackcreate/batch/${selectedBatchId}`)
                .then(response => {
                    setFetchedFeedback(response.data); // Set fetchedFeedback directly to response data array
                    console.log(response.data);
                })
                .catch(error => {
                    console.error('Error fetching feedback data for batch:', error);
                    setFetchedFeedback([]); // Clear fetchedFeedback if an error occurs or no data is found
                });
        } else {
            setFetchedFeedback([]); // Clear fetchedFeedback when no batch is selected
        }
    }, [selectedBatchId]);

    const fetchFeedbackByBatch = (batchId) => {
        axios.get(`generalfeedbackcreate/batch/${batchId}`)
            .then(response => {
                setFetchedFeedback(response.data); // Set fetchedFeedback directly to response data array
                console.log(response.data);
            })
            .catch(error => {
                console.error('Error fetching feedback data for batch:', error);
            });
    };
    // for fecth feedback by feedback id
    const fetchFeedbackDetails = (feedbackId) => {
        // console.log(feedbackId)
        axios.get(`generalfeedback/feedback/${feedbackId}`)
            .then(response => {
                console.log(response.data)
                setDetailedFeedback(response.data);
                setSelectedFeedback(response.data);
                console.log(response.data.studentName)

                setShowViewModal(true); // Show the view modal after data is fetched
            })
            .catch(error => {
                console.error('Error fetching feedback details:', error);
            });
    };

    const deleteStudentfeed = (id) => {
        const confirmed = window.confirm('Are you sure you want to delete the course feedback?');
        if (!confirmed) {
            return;
        }
        axios.delete(`generalfeedback/${id}`)
            .then(response => {
                setSelectedFeedback(prevFeedback => prevFeedback.filter(feedback => feedback.id !== id));
                toast.success('Feedback deleted successfully');
            })
            .catch(error => {
                console.error('Error deleting feedback data:', error);
            });
    };

    return (
        <div className="containerw mx-auto" >
            <h2>Feedback Form</h2>
            <br />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div style={{ marginRight: '10px', flex: 1 }}>
                    <TextField
                        label="Search by Question"
                        value={searchQuery}
                        onChange={handleSearch}
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div style={{ flex: 1 }}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="batchSelectLabel">Select Batch</InputLabel>
                        <Select
                            labelId="batchSelectLabel"
                            id="batchSelect"
                            value={selectedBatch}
                            onChange={(e) => {
                                setSelectedBatch(e.target.value);
                                const batch = batchCodes.find(batch => batch.batchcode === e.target.value);
                                if (batch) {
                                    setSelectedBatchId(batch.id);
                                    const batchFeedback = feedbackData.filter(feedback => feedback.batchId === batch.id);
                                    setSelectedBatchFeedback(batchFeedback);
                                } else {
                                    setSelectedBatchId(null);
                                    setSelectedBatchFeedback([]);
                                }
                            }}
                            label="Select Batch"
                        >
                            <MenuItem value="">
                                <em>Select a batch</em>
                            </MenuItem>
                            {batchCodes.map(batch => (
                                <MenuItem key={batch.id} value={batch.batchcode}>
                                    {batch.batchcode}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ flex: 1 }}>
                    {selectedBatch && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowCreateModal(true)}
                            style={{ marginRight: '-10px', marginLeft: '50%', padding: '10px 35px', fontSize: '15px' }}
                        >
                            <FaPlus /> Create
                        </Button>
                    )}
                </div>
            </div>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">S.No </TableCell>
                            <TableCell align="center">Module Name</TableCell>
                            <TableCell align="center">Lecture Name</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fetchedFeedback.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No feedback found</TableCell>
                            </TableRow>
                        ) : (
                            fetchedFeedback.map((feedback, index) => (
                                <TableRow key={feedback.id}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{feedback.moduleName}</TableCell>
                                    <TableCell align="center">{feedback.lectureName}</TableCell>
                                    <TableCell align="center">
                                        <Button variant="contained" color="secondary" onClick={() => handleDelete(feedback.id)}>
                                            <BsTrashFill />
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={() => fetchFeedbackDetails(feedback.id)}>
                                            View
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>

                </Table>
            </TableContainer>

            <Modal show={showCreateModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleCreate}>

                        <div className="form-group">
                            <label htmlFor="moduleName">Name of the module taken</label>
                            <input
                                type="text"
                                className="form-control"
                                id="moduleName"
                                placeholder="Enter module name"
                                value={moduleName}
                                onChange={(e) => setModuleName(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dateTaken">Date Taken</label>
                            <input
                                type="date"
                                className="form-control"
                                id="dateTaken"
                                placeholder="Enter date taken"
                                value={dateTaken}
                                onChange={(e) => setDateTaken(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="lectureName">Lecture Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="lectureName"
                                placeholder="Enter lecture name"
                                value={lectureName}
                                onChange={(e) => setLectureName(e.target.value)}
                            />
                        </div>
                        <div style={{display:'flex', }} >
                            <button variant="contained" type="submit" style={{ padding: '3px 10px',marginTop:'4px' }}>
                            Create
                        </button>
                        </div>
                        
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} dialogClassName="modal-fullscreen">
                <Modal.Header closeButton>
                    <Modal.Title>Feedback Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {selectedFeedback.map((feedback, index) => (
                        <div key={index}>
                            <h4>Student Name: {feedback.studentName}</h4>
                            Display other feedback details
                            <Button variant="contained" color="primary" onClick={() => setShowNestedModal(true)}>
                                View Questions and Answers
                            </Button>
                        </div>
                    ))} */}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell align="center">Student Name</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {selectedFeedback.map((feedback, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center"> {feedback.studentName}</TableCell>
                                        <TableCell align="center">
                                            <Button variant="contained" color="secondary" onClick={() => deleteStudentfeed(feedback.id)}>
                                                <BsTrashFill />
                                            </Button>
                                            <Button variant="contained" color="primary" onClick={() => setShowNestedModal(true)} >
                                                View
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Modal.Body>
            </Modal>

            <NestedModal showNestedModal={showNestedModal} handleCloseNestedModal={handleCloseNestedModal} detailedFeedback={detailedFeedback} />

            <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
        </div>
    );
};

const Allstudent = () => {
    return (
        <div>
            <Header />
            <div classname="col-md-4 mb-1 py-1 ">
                <App />
            </div>
        </div>
    );
};

export default Allstudent;

