import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import TeachHeader from './TeachHeader'
import axios from '../Axiosinstance'
import './Teacher.css';
import Onlinehed from './Onlinehed'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Loader from '../Loader';
import { baseURLdyte, Password, Username, baseURLdyteMeeting } from '../common'
import { toast, ToastContainer } from 'react-toastify';


const RQ = () => {

  const [batchesData, setBatchesData] = useState([]);
  const [matchingBatches, setMatchingBatches] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const studentTeacherId = localStorage.getItem('studentteacherid');
  const [sorting, setSorting] = useState({ column: '', direction: 'asc' });
  // const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [batchIds, setBatchIds] = useState([]);

  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const [filteredbatches, setFilteredbatches] = useState(0);

  const [expandedRows, setExpandedRows] = useState([]);

  // const filteredBatches = matchingBatches.filter((batch) => {
  //   const courseMatches = batch.course?.toLowerCase().includes(searchQuery.toLowerCase()) ?? false;
  //   const batchcodeMatches = batch.batchcode?.toLowerCase().includes(searchQuery.toLowerCase()) ?? false;
  //   const dateMatches = batch.date?.toLowerCase().includes(searchQuery.toLowerCase()) ?? false;
  //   const studentCountMatches = batch.count?.toString().includes(searchQuery) ?? false;
  //   const onlineClassLinkMatches = batch.onlineclasslink?.toLowerCase().includes(searchQuery.toLowerCase()) ?? false;

  //   return courseMatches || batchcodeMatches || dateMatches || studentCountMatches || onlineClassLinkMatches;
  // });
  const [loading, setLoading] = useState(true);
  // const totalPages = Math.ceil(filteredBatches.length / rowsPerPage);

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // const batchesToDisplay = filteredBatches.slice(startIndex, endIndex);



  useEffect(() => {
    fetchData();
  }, [itemsPerPage, currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const studentTeacherId = localStorage.getItem('studentteacherid');
      const Nameid = localStorage.getItem('name');
      const response = await axios.get('batch/byTrainersAndAssessors', {
        params: {
          id: Nameid+"("+studentTeacherId+")",
          size: itemsPerPage,
          page: currentPage,
          searchCriteria: search // Pass search term as a parameter
        }
      });

      setBatchesData(response.data.content);
      setFilteredbatches(response.data.totalElements); 
      setLoading(false);
      // console.log(response.data.content.reverse());
    } catch (error) {
      console.error('Error fetching batches:', error);
      setLoading(false);
    }
  };


  // useEffect(() => {
  //   axios.get('batches')
  //     .then((response) => {
  //       setBatchesData(response.data);
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //       setLoading(false)
  //     });
  //   handleSort('course', 'asc');


  // }, []);


  const calculateCountForBatch = (batchId, managesData) => {
    return managesData.filter((manage) => manage.batch_id === batchId).length;
  };

  // useEffect(() => {
  //   axios.get('manages')
  //     .then((response) => {
  //       const managesData = response.data;

  //       const batchDataWithCounts = batchesData.map((batch) => {
  //         const batchId = batch.id;
  //         const count = calculateCountForBatch(batchId, managesData);

  //         // Extract the batch.id and store it in the array
  //         setBatchIds((prevBatchIds) => [...prevBatchIds, batchId]);

  //         return { ...batch, count };
  //       });

  //       setMatchingBatches(batchDataWithCounts);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching manages data:', error);
  //     });
  // }, [batchesData]);



  const handleSort = (column, defaultDirection) => {
    setSorting((prevSorting) => {
      if (prevSorting.column === column) {
        // Toggle sorting direction if the same column is clicked
        return {
          column,
          direction: prevSorting.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        // Sort by the clicked column in ascending order by default
        return {
          column,
          direction: defaultDirection || 'asc',
        };
      }
    });
  };

  // const sortData = (data, column, direction) => {
  //   const sortedData = [...data];
  //   sortedData.sort((a, b) => {
  //     // Handle undefined or null values
  //     const valueA = a[column] || '';
  //     const valueB = b[column] || '';


  //     if (direction === 'asc') {
  //       return valueA.localeCompare(valueB);
  //     } else {
  //       return valueB.localeCompare(valueA);
  //     }
  //   });
  //   return sortedData;
  // };

  // const extractId = (str) => {
  //   if (!str) return ''; // Add a null check
  //   const match = str.match(/\((\d+)\)/);
  //   return match ? match[1] : '';
  // };
  // Update local storage when the data changes
  // useEffect(() => {
  //   // Compare 'studentteacherid' with trainer and assessor IDs and find matching batches
  //   const matchingBatches = batchesData.filter((batch) => {
  //     const trainerIds = batch.trainers.map(extractId);
  //     const assessorIds = batch.assessors.map(extractId);

  //     return trainerIds.includes(studentTeacherId) || assessorIds.includes(studentTeacherId);
  //   });

  //   // Set the matching batches in state
  //   setMatchingBatches(matchingBatches);


  // }, [batchesData, studentTeacherId, batchIds]);


  // const handleSearch = (event) => {
  //   setSearchQuery(event.target.value);
  // };
  // const sortedBatches = sortData(filteredBatches, sorting.column, sorting.direction);

  // const handlePageChange = (event, newPage) => {
  //   setCurrentPage(newPage + 1);
  // };

  // const handleRowsPerPageChange = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setCurrentPage(1);
  // };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    // Check if search term is not null or empty before fetching data
    setCurrentPage(0);
    if (search.trim() !== '') {
      fetchData();
    } else {
      toast.error('Please enter a search term');
    }
  };
  const handleResetClick = () => {
    // setCurrentPage(0);
    setSearch('');
    // console.log("hi"+search)
  };

  useEffect(() => {
    if (search === '') {
      setCurrentPage(0);
      fetchData();
    }
  }, [search]);


  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-us', { month: 'short' });
    const year = date.getFullYear();
    return `${getOrdinalSuffix(day)} ${month} ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  if (loading) {
    return <Loader />; // Display loader while data is being fetched
  }

  const extractMeetingId = (link) => {
    const match = link.match(/id=([a-z0-9-]+)&/i);
    return match ? match[1] : link.split('?id=')[1].split('&')[0];
  };

  const addParticipantToMeeting = async (meetingId) => {
    try {
      // Replace 'YOUR_ORG_ID' and 'YOUR_API_KEY' with your actual Dyte API credentials
      const basicAuth = btoa(`${Username}:${Password}`);
      // const orgId = 'cb589ea3-9b05-44f8-b7c2-de71d128cb0b';
      // const apiKey = '469efb6fc6d285934e1f';
      // const basicAuth = `Basic ${btoa(`${orgId}:${apiKey}`)}`;
      // const basicAuth = btoa('cb589ea3-9b05-44f8-b7c2-de71d128cb0b:469efb6fc6d285934e1f');


      const loggedInUserId = localStorage.getItem('userId');
      const userDetailsResponse = await axios.get(`home/user/${loggedInUserId}`);

      const userDetails = userDetailsResponse.data;

      const response = await axios.post(
        `${baseURLdyte}/meetings/${meetingId}/participants`,
        {
          name: userDetails.name + " facilitator" || "facilitator",
          picture: userDetails.profile_Url || "https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg",
          preset_name: 'group_call_host',
          custom_participant_id: userDetails.sfid.toString(),
        },
        {
          headers: {
            Authorization: `Basic ${basicAuth}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const updatedAuthTokenLink = `${baseURLdyteMeeting}/meeting?id=${meetingId}&authToken=${response.data.data.token}`;
      window.open(updatedAuthTokenLink, '_blank');
    }
    catch (error) {
      console.error('Error adding participant:', error);
    }
  };





  const toggleRowExpansion = (batchId) => {
    if (expandedRows.includes(batchId)) {
      setExpandedRows(expandedRows.filter(id => id !== batchId));
    } else {
      setExpandedRows([...expandedRows, batchId]);
    }
  };

  return (
    <div>

      <div className="container" style={{ maxWidth: '1200px' }}>
        <br></br>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
          <input
            type="text"
            placeholder="Search by Batchcode"
            value={search}
            onChange={handleSearchChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchClick();
              }
            }}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              flex: '1',
              fontSize: '16px',
            }}
          />
          <button className='btn btn-primary'
            onClick={handleSearchClick}
            style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>
            Search</button>
          <button className='btn btn-primary' onClick={handleResetClick} style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>reset</button>
        </div>


          <TableContainer sx={{ maxHeight: 440 }}>
          <Table aria-label="sticky table">
            <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                {/* <TableRow>
                  <TableCell scope="col" onClick={() => handleSort('course')}>Course {sorting.column === 'course' && sorting.direction === 'asc' && <span>▲</span>}
                    {sorting.column === 'course' && sorting.direction === 'desc' && <span>▼</span>}</TableCell>
                  <TableCell scope="col" onClick={() => handleSort('batchcode')}>Batch{sorting.column === 'attendance' && sorting.direction === 'asc' && <span>▲</span>}
                    {sorting.column === 'attendance' && sorting.direction === 'desc' && <span>▼</span>}</TableCell>
                  <TableCell scope="col" onClick={() => handleSort('date')}>Date {sorting.column === 'date' && sorting.direction === 'asc' && <span>▲</span>}
                    {sorting.column === 'date' && sorting.direction === 'desc' && <span>▼</span>}</TableCell>
                  {/* <TableCell scope="col" onClick={() => handleSort('onlineclasslink')}>Student On {sorting.column === 'content1' && sorting.direction === 'asc' && <span>▲</span>}
                    {sorting.column === 'content1' && sorting.direction === 'desc' && <span>▼</span>}</TableCell> */}
                  {/* <TableCell scope="col" onClick={() => handleSort('student')}>Guest Link{sorting.column === 'student' && sorting.direction === 'asc' && <span>▲</span>}
                    {sorting.column === 'student' && sorting.direction === 'desc' && <span>▼</span>}</TableCell> */}
                  {/* <TableCell scope="col" onClick={() => handleSort('content2')}>Action</TableCell> */}
                {/* </TableRow> */} 

                <TableRow >
                <TableCell className="col-2">
                course
                </TableCell>
                <TableCell className="col-2">
                batch
                </TableCell>
                <TableCell className="col-1">
                date
                </TableCell>
                <TableCell className="col-1">
                Action
                </TableCell>
              
              </TableRow>

              </TableHead>
              <TableBody>
    
    
              {batchesData.map((batch) => (
                    <TableRow key={batch.id}>
                      <TableCell>{batch.course}</TableCell>
                      <TableCell>{batch.batchcode}</TableCell>
                      <TableCell>
                      
                          {expandedRows.includes(batch.id) ? (
                            <>
                              {batch.days.map(day => getFormattedDate(day.batchDate)).join(', ')}
                              <br />
                              <span style={{
                                cursor: 'pointer',
                                color: 'blue',
                                textAlign: 'center', // Align text to center
                                marginTop: '5px', // Add some top margin for spacing
                              }}
                                onClick={() => toggleRowExpansion(batch.id)}>See less</span>
                            </>
                          ) : (
                            <>
                              {batch.days.map(day => getFormattedDate(day.batchDate)).slice(0, 1).join(', ')}
                              <br/>
                              {batch.days.length > 1 && (
                                <span style={{
                                  cursor: 'pointer',
                                  color: 'blue',
                                  textAlign: 'center', // Align text to center
                                  marginTop: '5px', // Add some top margin for spacing
                                }}
                                  onClick={() => toggleRowExpansion(batch.id)}
                                >See more</span>
                              )}
                            </>
                          )}
                      </TableCell>
                      {/* <TableCell>Student ({batch.count})</TableCell> */}
                      {/* <TableCell>
                      <a href={batch.onlineclasslink} target="_blank" rel="noopener noreferrer">
                        {batch.onlineclasslink}
                      </a>
                    </TableCell> */}
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const meetingId = extractMeetingId(batch.onlineclasslink);
                            // console.log('Meeting ID:', meetingId);
                            if (meetingId) {
                              addParticipantToMeeting(meetingId);
                            }
                          }}
                        >
                          Start Meeting
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredbatches}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => setCurrentPage(newPage)}
          // labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
          onRowsPerPageChange={event => {
            setItemsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />
        </Paper>
      </div>
    </div>


  );

};


const App = () => {
  return (
    <div>

      <RQ />




    </div>
  );
};

export default App;















