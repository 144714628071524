import React, { useState } from 'react';
import axios  from '../Axiosinstance';
import { logoImage } from '../common';
import { useNavigate } from 'react-router-dom';

function PasswordForm({ email }) {
    const [input1Value, setInput1Value] = useState('');
    const [input2Value, setInput2Value] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const navigate = useNavigate();

    const handleInput1Change = (e) => {
        setInput1Value(e.target.value);
    };

    const handleInput2Change = (e) => {
        setInput2Value(e.target.value);
    };

    const handleButtonClick = () => {
        if (input1Value === input2Value) {
            // If passwords match, make the HTTP request to reset password
            axios
                .post('/reset-password', {
                    email: email,
                    newPassword: input1Value, // Use input1Value as the new password
                })
                .then((response) => {
                    console.log('Password reset successful:', response.data);
                    setError('');
                    setSuccessMessage('Password reset successful.');
                    navigate("/login");
                    // Perform any additional actions upon successful password reset
                })
                .catch((error) => {
                    console.error('Error resetting password:', error);
                    setError('Error resetting password. Please try again.');
                    setSuccessMessage('');
                });
        } else {
            setError('Password mismatched. Please give correct password.');
            setSuccessMessage('');
        }
    };

    const isStrongPassword = (password) => {
        // Implement your password strength criteria here
        // For example, you can check for minimum length, presence of uppercase, lowercase, numbers, and special characters
        const minLength = 8;
        const containsUppercase = /[A-Z]/.test(password);
        const containsLowercase = /[a-z]/.test(password);
        const containsNumber = /[0-9]/.test(password);
        const containsSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);

        return (
            password.length >= minLength &&
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecialChar
        );
    };

    return (
        <div className="container-fluid d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <div className="card p-4" style={{ maxWidth: '500px', width: '100%' }}>
                <img
                    src={logoImage}
                    alt="Logo"
                    style={{
                        width: '150px',
                        marginBottom: '20px',
                        display: 'block',
                        margin: '0 auto',
                    }}
                />

                <form>
                    <div className="mb-3">
                        <label htmlFor="newPassword" className="form-label">
                            New Password
                        </label>
                        <input
                            type="password"
                            id="password1"
                            value={input1Value}
                            onChange={handleInput1Change}
                            className={`form-control ${input1Value && !isStrongPassword(input1Value) ? 'is-invalid' : ''}`}
                        />
                        {input1Value && !isStrongPassword(input1Value) && <div className="invalid-feedback">Plaease enter a strong Password.</div>}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="confirmPassword" className="form-label">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            id="password2"
                            value={input2Value}
                            onChange={handleInput2Change}
                            className={`form-control ${input2Value && !isStrongPassword(input2Value) ? 'is-invalid' : ''}`}
                        />
                        {input2Value && !isStrongPassword(input2Value) && <div className="invalid-feedback">Password must be strong.</div>}
                    </div>
                </form>
                <div className="text-center">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleButtonClick}
                        style={{ padding: '7px', maxWidth: '100px', width: '100%', fontSize: '12px', float: 'right' }}
                    >
                        Submit
                    </button>
                </div>
                {error && <div className="text-danger text-center mt-3">{error}</div>}
                {successMessage && <div className="text-success text-center mt-3">{successMessage}</div>}
            </div>
        </div>
    );
}

export default PasswordForm;
