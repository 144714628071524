import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../../components/Header'
import Heading from './Heading'


const EmailContainer = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  return (
    <div className="" style={{ margintop: '10px', width:'1000px' }}>
    <div className="card" style={{  borderRadius: '8px', padding: '20px' , height: '400px', width:'300px'}}>
      <h2>Roaster Setup</h2>
      <div className="instructions-container">
  <h5>Trainer Course Email</h5>
  <input type="checkbox" id="notificationCheckbox" />
  <label htmlFor="notificationCheckbox">Enable email notification</label>
  <div className="form-group">
    <h6>Subject</h6>
    <input
      type="text"
      id="invoiceNo"
      className="form-control"
      placeholder="Course Run Notification"
    />
    <label><h6>Body</h6></label>
  </div>
  {/* Assuming you have imported ReactQuill component properly */}
  <ReactQuill
    value={editorContent}
    onChange={handleEditorChange}
    placeholder="Enter your email body"
    style={{ fontSize: '16px', height: '100px' }}// Increase the size of the placeholder
  />
</div>

    </div>
  </div>
  );
};

const EmailNotificationContainer = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  return (
       <div className="" style={{ margintop: '10px' }}>
    <div className="card" style={{  borderRadius: '8px', padding: '20px' , height: '400px'}}>
      <h2>Student Course Email </h2><h5>Notification Templates</h5>
      <div className="instructions-container">

  <input type="checkbox" id="notificationCheckbox" />
  <label htmlFor="notificationCheckbox">Enable email notification</label>
  <div className="form-group">
    <h6>Subject</h6>
    <input
      type="text"
      id="invoiceNo"
      className="form-control"
      placeholder="Course Run Notification"
    />
    <label><h6>Body</h6></label>
  </div>
  {/* Assuming you have imported ReactQuill component properly */}
  <ReactQuill
    value={editorContent}
    onChange={handleEditorChange}
    placeholder="Enter your email body"
    style={{ fontSize: '16px', height: '100px',marginBottom:'12%' }}// Increase the size of the placeholder
  />
</div>

    </div>
  </div>
  );
};

const EmailPreview = () => {
  const [courseName, setCourseName] = useState('Name of the course');
  const [courseDate, setCourseDate] = useState('Row 1');
  const [assessmentDate, setAssessmentDate] = useState('Row 2');
  const [trainers, setTrainers] = useState('Row 3');
  const [assessors, setAssessors] = useState('Row 4');
  const [venue, setVenue] = useState('Row 5');

  return (
    <div className="container" style={{ border:'1px solid fff', borderRadius: '8px', padding: '20px' , height: '400px'}}>
      <h4>Email Preview</h4>
      <div className="table-responsive" >
        <table className="table table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Course</td>
              <td>{courseName}</td>
            </tr>
            <tr>
              <td>Course Date</td>
              <td>{courseDate}</td>
            </tr>
            <tr>
              <td>Assessment Date</td>
              <td>{assessmentDate}</td>
            </tr>
            <tr>
              <td>Trainers</td>
              <td>{trainers}</td>
            </tr>
            <tr>
              <td>Assessors</td>
              <td>{assessors}</td>
            </tr>
            <tr>
              <td>Venue</td>
              <td>{venue}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary" style={{background:'#03396c' ,  padding: '10px 54px',marginTop:'25%'}}>Login</button>
    </div>
  );
}

const Email = () => {
  const [fieldValue, setFieldValue] = useState('');

  const handleChange = (event) => {
    setFieldValue(event.target.value);
  };

  return (
    <div className="container" style={{ border: '1px solid fff', borderRadius: '8px', padding: '20px', height: '400px' }}>
      <h4>Email Preview</h4>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Course</td>
              <td>{fieldValue}</td>
            </tr>
            <tr>
              <td>Course Date</td>
              <td>{fieldValue}</td>
            </tr>
            <tr>
              <td>Assessment Date</td>
              <td>{fieldValue}</td>
            </tr>
            <tr>
              <td>Assessors</td>
              <td>{fieldValue}</td>
            </tr>
            <tr>
              <td>Venue</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary" style={{ background: '#03396c', padding: '10px 54px', marginTop: '40%' }}>Login</button>
    </div>
  );
};


const Bar = () => {
  return (
    <div>
      <Header />
      <Heading />

      <div className="container" style={{ border: "1px solid #ccc", borderRadius: '20px', padding: '20px', marginTop: '20px', }}>
        <div className="row">
          <div className="col-md-6">
            <EmailContainer />
          </div>
          <div className="col-md-6">
            <EmailPreview />
          </div>
        </div>
        <div style={{  marginTop: '20px'  }}>
          <button className="btn btn-primary" style={{ background: '#03396c' ,  padding: '10px 54px' }}>Save</button>
        </div>
      </div>

      <div className="container" style={{ border: "1px solid #ccc", borderRadius: '20px', padding: '20px', marginTop: '20px' }}>
        <div className="row">
          <div className="col-md-6">
            <EmailNotificationContainer />
          </div>
          <div className="col-md-6">
            <Email />
          </div>
        </div>
        <div style={{  marginTop: '20px' }}>
          <button className="btn btn-primary" style={{ background: '#03396c' ,  padding: '10px 54px' }}>Save</button>
        </div>
      </div>

    </div>
  );
};

export default Bar;
