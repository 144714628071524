import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import Header from '../components/Header'
import axios from '.././Axiosinstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NewBatches from './Newbatches'
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Loader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';


const Creen = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [batches, setBatches] = useState([]);
  // const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalBatches, setTotalBatches] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Default number of items per page
  const [currentPage, setCurrentPage] = useState(0);




  useEffect(() => {
    fetchData();
  }, [itemsPerPage, currentPage]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('batches/search', {
        params: {
          size: itemsPerPage,
          page: currentPage,
          batchcode: search // Pass search term as a parameter
        }
      });
      setBatches(response.data.content);
      setTotalBatches(response.data.totalElements);
      setLoading(false);
      // setBatches(response.data.content);
      // console.log(response.data.content);
      // console.log('Count of data in response:', response.data.content.length); // Print the count of data
      // console.log(response.data.totalElements);
      // console.log('current page' + currentPage);

    } catch (error) {
      console.error('Error fetching batches:', error);
      setLoading(false);
    }
  };






  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchClick = () => {
    // Check if search term is not null or empty before fetching data
    setCurrentPage(0);
    if (search.trim() !== '') {
      fetchData();
    } else {
      toast.error('Please enter a search term');
    }
  };


  const handleResetClick = () => {
    // setCurrentPage(0);
    setSearch('');
    // console.log("hi"+search)
  };

  useEffect(() => {
    if (search === '') {
      setCurrentPage(0);
      fetchData();
    }
  }, [search]);



  const handleDelete = async (id) => {
    try {
      // Fetch all manages
      const managesResponse = await axios.get('manages');
      const managesData = managesResponse.data;

      // Filter the manages data to check if any manage is associated with the batch
      const associatedManages = managesData.filter(manage => manage.batch_id === id);

      if (associatedManages.length > 0) {
        // If manages are associated with the batch, show a warning
        alert(`This batch is associated with ${associatedManages.length} students(s). Please delete the associated students before deleting this batch.`);
        return;

      } else {
        // If no manages are associated, ask for confirmation
        const confirmDelete = window.confirm('Are you sure you want to delete this batch?');

        if (!confirmDelete) {
          return; // Cancel deletion if the user doesn't confirm
        }
      }

      // If user confirms deletion or there are no associated manages, proceed with deletion
      await axios.delete(`batch/${id}`);
      const updatedBatches = batches.filter((batch) => batch.id !== id);
      setBatches(updatedBatches);
      toast.success('Batch deleted successfully');
    } catch (error) {
      console.error('Error deleting batch:', error);
      toast.error('Error deleting batch');
    }
  };



  // if (loading) {
  //   return <Loader />;
  // }
  const getStartingDate = (days) => {
    // Filter out undefined values
    const validDates = days.filter((day) => day.batchDate).map((day) => new Date(day.batchDate));

    // If there are no valid dates, return an empty string or a default value
    if (validDates.length === 0) {
      return '';
    }

    // Find the minimum date
    const minDate = new Date(Math.min(...validDates));

    // Format the minimum date
    return formatDate(minDate.toISOString());
  };



  const formatDate = (dateString, format = 'dd - mm - yyyy') => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const formattedDate = format
      .replace('dd', day < 10 ? `0${day}` : day)
      .replace('mm', month)
      .replace('yyyy', year);

    return formattedDate;
  };






  return (

    <div className="container" style={{ maxWidth: '1200px' }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '5px' }}>
          <input
            type="text"
            placeholder="Search by code"
            value={search}
            onChange={handleSearchChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSearchClick();
              }
            }}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              flex: '1',
              fontSize: '16px',
            }}
          />
          <button className='btn btn-primary'
            onClick={handleSearchClick}
            style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>
            Search</button>
          <button className='btn btn-primary' onClick={handleResetClick} style={{ marginLeft: '10px', padding: '8px 12px', fontSize: '16px' }}>reset</button>
        </div>

        <TableContainer sx={{ maxHeight: 'none' }}>
          <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Table aria-label="sticky table">
              <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  <TableCell style={{ width: '40%' }}>Batch Code</TableCell>
                  {/* <TableCell style={{ width: '10%' }}>SSGWSQ</TableCell> */}
                  <TableCell style={{ width: '15%' }}>Starting Date</TableCell>
                  <TableCell style={{ width: '20%' }}>Trainer</TableCell>
                  <TableCell style={{ width: '20%' }}>Manages</TableCell>
                  <TableCell style={{ width: '20%' }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center' }}>
                      <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  batches.map((batch) => (
                    <TableRow key={batch.id}>
                      <TableCell>
                        <Link to={`/Managebatches/${batch.id}`} style={{ textDecoration: 'none' }}>
                          {batch.batchcode}
                        </Link>
                      </TableCell>
                      {/* <TableCell>
                        <a href="/Managebatches" style={{ textDecoration: 'none' }}>
                          {batch.ssgWSQ} ssgWSQ
                        </a>
                      </TableCell> */}
                      <TableCell>{getStartingDate(batch.days)}</TableCell>
                      <TableCell>
                        {batch.trainers.map((trainer) => {
                          // Check if trainer is null or undefined, and handle accordingly
                          if (!trainer) {
                            return null; // Return null if trainer is null or undefined
                          }
                          return trainer.replace(/\(\d+\)/, ''); // Otherwise, perform replace operation
                        }).filter(Boolean).join(', ')} {/* Use filter(Boolean) to remove null values */}

                      </TableCell>
                      <TableCell>
                        <Link to={`/Managebatches/${batch.id}`} style={{ textDecoration: 'none' }}>
                          Manage
                        </Link>
                      </TableCell>
                      <TableCell style={{ width: '10%' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link to={`/BatchEdit/${batch.id}`} style={{ textDecoration: 'none' }}>
                            <BsPencilFill />
                          </Link>
                          <DeleteRoundedIcon
                            className="text-danger"
                            style={{ color: 'red', marginLeft: 10 }}
                            onClick={() => handleDelete(batch.id)}
                          />
                        </div>
                      </TableCell>

                    </TableRow>
                  ))
                )}
              </TableBody>

            </Table>
          </div>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalBatches}
          rowsPerPage={itemsPerPage}
          page={currentPage}
          onPageChange={(event, newPage) => setCurrentPage(newPage)}
          // labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
          onRowsPerPageChange={event => {
            setItemsPerPage(parseInt(event.target.value, 10));
            setCurrentPage(0);
          }}
        />


      </Paper>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
    </div>



  );
};

const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };



  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <div>
      <Header />
      <br />
      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Batches-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none', // Remove the underline
            },
            // Adjust width for mobile view
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="Batches " sx={{ minWidth: isMobile ? '50%' : '600px' }} />
          <Tab label="New Batches" sx={{ minWidth: isMobile ? '50%' : '600px' }} />
        </Tabs>
      </div>
      <div>
        {tabValue === 0 && <Creen />}
        {tabValue === 1 && <NewBatches onFormSubmit={() => handleTabChange(0)} />}
      </div>
    </div>
  );

};

export default New;
