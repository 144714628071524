import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from '@mui/material';






const EmailContainer = () => {
  const [editorContent, setEditorContent] = useState('');




  const handleEditorChange = (content) => {
    setEditorContent(content);
  };




  const styles = {
    container: {
     
      margin: "0 auto",
      padding: "40px 40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
     
    }
  };




  return (
    // <div className="" style={{ margintop: '10px' }}>
       <div style={styles.container}>
      <h2 style={{  color: '#000' }} >Roaster Setup <span> - Trainer Course Email </span></h2>
      <div className="instructions-container">
  {/* <h5>Trainer Course Email</h5> */}<br />




  <div className="form-group">
    <h5>Subject</h5>
    <input
      type="text"
      id="invoiceNo"
      className="form-control"
      maxLength={20}
      title="20 characters only"
      placeholder="Course Run Notification"
    />
    <br />
    <label><h5>Body</h5></label>
  </div>
  {/* Assuming you have imported ReactQuill component properly */}
  <ReactQuill
    value={editorContent}
    onChange={handleEditorChange}
    maxLength={50}
    title="50 characters only"
    placeholder="Enter your email body"
    style={{ fontSize: '16px', height: 'auto' }}
  />
 
</div>
<br />




<input type="checkbox" id="notificationCheckbox" />
  <label htmlFor="notificationCheckbox">Enable email notification</label>




  <Button variant="contained"
              color="primary"
               style={{   float: 'right'}}>Save</Button>
        </div>
 
   
  // </div>
  );
};




const EmailNotificationContainer = () => {
  const [editorContent, setEditorContent] = useState('');




  const handleEditorChange = (content) => {
    setEditorContent(content);
  };




  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "40px 40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
     
     
    }
  };
  return (
     
    <div style={styles.container}>
      <h2 style={{  color: '#000' }}> Email <span>- Notification Templates</span> </h2>
     <br />
      <div className="instructions-container">








  <div className="form-group">
    <h5>Subject</h5>
    <input
      type="text"
      id="invoiceNo"
      className="form-control"
      placeholder="Course Run Notification"
      maxLength={50}
      title="20 characters only"
    />
     <br />
    <label><h5>Body</h5></label>
  </div>
  {/* Assuming you have imported ReactQuill component properly */}
  <ReactQuill
    value={editorContent}
    onChange={handleEditorChange}
    maxLength={50}
    title="50 characters only"
    placeholder="Enter your email body"
    style={{ fontSize: '16px', height: 'auto' }}// Increase the size of the placeholder
  />
</div>
<br />
<input type="checkbox" id="notificationCheckbox" />
  <label htmlFor="notificationCheckbox">Enable email notification</label>
  <Button variant="contained"
              color="primary"
               style={{   float: 'right'}}>Save</Button>
    </div>
   
 
  );
};








const   Bar = () => {
  return (
    <div className="containerw" style={{ flexWrap: 'wrap' , marginTop: '2%', marginRight:'18%'}}>
      <div className="row">
        {/* Left Container */}
        <div className="col-md-6">
          <EmailContainer />
        </div>
       
        {/* Right Container */}
        <div className="col-md-6">
          <EmailNotificationContainer />
        </div>
      </div>
    </div>
  );
};






export default Bar;













