import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../Axiosinstance';
import Header from '../components/Header';




function AssesEdit() {
  let navigate = useNavigate();
  let { assesEdit } = useParams();




  // Define the assessment state model similar to facilitators
  const [assessment, setAssessment] = useState({
    course: '',
    batch: '',
    duration: '',
    name: '',
    startDate: '',
    endDate: '',
    editorContent: '',
  });




  const [error, setError] = useState('');




  const {
    course,
    batch,
    duration,
    name,
    startDate,
    endDate,
    editorContent,
  } = assessment;




  useEffect(() => {
    // Fetch assessment data by ID from your API
    axios
      .get(`assessment/${assesEdit}`)
      .then((response) => {
        const { data } = response;
        setAssessment(data);
      })
      .catch((error) => {
        console.error(error);
        setError('Error fetching assessment details');
      });
  }, [assesEdit]);




  const onInputChange = (e) => {
    setAssessment({ ...assessment, [e.target.name]: e.target.value });
  };




  const onSubmit = (e) => {
    e.preventDefault();
    // Update assessment data using a PUT request to your API
    axios
      .put(`assessment/${assesEdit}`, assessment)
      .then((response) => {
        navigate('/assesslink');
      })
      .catch((error) => {
        console.error(error);
        setError('Error updating assessment');
      });
  };


  const [isFormDirty, setIsFormDirty] = useState(false);




const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };




  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };




    window.addEventListener('beforeunload', handleBeforeUnload);




    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);




  return (
    <div className="container " style={{ maxWidth: '1000px'}}>
   
      <h2>Edit Assessment</h2>
      {error && <div className="text-danger">{error}</div>}
      <form onChange={handleInputChange} onSubmit={(e) => onSubmit(e)}>
        {/* Form fields */}
        {/* Define your form inputs similar to facilitators */}
        <div className="form-group">
          <label>Course</label>
          <input
            type="text"
            className="form-control"
            name="course"
            value={course}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>Batch</label>
          <input
            type="text"
            className="form-control"
            name="batch"
            value={batch}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={name}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>Duration (in minutes)</label>
          <input
            type="number"
            className="form-control"
            name="duration"
            value={duration}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control"
            name="startDate"
            value={startDate}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>End Date</label>
          <input
            type="date"
            className="form-control"
            name="endDate"
            value={endDate}
            onChange={onInputChange}
          />
        </div>




        <div className="form-group">
          <label>Editor Content</label>
          <textarea
            className="form-control"
            name="editorContent"
            value={editorContent}
            onChange={onInputChange}
          />
        </div>
<br/>
<div style={{ display: 'flex' , alignContent: 'end' , justifyContent: 'flex-end'}} >
        <button className="btn btn-primary">Update Assessment</button>
        <Link to="/assesslink" className="btn btn-secondary">
          Cancel
        </Link>
        </div>
      </form>
    </div>
  );
}


const Head = () => {
 


return (
  <div>
 <Header />
    <AssesEdit />
  </div>
);
};






export default Head;















