import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../Axiosinstance';
import Header from './Header';
import {  Button } from 'react-bootstrap';


function EditFacilitator() {
  let navigate = useNavigate();
  let { facilitatorId } = useParams();


  const [facilitator, setFacilitator] = useState({
    salutation: '',
    nric: '',
    email: '',
    phone: '',
    educational_level: '',
    qualification: '',
    domain: '',
    gender: '',
    password: '',
    name: '',
    usertype1: ''
  });


  const [error, setError] = useState('');


  const {
    salutation,
    nric,
    email,
    phone,
    educational_level,
    qualification,
    domain,
    gender,
    password,
    name,
    usertype1
  } = facilitator;


  useEffect(() => {
    axios
      .get(`facilitator/${facilitatorId}`)
      .then((response) => {
        const { data } = response;
        setFacilitator(data); // Make sure the 'data' includes the 'domain' field
      })
      .catch((error) => {
        console.error(error);
        setError('Error fetching facilitator details');
      });
  }, [facilitatorId]);
 


  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFacilitator((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
 








  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`facilitator/${facilitatorId}`, facilitator)
      .then((response) => {
        // console.log(facilitator)
        navigate('/facilitatorlink');
      })
      .catch((error) => {
        console.error(error);
        setError('Error updating facilitator');
      });
  };


  const [isFormDirty, setIsFormDirty] = useState(false);




  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };




  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };




    window.addEventListener('beforeunload', handleBeforeUnload);




    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);




  // shadow p-3 mb-5 bg-white rounded
  return (
   
    <div className="container " style={{ maxWidth: '1000px' }}>
       
      <h2>Edit Facilitator</h2>
      {error && <div className="text-danger">{error}</div>}
      <form onChange={handleInputChange} onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <label>Salutation</label>
          <select
            className="form-control"
            name="salutation"
            value={salutation}
            onChange={onInputChange}
          >
            <option value="">Select Salutation</option>
            <option value="Mr.">Mr.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Miss">Miss</option>
          </select>
        </div>


        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={name}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
          <label>User Type</label>
          <select
            className="form-control"
            name="usertype1"
            value={usertype1}
            onChange={onInputChange}
          >
            <option value="">Select User Type</option>
            <option value="new">New</option>
            <option value="existing">Existing</option>
          </select>
        </div>


        <div className="form-group">
          <label>NRIC ID</label>
          <input
            type="text"
            className="form-control"
            name="nric"
            value={nric}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={email}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            className="form-control"
            name="phone"
            value={phone}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
          <label>Educational Level</label>
          <input
            type="text"
            className="form-control"
            name="educational_level"
            value={educational_level}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
          <label>Qualification</label>
          <input
            type="text"
            className="form-control"
            name="qualification"
            value={qualification}
            onChange={onInputChange}
          />
        </div>


        <div className="form-group">
  <label>Domain</label>
  <input
    type="text"
    className="form-control"
    name="domain"
    value={domain}
    onChange={onInputChange}
  />
</div>






        <div className="form-group">
          <label>Gender</label>
          <select
            className="form-control"
            name="gender"
            value={gender}
            onChange={onInputChange}
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>


        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            name="password"
            value={password}
            onChange={onInputChange}
          />
        </div>
        <br />
        < div style={{ display: 'flex', alignContent: 'end', justifyContent: 'flex-end' }} >
        <Button variant="primary" type="submit" className="btn-submit">
        Update Facilitator
          </Button>
          <Button variant="danger" type="button" className="btn-cancel" onClick={() => navigate('/facilitatorlink')}>
            Cancel
          </Button>


        </div>
      </form>


    </div>
  );
}


const Edit = () => {
  return (
    <>
      <Header />
      <EditFacilitator />
    </>
  );
};


export default Edit;



