import React, { useState, useEffect } from 'react';
import axios, { logout } from '../Axiosinstance';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { BsPencilFill } from 'react-icons/bs';
import New from './New';
import Header from '../components/Header'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../Loader';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Modal } from 'react-bootstrap';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';



const columns = [
  { id: 'roomno', label: 'Room No', minWidth: 170 },
  { id: 'unit', label: 'Unit', minWidth: 100 },
  { id: 'block', label: 'Block', minWidth: 170 },
  { id: 'floor', label: 'Floor', minWidth: 170 },
  { id: 'street', label: 'Street', minWidth: 170 },
  { id: 'building', label: 'Building', minWidth: 170 },
  { id: 'postalcode', label: 'Post', minWidth: 170 },
  { id: 'wheelchair', label: 'Wheelchair Accessible', minWidth: 170 },
  { id: 'actions', label: 'Action', minWidth: 170 },
];




const Screen = () => {
  const [venues, setVenues] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [venuesPerPage, setVenuesPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredVenues, setFilteredVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  // State for edit modal
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedVenue, setEditedVenue] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);




  useEffect(() => {
    axios
      .get('venues')
      .then((response) => {
        setVenues(response.data.reverse());
        setFilteredVenues(response.data);
        setLoading(false);
      })
      .catch((error) => {

        // console.error('Error fetching data:', error);


        setLoading(false);
      });
  }, []);




  useEffect(() => {
    // Update filtered venues when the search query changes
    const filtered = venues.filter((venue) =>
      venue.roomno.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.unit.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.block.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.floor.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.street.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.building.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.postalcode.toLowerCase().includes(searchQuery.toLowerCase()) ||
      venue.wheelchair.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredVenues(filtered);
  }, [searchQuery, venues, currentPage, pageSize]);






  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };




  // Function to open the edit modal
  const openEditModal = (venue) => {
    setEditedVenue(venue);
    setEditModalOpen(true);
  };




  // Function to close the edit modal
  const closeEditModal = () => {
    setEditModalOpen(false);
  };




  const openDeleteModal = (id) => {
    setDeleteItemId(id);
    setShowDeleteModal(true);
  };


  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteItemId(null);
  };


  const handleDeleteConfirmation = async () => {
    try {


      const response = await axios.delete(`/venue/${deleteItemId}`);


      if (response.status === 200) {
        const updatedVenues = venues.filter((venue) => venue.id !== deleteItemId);
        setVenues(updatedVenues);
        toast.success('Venue deleted successfully');
      } else {

        console.error('Failed to delete venue');
      }
    }
    catch (error) {

      console.error('Error deleting venue:', error);
    } finally {
      closeDeleteModal();
    }
  };




  // Function to save the edited venue
  const handleSaveEdit = async () => {
    try {
      if (
        !editedVenue.roomno ||
        !editedVenue.unit ||
        !editedVenue.block ||
        !editedVenue.floor ||
        !editedVenue.street ||
        !editedVenue.building ||
        !editedVenue.postalcode ||
        !editedVenue.wheelchair


      ) {
        toast.error('Please fill in all required fields.');
        return;
      }
      const confirmation = window.confirm('Are you sure you want to update this venue?');
      if (confirmation) {
        // Make a PUT request to the edit API endpoint with the updated venue data
        const response = await axios.put(`/venue/${editedVenue.id}`, editedVenue);




        if (response.status === 200) {
          // Update the venues state with the edited venue
          const updatedVenues = venues.map((venue) =>
            venue.id === editedVenue.id ? editedVenue : venue
          );
          setVenues(updatedVenues);
          // Close the edit modal
          closeEditModal();
          toast.success('Venue updated successfully');
        } else {

          console.error('Failed to update venue');
        }
      }
    } catch (error) {

      console.error('Error updating venue:', error);
    }
  };




  return (
    <div>
      <div className="container" style={{ maxWidth: '1200px' }}>
        {loading ? (
          <Loader /> // Show loader while data is being fetched
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
              style={{
                padding: '8px 12px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                display: 'flex',
                fontSize: '16px',
              }}
            />




            <TableContainer
              sx={{
                overflowX: 'auto',
                maxWidth: '100%',
                '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                  overflowX: 'unset', // Enable horizontal scroll
                },
              }}
            >
              <Table aria-label="sticky table" style={{ tableLayout: 'fixed', minWidth: '600px' }} >
                <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="left"
                        style={{ minWidth: column.minWidth, fontWeight: 'bold', width: '100px' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredVenues
                    .slice((currentPage - 1) * venuesPerPage, currentPage * venuesPerPage)
                    .map((venue, index) => (
                      <TableRow key={index}>
                        <TableCell>{venue.roomno}</TableCell>
                        <TableCell>{venue.unit}</TableCell>
                        <TableCell>{venue.block}</TableCell>
                        <TableCell>{venue.floor}</TableCell>
                        <TableCell>
                          {venue.street.length > 6 ? (
                            <Tooltip title={venue.street}>
                              <span>{`${venue.street.substring(0, 6)}...`}</span>
                            </Tooltip>
                          ) : (
                            venue.street
                          )}
                        </TableCell>
                        <TableCell>
                          {venue.building.length > 6 ? (
                            <Tooltip title={venue.building}>
                              <span>{`${venue.building.substring(0, 6)}...`}</span>
                            </Tooltip>
                          ) : (
                            venue.building
                          )}
                        </TableCell>


                        <TableCell>{venue.postalcode}</TableCell>
                        <TableCell >
                          {venue.wheelchair === 'yes' ? (
                            <span style={{ color: 'green' }}>Available</span>
                          ) : (
                            <span style={{ color: 'red' }}>Not Available</span>
                          )}
                        </TableCell>
                        <TableCell >
                          <BsPencilFill onClick={() => openEditModal(venue)} />
                          <DeleteRoundedIcon style={{ color: 'red', marginLeft: 20 }} onClick={() => openDeleteModal(venue.id)} />
                        </TableCell>


                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredVenues.length}
              labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}


              rowsPerPage={venuesPerPage}
              page={currentPage - 1}
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
              onRowsPerPageChange={(event) => {
                setCurrentPage(1);
                setVenuesPerPage(event.target.value);
              }}
            />
          </Paper>
        )}
      </div>




      {/* Edit Venue Modal */}
      <Dialog open={isEditModalOpen} onClose={closeEditModal}>
        <DialogTitle>Edit Venue  (Newton International College)</DialogTitle>
        <DialogContent>
          <TextField
            label="Room Number"
            name="roomno"
            type="number"
            value={editedVenue.roomno}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, '').substring(0, 6); // Allow only digits and limit to 10 characters


              setEditedVenue({ ...editedVenue, roomno: inputValue })
            }}
            fullWidth
            margin="normal"


            required

          />
          <TextField
            label="Unit"
            name="unit"
            type="number"
            value={editedVenue.unit}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, '').substring(0, 6); // Allow only digits and limit to 10 characters


              setEditedVenue({ ...editedVenue, unit: inputValue })
            }}
            fullWidth
            margin="normal"
            required

          />
          <TextField
            label="Block"
            name="block"
            type="text"
            value={editedVenue.block}
            onChange={(e) => {
              const inputValue = e.target.value.substring(0, 20); // Allow only digits and limit to 10 characters


              setEditedVenue({ ...editedVenue, block: inputValue })
            }}
            fullWidth
            margin="normal"
            required

          />
          <TextField
            label="Floor"
            name="floor"
            type="number"
            value={editedVenue.floor}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, '').substring(0, 6); // Allow only digits and limit to 10 characters


              setEditedVenue({ ...editedVenue, floor: inputValue })
            }}
            fullWidth
            margin="normal"
            required

          />
          <TextField
            label="Street"
            name="street"
            value={editedVenue.street}
            onChange={(e) => setEditedVenue({ ...editedVenue, street: e.target.value })}
            fullWidth
            margin="normal"
            required

          />


          <TextField
            label="Building"
            name="building"
            value={editedVenue.building}
            onChange={(e) => setEditedVenue({ ...editedVenue, building: e.target.value })}
            fullWidth
            margin="normal"
            required





          />
          <TextField
            label="Postal Code"
            name="postalcode"
            value={editedVenue.postalcode}
            onChange={(e) => {


              const inputValue = e.target.value.replace(/\D/g, '').substring(0, 5); // Allow only digits and limit to 10 characters


              setEditedVenue({ ...editedVenue, postalcode: inputValue })
            }}
            fullWidth
            margin="normal"
            required

          />


          <FormControlLabel
            control={
              <Checkbox
                checked={editedVenue.wheelchair === 'yes'} // Assuming editedVenue.wheelchair is a string ('yes' or 'no')
                onChange={(e) =>
                  setEditedVenue({
                    ...editedVenue,
                    wheelchair: e.target.checked ? 'yes' : 'no' // Convert boolean value to 'yes' or 'no'
                  })
                }
                name="wheelchair"
                color="primary"
              />
            }
            label="Wheelchair Accessible"
          />




        </DialogContent>
        <DialogActions>





          <Button
            variant="secondary"
            onClick={handleSaveEdit}
            style={{
              backgroundColor: 'green',
              color: 'white',
              marginRight: '10px',
              width: '120px', // Set the width
              height: '40px', // Set the height
              borderRadius: '8px', // Add border radius
            }}
          >
            Update
          </Button>
          <Button
            variant="danger"
            onClick={closeEditModal}
            style={{
              backgroundColor: 'red',
              color: 'white',
              width: '120px', // Set the width
              height: '40px', // Set the height
              borderRadius: '8px', // Add border radius
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>




      <Modal show={showDeleteModal} onHide={closeDeleteModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20%', // Adjust this value to move the modal down
        }}>
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this venue?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={closeDeleteModal}
              style={{ backgroundColor: 'green', color: 'white', marginRight: '10px' }} // Adding margin-right for space
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteConfirmation}
              style={{ backgroundColor: 'red', color: 'white' }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </div>
      </Modal>


      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
    </div>
  );
};




const Ven = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };




  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




  return (
    <div>
      <Header />
      <br />
      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Venues-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none', // Remove the underline
            },
            // Adjust width for mobile view
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically on mobile
              "& button": {
                width: '100%', // Full-width buttons on mobile
              },
            }),
          }}
        >
          <Tab label="Venues" sx={{ minWidth: isMobile ? '100%' : '600px' }} />
          <Tab label="New Venue" sx={{ minWidth: isMobile ? '100%' : '600px' }} />
        </Tabs>
        <br />
      </div>
      <div>
        {tabValue === 0 && <Screen />}
        {tabValue === 1 && <New onFormSubmit={() => handleTabChange(0)} />}
      </div>
    </div>
  );


};


export default Ven;



















