import React, { useState, useEffect, useRef } from 'react';
import Header from './../components/Header';
import {  useNavigate,useParams } from 'react-router-dom';
import axios from '../Axiosinstance';
import { CircularProgress, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import {

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    TableHead,



    Paper,
    Grid,
    makeStyles,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    IconButton,
    Snackbar,


} from '@material-ui/core';
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignatureCanvas from 'react-signature-canvas';


const formatNRIC = (nric) => {
    // Check if the NRIC has a length of at least 5 characters
    if (nric.length >= 4) {
        // Replace characters from index 1 to length - 3 with 'X'
        return `${nric.charAt(0)}${'X'.repeat(nric.length - 3)}${nric.slice(-3)}`;
    } else {
        return nric; // Return original NRIC if length is less than 5
    }
};

function UpdateAR({ courseData, studentData, batchDetails }) {
    const [loading, setLoading] = useState(true);
    // const [showModal, setShowModal] = useState(false);

    // const [showModal1, setShowModal1] = useState(false);
    // const [showModal2, setShowModal2] = useState(false);
    // const [courseData, setCourseData] = useState(null);
    // const [studentData, setStudentData] = useState(null);
    // const [batchDetails, setBatchDetails] = useState(null);



    const getLastAssessmentDate = () => {
        if (batchDetails && batchDetails.days) {
            const assessmentDates = batchDetails.days
                .filter(day => day.assessment === "true")
                .map(day => new Date(day.batchDate));

            if (assessmentDates.length > 0) {
                const lastAssessmentDate = new Date(Math.max(...assessmentDates));
                return lastAssessmentDate.toLocaleDateString('en-GB'); // Format: DD/MM/YYYY
            }
        }

        return "No assessment date found";
    };

    const Style = {
        fontWeight: 'bold',
        fontSize: '15px',

    };

    const formattedNRIC = studentData ? formatNRIC(studentData.nric) : '';









    return (
        // style={{ maxWidth: '1400px', margin: '0 auto', boxShadow: '0px 14px 18px rgba(0, 0, 0, 0.1)', borderRadius: '8px', backgroundColor: '#ffffff', padding: '20px' }}
        <div style={{ textAlign: 'center', marginTop: '-15px', marginBottom: '1%' }}>
            <Header />
            <Table style={{ width: '80%', margin: 'auto', maxWidth: '1400px', boxShadow: '0px -14px 18px rgba(0.3, 0.3, 0.3, 0.2), 0px 14px 18px rgba(0.3, 0.3, 0.3, 0.2)', borderRadius: '8px', backgroundColor: '#ffffff' }}>
                <TableBody>
                    {/* Display course data */}
                    {courseData && (
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">Skill Code: <span style={Style}> {courseData.reference}</span></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Skill: <span style={Style}> {courseData.name}</span></Typography>
                            </TableCell>
                        </TableRow>
                    )}

                    {/* Display student data */}
                    {studentData && (
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">Name of the Participant: <span style={Style}> {studentData.fullname}</span></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">NRIC of the Participant: <span style={Style}>{formattedNRIC}</span></Typography>
                            </TableCell>
                        </TableRow>
                    )}

                    {/* Display batch details */}
                    {batchDetails && (
                        <TableRow>
                            <TableCell>
                                <Typography variant="body1">Name of the Assessor: <span style={Style}> {batchDetails.assessors.map(assessor => assessor.split('(')[0]).join(', ')}</span></Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body1">Assessment Date: <span style={Style}> {getLastAssessmentDate()}</span></Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}


const TemplateTable = ({ courseId, courseData, studentData, batchDetails }) => {
    const [templateData, setTemplateData] = useState(null);
    const [totalCriteriaOral, setTotalCriteriaOral] = useState({});
    const [totalCriteriaRolePlay, setTotalCriteriaRolePlay] = useState({});
    const [totalCriteriaWritten, setTotalCriteriaWritten] = useState({});
    const { studentId: StudentId } = useParams
    const [pcNyc, setPcNyc] = useState("");
    const { batchId, manageId } = useParams();
    const [loading, setLoading] = useState(true);
    const [pdfUrl, setPdfUrl] = useState(null);
    const Navigate = useNavigate();
    const url = `/View/${batchId}`;

    const [showModal1, setShowModal1] = useState(false);//view
    const [showModal2, setShowModal2] = useState(false);//sign here

    const [error, setError] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const signatureRef = useRef();

    const getLastAssessmentDate = () => {
        if (batchDetails && batchDetails.days) {
            const assessmentDates = batchDetails.days
                .filter((day) => day.assessment === "true")
                .map((day) => new Date(day.batchDate));

            if (assessmentDates.length > 0) {
                const lastAssessmentDate = new Date(Math.max(...assessmentDates));
                return lastAssessmentDate.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
            }
        }

        return "No assessment date found";
    };

    const Style = {
        fontWeight: "bold",
        fontSize: "15px",
    };



    const handleGetStudentData = async (templateId) => {
        try {
            const response = await axios.get(`arwritten/search?studentId=${studentData.id}&batchId=${batchId}&courseId=${courseId}&templateId=${templateId}`);
            const fetchedPdfUrl = response.data;
            if (fetchedPdfUrl) {
                setPdfUrl(fetchedPdfUrl);
                setShowModal1(true);
                setError(null);
            } else {
                setPdfUrl(null);
                setShowModal1(true); // Open modal even if URL is not fetched (to show message)
                setError("No assessment uploaded by the student.");
            }
        } catch (error) {
            setPdfUrl(null); // Reset pdfUrl if API call fails
            setShowModal1(true); // Open modal to show error message
            setError("No assessment uploaded by the student.");
        }
    }







    const formattedNRIC = studentData ? formatNRIC(studentData.nric) : "";


    // const handleShowModal1 = (index) => {
    //     setSelectedIndex(index);
    //     setShowModal1(true);
    // };

    const handleCloseModal1 = () => {
        setShowModal1(false);
        setSelectedIndex(null);
    };

    const handleShowModal2 = (index) => {
        setSelectedIndex(index);
        setShowModal2(true);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
        setSelectedIndex(null);
    };


    const clearSignature = () => {
        signatureRef.current.clear();
    };






    const saveSignature = () => {
        const signatureData = signatureRef.current.toDataURL();
        // Handle the signature data (e.g., send it to the server)

        toast.success('Save successfully!', {
            position: toast.POSITION.BOTTOM_RIGHT,
        }); // Close the modal after saving the signature
        handleCloseModal2();
    };



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("templates");
                const data = response.data;

                const filteredData = data.filter(
                    (template) => template.courseId == courseId
                );
                // console.log(filteredData)

                if (filteredData.length > 0) {
                    setTemplateData(filteredData);
                } else {
                    console.log("No templates found for the courseId:", courseId);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [courseId]);

    const filterTemplatesByType = (type) => {
        return templateData.filter((template) => template.type === type);
    };
    const [formData, setFormData] = useState({
        totalCriteria: {},
        selectedValue: "", // Initialize selectedValue
    });
    const handleInputChange = (name, value) => {
        setFormData((prevFormData) => {
            const matchResult = name.match(/([a-zA-Z]+)(\d+)([a-zA-Z]+)/);
            if (matchResult) {
                const [type, index, key] = matchResult.slice(1);
                return {
                    ...prevFormData,
                    totalCriteria: {
                        ...prevFormData.totalCriteria,
                        [type + index]: {
                            ...prevFormData.totalCriteria[type + index],
                            [key]: value,
                        },
                    },
                };
            } else if (name === "result" || name === "selectedValue") {
                return {
                    ...prevFormData,
                    [name]: value,
                };
            } else if (name.startsWith("c_nyc")) {
                const [, index, key] = name.match(/c_nyc(\d+)([a-zA-Z]+)/);
                return {
                    ...prevFormData,
                    totalCriteria: {
                        ...prevFormData.totalCriteria,
                        [`oral${index}${key}`]: {
                            ...prevFormData.totalCriteria[`oral${index}${key}`],
                            c_nyc: value,
                        },
                    },
                };
            } else {
                return {
                    ...prevFormData,
                    [name]: value,
                };
            }
        });
    };

    const handleSubmit = async () => {
        let payload = {};
        if (templateData?.length) {
            payload = {
                cId: courseId,
                skillCode: courseData.reference,
                skill: courseData.name,
                sNric: studentData.nric,
                assessors: batchDetails.assessors,
                bId: batchDetails.id,
                assessmentDate: getLastAssessmentDate(),
                pc_nyc: pcNyc,
                feedback: formData.feedback,
                eSign: formData.eSign,
                sname: studentData.fullname,
                sid: studentData.id,
            };
        }
        try {
            // Prepare data for submission
            const templates = await templateData.map((template) => {
                const templateId = template.id;
                const templateType = template.type;
                const learningOutcome = template.learningOutcome;

                return {
                    type: templateType,
                    courseId: courseId,
                    learningOutcome: learningOutcome,
                    templateId: templateId,
                    totalCriteria:
                        templateType === "Role Play"
                            ? Object.values(totalCriteriaRolePlay)
                            : templateType === "Oral Assessment"
                                ? Object.values(totalCriteriaOral)
                                : Object.values(totalCriteriaWritten),
                };
            });

            payload = {
                ...payload,
                templates,
            };
            // Perform API call to post data
            const isStudent = await axios.get(
                `api/assessments/sid/${studentData.id}`
            );

            let response = null;
            if (isStudent?.data?.length) {
                let id = isStudent?.data[0]?.id;
                response = await axios.put(`api/assessments/${id}`, payload);
            } else {
                response = await axios.post("api/assessments", payload);
            }
            console.log("Response from server:", response.data);
            alert("Data submitted successfully!");
            window.location.href = url;
        } catch (error) {
            console.error("Error submitting data:", error);
            // Handle errors appropriately, such as displaying an error message to the user
            alert("Error submitting data. Please try again later.");
        }
    };

    const tableStyle = {
        maxWidth: "1330px",
        margin: "auto",
        border: "1px solid #ddd",
        marginBottom: "20px",
    };

    const handleOnChnages = (currentValue, key, index, label, criteria) => {
        let newKey = "criteria" + (index + 1);
        if (totalCriteriaOral[newKey] && key) {
            totalCriteriaOral[newKey] = {
                ...totalCriteriaOral[newKey],
                evidences: currentValue,
            };
        } else {
            if (key) {
                totalCriteriaOral[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: `c_nyc_${index + 1}`,
                    evidences: currentValue,
                };
            } else {
                totalCriteriaOral[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: currentValue,
                    evidences:
                        totalCriteriaOral[newKey]?.evidences ?? `evidences ${index + 1}`,
                };
            }
        }
        setTotalCriteriaOral({ ...totalCriteriaOral });
    };

    const handleOnChnagesRolePlay = (
        currentValue,
        key,
        index,
        label,
        criteria
    ) => {
        let newKey = "criteria" + (index + 1);
        if (totalCriteriaRolePlay[newKey] && key) {
            totalCriteriaRolePlay[newKey] = {
                ...totalCriteriaRolePlay[newKey],
                evidences: currentValue,
            };
        } else {
            if (key) {
                totalCriteriaRolePlay[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: `c_nyc_${index + 1}`,
                    evidences: currentValue,
                };
            } else {
                totalCriteriaRolePlay[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: currentValue,
                    evidences:
                        totalCriteriaRolePlay[newKey]?.evidences ??
                        `evidences ${index + 1}`,
                };
            }
        }
        setTotalCriteriaRolePlay({ ...totalCriteriaRolePlay });
    };

    const handleOnChnagesWritten = (
        currentValue,
        key,
        index,
        label,
        criteria
    ) => {
        let newKey = "criteria" + (index + 1);
        if (totalCriteriaWritten[newKey] && key) {
            totalCriteriaWritten[newKey] = {
                ...totalCriteriaWritten[newKey],
                evidences: currentValue,
            };
        } else {
            if (key) {
                totalCriteriaWritten[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: `c_nyc_${index + 1}`,
                    evidences: currentValue,
                };
            } else {
                totalCriteriaWritten[newKey] = {
                    criteria: criteria ? criteria : `Criteria ${index + 1}`,
                    label: label ? label : `Label ${index + 1}`,
                    c_nyc: currentValue,
                    evidences:
                        totalCriteriaWritten[newKey]?.evidences ??
                        `evidences ${index + 1}`,
                };
            }
        }
        setTotalCriteriaWritten({ ...totalCriteriaWritten });
    };






    return (
        <div style={{ maxWidth: '1400px', margin: '0 auto', borderRadius: '8px', backgroundColor: '#ffffff', padding: '20px' }}> {/* Container with black shadow */}
            {templateData ? (
                <>

                    {/* Assessment Record for Role Play */}
                    <Table style={{ ...tableStyle, borderCollapse: 'collapse', width: '100%', border: '2px solid black', borderRadius: '8px', overflow: 'hidden', fontSize: '1.4rem', fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>


                        <TableRow style={{ backgroundColor: '#f2f2f2' }}>
                            <TableCell colSpan={3} style={{ textAlign: 'center', borderBottom: '2px solid #ddd', padding: '16px' }}>
                                <h2 style={{ margin: 0, fontSize: '1.8rem' }}>Assessment Record for Role Play</h2>
                            </TableCell>
                        </TableRow>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col">Learning Outcome</th>
                                    <th scope="col">Criteria / Evidences of 'C' and 'NC' </th>
                                    {/* <th scope="col">Evidences of 'C' and 'NC'</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filterTemplatesByType('Oral Assessment').map((template, index) => (
                                    <tr key={template.id}>
                                        {/* <th scope="row">{index + 1}</th> */}
                                        <td>{template.learningOutcome}</td>
                                        <td>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {Object.entries(template.questLabels).map(([key, value], i, arr) => (
                                                        <tr key={key}>
                                                            <td>{value}</td>
                                                            <td>{template.criteria[key]}</td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name={`oral${index + 1}${key}`}
                                                                    style={{ marginRight: '8px', verticalAlign: 'middle'  }}
                                                                    onChange={(e) =>
                                                                        handleOnChnagesRolePlay(
                                                                            "C",
                                                                            false,
                                                                            index,
                                                                            value,
                                                                            template.criteria[key]
                                                                        )
                                                                    }
                                                                />
                                                                <label style={{ marginRight: '16px' , fontSize: '1.1rem' }}>C</label>
                                                                <input
                                                                    type="radio"
                                                                    name={`oral${index + 1}${key}`}
                                                                    style={{ marginRight: '8px', verticalAlign: 'middle'  }}
                                                                    onChange={(e) =>
                                                                        handleOnChnagesRolePlay(
                                                                            "NYC",
                                                                            false,
                                                                            index,
                                                                            value,
                                                                            template.criteria[key]
                                                                        )
                                                                    }
                                                                />
                                                                <label  style={{ fontSize: '1.1rem' }} >NC</label>
                                                            </td>
                                                            <td>
                                                                <textarea
                                                                    rows="2"
                                                                    cols="30"
                                                                    style={{ width: '100%', borderRadius: '8px', border: '1px solid #ddd', resize: 'vertical' }}
                                                                    onChange={(e) =>
                                                                        handleOnChnagesRolePlay(
                                                                            e.target.value,
                                                                            true,
                                                                            index,
                                                                            value,
                                                                            template.criteria[key]
                                                                        )
                                                                    }
                                                                ></textarea>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </Table>
                    {/* Assessment Record for Oral */}

                    <Table style={{ ...tableStyle, borderCollapse: 'collapse', marginTop: '-1%', width: '100%', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden', fontSize: '1.4rem', fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
                        <TableRow style={{ backgroundColor: '#f2f2f2' }}>
                            <TableCell colSpan={3} style={{ textAlign: 'center', borderBottom: '2px solid #ddd', padding: '16px' }}>
                                <h2 style={{ margin: 0 }}>Assessment Record for Oral</h2>
                            </TableCell>
                        </TableRow>
                        <table className="table table-bordered">
                            <thead>
                                <tr>

                                    <th scope="col">Learning Outcome</th>
                                    <th scope="col">Criteria / Evidences of 'C' and 'NC'</th>
                                    {/* <th scope="col">Evidences of 'C' and 'NC'</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {filterTemplatesByType('Role Play').map((template, index) => (
                                    <tr key={template.id}>
                                        <td>{template.learningOutcome}</td>

                                        <td>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {Object.entries(template.questLabels).map(([key, value]) => (
                                                        <tr key={key}>
                                                            <td>{value}</td>
                                                            <td>{template.learningOutcome}</td>
                                                            <td>
                                                                <input
                                                                    type='radio'
                                                                    name={`role${index + 1}${key}`}

                                                                    style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                                    onChange={(e) =>
                                                                        handleOnChnages(
                                                                            "C",
                                                                            false,
                                                                            index,
                                                                            value,
                                                                            template.criteria[key]
                                                                        )
                                                                    }
                                                                />
                                                                <label style={{ marginRight: '16px', fontSize: '1.1rem' }}>C</label>
                                                                <input
                                                                    type='radio'
                                                                    name={`role${index + 1}${key}`}

                                                                    style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                                    onChange={(e) =>
                                                                        handleOnChnages(
                                                                            "NC",
                                                                            false,
                                                                            index,
                                                                            value,
                                                                            template.criteria[key]
                                                                        )
                                                                    }
                                                                />
                                                                <label style={{ fontSize: '1.1rem' }}  >NC</label>
                                                            </td>
                                                            <td>
                                                                <textarea rows="2" cols="30"    style={{ width: '100%', borderRadius: '8px', border: '1px solid #ddd', resize: 'vertical' }}
                                                                 onChange={(e) =>
                                                                    handleOnChnages(
                                                                        e.target.value,
                                                                        true,
                                                                        index,
                                                                        value,
                                                                        template.criteria[key]
                                                                    )
                                                                }
                                                                ></textarea>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </Table>

                    {/* written assessment */}
                    <>
                        <Table style={{ ...tableStyle, borderCollapse: 'collapse', marginTop: '-1%', width: '100%', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden', fontSize: '1.4rem', fontFamily: 'Arial, sans-serif', lineHeight: '1.5' }}>
                            <TableRow style={{ backgroundColor: '#f2f2f2' }}>
                                <TableCell colSpan={3} style={{ textAlign: 'center', borderBottom: '2px solid #ddd', padding: '12px' }}>
                                    <h2 style={{ margin: 0 }}>Written Assessment</h2>
                                </TableCell>
                            </TableRow>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Learning Outcome</th>
                                        <th scope="col">Criteria  / Evidences of 'C' and 'NC'</th>
                                        <th scope="col">View</th> {/* Move the View column header here */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterTemplatesByType('Written Assessment').map((template, index) => (
                                        <tr key={template.id}>
                                            <td>{template.learningOutcome}</td>
                                            <td>
                                                {/* Display criteria */}
                                                {Object.entries(template.criteria).map(([key, value], innerIndex) => (
                                                    <tr key={key}>
                                                        <td>{value}</td>
                                                        <td>{/* Insert Learning Outcome here */}</td>
                                                        <td>
                                                            {/* C/NYC radio buttons */}
                                                            <input
                                                                type='radio'
                                                                name={`written${index + 1}${key}`}
                                                                value='C'
                                                                style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                                onChange={(e) =>
                                                                    handleOnChnagesWritten(
                                                                        "C",
                                                                        false,
                                                                        index,
                                                                        value,
                                                                        template.criteria[key]
                                                                    )
                                                                }
                                                            />
                                                             <label style={{ marginRight: '16px', fontSize: '1.1rem' }}>C</label>
                                                            <input
                                                                type='radio'
                                                                name={`written${index + 1}${key}`}
                                                                value='NC'
                                                                style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                                onChange={(e) =>
                                                                    handleOnChnagesWritten(
                                                                        "NC",
                                                                        false,
                                                                        index,
                                                                        value,
                                                                        template.criteria[key]
                                                                    )
                                                                }
                                                            />
                                                            <label style={{ fontSize: '1.1rem' }}  >NC</label>
                                                        </td>
                                                        <td>
                                                            {/* Evidences textarea */}
                                                            <textarea
                                                                rows="2"
                                                                cols="30"
                                                                   style={{ width: '100%', borderRadius: '8px', border: '1px solid #ddd', resize: 'vertical' }}
                                                                onChange={(e) =>
                                                                    handleOnChnagesWritten(
                                                                        e.target.value,
                                                                        true,
                                                                        index,
                                                                        value,
                                                                        template.criteria[key]
                                                                    )
                                                                }
                                                            ></textarea>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </td>
                                            <td> {/* Move the View button here */}
                                                <button className='btn btn-primary' 
                                                style={{ padding: '7px', maxWidth: '100px',  width: '100%',  fontSize: '12px'  }} 
                                                
                                                onClick={() => handleGetStudentData(template.id)}>View</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </Table>
                        {/* Modal to display PDF viewer */}
                        <Modal show={showModal1} onHide={handleCloseModal1} dialogClassName="modal-fullscreen">
                            <Modal.Header closeButton>
                                <Modal.Title>Written Assessment</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {pdfUrl ? (
                                    <iframe src={pdfUrl} style={{ width: '100%', height: '100%' }} title="PDF Viewer"></iframe>
                                ) : (
                                    <p>{error}</p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseModal1}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>


                    {/* Assessment Details  */}

                    <Table style={{ ...tableStyle, borderCollapse: 'collapse', marginTop: '-1%', width: '100%', marginTop: '-2%', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
                        <TableRow style={{ backgroundColor: '#f2f2f2' }}>
                            <TableCell colSpan={3} style={{ textAlign: 'center', borderBottom: '2px solid #ddd', padding: '12px' }}>
                                <h2 style={{ margin: 0 }}>Assessment Details</h2>
                            </TableCell>
                        </TableRow>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Assessment Result</th>
                                    <th scope="col">Feedback</th>
                                    <th scope="col">E-Sign</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <span>
                                            <input
                                                type='radio'
                                                name='result'
                                                style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                onChange={(e) => {
                                                    setPcNyc("c");
                                                }}
                                            />
                                              <label style={{ marginRight: '16px', fontSize: '1.1rem' }}>C</label>
                                            <input
                                                type='radio'
                                                name='result'
                                                style={{ marginRight: '8px', verticalAlign: 'middle' }}
                                                onChange={(e) => {
                                                    setPcNyc("nc");
                                                }}
                                            />
                                             <label style={{ marginRight: '16px', fontSize: '1.1rem' }}>NC</label>
                                        </span>
                                    </td>
                                    <td>
                                        <textarea rows="2" cols="30" onChange={(e) => handleInputChange('feedback', e.target.value)}></textarea>
                                    </td>
                                    <td>
                                        {showModal2 ? ( // Check if modal is open
                                            <Modal show={showModal2} onHide={handleCloseModal2} size="lg">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Sign Here</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <SignatureCanvas
                                                        ref={signatureRef}
                                                        canvasProps={{ width: 740, height: 300, className: 'signature-canvas' }}
                                                    />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="contained" color="default" onClick={handleCloseModal2} style={{ marginRight: '8px' }}>
                                                        Close
                                                    </Button>
                                                    <Button variant="contained" color="error" onClick={clearSignature} style={{ marginRight: '8px' }} >
                                                        Clear
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={saveSignature}>
                                                        Save
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        ) : (
                                            // Render the "Sign Here" button only when modal is not open
                                            <button
                                                type='Button'
                                                className='btn btn-primary'
                                                style={{ padding: '7px', maxWidth: '100px',  width: '100%',  fontSize: '12px'  }} 
                                                onClick={() => handleShowModal2()}
                                            >
                                                Sign Here
                                            </button>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>
                                        <Button className="btn btn-primary"
                                         style={{ padding: '7px', maxWidth: '100px',  width: '100%',  fontSize: '12px'  }} 
                                        onClick={handleSubmit}>Submit</Button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Table>

                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};


function AR() {
    const { courseId, batchId, manageId } = useParams();
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [batchDetails, setBatchDetails] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [courseResponse, manageResponse, batchResponse] = await Promise.all([
                    axios.get(`course/${courseId}`),
                    axios.get(`manage/${manageId}`),
                    axios.get(`batch/${batchId}`)
                ]);

                const sId = manageResponse.data.sId;
                const [studentResponse] = await Promise.all([
                    axios.get(`student/${sId}`)
                ]);

                setCourseData(courseResponse.data);
                setStudentData(studentResponse.data);
                setBatchDetails(batchResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [courseId, manageId, batchId]);
    return (
        <div>
            <Header />
            {loading ? (
                <CircularProgress style={{ marginTop: '30px' }} />
            ) : (
                <>
                    <UpdateAR
                        courseData={courseData}
                        studentData={studentData}
                        batchDetails={batchDetails}
                    />
                    <TemplateTable
                        courseId={courseId}
                        courseData={courseData}
                        studentData={studentData}
                        batchDetails={batchDetails}
                    />
                </>
            )}
        </div>
    )
}

export default AR;







