import React, { useState, useEffect } from 'react';
import { Form, Col, Button, Row, Table } from 'react-bootstrap';
import axios from '../Axiosinstance';
import './feedback.css';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Head from './Headstu';
import { logoImage } from '../common';

const Rating = () => {
    const [ratings, setRatings] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [feedbackId, setFeedbackId] = useState("");
    const [feedbackDetails, setFeedbackDetails] = useState([]);
    const [unsubmittedFeedback, setUnsubmittedFeedback] = useState([]);
    const [selectedModule, setSelectedModule] = useState(null);

    const { batchid } = useParams();
    const navigate = useNavigate();

    const studentteacherid = localStorage.getItem('studentteacherid');
    const studentname = localStorage.getItem('name');

    useEffect(() => {
        const fetchFeedbackDetails = async () => {
            try {
                const feedbackModulesResponse = await axios.get(`generalfeedbackcreate/batch/${batchid}`);
                const feedbackModules = feedbackModulesResponse.data;

                const submittedFeedbackResponse = await axios.get(`generalfeedback/student/${studentteacherid}`);
                const submittedFeedback = submittedFeedbackResponse.data;

                const unsubmitted = feedbackModules.filter(
                    module => !submittedFeedback.some(feedback => feedback.feedbackId === module.id)
                );

                setFeedbackDetails(feedbackModules);
                setUnsubmittedFeedback(unsubmitted);
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            }
        };

        fetchFeedbackDetails();
    }, [batchid, studentteacherid]);

    useEffect(() => {
        if (selectedModule) {
            setQuestions(selectedModule.questions);
            setFeedbackId(selectedModule.id);
        }
    }, [selectedModule]);

    const handleRatingChange = (rating, index) => {
        const updatedRatings = [...ratings];
        updatedRatings[index] = rating;
        setRatings(updatedRatings);
    };

    const handleCancel = () => {
        window.alert('Feedback submission cancelled.');
        navigate('/Dashboard');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (ratings.length !== questions.length) {
            toast.error('Please answer all questions before submitting.');
            return;
        }

        const feedbackData = {
            feedbackId: feedbackId,
            studentId: studentteacherid,
            studentName: studentname,
            batchId: batchid,
            questions: questions,
            answers: ratings.map((rating) => {
                switch (rating) {
                    case 1:
                        return "Strongly Dissatisfied";
                    case 2:
                        return "Dissatisfied";
                    case 3:
                        return "Neutral";
                    case 4:
                        return "Satisfied";
                    case 5:
                        return "Very Satisfied";
                    default:
                        return "";
                }
            })
        };

        try {
            const response = await axios.post('generalfeedback/', feedbackData);
            console.log('Feedback submitted:', response.data);
            toast.success('Feedback submitted successfully!');
        } catch (error) {
            console.error('Error submitting feedback:', error);
            toast.error('Failed to submit feedback. Please try again later.');
        }
        navigate('/Dashboard');
    };

    const nav = () => {
        navigate('/Dashboard');
    }

    return (
        <>
            <Head />
            <div style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', background: '#FAF9F6', maxWidth: '1000px', margin: 'auto', padding: '20px' }} className="mt-5">
                <ToastContainer position="bottom-right" autoClose={5000} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px',
                    }}
                >
                    <div >
                        <img
                            src={logoImage}
                            alt="logoImage"
                            style={{ width: '100px', height: '60px' }}
                        />
                    </div>
                    <h1 style={{ flex: '1', textAlign: 'center', margin: 0 , color: 'black'  }}>
                        Newton International College
                    </h1>
                </div>

                <br />
                <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
                    {unsubmittedFeedback.length === 0 ? (
                        <div style={{ textAlign: 'center' }}>
                            <h3>No feedbacks available.</h3>
                            <Button variant="danger" onClick={nav}>
                                Back
                            </Button>
                        </div>
                    ) : (
                        <Form onSubmit={handleSubmit} style={{ padding: '20px' }}>
                            <Row>
                                <h3 style={{textAlign:'center'}}>MODULE FEEDBACK SURVEY</h3>
                                <Col xs={12} className="mb-4">
                                    <Form.Group style={{ textAlign: 'left' , color: 'black'  }}>
                                        <Form.Label>Select Module</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => setSelectedModule(unsubmittedFeedback.find(module => module.id === parseInt(e.target.value)))}
                                        >
                                            <option value="">Select a module</option>
                                            {unsubmittedFeedback.map((module) => (
                                                <option key={module.id} value={module.id}>
                                                    {module.moduleName} - {module.dateTaken}
                                                </option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </Col>

                                <p style={{ textAlign: 'left' , color: 'black'  }}>Dear Students,</p>
                                <div style={{ textAlign: 'justify' }}>
                                    <p>
                                        Module feedback survey is taken at the end of every module on the module content, duration, course material,
                                        module assessment, evaluation of lectures & teaching methods, facilities and overall satisfaction.
                                        Please answer the following questions about your experiences in this module and the lecturing of this class.
                                        For each question mark the box which best reflects those experiences. Your responses will be completely anonymous,
                                        confidential, and used to improve the quality of the courses and modules.
                                    </p>
                                </div>
                            </Row>
                            {selectedModule && (
                                <Row>
                                    <p className="text-start">
                                        Please select the appropriate box to indicate your degree of satisfaction:<br /><span style={{ fontSize: '14px', fontWeight: 'bolder' , color: 'black'  }}>
                                            1: Strongly Dissatisfied, 2: Dissatisfied, 3: Neutral, 4: Satisfied, 5: Very Satisfied<br />
                                        </span>

                                        <span style={{ fontWeight: 'bolder' }}>  QUESTIONS </span>

                                    </p>
                                    <Row>
                                        <div>
                                            {questions.map((question, index) => (
                                                <Col key={index} xs={12} className="mb-4">
                                                    <div className="d-flex flex-column align-items-start">
                                                        <p style={{ fontSize: '18px', fontWeight: 'bold' , color: 'black' }}>
                                                            <span style={{ whiteSpace: 'nowrap' }}>{index + 1}. </span>
                                                            {question}
                                                        </p>
                                                        <Table borderless>
                                                            <tbody>
                                                                <tr>
                                                                    {[1, 2, 3, 4, 5].map((rating) => (
                                                                        <td key={`${index}_${rating}`} className="text-center">
                                                                            <Form.Check
                                                                                inline
                                                                                label={<span style={{ fontSize: '14px', fontWeight: 'bold' }}>{rating}</span>}
                                                                                type="checkbox"
                                                                                id={`rating_${index}_${rating}`}
                                                                                checked={ratings[index] === rating}
                                                                                onChange={() => handleRatingChange(rating, index)}
                                                                                style={{ transform: 'scale(1.5)', margin: '5px' }} // Increase size of checkbox
                                                                                className={ratings[index] === rating ? 'checked' : 'unchecked'}
                                                                            />
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            ))}
                                        </div>
                                    </Row>
                                </Row>
                            )}
                            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                <Button variant="primary" type="submit" style={{ marginRight: '10px' }}>
                                    Submit
                                </Button>
                                <Button variant="danger" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        </>
    );
};

function 
Feedback() {
    return (
        <div>
            <Rating />
        </div>
    )
}

export default Feedback;
