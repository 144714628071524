import React from 'react';
import './Manage.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import axios from '../Axiosinstance';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import Container from './Container';
import { toast, ToastContainer } from 'react-toastify';
import {  Tooltip } from '@mui/material';



const AddStudentModal = ({ showModal, onClose, onSave }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [search, setSearch] = useState('');
  const [batchDetails, setBatchDetails] = useState(null);
  const { batchId } = useParams();
  const [existingSIds, setExistingSIds] = useState([])
  const [intake, setIntake] = useState([]);
  const [batchIdCount, setBatchIdCount] = useState([])


  useEffect(() => {


    fetchBatchDetails(); // Fetch batch details when the component mounts
  }, [batchId]);




  useEffect(() => {
    // Fetch student data from the API URL http://localhost:8080/students
    const fetchStudents = async () => {
      try {
        const response = await axios.get('students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching student data:', error);
      }
    };
    fetchStudents();
  }, []);




  const fetchBatchDetails = async () => {
    try {
      const response = await axios.get(`batch/${batchId}`);
      setBatchDetails(response.data);
      setIntake(response.data.intakesize)
    } catch (error) {
      console.error('Error fetching batch details:', error);
    }
  };




  useEffect(() => {
    // Fetch 'sId' values from the API http://localhost:8080/manages
    const fetchExistingSIds = async () => {
      try {
        const response = await axios.get('manages');
        const existingSIds = response.data
          .filter((manage) => manage.batch_id === parseInt(batchId)) // Filter by current batch
          .map((manage) => manage.sId);
        setExistingSIds(existingSIds);
        // console.log(existingSIds);
 
        // Count the occurrences of batchId in existingSIds
        setBatchIdCount(existingSIds.filter((id) => id === parseInt(batchId)).length);
      } catch (error) {
        console.error('Error fetching existing batch_ids:', error);
      }
    };
    fetchExistingSIds();
  }, [batchId]);
 




  const filteredStudents = students.filter(
    (student) =>




      student?.fullname?.toLowerCase().includes(search.toLowerCase()) ||
      student?.email?.toLowerCase().includes(search.toLowerCase()) ||
      student?.phone?.toLowerCase().includes(search.toLowerCase())
  );




  const handleStudentCheckboxChange = (id) => {
    setSelectedStudents((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };




  const handleSave = async () => {
    if (selectedStudents.length === 0) {
      window.alert('Please select at least one student to save.');
      return;
    }
 
    const confirmed = window.confirm('Are you sure you want to save the selected student?');
    if (confirmed) {
      if (batchIdCount >= intake) {
        window.alert('The selected number of students exceeds the intake size. Please review before saving.');
        return;
      }
 
      let manageId;
      const selectedStudentData = selectedStudents.map((studentId) => {
        const selectedStudent = students.find((student) => student.id === studentId);
        return {
          batch_id: batchId,
          sName: selectedStudent.fullname,
          sEmail: selectedStudent.email,
          sPhone: selectedStudent.phone,
          status: true,
          registered: new Date().toISOString().slice(0, 10),
          sId: selectedStudent.id,
          sNric: selectedStudent.nric,
        };
      });
 
      const savedStudentDetails = [];
 
      for (const manageData of selectedStudentData) {
        try {
          const response = await axios.post('manage', manageData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
 
          if (response.status === 200) {
            toast.success('Student added successfully');
            savedStudentDetails.push(manageData);
            manageId = response.data.id;
            console.log('Data saved successfully for student:', manageData.sName);
          } else {
            console.error('Failed to save data for student:', manageData.sName);
            console.error('Error response data:', response.data);
            // Handle the error scenario as needed
          }
          onClose();
        } catch (error) {
          console.error('Error while saving data for student:', manageData.sName, error);
          // Handle the error scenario as needed
        }
      }
 
      try {
        const batchResponse = await axios.get(`batch/${batchId}`);
        const batchDetails = batchResponse.data;
 
        const attendanceDataArray = savedStudentDetails.map((savedStudent) => ({
          manageId: manageId,
          sName: savedStudent.sName,
          sId: savedStudent.sId,
          sEmail: savedStudent.sEmail,
          sNric: savedStudent.sNric,
          duration: "Full day",
          course: batchDetails.course,
          trainers: batchDetails.trainers,
          batchCode: batchDetails.batchcode,
          batch_id: batchId,
          days: batchDetails.days.map(day => ({
            morningFrom: day.morningFrom,
            morningTo: day.morningTo,
            eveningFrom: day.eveningFrom,
            eveningTo: day.eveningTo,
            batchDate: day.batchDate,
            signatureMorning: day.signatureMorning,
            signatureEvening: day.signatureEvening,
          })),
        }));
 
        for (const attendanceData of attendanceDataArray) {
          const response = await axios.post('attendance', attendanceData);
          if (response.status === 200) {
             
            console.log('Attendance recorded successfully for student:', attendanceData.sName);
          } else {
            console.error('Failed to record attendance for student:', attendanceData.sName);
            console.error('Error response data:', response.data);
            // Handle the error scenario as needed
          }
        }
        window.location.reload();
        onClose();
      } catch (error) {
        console.error('Error fetching batch details:', error);
        // Handle the error scenario as needed
      }
 
      onSave(selectedStudents);
    } else {
      window.alert('Action canceled. No student has been saved.');
      console.log('User canceled the action of adding student(s).');
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5); // Initial rows per page
 


  // Function to handle page change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };


  // Function to handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset page to 1 when changing rows per page
  };


  return (
    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Student</h5>
            <button  className="btn-close" onClick={onClose} aria-label="Close" />
             
          </div>
          <div className="modal-body">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Name, Email, or Phone"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
             <div style={{ height: '400px', overflowY: 'scroll' }}>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
              {filteredStudents
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((student) => (
              <tr key={student.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedStudents.includes(student.id)}
                    onChange={() => handleStudentCheckboxChange(student.id)}
                    disabled={existingSIds.includes(String(student.id))}
                  />
                </td>
                <td>{student.fullname}</td>
                <td>{student.email}</td>
                <td>{student.phone}</td>
              </tr>
            ))}
        </tbody>
            </Table>
            <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredStudents.length}
        rowsPerPage={itemsPerPage}
        page={currentPage - 1}
        onPageChange={handlePageChange}
        labelDisplayedRows={({ from, to }) => `${from}-${to} of ${filteredStudents.length}`}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
       </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary " style={{ backgroundColor: 'red'}} onClick={onClose}>
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


const Green = () => {
  const { batchId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState('');
  const [students, setStudents] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [studentsPerPage, setStudentsPerPage] = useState(5);


  useEffect(() => {
    fetchDataFromAPI();
  }, [batchId]);




  const fetchDataFromAPI = async () => {
    try {
      const response = await axios.get('manages');
      const allStudents = response.data;
      // Adjust the filter based on your actual data structure
      const filteredStudents = allStudents.filter((student) => student.batch_id === parseInt(batchId));


      setStudents(filteredStudents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const handleAddStudent = () => {
   
      setShowModal(true); // Open the modal to add a student
   
  };




  const handleCloseModal = () => {
    setShowModal(false);
  };



  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(0); // Reset page to 0 when searching
  };


  const filteredStudents = students.filter((student) =>
    student.sName.toLowerCase().includes(search.toLowerCase())
  );

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setStudentsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const indexOfLastStudent = (currentPage + 1) * studentsPerPage;
  const indexOfFirstStudent = currentPage * studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);



  const handleDelete = async (id) => {
    // Show confirmation dialog
    const confirmDelete = window.confirm('Are you sure you want to delete this student from the batch?');
 
    if (confirmDelete) {
      try {
        await axios.delete(`manage/${id}`);
        toast.success('Student and attendance records deleted successfully');
        window.location.reload();
        // Fetch attendance details using the 'attendances' API
        const attendanceResponse = await axios.get(`attendances?manageId=${id}`);
        const attendanceData = attendanceResponse.data;
 
        // Delete each attendance record
        for (const attendanceRecord of attendanceData) {
          await axios.delete(`attendance/${attendanceRecord.id}`);
        }
 
        // Delete the student record from the 'manage' API
     
 
        // Update the state to reflect the deletion
        const updatedStudents = students.filter((student) => student.id !== id);
        setStudents(updatedStudents);
 
       
      } catch (error) {
        console.error('Error deleting student and attendance records:', error);
      }
    }
  };
 
 

  const handleSave = async (selectedStudents) => {
    // Your save logic here
    await fetchDataFromAPI();
    handleCloseModal();
  };



  // const calculateTimeFrame = (registeredDate) => {
  //   const currentDate = new Date(); // Current date
  //   const registrationDate = new Date(registeredDate); // Convert the registration date to a Date object
  //   const timeDifference = currentDate - registrationDate; // Calculate the time difference in milliseconds
  //   // Calculate the time frame in terms of days
  //   const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  //   if (daysDifference < 1) {
  //     return 'Today';
  //   } else if (daysDifference === 1) {
  //     return 'Yesterday';
  //   } else if (daysDifference <= 30) {
  //     return `${daysDifference} days ago`;
  //   } else if (daysDifference <= 60) {
  //     return '1 month ago';
  //   } else if (daysDifference <= 365) {
  //     const monthsDifference = Math.floor(daysDifference / 30);
  //     return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
  //   } else {
  //     const yearsDifference = Math.floor(daysDifference / 365);
  //     return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
  //   }
  // };




  const [selectAll, setSelectAll] = useState(false);


  const handleToggleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allStudentIds = currentStudents.map(student => student.id);
      setSelectedStudents(allStudentIds);
    } else {
      setSelectedStudents([]);
    }
  };






  const [selectedStudents, setSelectedStudents] = useState([]);
 
  const handleToggleSelect = (studentId) => {
    if (selectedStudents.includes(studentId)) {
      setSelectedStudents(selectedStudents.filter(id => id !== studentId));
    } else {
      setSelectedStudents([...selectedStudents, studentId]);
    }
  };
 
  const handleDeleteSelected = async () => {
    // Implement deletion logic for selected students here
    const confirmDelete = window.confirm('Are you sure you want to delete all selected students?');
    if (confirmDelete) {
      try {
        for (const studentId of selectedStudents) {
          await axios.delete(`manage/${studentId}`);
          // Additional logic for deleting attendance records if needed
        }
        toast.success('Selected students deleted successfully');
        setSelectedStudents([]); // Clear selected students after deletion
        await fetchDataFromAPI(); // Refetch data or update state as needed
      } catch (error) {
        console.error('Error deleting selected students:', error);
      }    }


  };

  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className='container-responsive' >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
  <div className="col">
    <div className="input-group" style={{ maxWidth: '700px' }}>
      <input
        type="text"
        className="form-control form-control-sm me-3"
        placeholder="Search by name"
        value={search}
        onChange={handleSearchChange}
      />
   <Button
        onClick={handleAddStudent}
        variant="contained" // Use 'contained' for a solid primary color button
        color="primary" // This sets the button's color to the primary color defined in your theme
        style={{ fontSize: '12px', borderRadius: '5px' , marginRight: '2%' }}
      >
        Add Student
      </Button>
      
      <Button
      style={{ fontSize: '12px', borderRadius: '5px' }}
      variant="contained" // Use 'contained' for a solid primary color button
      color="primary" // This sets the button's color to the primary color defined in your theme
      onClick={handleDeleteSelected}
    >
      Delete 
    </Button>
      
    </div>
    <div className="form-check form-switch" style={{ marginTop: '10px' }}>
      <input
        className="form-check-input"
        type="checkbox"
        checked={selectAll}
        onChange={handleToggleSelectAll}
      />
      <label className="form-check-label">Select All</label>
    </div>
  </div>
</div>



   <br />
 
     <div>
    <TableContainer  sx={{
                overflowX: 'auto',
                maxWidth: '100%',
                '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                  overflowX: 'unset', // Enable horizontal scroll
                },
              }}
            >

       <Table aria-label="sticky table" style={{ tableLayout: 'fixed', minWidth: '500px'  , maxWidth: '1200px'}}>
       <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
          <TableRow className='table-info'>
            <TableCell>Select</TableCell>
            <TableCell>Student Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone Number</TableCell>
            <TableCell>Registered on</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentStudents.map((student) => (
            <TableRow key={student.id}>
              <TableCell>
                <Checkbox
                  checked={selectedStudents.includes(student.id)}
                  onChange={() => handleToggleSelect(student.id)}
                />
              </TableCell>
              <TableCell>
        {student.sName.length > 6 ? (
          <Tooltip title={student.sName}>
            <span>{`${student.sName.substring(0, 10)}...`}</span>
          </Tooltip>
        ) : (
          student.sName
        )}
      </TableCell>
              <TableCell>
        {student.sEmail.length > 6 ? (
          <Tooltip title={student.sEmail}>
            <span>{`${student.sEmail.substring(0, 6)}...`}</span>
          </Tooltip>
        ) : (
          student.sEmail
        )}
      </TableCell>
              <TableCell>
        {student.sPhone.length > 6 ? (
          <Tooltip title={student.sPhone}>
            <span>{`${student.sPhone.substring(0, 6)}...`}</span>
          </Tooltip>
        ) : (
          student.sPhone
        )}
      </TableCell>
              <TableCell>{student.registered}</TableCell>
              <TableCell>{student.status ? 'Approved' : 'Pending'}</TableCell>
              <TableCell>
                <DeleteRoundedIcon
                  style={{ color: 'red', marginLeft: 10 }}
                  onClick={() => handleDelete(student.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        
      </Table>
     
    </TableContainer>
    <div style={{ display: 'flex', justifyContent: 'center'  , marginRight:"-15%"}}> 
       <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredStudents.length}
        rowsPerPage={studentsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      </div>

    </div>
   
    <div className="pagination">
      <AddStudentModal
        showModal={showModal}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
    </div>
    <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
  </div>
  );
};






const Manage = () => {
  return (
    <div >
      <div >
        <Green />
      </div>
    </div>
  );
};




export default Manage;















