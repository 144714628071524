
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useNavigate,Link  } from "react-router-dom";
import React, { Fragment } from 'react';
import Login from "./Login"
import Fp from './login/Fp';
import Otp from './login/Otp';
import Setp from './login/Setp';
import Header from './components/Header';
import Adhome from './components/Adhome';
import Facilitor from './components/Facilitor'
import Newfacilitators from './components/Newfacilitators'
import Faciedit from "./components/Faciedit";
import Batches from './components/Batches'
import Newbatches from './components/Newbatches'
import BatchEdit from "./components/BatchEdit";
import Notification from './Batchescourserun/Notification'
import Assesmennt from './Batchescourserun/Assesmennt'
import Manage from './Batchescourserun/Manage'
import Invoice from './Batchescourserun/Invoice'
import Attend from './Batchescourserun/Attend'
import Update from './Batchescourserun/Assesment/Update'
import CourseCatagories from './Resource/CourseCatagories';
import Asessmentsetup from './Resource/Asessmentsetup';
import Facilitators from './Resource/Facilitators';
import Facilitatorlink from './components/facilitatorlink';
import GeneralReports from './Resource/GeneralReports';
import Venues from './Resource/Venues';
import Edit from './Venue/Edit';
import AllCourse from './Resource/Course/AllCourse'
import Managec from './Resource/Course/Managec';
import Stuendent from './Resource/Course/Stuendent';
import Trainer from './Resource/Course/Trainer';
import Setup from './Resource/Course/Setup';
import A from './Batchescourserun/A';
import Sswcommon from './SSW/Sswcommon';
import Allstudent from './Student/Allstudent';
import E from './Student/E';
import Bulkupload from './Student/Bulkupload';
import DownloadSample from './Student/DownloadSample';
import Newstudent from './Student/Newstudent';
import Studentlink from './Student/studentlink'
import Activity from './Batchescourserun/Invoiceclick/Activity';
import Api from './Batchescourserun/Invoiceclick/Api';
import Blogs from './Batchescourserun/Invoiceclick/Blogs';
import Feedback from './Batchescourserun/Invoiceclick/Feedback';
import General from './Batchescourserun/Invoiceclick/General';
import Invoicesetting from './Batchescourserun/Invoiceclick/Invoicesetting';
import Maintance from './Batchescourserun/Invoiceclick/Maintance';
import Payment from './Batchescourserun/Invoiceclick/Payment';
import Roaster from './Batchescourserun/Invoiceclick/Roaster';
import Coursep from './Batchescourserun/F/Coursep';
import Coursefeedback from './Batchescourserun/F/Coursefeedback';
import Traine1r from './Batchescourserun/F/Traine1r';
import Gen from './Batchescourserun/F/Gen';
import All from './Venue/All';
import New from './Venue/New';
import Venuelink from './Venue/Venuelink';
import Allas from './Assesmentsetup/Allas';
import CreateAsse from './Assesmentsetup/CreateAsse';
import Questiobank from './Assesmentsetup/Questiobank';
import Validate from './Assesmentsetup/Validate';
import Acadamic from './General/Acadamic';
import Asat from './General/Asat';
import Course from './General/Course';
import Bill from './General/Bill';
import Courseei from './SSW/Courseei';
import Editv from './SSW/Editv';
import Allv from './SSW/Allv';
import Nfc from './SSW/Nfc';
import UpdateAR from "./Batchescourserun/UpdateAR";

import Heading from './Batchescourserun/Invoiceclick/Heading'
import Newcou from "././Resource/./Course/Newcou"
import Editc from "././Resource/./Course/Editc"
/*   */
import Activit from './SSW/SS/Activit';
import Ap from './SSW/SS/Ap';
import Blog from './SSW/SS/Blog';
import Feedbac from './SSW/SS/Feedbac';
import Genera from './SSW/SS/Genera';
import Invoicesettin from './SSW/SS/Invoicesettin';
import Maintanc from './SSW/SS/Maintanc';
import Paymen from './SSW/SS/Paymen';
import Roaste from './SSW/SS/Roaste'
import Grandcalculator from './SSW/Grandcalculator'
import AssesEdit from './Assesmentsetup/AssesEdit';
/* */
import Activ from './Settingss/Set/Activ';
import Aa from './Settingss/Set/Aa';
import Blo from './Settingss/Set/Blo';
import Feedb from './Settingss/Set/Feedb';
import Genee from './Settingss/Set/Genee';
import Invoicesett from './Settingss/Set/Invoicesett';
import Mainta from './Settingss/Set/Mainta';
import Paym from './Settingss/Set/Paym';
import Roasr from './Settingss/Set/Roasr'
import Hed from "./Settingss/Set/Hed";
import Setting from "./Settingss/Settings";

/* */
import Managebatches from './Batchescourserun/Managebatches';



import Teacher from './Teacher/Teacher'
import Trainerr from './Roaster/Trainerr'
import Batch from './Roaster/Batch'
import Support from './Help/Support'
import Chat from './Contact/Chat'
import TeacherChat from './Contact/TeacherChat'
import Studentchat from './Contact/Studentchat'

import Written from './StudentLogin/Written'
import Contact from './Help/Contact'
import Live from './Help/Live'
import Remotedesktop from './Help/Remotedesktop'

import Certificate from './Student/Certificate'
import Myattandance from './Teacher/Myattandance'
import Managee from './Teacher/Managee'
import View from './Teacher/View'
import Updaate from "./Teacher/Updaate";
import Student from "./Teacher/Student";
import Attandacereport from "./Teacher/Attandacereport";
import StudentFeed from "./Teacher/StudentFeed";
import Footer from "./components/Footer";
import Profile from './Contact/Profile';
import Online from './Teacher/Online'
import Assement from './Teacher/Assement'
import Assesslink from './Assesmentsetup/assesslink'
import Vieww from './Teacher/Vieww'

import TeachHeader from './Teacher/TeachHeader'
import Tprofile from './Teacher/Tprofile'
import Tvcontact from './Teacher/Tvcontact'

import Generallink from "./General/Generallink";

import Dashboard from './StudentLogin/Dashboard'
import Headstu from './StudentLogin/Headstu'
import FeedbackStudent from './StudentLogin/Feedback';
import Profilestu from './StudentLogin/Profilestu'
import Contactstu from './StudentLogin/Contactstu'
import Notifications from './Teacher/Notifications';
import Sign from './Teacher/Sign';
import Homepage from './All/Homepage'
import Home from './login/Home'
import Sfcnew from './SSW/Sfcnew';
import AddRequestStudentForm from "./All/RequestStudent";
import Coureses from './All/Coureses';
import StudentCourese from './StudentLogin/Courses'
import CourseDetails from './StudentLogin/CourseDetails';
import Content from './StudentLogin/Content'
import Passwordform from './login/passwordform'
import PDFwritten from './StudentLogin/PDFwritten'
import Newfeedback from './Static feedback/Newfeedback';

import { useState,useEffect } from 'react';

import Newregister from './components/Newregister';
import Studentreq from './components/Studentreq';
//import PrivateRoute from "./PrivateRoute";
import Upcomingcourses from "./components/Upcomingcourses";
import {  FaArrowLeft } from 'react-icons/fa';
import Loader from "./Loader";

function App() {
  const PrivateRoute = ({ children }) => {
    const loggedInUserId = localStorage.getItem("jwtToken");
    const userType = localStorage.getItem("usertype");

    return loggedInUserId ? <Outlet /> : <Navigate to="/login" />;
  }

  const NotFound = () => {
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulate loading for 3 seconds
      const timeout = setTimeout(() => {
        setLoading(false);
      }, 1000);
  
      return () => clearTimeout(timeout);
    }, []);
  
    const styles = {
      container: {
        textAlign: 'center',
        marginTop: '50px',
      },
      heading: {
        fontSize: '36px',
        marginBottom: '20px',
      },
      text: {
        fontSize: '18px',
        marginBottom: '20px',
      },
      buttonContainer: {
        marginTop: '20px',
      },
      button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'inline-block',
      },
      icon: {
        marginRight: '5px',
      },
    };
  
    return (
      <div style={styles.container}>
        {loading ? (
          <div><Loader/></div>
        ) : (
          <>
            <h1 style={styles.heading}>404 - Page Not Found</h1>
            <p style={styles.text}>Oops! Looks like you've ventured into uncharted territory.</p>
            <div style={styles.buttonContainer}>
              <button onClick={() => window.history.back()} style={styles.button}>
                <FaArrowLeft style={styles.icon} /> Go Back
              </button>
            </div>
          </>
        )}
      </div>
    );
  };
  
  const styles = {
    container: {
      textAlign: 'center',
      marginTop: '100px',
      animation: 'fadeInDown 0.5s ease-out', // Fade-in animation
    },
    heading: {
      fontSize: '36px',
      marginBottom: '20px',
      color: '#333',
    },
    text: {
      fontSize: '18px',
      marginBottom: '30px',
      color: '#666',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      animation: 'fadeInUp 0.5s ease-out', // Fade-in animation
      animationDelay: '0.5s', // Delay the animation
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 20px',
      margin: '0 10px',
      fontSize: '16px',
      color: '#fff',
      backgroundColor: '#007bff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      textDecoration: 'none',
      transition: 'background-color 0.3s',
    },
    icon: {
      marginRight: '8px',
      animation: 'iconAnimation 0.5s ease-in-out infinite alternate', // Icon animation
    },
    // Keyframe animations
    '@keyframes fadeInDown': {
      '0%': { opacity: 0, transform: 'translateY(-20px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    '@keyframes fadeInUp': {
      '0%': { opacity: 0, transform: 'translateY(20px)' },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    },
    '@keyframes iconAnimation': {
      '0%': { transform: 'scale(1)' },
      '100%': { transform: 'scale(1.2)' },
    },
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/Categories/:categoryId" element={<Coureses />} />

          <Route path="/login" element={<Login />} />
          <Route path="/Fp" element={<Fp />} />
          <Route path="/Otp" element={<Otp />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Setp" element={<Setp />} />
          <Route path="/Passwordform" element={<Passwordform />} />

          <Route path="/newfeedback" element={<Newfeedback/>} />
          
          <Route element={<PrivateRoute />}>
            {/* Admin Routes */}
            {localStorage.getItem("usertype") === "3" && (
              <>
                <Route path='/Sswcommon' element={<Sswcommon />} />
                <Route path="/Header" element={<Header />} />
                <Route exact path='/Adhome' element={<Adhome />} />
                <Route path="/Facilitor" element={<Facilitor />} />
                <Route path="/Newfacilitators" element={<Newfacilitators />} />
                <Route path="/Facilitatorlink" element={<Facilitatorlink />} />
                <Route path="/Batches" element={<Batches />} />
                <Route path="/BatchEdit/:BatchId" element={<BatchEdit />} />
                <Route path="/Newbatches" element={<Newbatches />} />
                <Route path="/Notification/:batchId" element={<Notification />} />
                <Route path="/Assesmennt" element={<Assesmennt />} />
                <Route path="/Hed" element={<Hed />} />
                <Route path="/Chat" element={<Chat />} />
                <Route path="/Assement" element={<Assement />} />
                <Route path="/AssesEdit/:assesEdit" element={<AssesEdit />} />
                <Route path="/Manage/:batchId" element={<Manage />} />
                <Route path="/Attend/:batchId" element={<Attend />} />
                <Route path="/Invoice/:batchId" element={<Invoice />} />
                <Route path='/Editc/:courseid' element={<Editc />} />
                <Route path="/Managebatches/:batchId" element={<Managebatches />} />
                <Route path="/Update" element={<Update />} />
                <Route path="/CourseCatagories" element={<CourseCatagories />} />
                <Route path='/Newcou' element={<Newcou />} />
                <Route path="/Asessmentsetup" element={<Asessmentsetup />} />
                <Route path="/Facilitators" element={<Facilitators />} />
                <Route path="/GeneralReports" element={<GeneralReports />} />
                <Route path="/Venues" element={<Venues />} />
                <Route path="/AllCourse" element={<AllCourse />} />
                <Route path="/Managec/:curriculumid" element={<Managec />} />
                <Route path="/Stuendent" element={<Stuendent />} />
                <Route path="/Trainer" element={<Trainer />} />
                <Route path="/Setup/:id" element={<Setup />} />
                <Route path="/A/:batchId" element={<A />} />
                <Route path="/Allstudent" element={<Allstudent />} />
                <Route path="/studentlink" element={<Studentlink />} />
                <Route path="/E/:studentId" element={<E />} />
                <Route path="/Bulkupload" element={<Bulkupload />} />
                <Route path="/DownloadSample" element={<DownloadSample />} />
                <Route path="/Newstudent" element={<Newstudent />} />
                <Route path="/Activity" element={<Activity />} />
                <Route path="/Api" element={<Api />} />
                <Route path="/Blogs" element={<Blogs />} />
                <Route path="/Feedback" element={<Feedback />} />
                <Route path="/General" element={<General />} />
                <Route path="/Invoicesetting" element={<Invoicesetting />} />
                <Route path="/Maintance" element={<Maintance />} />
                <Route path="/Payment" element={<Payment />} />
                <Route path="/Roaster" element={<Roaster />} />
                <Route path='/Coursep' element={<Coursep />} />
                <Route path='/Coursefeedback' element={<Coursefeedback />} />
                <Route path='/Gen' element={<Gen />} />
                <Route path='/Traine1r' element={<Traine1r />} />
                <Route path='/All' element={<All />} />
                <Route path='/New' element={<New />} />
                <Route path='/Venuelink' element={<Venuelink />} />
                <Route path="/Faciedit/:facilitatorId" element={<Faciedit />} />
                <Route path='/Allas' element={<Allas />} />
                <Route path='/Assesslink' element={<Assesslink />} />
                <Route path='/CreateAsse' element={<CreateAsse />} />
                <Route path='/Questiobank' element={<Questiobank />} />
                <Route path='/Validate' element={<Validate />} />
                <Route path='/Acadamic' element={<Acadamic />} />
                <Route path='/Asat' element={<Asat />} />
                <Route path='/Course' element={<Course />} />
                <Route path='/Bill' element={<Bill />} />
                <Route path='/Courseei' element={<Courseei />} />
                <Route path='/Editv' element={<Editv />} />
                <Route path='/Allv' element={<Allv />} />
                <Route path='/Nfc' element={<Nfc />} />
                <Route path='/Edit/:venueId' element={<Edit />} />
                <Route path="/Activit" element={<Activit />} />
                <Route path="/Ap" element={<Ap />} />
                <Route path="/Blog" element={<Blog />} />
                <Route path="/Feedbac" element={<Feedbac />} />
                <Route path="/Genera" element={<Genera />} />
                <Route path="/Invoicesettin" element={<Invoicesettin />} />
                <Route path="/Maintanc" element={<Maintanc />} />
                <Route path="/Paymen" element={<Paymen />} />
                <Route path="/Roaste" element={<Roaste />} />
                <Route path="/Grandcalculator" element={<Grandcalculator />} />
                <Route path="/Heading" element={<Heading />} />
                <Route path="/Upcomingcourses" element={<Upcomingcourses />} />
                <Route path="/Generallink" element={<Generallink />} />
                <Route path="Managebatches/updatear/:courseId/:batchId/:manageId/:studentId" element={<UpdateAR />} />

                <Route path='/certificate' element={<Certificate />} />



                <Route path="/Activ" element={<Activ />} />
                <Route path="/Aa" element={<Aa />} />
                <Route path="/Blo" element={<Blo />} />
                <Route path="/Feedb" element={<Feedb />} />
                <Route path="/Genee" element={<Genee />} />
                <Route path="/Setting" element={<Setting />} />

                <Route path="/Invoicesett" element={<Invoicesett />} />
                <Route path="/Mainta" element={<Mainta />} />
                <Route path="/Paym" element={<Paym />} />
                <Route path="/Roasr" element={<Roasr />} />
                <Route path="/Batch" element={<Batch />} />
                <Route path="/Support" element={<Support />} />
                <Route path="/Contact" element={<Contact />} />
                <Route path="/Live" element={<Live />} />
                <Route path="/Remotedesktop" element={<Remotedesktop />} />
                <Route path="/Sign" element={<Sign />} />
                <Route path="/Home" element={<Home />} />
                <Route path="/Sfcnew" element={<Sfcnew />} />
              </>
            )}
            {/* teacher */}
            {localStorage.getItem("usertype") === "2" && (

              <>
                <Route path="/Teacher" element={<Teacher />} />
                <Route path="/TeachHeader" element={<TeachHeader />} />
                <Route path="/Footer" element={<Footer />} />
                <Route path="/Vieww" element={<Vieww />} />
                <Route path="/Tprofile" element={<Tprofile />} />
                <Route path="/Tvcontact" element={<Tvcontact />} />
                <Route path="/Myattandance" element={<Myattandance />} />
                <Route path="/Managee/:id" element={<Managee />} />
                <Route path="/View/:bid" element={<View />} />
                <Route path="/Updaate/:courseId/:batchId/:manageId/:studentId" element={<Updaate />} />
                <Route path="/Student/:batchId" element={<Student />} />
                <Route path="/Attandacereport" element={<Attandacereport />} />
                <Route path="/StudentFeed" element={<StudentFeed />} />
                <Route path="/Trainerr" element={<Trainerr />} />
                <Route path="/TeacherChat" element={<TeacherChat/>} />

               

                <Route path="/Online" element={<Online />} />
                <Route path="/Notifications" element={<Notifications />} />
              </>
            )}


            {/* student */}
            {localStorage.getItem("usertype") === "1" && (
              <>
                <Route path="/Written/:bid/:cid" element={<Written />} />

                <Route path="/PDFwritten/:bid/:cid" element={<PDFwritten/>} />
                <Route path="/Dashboard" element={<Dashboard />} />
                <Route path="/StudentCourese" element={<StudentCourese />} />
                <Route path="/course/:id" element={<CourseDetails />} />
                <Route path="/Headstu" element={<Headstu />} />
                <Route path="/Profilestu" element={<Profilestu />} />
                <Route path="/Contactstu" element={<Contactstu />} />
                <Route path="/feedbackstudent/:batchid" element={<FeedbackStudent />} />
                <Route path="/content/:id" element={<Content />} />
                <Route path="/studentchat" element={<Studentchat/>} />



              </>
            )}
          </Route>
          <Route path="*" element={<NotFound />} />


          <Route exact path='/Newregister' element={<Newregister />} />
          <Route exact path='/Studentreq' element={<Studentreq />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;