import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography, Container } from '@mui/material';
import Coursep from '../F/Coursep';
import Gen from '../F/Gen';
import Traine1r from '../F/Traine1r';
import Feedback from './Fedback';
import Header from '../../components/Header';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Newfeedback from'../../Static feedback/Newfeedback';


const NavigationButtons = () => {
  const [tabValue, setTabValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false); // Assuming you have this state variable


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const handleDropdownChange = (event) => {
    setTabValue(event.target.value);
  };


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  return (
    <Container maxWidth="md">
      <Box my={4} textAlign="center">
        {/* Your header or any other content */}
      </Box>
      {isMobile ? (
        <div style={{ marginTop: '5%' }}>
          <Select value={tabValue} onChange={handleDropdownChange} displayEmpty>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            <MenuItem value={0}>Course</MenuItem>
            {/* <MenuItem value={1}>General feedback</MenuItem> */}
            <MenuItem value={1}>Trainer feedback</MenuItem>
            <MenuItem value={2}>Student feedback</MenuItem>
            <MenuItem value={3}>feedback</MenuItem>
          </Select>
          <div>
            {/* {tabValue === 0 && <Coursep />} */}
            {/* {tabValue === 1 && <Gen />} */}
            {/* {tabValue === 1 && <Traine1r />}
            {tabValue === 2 && <Feedback />} */}
            {tabValue === 0 && <Newfeedback />}
          </div>
        </div>
      ) : (
        <div>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="navigation-tabs"
            variant="fullWidth"
            className="d-md-flex justify-content-md-center"
            sx={{
              borderRadius: 10,
              display: 'flex',
              justifyContent: 'center',
              "& button": {
                display: 'flex',
                justifyContent: 'center',
              },
              "& button:hover": {
                backgroundColor: "#eee",
              },
              "& button:active": {
                backgroundColor: "#ddd",
              },
              "& button.Mui-selected": {
                backgroundColor: "#007bff",
                color: "#fff",
              },
              "& .MuiTabs-indicator": {
                display: 'none',
              },
            }}
          >
            {/* <Tab label="Course" sx={{ minWidth: '200px' }} /> */}
            {/* <Tab label="General feedback" sx={{ minWidth: '200px' }} /> */}
            {/* <Tab label="Trainer feedback" sx={{ minWidth: '200px' }} /> */}
            {/* <Tab label="Student feedback" sx={{ minWidth: '200px' }} /> */}
          <Tab label="feedback" sx={{ minWidth: '200px' }} />


          </Tabs>
          <Box p={3}>
            {/* {tabValue === 0 && <Coursep />} */}
            {/* {tabValue === 1 && <Gen />} */}
            {/* {tabValue === 1 && <Traine1r />} */}
            {/* {tabValue === 2 && <Feedback />} */}
             {tabValue === 0 && <Newfeedback />}
          </Box>
        </div>
      )}
    </Container>
  );
};


export default NavigationButtons;





