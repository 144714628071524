import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import { Link} from 'react-router-dom';

const Container = () => {
  return (
    <div className="container" style={{ maxWidth: '1000px' }}>
      
      <div className="row" >
        
        <div className="col-md-6">
          <div className="container-content" style={{ backgroundColor: '#e6f2ff'  }}>
            <p className="paragraph">Course:</p>
            <p className="paragraph">Course Date:</p>
            <p className="paragraph">Trainers:</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="container-content" style={{ backgroundColor: '#e6f2ff' }}>
            <p className="paragraph">Batch:</p>
            <p className="paragraph">Student Enrolled:</p>
            <p className="paragraph">Assessors:</p>
          </div>
        </div>
      </div>
    </div>
  );
};








const TableComponent = ({ data }) => {
  return (
    <div className="table-responsive">
   
    <table className="table table-bordered custom-table">
      <thead className="thead-dark">
        <tr>
          <th>Student Name</th>
          <th>Attempted</th>
          <th>Status</th>
          <th>Result</th>
        
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.attempted}</td>
            <td>
              <span
                className={`badge ${
                  item.status === 'Passed' ? 'badge-success' : 'badge-danger'
                }`}
              >
                {item.status}
              </span>
            </td>
            <td>{item.result}</td>
            
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  );
};


const Head = () => {
  // Example data for the table
  const [tableData, setTableData] = useState([
    { id: 1, name: 'Rahul Kumar', attempted: 5, status: '-', result: '-' },
    { id: 2, name: 'Priya Sharma', attempted: 3, status: '-', result: '-' },
    { id: 3, name: 'Amit Singh', attempted: 6, status: '-', result: '-' },
    { id: 4, name: 'Anjali Patel', attempted: 4, status: '-', result: '-' },
    { id: 5, name: 'Vikram Joshi', attempted: 2, status: '-', result: '-' },
    { id: 6, name: 'Neha Verma', attempted: 7, status: '-', result: '-' },
    { id: 7, name: 'Rajiv Gupta', attempted: 3, status: '-', result: '-' },
    
  ]);


  return (
    <div>
      <Header />
      {/* <Container /> */}
      <br/>  <br/>  <br/>  <br/>  <br/>
 
      <TableComponent data={tableData} />
    </div>
  );
};


export default Head;

 
