import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import Header from '../components/Header';
import Modal from 'react-bootstrap/Modal';
import axios from '../Axiosinstance';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Paper from '@mui/material/Paper';
import { toast, ToastContainer } from 'react-toastify';







const App = () => {
  const [students, setStudents] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState(''); // Provide a default value here
  const [currentPage, setCurrentPage] = useState(1);
 
  const [editingStudent, setEditingStudent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [studentsData, setStudentsData] = useState([]);
  const [batchesData, setBatchesData] = useState([]);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [fee, setFee] = useState('');






  useEffect(() => {
    // Fetch student data from the API
    axios
      .get('students')
      .then((response) => {
        const data = response.data;
        setStudentsData(data);
      })
      .catch((error) => {
        console.error('Error fetching student data:', error);
      });








    // Fetch batch data from the API
    axios
      .get('batches')
      .then((response) => {
        const data = response.data;
        setBatchesData(data);
      })
      .catch((error) => {
        console.error('Error fetching batch data:', error);
      });
  }, []);








  useEffect(() => {
    // Fetch data from the API using Axios
    axios
      .get('sfcpays')
      .then((response) => {
        const data = response.data;
        // Update the status for each student to "initiated"
        const updatedData = data.map((student) => ({
          ...student,
          status: 'initiated',
        }));
        setStudents(updatedData);
        setFetchError(null); // Clear any previous fetch errors
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setFetchError(error.message); // Set fetch error message
      });
  }, []);






  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };






  const filteredStudents = students.filter((student) => {
    const lowerCaseName = student.student ? student.student.toLowerCase() : '';
    const lowerCaseStatus = student.status ? student.status.toLowerCase() : '';






    return (
      lowerCaseName.includes(searchText.toLowerCase()) &&
      (filterStatus === '' || lowerCaseStatus === filterStatus)
    );
  });






  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);






  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredStudents.length / studentsPerPage); i++) {
    pageNumbers.push(i);
  }






  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };






  const handleDelete = (id) => {
    // Display a confirmation dialog before deleting the student
    const confirmation = window.confirm('Are you sure you want to delete this student?');






    if (confirmation) {
      // Make a DELETE request to the API
      axios
        .delete(`sfcpay/${id}`)
        .then((response) => {
          if (response.status === 200) {
            const updatedStudents = students.filter((student) => student.id !== id);
            setStudents(updatedStudents);






            // Display a success message
            toast.success('sfc pay deleted successfully');
          } else {
            window.alert('Failed to delete student'); // Display an error message if the delete request fails
          }
        })
        .catch((error) => console.error('Error deleting student:', error));
    }
  };




  const handleEdit = (student) => {
    axios.get(`sfcpay/${student.id}`)
      .then((response) => {
        setEditingStudent(response.data);
        setShowEditModal(true);
      })
      .catch((error) => console.error('Error fetching student data:', error));
  };


  const handleSave = () => {
    const confirmed = window.confirm('Are you sure you want to update the sfc pay?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
    if (editingStudent) {
      axios.put(`sfcpay/${editingStudent.id}`, editingStudent)
        .then((response) => {
          if (response.status === 200) {
            const updatedStudents = studentsData.map((student) =>
              student.id === editingStudent.id ? editingStudent : student
            );
            setStudentsData(updatedStudents);
            toast.success('Sfc pay updated successfully');
            setShowEditModal(false);
            setEditingStudent(null);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.error('Server responded with an error:', error.response.data);
            console.error('Status code:', error.response.status);
            toast.error('Failed to update sfc pay');
          } else {
            console.error('Error setting up the request:', error.message);
            toast.error('Error update sfc pay');
          }
        });
    }
  };






  return (
     
     
      <div className="container" style={{ maxWidth: '1000px' }}>
   
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div className="row">
        <div className="col-md-6">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Search student..."
              value={searchText}
              onChange={handleSearchChange}
            />
            <Button
             
              variant="contained"
              color="primary"
             
            >
              <Link to="/Sfcnew" style={{ color: '#fff', textDecoration: 'none' }}>
                <FaPlus /> Add New
              </Link>
            </Button>
          </div>
        </div>
      </div>




     
      <TableContainer sx={{ maxHeight: 'none' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              <TableCell>Batch</TableCell>
              <TableCell>WSG-SSG</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStudents.map((student) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={student.id}>
                <TableCell>{student.student}</TableCell>
                <TableCell>{student.batch}</TableCell>
                <TableCell>{student.wwssgg}</TableCell>
                <TableCell>{student.status}</TableCell>
                <TableCell>
                  <BsPencilFill onClick={() => handleEdit(student)} />
                  <DeleteRoundedIcon
                    style={{ color: 'red', marginLeft: 20 }}
                    onClick={() => handleDelete(student.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={filteredStudents.length}
        rowsPerPage={studentsPerPage}
        page={currentPage - 1}
        onPageChange={(e, newPage) => paginate(newPage + 1)}
        onRowsPerPageChange={(e) => setStudentsPerPage(parseInt(e.target.value, 10))}
      />
    </Paper>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Sfc Pay</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
           
            <div className="form-group">
              <label htmlFor="edit-batch">Batch</label>
              <select
  className="form-control"
  id="edit-batch"
  value={editingStudent ? editingStudent.batch : ''}
  onChange={(e) =>
    setEditingStudent({
      ...editingStudent,
      batch: e.target.value,
    })
  }
>
  <option value="">Select Batch</option>
  {batchesData.map((batch) => (
    <option key={batch.id} value={batch.batchcode}>
      {batch.batchcode}
    </option>
  ))}
</select>
            </div>
            <div className="form-group">
              <label htmlFor="edit-student">Student</label>
              <select
                className="form-control"
                id="edit-student"
                value={editingStudent ? editingStudent.student : ''}
                onChange={(e) =>
                  setEditingStudent({
                    ...editingStudent,
                    student: e.target.value,
                  })
                }
              >
                <option value="">Select Student</option>
                {studentsData.map((student) => (
                  <option key={student.id} value={student.name}>
                    {student.fullname}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="edit-fee">NetFee</label>
              <input
                type="text"
                className="form-control"
                id="edit-fee"
                placeholder="NetFee"
                value={editingStudent ? editingStudent.fee : ''}
                onChange={(e) =>
                  setEditingStudent({
                    ...editingStudent,
                    fee: e.target.value,
                  })
                }
              />
            </div>
           
           
            <div className="mb-3">
                  <label htmlFor="edit-supportDocument">Support Document</label>
                  <input
                    type="file"
                    id="edit-supportDocument"
                    className="form-control"
                    accept=".pdf,.doc,.docx"
                    value={editingStudent ? editingStudent.supportDocument : ''}
                onChange={(e) =>
                  setEditingStudent({
                    ...editingStudent,
                    supportDocument: e.target.value,
                  })
                }
                  />
                </div>


          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary btn-block"
            onClick={handleSave}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-secondary btn-block mt-2"
            onClick={() => {
              setShowEditModal(false);
              setEditingStudent(null);
            }}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />

    </div>
 
  );
};


const TableForm = () => {
  return (
    <div>
      <Header />
     
      <App />
    </div>
  );
};




export default TableForm;







