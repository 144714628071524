import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Header from '../../components/Header'

const NavigationBar = () => {
  return (
    <div className="navigation-buttons">
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
      </button>
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
      </button>
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
      </button>
      
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
      </button>
    </div>
  );
};
const EmailContainer = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  return (
    <div className="container" style={{ border: '1px solid #ccc', borderRadius: '12px' }}>
      <div className="cad">
        <h5>Roaster Setup</h5>
        <div className="instructions-container">
          <h4>Trainer Course Email</h4>
          <input type="checkbox" id="notificationCheckbox" />
          <label htmlFor="notificationCheckbox">Enable email notification</label>
          <div className="form-group">
            <h6>Subject</h6>
            <input
              type="text"
              id="invoiceNo"
              className="form-control"
              placeholder="Course Run Notification"
            />
            <label><h6>Body</h6></label>
          </div>
          <ReactQuill
            value={editorContent}
            onChange={handleEditorChange}
            placeholder="Enter your email body"
            style={{ fontSize: '16px' }} // Increase the size of the placeholder
          />
        </div>
      </div>
    </div>
  );
};

const EmailNotificationContainer = () => {
  const [editorContent, setEditorContent] = useState('');

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  return (
    <div className="container" style={{ border: '1px solid #ccc', borderRadius: '12px' }}>
      <div className="crd">
        <div className="instructions-container">
          <h5>Student Course Email Notification Templates</h5>
          <input type="checkbox" id="notificationCheckbox" />
          <label htmlFor="notificationCheckbox">Enable email notification</label>
          <div className="form-group">
            <h6>Subject</h6>
            <input
              type="text"
              id="invoiceNo"
              className="form-control"
              placeholder="Course Run Notification"
            />
            <label><h6>Body</h6></label>
          </div>
          <ReactQuill
            value={editorContent}
            onChange={handleEditorChange}
            placeholder="Enter your email body"
            style={{ fontSize: '16px' }} // Increase the size of the placeholder
          />
        </div>
      </div>
    </div>
  );
};

const EmailPreview = () => {
  const [courseName, setCourseName] = useState('Name of the course');
  const [courseDate, setCourseDate] = useState('Row 1');
  const [assessmentDate, setAssessmentDate] = useState('Row 2');
  const [trainers, setTrainers] = useState('Row 3');
  const [assessors, setAssessors] = useState('Row 4');
  const [venue, setVenue] = useState('Row 5');

  return (
    <div className="container">
      <h4>Email Preview</h4>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Course</td>
              <td>{courseName}</td>
            </tr>
            <tr>
              <td>Course Date</td>
              <td>{courseDate}</td>
            </tr>
            <tr>
              <td>Assessment Date</td>
              <td>{assessmentDate}</td>
            </tr>
            <tr>
              <td>Trainers</td>
              <td>{trainers}</td>
            </tr>
            <tr>
              <td>Assessors</td>
              <td>{assessors}</td>
            </tr>
            <tr>
              <td>Venue</td>
              <td>{venue}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary" style={{background:'#03396c'}}>Login</button>
    </div>
  );
}

const Email = () => {
  const [fieldValue, setFieldValue] = useState('');

  const handleChange = (event) => {
    setFieldValue(event.target.value);
  };

  return (
    <div className="container">
      <h4>Email Preview</h4>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-dark">
            <tr>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Course</td>
              <td>
            
              </td>
            </tr>
            <tr>
              <td>Course Date</td>
              <td>Row 1</td>
            </tr>
            <tr>
              <td>Assessment Date</td>
              <td>Row 2</td>
            </tr>
            <tr>
              <td>Trainers</td>
              <td>Row 3</td>
            </tr>
            <tr>
              <td>Assessors</td>
              <td>Row 4</td>
            </tr>
            <tr>
              <td>Venue</td>
              <td>Row 5</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary" style={{background:'#03396c'}}>Login</button>
    </div>
  );
};

const Bar = () => {
  return (
    <div>
    <Header/>
      <NavigationBar />

      <div className="container" style={{ border: "1px solid #ccc", borderRadius: '20px' }}>
        <div className="row">
          <div className="col-md-6">
            <EmailContainer />
          </div>
          <div className="col-md-6">
            <EmailPreview />
          </div>
        </div>
        <button className="btn btn-primary" style={{background:'#03396c'}}>Save</button>
      </div>

      <div className="container" style={{ border: "1px solid #ccc", borderRadius: '20px' }}>
        <div className="row">
          <div className="col-md-6">
            <EmailNotificationContainer />
          </div>
          <div className="col-md-6">
            <Email />
          </div>
        </div>
        <button className="btn btn-primary" style={{background:'#03396c'}}>Save</button>
      </div>
  
    </div>
  );
};

export default Bar;
