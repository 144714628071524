import React, { useState, useEffect } from 'react';
import axios from '../Axiosinstance';
import Header from './Header';
import CircularProgress from '@mui/material/CircularProgress';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';


const Upcomingcourses = () => {
  const [batches, setBatches] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showAllDates, setShowAllDates] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const batchResponse = await axios.get('batches');
        const batchDetails = batchResponse.data;
        setBatches(batchDetails);
        setLoading(false);
        console.log(batchDetails);
      } catch (error) {
        console.error('Error fetching batch details:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   // axios.get('batches')
  //   //   .then(response => setBatches(response.data))
  //   //   .catch(error => console.error('Error fetching data:', error));
  //   setBatches(batchess)
  //   setLoading(false);
  // }, [batchess]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const suffix = (day >= 11 && day <= 13) ? 'th' : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][day % 10];
    return `${day}${suffix} ${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
  };


  const isFutureDate = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return date > today;
  };


  const futureBatches = batches.filter(batch =>
    batch.days.some(day => isFutureDate(day.batchDate))
  );


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to the first page when changing rowsPerPage
  };


  const toggleShowDates = (batchId) => {
    setShowAllDates(prevState => ({
      ...prevState,
      [batchId]: !prevState[batchId]
    }));
  };


  return (
    <div >
      <Header />
      <div style={{ padding: '20px 20px' }}>

        <div className="container-fluid" style={{ maxWidth: '85%' }}>
          <h2 className="heading" align="center" style={{ color: '#FFFFFF', marginTop: '3%', fontSize: '28px' }}>Upcoming Courses</h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Course</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Trainers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : (
                  (rowsPerPage > 0
                    ? futureBatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : futureBatches
                  ).map(batch => (
                    <TableRow key={batch.id}>
                      <TableCell style={{ width: '30%', border: '1px solid #ccc' }}>{batch.course.replace(/\(\d+\)/, '')},{batch.batchcode}</TableCell>
                      <TableCell style={{ minWidth: 120, border: '1px solid #ccc' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            <li>
                              {batch.days
                                .filter(day => isFutureDate(day.batchDate))
                                .map((day, index) => (
                                  <span key={day.id} style={{ marginRight: '10px' }}>
                                    {showAllDates[batch.id] || index < 3 ? formatDate(day.batchDate) + ' ,' : null}
                                  </span>
                                ))}
                            </li>
                          </ul>
                        </div>
                        {batch.days.length > 3 && (
                          <span
                            onClick={() => toggleShowDates(batch.id)}
                            style={{
                              cursor: 'pointer',
                              color: 'blue',
                              textAlign: 'center', // Align text to center
                              marginTop: '5px', // Add some top margin for spacing
                            }}
                          >
                            {showAllDates[batch.id] ? 'See Less' : 'See More'}
                          </span>
                        )}
                      </TableCell>
                      <TableCell style={{ border: '1px solid #ccc' }}>{batch.trainers.map(trainer => trainer.replace(/\(\d+\)/, '')) + ' ,'}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={futureBatches.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </div>
    </div>
  );
};


export default Upcomingcourses;


