import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import Genee from '../Settingss/Set/Roasr';
// import Roasr from '../Settingss/Set/Genee';
import Grandcalculator from './Grandcalculator';
import Courseei from './Courseei';
import Header from '../components/Header';
import Settings from '../Settingss/Settings'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function Setting() {
  const [tabValue, setTabValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const handleDropdownChange = (event) => {
    setTabValue(event.target.value);
  };


  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };


  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));





  return (
    <div>
      <div>
        <Header />
        <br />
      </div>
 
      {isMobileView ? (
        <div style={{ marginTop: '5%'}}> 
          <Select value={tabValue} onChange={handleDropdownChange} displayEmpty>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            {/* <MenuItem value={0}>Courses</MenuItem>
            <MenuItem value={1}>Course Run</MenuItem> */}
            <MenuItem value={0}>Grandcalculator</MenuItem>
            <MenuItem value={1}>SFC Payment</MenuItem>
          </Select>
          <div>
            {/* {tabValue === 0 && <Settings />}
            {tabValue === 1 && <Settings />} */}
            {tabValue === 0 && <Grandcalculator />}
            {tabValue === 1 && <Courseei />}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: '2%'}}>
         
          <div className="d-flex justify-content-center">
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="settings-tabs"
            variant="fullWidth"
            className="d-md-flex justify-content-md-center"
            sx={{
              borderRadius: 10,
             display: 'flex',
             justifyContent: 'center',
             "& button": {
               display: 'flex',
               justifyContent: 'center',
             },
             "& button:hover": {
               backgroundColor: "#eee",
             },
             "& button:active": {
               backgroundColor: "#ddd",
             },
             "& button.Mui-selected": {
               backgroundColor: "#007bff",
               color: "#fff",
             },
             "& .MuiTabs-indicator": {
               display: 'none',
             },
             // Adjust width for mobile view
             ...(isMobile && {
               flexDirection: 'column', // Stack tabs vertically
               "& button": {
                 width: '100%', // Set tabs to full width on mobile
               },
             }),
           }}
         >
            {/* <Tab label="Courses" sx={{ minWidth: isMobile ? '50%' : '300px' }} />
            <Tab label="Course Run"  sx={{ minWidth: isMobile ? '50%' : '300px' }}/> */}
            <Tab label="Grandcalculator" sx={{ minWidth: isMobile ? '50%' : '300px' }} />
            <Tab label="SFC Payment" sx={{ minWidth: isMobile ? '50%' : '300px' }} />
          </Tabs>
          </div>
          <div>
            {/* {tabValue === 0 && <Settings />}
            {tabValue === 1 && <Settings />} */}
            {tabValue === 0 && <Grandcalculator />}
            {tabValue === 1 && <Courseei />}
          </div>
        </div>
      )}
    </div>
  );
 
}


export default Setting;



