import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';
import Heading from './Heading';


const Api = () =>{

  return(
    <div>

    </div>
  )
}


const Activity = () => {
  return (
    <div>
      <Header />
      <Heading />
<Api />
    </div>
  );
};

export default Activity;
