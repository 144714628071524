import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './../components/Header';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Trainerr from './Trainerr'; // Fixed typo in import
import axios from '../Axiosinstance';
import { Modal, Button } from 'react-bootstrap';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CircularProgress from '@mui/material/CircularProgress';


const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(() => {
    // Initialize tabValue with stored value or default to 0
    const storedTab = localStorage.getItem('calendarTab');
    return storedTab ? parseInt(storedTab) : 0;
  });
  const [batchess, setBatchess] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    axios.get('batches')
      .then((response) => {
        const data = response.data;
        const newEvents = data.reduce((acc, batch) => {
          const batchEvents = batch.days.map((day) => {
            const morningStart = day.morningFrom ? Object.values(day.morningFrom)[0] : null;
            const morningEnd = day.morningTo ? Object.values(day.morningTo)[0] : null;
            const eveningStart = day.eveningFrom ? Object.values(day.eveningFrom)[0] : null;
            const eveningEnd = day.eveningTo ? Object.values(day.eveningTo)[0] : null;

            const eventRanges = [
              { start: morningStart, end: morningEnd },
              { start: eveningStart, end: eveningEnd },
            ];

            const validEventRanges = eventRanges.filter((range) => range.start !== undefined && range.end !== undefined);

            return validEventRanges.map((range) => ({
              title: `${batch.course.replace(/[^a-zA-Z]/g, '')} (${batch.batchcode})`,
              start: moment(`${day.batchDate} ${range.start}`, 'YYYY-MM-DD HH:mm').toDate(),
              end: moment(`${day.batchDate} ${range.end}`, 'YYYY-MM-DD HH:mm').toDate(),
              allDay: false,
              courseId: batch.id,
            }));
          });

          return acc.concat(...batchEvents);
        }, []);

        setEvents(newEvents);
        setBatchess(data); // Set batchess here
        setLoading(false); // Update loading state
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Update loading state in case of error
      });
  }, []);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('calendarTab', newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToBatch = () => {
    setTabValue(0);
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <br />
      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Roaster-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none',
            },
            // Adjust width for mobile view
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="Batch Schedule" sx={{ minWidth: isMobile ? '50%' : '660px' }} />
          <Tab label="Trainer Schedule" sx={{ minWidth: isMobile ? '50%' : '660px' }} />
        </Tabs>
      </div>

      {tabValue === 0 && (
        <div className="shadow p-3 mb-5 bg-light rounded" style={{ position: 'relative', zIndex: '1', border: '2px solid #3498db', borderRadius: '8px', margin: '10px auto', maxWidth: '1300px' }}>
          <h4>Batch/Schedule</h4>
          <br />
          {loading ? ( // Display circular progress when loading is true
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          ) : (
            <Calendar localizer={localizer} events={events} startAccessor="start" endAccessor="end" onSelectEvent={handleEventClick} style={{ height: 740, width: '100%', backgroundColor: '#F5F5F5' }} />
          )}
        </div>
      )}

      {tabValue === 1 && (
        <Trainerr onCourseCreated={navigateToBatch} batchess={batchess} />
      )}

      <Modal show={modalIsOpen} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Course Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Title: {selectedEvent?.title}</p>
          <p>Start Time: {moment(selectedEvent?.start).format('MMMM Do YYYY, h:mm:ss a')}</p>
          <p>End Time: {moment(selectedEvent?.end).format('MMMM Do YYYY, h:mm:ss a')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CalendarComponent;
