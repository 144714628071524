import React, { useState, useEffect } from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button } from 'react-bootstrap';
import Header from '../components/Header';
import axios from '../Axiosinstance'
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import { FaPlus } from 'react-icons/fa';
import Loader from '../Loader';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [categoriesPerPage, setCategoriesPerPage] = useState(10);
  const [showForm, setShowForm] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [modalCategoryName, setModalCategoryName] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [modalValidationMessage, setModalValidationMessage] = useState('');
  const [originalCreatedAt, setOriginalCreatedAt] = useState(null);
  const [sortCreatedAtOrder, setSortCreatedAtOrder] = useState('asc');
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [isFormReady, setIsFormReady] = useState(false);
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true);
  const handleNewCategoryImageChange = (e) => {
    setNewCategoryImage(e.target.files[0]);
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  ;
  const [error, setError] = useState('');

  const handleNewCategoryClick = () => {
    setShowModal(true);
  };

  const [errorMessage, setErrorMessage] = useState('');


  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    // Perform API call with the selected page size
  };


  const handleDelete = async (id) => {
    setDeleteCategoryId(id);
    setShowModal(true);
  }
  const loadCategories = async () => {
    try {
      const response = await axios.get(`categorys?limit=${categoriesPerPage}`);
      setCategories(response.data.reverse());
      setLoading(false); // Set loading to false when data is loaded
    } catch (error) {
      console.log(error);
      setLoading(false); // Set loading to false in case of an error
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);




  useEffect(() => {
    setFilteredCategories(
      categories.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [categories, searchTerm, currentPage, pageSize]);



  const confirmDelete = async () => {
    try {
      await axios.delete(`category/${deleteCategoryId}`);
      loadCategories();
      toast.success('Category deleted successfully');
    } catch (error) {
      console.log(error);
      toast.error('An error occurred while deleting the category');
    } finally {
      setDeleteCategoryId(null);
      setShowModal(false); // Close the modal after successful delete
    }
  };




  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };




  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };





  const handleCategoriesPerPageChange = (event) => {
    const selectedValue = Number(event.target.value);
    setCategoriesPerPage(selectedValue);
    const newTotalPages = Math.ceil(filteredCategories.length / selectedValue);
    setCurrentPage(Math.min(currentPage, newTotalPages)); // Ensure the current page is within the new total pages
  };

  const indexOfLastCategory = currentPage * categoriesPerPage;
  const indexOfFirstCategory = indexOfLastCategory - categoriesPerPage;
  const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);
  const totalPages = Math.ceil(filteredCategories.length / categoriesPerPage);








  {/* Pagination component */ }
  <div className="text-center mt-3">
    <ul className="pagination">
      {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
        <li
          key={page}
          className={`page-item ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
          style={{ cursor: 'pointer' }}
        >
          <span className="page-link">{page}</span>
        </li>
      ))}
    </ul>
  </div>




  const handleSortByChange = (event) => {
    const selectedSortBy = event.target.value;
    setSortBy(selectedSortBy);
    if (selectedSortBy === 'createdAt') {
      setSortCreatedAtOrder('asc');
    } else {
      setSortOrder('asc');
    }
  };

  const handleImageUpload = async (e) => {
    try {
      const file = e.target.files[0];

      // Check if a file is selected
      if (!file) {
        return;
      }

      const currentTimestamp = new Date().getTime(); // Generate a current timestamp
      const fileNameWithTimestamp = `${modalCategoryName}_${currentTimestamp}`; // Combine name and timestamp

      const formData = new FormData();
      formData.append('file', file, fileNameWithTimestamp); // Append file with the generated unique name
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      formData.append('public_id', `LMS/Category/${fileNameWithTimestamp}`);

      const response = await fetch(baseURL, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.secure_url !== '') {
        const uploadedFileUrl = data.secure_url;
        setImageUrl(uploadedFileUrl);
        setIsFormReady(true);
        // console.log(uploadedFileUrl);
      }
    } catch (err) {
      console.error(err);
    }
  };


  const handleNewCategorySubmit = async (event) => {
    event.preventDefault();


    let uploadedFileUrl = imageUrl;

    if (!modalCategoryName.trim()) {
      setModalValidationMessage('Category name cannot be empty');
      return;
    }

    if (!imageUrl) {
      // If no image uploaded, prevent form submission
      alert('Please upload an image.'); // Show an alert or handle as needed
      return;
    }


    const isDuplicate = categories.some(
      (category) => category.name.toLowerCase() === modalCategoryName.toLowerCase()
    );




    if (isDuplicate) {
      setModalValidationMessage('Category name already exists');
      return;
    }


    const confirmSubmission = window.confirm('Are you sure you want to create this category?');

    if (confirmSubmission) {

      try {

        const formData = new FormData();
        formData.append('name', modalCategoryName);
        formData.append('image', newCategoryImage);
        formData.append('imageUrl', imageUrl);

        const newCategory = {
          name: modalCategoryName,
          createdAt: new Date(), // Replace this with the appropriate createdAt value
          url: uploadedFileUrl // Include the uploaded file URL
        };


        const createdAt = new Date();
        const response = await axios.post('category', newCategory);
        const responseData = response.data;

        if (responseData.id) {
          // If the response contains an 'id', it was successful
          // Perform any necessary actions upon successful creation

          // Example: Display success message
        } else {
          // If the response does not contain an 'id', there was an error
          // Handle the error or show an error message
          toast.error('Error creating the category');
        }

        loadCategories();

        setModalCategoryName('');
        setNewCategoryImage(null);
        setImageUrl('');
        setIsFormReady(false);
        setShowModal(false);
        setModalValidationMessage('');
        toast.success('Category created successfully');

      } catch (error) {
        // console.log(error);
        toast.error('An error occurred while creating the category');

      }
    }
  };




  const handleNewCategoryCancel = () => {
    setModalCategoryName('');
    setShowModal(false);
    setModalValidationMessage('');
  };




  const handleEditCategory = (categoryId) => {
    setEditCategoryId(categoryId);
    const category = categories.find((category) => category.id === categoryId);
    setEditCategoryName(category.name);
    setOriginalCreatedAt(category.createdAt);
    setUrl(category.url)
  };


  const handleUpdateCategory = async (event) => {
    event.preventDefault();

    try {

      if (!editCategoryName.trim()) {
        toast.error('Category name cannot be empty');
        return;
      }

      // Check if the image URL is empty
      if (!url) {
        toast.error('Image URL cannot be empty');
        return;
      }

      let updatedImageUrl = url; // Use the existing URL as a fallback

      // Check if a new image is selected
      if (newCategoryImage) {
        // Upload the new image
        const imageFormData = new FormData();
        imageFormData.append('file', newCategoryImage);
        imageFormData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        imageFormData.append('public_id', `LMS/Category/${modalCategoryName}_${new Date().getTime()}`);

        const imageResponse = await fetch(baseURL, {
          method: 'POST',
          body: imageFormData,
        });

        const imageData = await imageResponse.json();

        if (imageData.secure_url !== '') {
          updatedImageUrl = imageData.secure_url;
        } else {
          // Handle the case where image upload fails
          console.error('Image upload failed');
        }
      }

      // Use window.confirm for update confirmation
      const shouldUpdate = true;

      if (shouldUpdate) {
        // Update the category with the new image URL
        await axios.put(`category/${editCategoryId}`, {
          name: editCategoryName,
          createdAt: originalCreatedAt,
          url: updatedImageUrl,
        });

        setEditCategoryId(null);
        setEditCategoryName('');
        loadCategories();
        toast.success('Category updated successfully');
      }
      setShowConfirmation(false);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while updating the category');
    }
  };


  const handleCancelUpdate = () => {
    setEditCategoryId(null);
    setEditCategoryName('');
  };



  function formatToIST(dateString) {
    const date = new Date(dateString);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);

    const options = {
      timeZone: 'Asia/Kolkata',
      hour12: false,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return date.toLocaleString('en-IN', options);
  }

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const sortedCategories = [...currentCategories].sort((a, b) => {
    if (sortBy === 'name') {
      return sortOrder === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortBy === 'createdAt') {
      const dateA = a.createdAt || '';
      const dateB = b.createdAt || '';

      return sortOrder === 'asc'
        ? dateA.localeCompare(dateB)
        : dateB.localeCompare(dateA);
    }
  });




  return (
    <div>


      <Header />
      <div class="mt-3 text-center mt-5">
        <h3>All Categories</h3>
      </div>


      <div className="container" style={{ maxWidth: '1000px' }}>
        <Paper style={{ width: '100%', overflow: 'hidden' }}>
          <div className="row align-items-center">
            <div className="col">
              <div className=" " style={{ display: 'flex', justifyContent: 'space-between' }}>
                <label htmlFor="searchInput" className="visually-hidden">Search Categories</label>
                <input
                  type="text"
                  id="searchInput"

                  placeholder="Search Categories name"
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    padding: '12px 12px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',




                  }}
                />

                <button
                  type="button"
                  className="primary"
                  onClick={handleNewCategoryClick}
                  style={{
                    backgroundColor: '#1E90FF',
                    color: 'white',
                    border: 'none',
                    padding: '0.8em', // Adjusted padding
                    borderRadius: '7px',
                    fontSize: '0.9em', // Adjusted font size
                    transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                    cursor: 'pointer', // Change cursor to indicate interactivity
                  }}
                  onMouseEnter={(e) => e.target.style.backgroundColor = '#007bff'} // Change background color on hover
                  onMouseLeave={(e) => e.target.style.backgroundColor = '#1E90FF'} // Revert background color on mouse leave
                >
                  <FaPlus /> Add New Category
                </button>

              </div>
            </div>
          </div>
          {loading ? (
            <Loader /> // Show loader while data is being fetched
          ) : (
            <TableContainer sx={{ maxHeight: 'none' }}>

              <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>

                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>S.no</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Image</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Created At</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCategories
                      .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
                      .map((category, index) => (
                        <TableRow key={category.id} >
                          <TableCell>{(currentPage - 1) * rowsPerPage + index + 1}</TableCell> {/* Serial number */}
                          <TableCell>


                            {editCategoryId === category.id ? (
                              <input
                                type="text"
                                className="form-control"
                                value={editCategoryName}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Use a regular expression to match only letters and spaces
                                  const sanitizedValue = inputValue.replace(/[^A-Za-z\s]/g, '');
                                  // Trim the input to a maximum length of 30 characters
                                  const trimmedValue = sanitizedValue.substring(0, 30);
                                  // Update the state with the trimmed value
                                  setEditCategoryName(trimmedValue);
                                }}
                                maxLength={30}
                              />

                            ) : (
                              category.name
                            )}
                          </TableCell>
                          <TableCell>
                            {editCategoryId === category.id ? (
                              <div>
                                <img
                                  src={imageUrl || category.url}
                                  alt={category.name}
                                  style={{ width: '156px', height: '106px' }}
                                />
                                <input
                                  accept="image/*"
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => handleNewCategoryImageChange(e)}
                                />
                              </div>
                            ) : (
                              <img
                                src={category.url}
                                alt={category.name}
                                style={{ width: '156px', height: '106px' }}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {formatToIST(
                              editCategoryId === category.id ? originalCreatedAt : category.createdAt
                            )}
                          </TableCell>
                          <TableCell style={{ width: '10%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {editCategoryId === category.id ? (
                                <>
                                  <button
                                    className="btn btn-success btn-sm"
                                    style={{ backgroundColor: 'green', width: '77px' }}
                                    onClick={() => setShowConfirmation(true)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{ backgroundColor: 'red' }}
                                    onClick={handleCancelUpdate}
                                  >
                                    Cancel
                                  </button>
                                  <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)} centered>
                                    <Modal.Header closeButton>
                                      <Modal.Title>Update Confirmation</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>Are you sure you want to update this category?</Modal.Body>
                                    <Modal.Footer>
                                      <Button variant="secondary" onClick={() => setShowConfirmation(false)} style={{ backgroundColor: 'red' }}  >
                                        Cancel
                                      </Button>
                                      <Button variant="primary" onClick={handleUpdateCategory} style={{ backgroundColor: 'green' }}>
                                        Confirm
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                </>
                              ) : (
                                <>


                                  <BsPencilFill style={{ marginRight: 10 }} onClick={() => handleEditCategory(category.id)} />
                                  <DeleteRoundedIcon
                                    style={{ color: 'red', marginLeft: 10 }}
                                    onClick={() => handleDelete(category.id)}
                                  />


                                </>
                              )}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}


                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={filteredCategories.length}
            rowsPerPage={rowsPerPage}
            page={currentPage - 1}
            labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={(event) => {
              setCurrentPage(1);
              setRowsPerPage(event.target.value);
            }}
          />

        </Paper>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{deleteCategoryId ? 'Delete Confirmation' : 'Add New Category (Newton International College)'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteCategoryId ? (
            <div>
              <p>Are you sure you want to delete this category?</p>
              <div className="text-end">
                <Button variant="secondary" onClick={() => setShowModal(false)} style={{ backgroundColor: 'grey' }}>
                  Cancel
                </Button>
                <Button style={{ backgroundColor: 'red' }} variant="danger" onClick={confirmDelete}>
                  Delete
                </Button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleNewCategorySubmit}>
              <div className="mb-3">
                <label className="form-label">Category Name*</label>
                <input
                  type="text"
                  className="form-control"
                  value={modalCategoryName}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces

                    if (onlyLetters.test(inputValue) || inputValue === "") {
                      setModalCategoryName(inputValue);
                      setModalValidationMessage('');
                    }
                  }}
                />
                {modalValidationMessage && (
                  <div className="text-danger">{modalValidationMessage}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Category Image*</label>
                <input
                  type="file"
                  className="form-control"
                  accept="image/*"
                  onChange={(e) => {
                    handleNewCategoryImageChange(e); // Invoke the defined function here
                    handleImageUpload(e); // Trigger image upload function
                  }}
                />

              </div>
              <div className="text-end" style={{ marginLeft: '12%' }}>
                <Button variant="danger" style={{ backgroundColor: 'red' }} onClick={handleNewCategoryCancel}>
                  Cancel
                </Button>
                <Button
                  style={{ backgroundColor: 'green' }}
                  type="submit"
                  className="btn btn-success"
                  disabled={!isFormReady} // Disable if form is not ready (image not uploaded)
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />

    </div>
  );
};



export default Categories;


