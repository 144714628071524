import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';




import 'bootstrap/dist/css/bootstrap.min.css';



const apiData = [
  {
    tableName: "Get Course Categorys",
    endPoint: "/categorys",
    method: "GET",
    parameters: "N/A",
    successResponse: `[{"id":1,"name":"Cooking","course":2,"createdAt":"2023-09-26T03:19:16"},
      {"id":174,"name":"Web Development","course":0,"createdAt":"2023-09-26T03:19:16"}]`,
      errorResponse: `{
        "status": 400,
        "message": "The request is invalid or missing required parameters."
      }`,
  },
  {
    tableName: "Get Courses",
    endPoint: "/courses",
    method: "GET",
    parameters: "N/A",
    successResponse: `[{"id":9,"category":"Cooking","name":"Jhon","reference":"5365","duration":99,
    "fee":54,"idi":null,"clustercode":213,"qualificationcode":18,
    "description":"<p>To learn how to cook</p>","outcome":"<p>Become chief</p>",
    "requirement":"<p>interest</p>"},{"id":19,"category":"Web Development","name":"lider","reference":"99",
    "duration":99,"fee":100000,"idi":null,"clustercode":12,"qualificationcode":18,
    "description":"<p>To learn how develop a web site</p>","outcome":"<p>Become a Web developer</p>",
    "requirement":"<p>Coding interest</p>"}]]`,
    errorResponse: `{
      "status": 404,
      "message": "The requested resource was not found."
    }`,
  },
  {
    tableName: "Get all Batches",
    endPoint: "/batches",
    method: "GET",
    parameters: "N/A",
    successResponse: `[{"id":10,"course":"Test course","batchcode":"5736","onlineclasslink":"3432","attendanceqrlink":"",
    "esignrequired":"","threshold":43,"intakesize":54,"venue":"501","status":"","assessment":false,
    "assessors":["Subash S(7)"],"trainers":["John Doe(5)"],"morningFrom":{"1":"2023-09-12T09:30:00.559Z"},
    "morningTo":{"1":"2023-09-12T10:30:00.119Z"},"eveningFrom":{"1":"2023-09-12T11:00:00.456Z"},
    "eveningTo":{"1":"2023-09-12T11:15:00.792Z"},"batchDate":{}}, {"id":17,"course":"Test course",
    "batchcode":"ram","onlineclasslink":"3432","attendanceqrlink":"","esignrequired":"","threshold":43,
    "intakesize":54,"venue":"501","status":"active","assessment":false,"assessors":["rani(11)","ramajam(15)"],
    "trainers":["Subash S(7)","raja(13)"],"morningFrom":{"1":"2023-09-12T04:30:00.767Z"},
    "morningTo":{"1":"2023-09-12T05:30:00.983Z"},"eveningFrom":{"1":"2023-09-12T08:30:00.047Z"},
    "eveningTo":{"1":"2023-09-12T09:30:00.375Z"},"batchDate":{}}]`,
    errorResponse: `{
      "status": 400,
      "message": "Invalid input. The following fields are missing or invalid: [Field Name(s)]."
    }`,
  },
 
  {
    tableName: "Get Staffs",
    endPoint: "/facilitators",
    method: "GET",
    parameters: "N/A",
    successResponse: `[{"id":5,"salutation":"Mr.","nric":"4534","email":"sugi@gmail.com","phone":"9876543211",
    "educational_level":"pg","qualification":"BE","domain":"eee","gender":"Male","password":"123","name":"John Doe",
    "usertype1":"new"},{"id":7,"salutation":"Mr.","nric":"5647","email":"subash@gmail.com","phone":"9876543210",
    "educational_level":"UG","qualification":"hjh","domain":"male","gender":"Male","password":"123","name":"Subash S","usertype1":"new"},
    {"id":11,"salutation":"Miss","nric":"67648","email":"rani@gmail.com","phone":"98765433212","educational_level":"test12w1",
    "qualification":"be","domain":"yjmy","gender":"","password":"123","name":"rani","usertype1":"existing"}]`,
    errorResponse: `{
      "status": 400,
      "message": "Update failed. The specified batch does not exist."
    }`,
  },
  {
    tableName: "Get Staff",
    endPoint: "/facilitator/{id}",
    method: "GET",
    parameters: "facilitator_id",
    successResponse: `{"id":5,"salutation":"Mr.","nric":"4534","email":"sugi@gmail.com","phone":"9876543211",
    "educational_level":"pg","qualification":"BE","domain":"eee","gender":"Male","password":"123","name":"John Doe","usertype1":"new"}]`,
    errorResponse: `{
      "status": 404,
      "message": "User not found. The requested user with ID {id} does not exist."
    }`,
  },
  {
    tableName: "Get students",
    endPoint: "/students",
    method: "GET",
    parameters: "N/A",
    successResponse: `[{"id":4,"fullname":"ram","email":"krishna@gmail.com","phone":"9876543211","gender":"male","dob":"2023-09-15",
    "password":"123@lider","address":"chennai","postalcode":"600000","race":"Indian","eduction":"testing","employmentstatus":"testing",
    "salary":"90000","companyname":"lider Corporation","corporatecompanysponsored":"lider","designation":"Software Developer"},
    {"id":18,"fullname":"krishna","email":"krishna@gmail.com","phone":"36563565633","gender":"Male","dob":"2000-02-09",
    "password":"123@lider","address":"ddwwwwwwwwwwwwww","postalcode":"eeeeeeeeeeee","race":"Indian","eduction":"testing",
    "employmentstatus":"testing","salary":"90000","companyname":"3232","corporatecompanysponsored":"3232","designation":"testing"},
    {"id":19,"fullname":"Subash S","email":"subash@gmail.com","phone":"8765434567","gender":"female","dob":"2023-08-30",
    "password":"123","address":"ddwwwwwwwwwwwwww","postalcode":"eeeeeeeeeeee","race":"Indian","eduction":"testing",
    "employmentstatus":"testing","salary":"testing","companyname":"3232","corporatecompanysponsored":"3232","designation":"testing"},
    {"id":39,"fullname":"impulz","email":"impulz@gmail.com","phone":"987654323","gender":"male","dob":"2023-09-15","password":"123",
    "address":"chennai","postalcode":"600019","race":"indian","eduction":"BE","employmentstatus":"developer","salary":"12000",
    "companyname":"lider","corporatecompanysponsored":"lider tech","designation":"devop"}]`,
    errorResponse: "Error Message",
  },
  {
    tableName: "Get student",
    endPoint: "/student/{id}",
    method: "GET",
    parameters: "student_id",
    successResponse: `{"id":4,"fullname":"ram","email":"krishna@gmail.com","phone":"9876543211","gender":"male",
    "dob":"2023-09-15","password":"123@lider","address":"chennai","postalcode":"600000","race":"Indian","eduction":"testing",
    "employmentstatus":"testing","salary":"90000","companyname":"lider Corporation","corporatecompanysponsored":"lider",
    "designation":"Software Developer"}]`,
    errorResponse: `{
      "status": 401,
      "message": "Authentication failed. Please check your username and password."
    }`,
  },
 
];




const Api = () => {
  return (
    <div className="responsive " style={{ maxWidth: '1000px' }}>
      <h2 className="mt-2" style={{  color: '#000' }} >API Documentation</h2>
      <div style={{ display: 'flex' ,  justifyContent: 'space-between'}}>
    <div style={{marginTop:'20px' }}  >
        <h4>Credentials URL : <span style={{ fontWeight: 'lighter'}} > 'http://ec2-3-111-200-106.ap-south-1.compute.amazonaws.com:8080/' </span> </h4>
        <h4>Headers: </h4>
        <h4 style={{marginLeft:'100px'}}>Content-typ:- <span style={{ fontWeight: 'lighter'}}>  Application/json</span></h4>
        <h4 style={{marginLeft:'100px'}}>Authorization :<span style={{ fontWeight: 'lighter'}}> JWT</span></h4>
        


    </div>
    
</div>




<br />
<div className="table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <h2>APIs</h2>
     
       
        <table className="table table-bordered table-striped">
            <thead className="thead-dark" style={{ position: 'sticky', top: '0' }}>
            <tr className='table-primary'>
              <th>Table Name</th>
              <th>Endpoint</th>
              <th>Method</th>
              <th>Parameters</th>
              <th>Success Response</th>
              <th>Error Response</th>
            </tr>
          </thead>
          <tbody>
            {apiData.map((api, index) => (
              <tr key={index}>
                <td>{api.tableName}</td>
                <td>{api.endPoint}</td>
                <td>{api.method}</td>
                <td>{api.parameters}</td>
                <td>
                  <pre>{api.successResponse}</pre>
                </td>
                <td>
                  <pre>{api.errorResponse}</pre>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};




const Activity = () => {
  return (
    <div>
   
 
<Api />
    </div>
  );
};




export default Activity;













