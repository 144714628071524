import React, { useState, useEffect } from 'react';
import axios from '../../Axiosinstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsPencilFill } from 'react-icons/bs';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast, ToastContainer } from 'react-toastify';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,

  // other necessary components...
} from '@mui/material';






const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'type', label: 'Type', minWidth: 100 },
  { id: 'calculator', label: 'Calculation Mode', minWidth: 170 },
  { id: 'value', label: 'Value', minWidth: 170 },
  { id: 'actions', label: 'Action', minWidth: 170 },
];








const ResponsiveTable = () => {
  const [editableItemId, setEditableItemId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [facilitators, setFacilitators] = useState([]);
  const [filteredFacilitators, setFilteredFacilitators] = useState([]);
  const [pageSize, setPageSize] = useState(10);








  const fetchData = () => {
    axios
      .get('invoicesettings')
      .then((response) => {
        const data = response.data;
        setTableData(data);
        setFilteredData(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };








  useEffect(() => {
    fetchData();
  }, []);








  const handleEdit = (itemId) => {
    const item = tableData.find((item) => item.id === itemId);
    setEditedData(item);
    setEditableItemId(itemId); // Set the ID of the item being edited
    setOpenDialog(true);
  };








  const handleSave = async () => {
    try {
      const response = await axios.put(`invoicesettings/${editableItemId}`, editedData);

      if (response.status === 200) {
        const updatedTableData = tableData.map(item => {
          if (item.id === editableItemId) {
            return { ...item, ...editedData };
          }
          return item;
        });

        setTableData(updatedTableData);
        setFilteredData(updatedTableData);
        setOpenDialog(false);
        setEditableItemId(null);
        setEditedData({});
        toast.success('Invoice saved successfully');
      } else {
        console.error('Failed to update invoice settings');
      }
    } catch (error) {
      console.error('Error updating invoice settings:', error);
    }
  };








  const handleDelete = async (id) => {
    try {
      const confirmation = window.confirm('Are you sure you want to delete this ID?');








      if (confirmation) {
        const response = await axios.delete(`invoicesettings/${id}`);








        if (response.status === 200) {
          const updatedTableData = tableData.filter(item => item.id !== id);
          setTableData(updatedTableData);
          setFilteredData(updatedTableData);








          toast.success('Invoice deleted successfully');
        } else {
          console.error('Failed to delete invoice settings');
        }
      }
    } catch (error) {
      console.error('Error deleting invoice settings:', error);
    }
  };
  useEffect(() => {
    const filtered = tableData.filter((row) =>
      row.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, tableData, currentPage, pageSize]);




  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };




  const [errors, setErrors] = useState({
    name: false,
    type: false,
    calculator: false,
    value: false,
    // other fields...
  });




  const handleFieldChange = (field, value) => {
    setEditedData({ ...editedData, [field]: value });
    setErrors({ ...errors, [field]: value === '' });
  };




  return (
    <div>
      <div className="containerd" >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearch}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              float: 'left',
              fontSize: '12px',
              marginTop: '2%'


            }}
          />
          <TableContainer sx={{ maxHeight: 'none' }}>
            <Table aria-label="sticky table">
              <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>

                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align="left"
                      style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.calculator}</TableCell>
                      <TableCell>{row.value}</TableCell>
                      <TableCell>
                        <BsPencilFill
                          style={{ marginRight: 10 }}
                          onClick={() => handleEdit(row.id, null)}
                        />
                        <DeleteRoundedIcon
                          style={{ color: 'red', marginLeft: 10 }}
                          onClick={() => handleDelete(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={filteredData.length}
            rowsPerPage={itemsPerPage}
            page={currentPage - 1}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={event => {
              setItemsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(1);
            }}
          />
        </Paper>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Edit Data</DialogTitle>
        <DialogContent>
          {/* Fields for editing */}
          <TextField
            label="Name"
            value={editedData.name}
            onChange={(e) => handleFieldChange('name', e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            error={errors.name}
            helperText={errors.name ? 'Name cannot be empty' : ''}
          />
          <FormControl fullWidth className="form-group">
            <InputLabel id="invoiceTypeLabel">Type:</InputLabel>
            <Select
              labelId="invoiceTypeLabel"
              id="invoiceType"
              value={editedData.type || ''}
              onChange={(e) => setEditedData({ ...editedData, type: e.target.value })}
              label="Type"
            >
              <MenuItem value="" disabled>
                Select Type
              </MenuItem>
              <MenuItem value="add">Add</MenuItem>
              <MenuItem value="deduct">Deduct</MenuItem>
            </Select>
          </FormControl>









          <FormControl fullWidth className="form-group">
            <InputLabel id="invoiceCalculatorLabel">Calculator:</InputLabel>
            <Select
              labelId="invoiceCalculatorLabel"
              id="invoiceCalculator"
              value={editedData.calculator || ''}
              onChange={(e) => setEditedData({ ...editedData, calculator: e.target.value })}
              label="Calculator"
            >
              <MenuItem value="" disabled>
                Select Calculator
              </MenuItem>
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="fixed">Fixed</MenuItem>
            </Select>
          </FormControl>
















          <TextField
            label="Value"
            value={editedData.value}
            onChange={(e) => handleFieldChange('value', e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            error={errors.value}
            helperText={errors.value ? 'Value cannot be empty' : ''}
          />
          {/* Add other fields for editing */}
          {/* ... */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>




      </Dialog>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />




    </div>
  );
};








export default ResponsiveTable;



