import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import axios from '../../Axiosinstance';
import {
 
  Button
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';


const InvoiceForm = () => {
  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceStart, setInvoiceStart] = useState('');
  const [paymentTerms, setPaymentTerms] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [invoiceNoError, setInvoiceNoError] = useState('');
  const [invoiceStartError, setInvoiceStartError] = useState('');
  const [paymentTermsError, setPaymentTermsError] = useState('');
  const [editorContentError, setEditorContentError] = useState('');


  const handleInvoiceNoChange = (e) => {
    setInvoiceNo(e.target.value);
    setInvoiceNoError('');
  };




  const handleInvoiceStartChange = (e) => {
    setInvoiceStart(e.target.value);
    setInvoiceStartError('');
  };




  const handlePaymentTermsChange = (e) => {
    setPaymentTerms(e.target.value);
    setPaymentTermsError('');
  };




  const handleEditorChange = (content) => {
    setEditorContent(content);
    setEditorContentError('');
  };




  const handleSave = () => {
    // Prepare the data to be sent
    const formData = {
      invoiceNo: invoiceNo,
      invoiceStart: invoiceStart,
      paymentTerms: paymentTerms,
      editorContent: editorContent,
    };
    setInvoiceNoError('');
    setInvoiceStartError('');
    setPaymentTermsError('');
    setEditorContentError('');


    let isValid = true;




    // Validate invoiceNo
    if (invoiceNo.trim() === '') {
      setInvoiceNoError('Invoice Prefix is required');
      isValid = false;
    }


    // Validate invoiceStart
    if (invoiceStart.trim() === '') {
      setInvoiceStartError('Invoice Starting No is required');
      isValid = false;
    }


    // Validate paymentTerms
    if (paymentTerms.trim() === '') {
      setPaymentTermsError('Payment Terms is required');
      isValid = false;
    }


    // Validate editorContent
    if (editorContent.trim() === '') {
      setEditorContentError('Instruction is required');
      isValid = false;
    }


    if (isValid) {
      // Prepare the data to be sent
      const formData = {
        invoiceNo: invoiceNo,
        invoiceStart: invoiceStart,
        paymentTerms: paymentTerms,
        editorContent: editorContent,
      };


      axios
        .post('invoicebasic', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.log('Data successfully posted:', response.data);
          // Reset the form or perform any other action
          setInvoiceNo('');
          setInvoiceStart('');
          setPaymentTerms('');
          setEditorContent('');
          toast.success('Particular updated successfully');


        })
        .catch((error) => {
          console.error('Error posting data:', error);
        });
    }
  };




  return (


    <div className="container-fluid" style={{  borderRadius: '5px' ,
    padding: "20px 20px",
    border: "1px solid #ddd", backgroundColor: "#f9f9f9", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",  background: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}}>
   


      <div className="container-fluid">
      <h5>Invoice Basic Settings</h5>
      <div className="row">
        <div className="col-md-6">
          <form>
            <div className="form-group">
              <label htmlFor="invoiceNo">Invoice Basic Prefix:</label>
              <input
                type="text"
                id="invoiceNo"
                className={`form-control ${invoiceNoError ? 'is-invalid' : ''}`}
                placeholder="EX-INVOICE2020"
                value={invoiceNo}
                onChange={handleInvoiceNoChange}
                required
              />
              {invoiceNoError && <div className="invalid-feedback">{invoiceNoError}</div>}
            </div>


            <div className="form-group">
              <label htmlFor="invoiceStart">Invoice Starting No:</label>
              <input
                type="number"
                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                id="invoiceStart"
                className={`form-control ${invoiceStartError ? 'is-invalid' : ''}`}
                placeholder="EX-1001"
                value={invoiceStart}
                onChange={handleInvoiceStartChange}
                required
              />
              {invoiceStartError && <div className="invalid-feedback">{invoiceStartError}</div>}
            </div>


            <div className="form-group">
              <label htmlFor="invoice">Payment Terms</label>
              <input
                type="text"
                id="Pay"
                className={`form-control ${paymentTermsError ? 'is-invalid' : ''}`}
                placeholder="Cash Cheque"
                value={paymentTerms}
                onChange={handlePaymentTermsChange}
                required
              />
              {paymentTermsError && <div className="invalid-feedback">{paymentTermsError}</div>}
            </div>
          </form>
        </div>


        <div className="col-md-6">
          <div className="instructions-container">
            <h6>Instruction (Shown at bottom of the Page)</h6>
            <ReactQuill
              required
              value={editorContent}
              onChange={handleEditorChange}
              placeholder="Enter your text"
            />
            {editorContentError && <div style={{ color: 'red' }}>{editorContentError}</div>}
          </div>
         
        </div>
     
     
      </div>
     
        <Button
          variant="contained"
    color="primary"
          onClick={handleSave}
         
        >
          Save
        </Button>
   
    </div>


     
     
    </div>
  );
};




export default InvoiceForm;













