import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
import { Form, Button } from 'react-bootstrap';
import './N.css';
import axios from '../Axiosinstance';


const EditVenue = () => {
  const { venueId } = useParams();
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    roomno: '',
    unit: '',
    block: '',
    floor: '',
    street: '',
    building: '',
    postalcode: '',
    wheelchair: false,
  });


  useEffect(() => {
    fetchVenueDetails(venueId);
  }, [venueId]);


  const fetchVenueDetails = async (venueId) => {
    try {
      const response = await axios.get(`venue/${venueId}`);

      if (response.status === 200) {
        const venueData = response.data;
        // Convert the 'wheelchair' value to boolean
        venueData.wheelchair = venueData.wheelchair === 'yes';
        setFormData(venueData);
      } else {
        console.error('Failed to fetch venue details:', response.status);
      }
    } catch (error) {
      console.error('Error while fetching venue details:', error);
    }
  };



  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const { wheelchair, ...formDataWithoutWheelchair } = formData;
      const wheelchairValue = wheelchair ? 'yes' : 'no';

      const response = await axios.put(`venue/${venueId}`, {
        ...formDataWithoutWheelchair,
        wheelchair: wheelchairValue,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        navigate('/Venuelink');
      } else {
        console.error('Failed to update venue details:', response.status);
      }
    } catch (error) {
      console.error('Error while updating venue details:', error);
    }
  };


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: fieldValue,
    }));
  };


  const [isFormDirty, setIsFormDirty] = useState(false);




  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };


    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);



  return (
    <div>
      <Header />
      <div className="container" style={{ maxWidth: '1000px' }}>

        <h3>Edit Venue</h3>


        <Form onSubmit={handleSubmit} onChange={handleInputChange}>
          <Form.Group controlId="roomNo">
            <Form.Label>Room No</Form.Label>
            <Form.Control
              type="text"
              name="roomno"
              value={formData.roomno}
              onChange={handleChange}
              placeholder="Enter Room No"
              required
            />
          </Form.Group>


          <Form.Group controlId="unit">
            <Form.Label>Unit</Form.Label>
            <Form.Control
              type="text"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              placeholder="Enter Unit"
              required
            />
          </Form.Group>


          <Form.Group controlId="block">
            <Form.Label>Block</Form.Label>
            <Form.Control
              type="text"
              name="block"
              value={formData.block}
              onChange={handleChange}
              placeholder="Enter Block"
              required
            />
          </Form.Group>


          <Form.Group controlId="floor">
            <Form.Label>Floor</Form.Label>
            <Form.Control
              type="text"
              name="floor"
              value={formData.floor}
              onChange={handleChange}
              placeholder="Enter Floor"
              required
            />
          </Form.Group>


          <Form.Group controlId="street">
            <Form.Label>Street</Form.Label>
            <Form.Control
              type="text"
              name="street"
              value={formData.street}
              onChange={handleChange}
              placeholder="Enter Street"
              required
            />
          </Form.Group>


          <Form.Group controlId="building">
            <Form.Label>Building</Form.Label>
            <Form.Control
              type="text"
              name="building"
              value={formData.building}
              onChange={handleChange}
              placeholder="Enter Building"
              required
            />
          </Form.Group>


          <Form.Group controlId="postalcode">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              name="postalcode"
              value={formData.postalcode}
              onChange={handleChange}
              placeholder="Enter Postal Code"
              maxLength="5"
              required
            />

          </Form.Group>


          <Form.Group controlId="checkbox">
            <Form.Check
              type="checkbox"
              label="Wheelchair access"
              name="wheelchair"
              checked={formData.wheelchair}
              onChange={handleChange}
            />
          </Form.Group>
          <br />
          <Button variant="primary" type="submit" className="btn-submit">
            Update
          </Button>


          <Button variant="danger" type="button" className="btn-cancel" onClick={() => navigate('/Venuelink')}>
            Cancel
          </Button>
        </Form>
      </div>
    </div>
  );
};


export default EditVenue;





