import React from 'react'

const Meeting = (props) => {
    const { meetingId } = props.match.params;
  return (
    <div>
      {meetingId}
    </div>
  )
}

export default Meeting
