import React, { useState, useEffect } from 'react';
import axios from '../Axiosinstance';
import { Modal, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Header from '../components/Header'
import { useNavigate } from 'react-router-dom';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {baseURLdyte, Password,Username} from '../common'



const EditBatchForm = ({ onFormSubmit }) => {
    const [selectedTrainers, setSelectedTrainers] = useState([]);
    const [selectedAssessors, setSelectedAssessors] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [facilitatorData, setFacilitatorData] = useState(null);
    const { BatchId } = useParams();
    const [searchText, setSearchText] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);




    const navigate = useNavigate();




    const [batchData, setBatchData] = useState({
        batchcode: '',
        course: '',
        threshold: 0,
        intakesize: 0,
        venue: '',
        status: '',




        // ... add other properties as needed
    });
    const [days, setDays] = useState([]);
    // Add these state variables
    const [allSelectedTrainers, setAllSelectedTrainers] = useState([]);
    const [allSelectedAssessors, setAllSelectedAssessors] = useState([]);












    useEffect(() => {
        const fetchBatchData = async () => {
            try {
                const response = await axios.get(`batch/${BatchId}`);
                setBatchData(response.data);
                setDays(response.data.days || []);




                // Update selected trainers and assessors based on fetched data
                setSelectedTrainers(response.data.trainers || []);
                setSelectedAssessors(response.data.assessors || []);
            } catch (error) {
                console.error('Error fetching batch data:', error);
            }
        };




        fetchBatchData();
    }, [BatchId]);








    useEffect(() => {
        axios.get('courses')
            .then(response => setCourses(response.data))
            .catch(error => console.error('Error fetching course data:', error));
    }, []);




    useEffect(() => {
        axios.get('venues')
            .then(response => setVenues(response.data))
            .catch(error => console.error('Error fetching venue data:', error));
    }, []);




    const handleCloseModal = () => {
        setShowModal(false);
    };




    const handleSelectTrainer = (trainer) => {
        setSelectedTrainers((prevTrainers) => {
            const newTrainers = [...prevTrainers, trainer];
            setAllSelectedTrainers(newTrainers);
            setBatchData((prevData) => ({
                ...prevData,
                trainers: newTrainers,
            }));
            return newTrainers;
        });
    };




    const handleSelectAssessor = (assessor) => {
        setSelectedAssessors((prevAssessors) => {
            const newAssessors = [...prevAssessors, assessor];
            setAllSelectedAssessors(newAssessors);
            setBatchData((prevData) => ({
                ...prevData,
                assessors: newAssessors,
            }));
            return newAssessors;
        });
    };
















    const handleDeleteTrainer = (trainerToRemove) => {
        setSelectedTrainers((prevTrainers) =>
            prevTrainers.filter((trainer) => trainer !== trainerToRemove)
        );




        // Update batchData to remove the trainer
        setBatchData((prevData) => ({
            ...prevData,
            trainers: prevData.trainers.filter((trainer) => trainer !== trainerToRemove),
        }));




        // Mark the form as modified when deleting a trainer
        setFormModified(true);
    };




    const handleDeleteAssessor = (assessorToRemove) => {
        setSelectedAssessors((prevAssessors) =>
            prevAssessors.filter((assessor) => assessor !== assessorToRemove)
        );




        // Update batchData to remove the assessor
        setBatchData((prevData) => ({
            ...prevData,
            assessors: prevData.assessors.filter((assessor) => assessor !== assessorToRemove),
        }));




        // Mark the form as modified when deleting an assessor
        setFormModified(true);
    };
















    const fetchFacilitatorDetails = () => {
        axios.get('facilitators')
            .then(response => setFacilitatorData(response.data))
            .catch(error => console.error('Error fetching facilitator data:', error));
    };




    useEffect(() => {
        fetchFacilitatorDetails();
    }, []);




    const handleAddTrainer = () => {
        fetchFacilitatorDetails();
        setShowModal(true);
    };




    const handleAddAssessor = () => {
        fetchFacilitatorDetails();
        setShowModal(true);
    };




    const [formModified, setFormModified] = useState(false);




    useEffect(() => {
        const handleFormModification = () => {
            if (!formModified) {
                setFormModified(true);
            }
        };




        window.addEventListener('input', handleFormModification);
        return () => {
            window.removeEventListener('input', handleFormModification);
        };
    }, [formModified]);




    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (formModified) {
                event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
            }
        };
        window.onbeforeunload = handleBeforeUnload;
        return () => {
            window.onbeforeunload = null;
        };
    }, [formModified]);




    const [courses, setCourses] = useState([]);
    const [venues, setVenues] = useState([]);












    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get('courses');
                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching courses:', error);
            }
        };




        const fetchVenues = async () => {
            try {
                const response = await axios.get('venues');
                setVenues(response.data);
            } catch (error) {
                console.error('Error fetching venues:', error);
            }
        };




        fetchCourses();
        fetchVenues();
    }, []);




    const handleInputChange = (e, dayIndex) => {
        const { name, value, type, checked } = e.target;




        if (name === 'course') {
            const selectedCourse = courses.find((course) => course.name === value);
            setBatchData((prevData) => ({
                ...prevData,
                course: value,
                courseId: selectedCourse ? selectedCourse.id : '',
            }));
        } else if (type === 'checkbox' && name === 'assessment') {
            setBatchData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else if (type === 'date' && name === 'batchDate') {
            // Validate maximum age (e.g., 50 years)
            const currentDate = new Date();
            const selectedDate = new Date(value);
            const maxAgeDate = new Date();
            maxAgeDate.setFullYear(maxAgeDate.getFullYear() - 50);




            if (selectedDate > currentDate || selectedDate < maxAgeDate) {
                // Handle validation error, for example, show an error message
                console.error('Invalid date. Please select a date within the last 50 years.');
                // Optionally, you can set an error state to display an error message in the UI
            } else {
                // Update batchData if the date is valid
                setBatchData((prevData) => ({
                    ...prevData,
                    days: prevData.days.map((day, index) =>
                        index === dayIndex ? { ...day, batchDate: value } : day
                    ),
                }));
            }
        } else {
            setBatchData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };








    const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState(false);




    const handleUpdate = async () => {
        setShowUpdateConfirmationModal(true);
    };

    const handleConfirmUpdate = async () => {

        try {
            if (

                !batchData.batchcode ||
                !batchData.course ||
                // !batchData.attendanceqrlink ||
                !batchData.onlineclasslink ||
                !batchData.threshold ||
                !batchData.intakesize ||
                !batchData.venue ||
                !batchData.status



            ) {
                toast.error('Please fill in all required fields.');
                return;
            }

            const updatedBatchData = {
                ...batchData,
                trainers: selectedTrainers,
                assessors: selectedAssessors,
                days: days,
            };

            const response = await axios.put(`batch/${BatchId}`, updatedBatchData);

            if (response.status === 200) {
                console.log('Batch data updated successfully!');
                navigate('/Batches');
                onFormSubmit();
            } else {
                console.error('Failed to update batch data.');
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setShowUpdateConfirmationModal(false);
        }
    };

    const handleCancelUpdate = () => {
        setShowUpdateConfirmationModal(false);
    };








    const handleAddDay = () => {
        setDays((prevDays) => [
            ...prevDays,
            {
                batchDate: '',
                morningFrom: { 1: '' },
                morningTo: { 1: '' },
                eveningFrom: { 1: '' },
                eveningTo: { 1: '' },
                assessment: false,
            },
        ]);
    };








    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [dayIndexToRemove, setDayIndexToRemove] = useState(null);

    const handleRemoveDay = (dayIndex) => {
        setDayIndexToRemove(dayIndex);
        setShowConfirmationModal(true);
    };

    const confirmRemoveDay = () => {
        setDays((prevDays) => prevDays.filter((day, index) => index !== dayIndexToRemove));
        setFormModified(true);
        setShowConfirmationModal(false);
        toast.success('Day removed successfully!', {
            position: 'bottom-right',
        });
    };

    const cancelRemoveDay = () => {
        setShowConfirmationModal(false);
    };




    const handleDateChange = (dayIndex, value) => {
        setDays((prevDays) =>
            prevDays.map((day, index) =>
                index === dayIndex ? { ...day, batchDate: value } : day
            )
        );
        setFormModified(true); // Mark the form as modified when changing a date
    };




    // Define the handleTimeChange function
    const handleTimeChange = (dayIndex, timeType, timeIndex, value) => {
        setDays((prevDays) => {
            const updatedDays = [...prevDays];
            let selectedDay = updatedDays[dayIndex];




            // Ensure selectedDay is initialized
            if (!selectedDay) {
                selectedDay = {
                    batchDate: '',
                    morningFrom: {},
                    morningTo: {},
                    eveningFrom: {},
                    eveningTo: {},
                    assessment: false,
                };
            }




            // Ensure selectedDay[timeType] is initialized
            if (!selectedDay[timeType]) {
                selectedDay[timeType] = {};
            }




            // Remove the index if the value is empty
            if (!value) {
                delete selectedDay[timeType][timeIndex];
            } else {
                // Update the specific time field based on the type (morningFrom, morningTo, eveningFrom, eveningTo)
                selectedDay[timeType][timeIndex] = value;
            }




            // Update the state with the modified days array
            updatedDays[dayIndex] = selectedDay;
            return updatedDays;
        });




        // Mark the form as modified when changing time
        setFormModified(true);
    };




    const handleCheckboxChange = (dayIndex) => {
        setDays((prevDays) => {
            const updatedDays = [...prevDays];
            updatedDays[dayIndex] = {
                ...updatedDays[dayIndex],
                assessment: !updatedDays[dayIndex].assessment,
            };
            setFormModified(true); // Mark the form as modified when changing the checkbox
            return updatedDays;
        });
    };




    const isValidDate = (dateString) => {
        const selectedDate = new Date(dateString);
        const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
        const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
        return selectedDate >= minDate && selectedDate <= maxDate;
    };




    const handleCheckboxChange1 = (e) => {
        const value = e.target.checked ? "yes" : "no"; // Use "yes" or "no" based on your database values
        setBatchData({ ...batchData, esignrequired: value });
    }








    const [meetingDetails, setMeetingDetails] = useState({});
    const [meetingLink, setMeetingLink] = useState('');
    const username =  Username;
    const password = Password;
    const baseURL = baseURLdyte;
    const [showMeetingModal, setShowMeetingModal] = useState(false);
    const [meetingTitle, setMeetingTitle] = useState('');
  
    const handleShowMeetingModal = () => {
      setShowMeetingModal(true);
    };
  
    const handleCloseMeetingModal = () => {
      setShowMeetingModal(false);
    };
  
    const handleMeetingTitleChange = (e) => {
      setMeetingTitle(e.target.value);
    };
  
    const handleCreateMeeting = async () => {
        try {
          const response = await axios.post(
            `${baseURL}/meetings`,
            {
              title: meetingTitle,
              preferred_region: 'ap-south-1',
              record_on_start: false,
              live_stream_on_start: false,
              recording_config: {
                max_seconds: 60,
                file_name_prefix: 'string',
                video_config: {
                  codec: 'H264',
                  width: 1280,
                  height: 720,
                  watermark: {
                    url: 'http://example.com',
                    size: { width: 1, height: 1 },
                    position: 'left top',
                  },
                },
                audio_config: { codec: 'AAC', channel: 'stereo' },
                storage_config: {
                  type: 'aws',
                  access_key: 'string',
                  secret: 'string',
                  bucket: 'string',
                  region: 'us-east-1',
                  path: 'string',
                  auth_method: 'KEY',
                  username: 'string',
                  password: 'string',
                  host: 'string',
                  port: 0,
                  private_key: 'string',
                },
                dyte_bucket_config: { enabled: true },
                live_streaming_config: { rtmp_url: 'rtmp://a.rtmp.youtube.com/live2' },
              },
            },
            {
              auth: {
                username: username,
                password: password,
              },
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          if (response && response.data) {
            // Extract the meeting ID from the response
            const meetingId = response.data.data.id;
      
            // Omit participant addition in this part of the code
      
            // Construct the complete meeting link using base link, id, and authToken
            const completeMeetingLink = `${baseURL}/meeting?id=${meetingId}`;
      
            // Update batchData with the meeting link
            setBatchData((prevData) => ({
              ...prevData,
              onlineclasslink: completeMeetingLink,
            }));
      
            // Close the meeting modal
            handleCloseMeetingModal();
          } else {
            console.error('Invalid response from Dyte API');
          }
        } catch (error) {
          console.error('Error creating meeting:', error);
        }
      };



    const containerStyle = {
        backgroundColor: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        maxWidth: '800px', // Adjust the max-width as needed
        margin: 'auto', // Center the container
        marginTop: '20px', // Add top margin for spacing
    };



    return (
        <div className="batch-form-container">
            <Form onSubmit={handleUpdate} className="row" style={containerStyle} >
                <h2>Edit Batch  (Newton International College)</h2>
                <br />
                <br />
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="batchcode">Batch Code:</label>
                        <input
                            type="text"
                            className="form-control"
                            name="batchcode"
                            value={batchData.batchcode}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                            // maxLength={20} // Set the maximum character length to 20
                            // title="20 characters only" // Tooltip message
                        />
                    </div>


                    <div className="form-group">
                        <label htmlFor="course">Course:</label>
                        <select
                            name="course"
                            value={batchData.course}
                            className="form-control"
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>Select a course</option>
                            {courses.map((course) => (
                                <option key={course.id} value={`${course.name}(${course.id})`}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>




                    {/* <div className="form-group">
                        <label htmlFor=" attendanceqrlink" >Attendance Link:</label>
                        <input
                            type="text"
                            className="form-control"
                            name="attendanceqrlink"
                            value={batchData.attendanceqrlink}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}




                        />
                    </div> */}
                    <div className="form-group">
                        <label>Online Class Link:</label>
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                name="onlineclasslink"
                                value={batchData.onlineclasslink}
                                onChange={handleInputChange}




                                placeholder="Enter online class link"
                                style={{ height: '42px', borderRadius: '8px 0 0 6px', border: '1px solid #ced4da' }}
                                required
                            />
                            <button
                                type="button"
                                variant="outline-secondary"
                                onClick={handleShowMeetingModal}
                                style={{
                                    background: "rgb(30, 144, 255)",
                                    color: "white",
                                    border: "none",
                                    borderRadius: '0 8px 8px 0',
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                }}
                            >
                                +
                            </button>
                        </div>
                    </div>
                    <Modal show={showMeetingModal} onHide={handleCloseMeetingModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enter Meeting Title</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control
                                type="text"
                                placeholder="Enter meeting title"
                                value={meetingTitle}
                                onChange={handleMeetingTitleChange}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseMeetingModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleCreateMeeting}>
                                Create
                            </Button>
                        </Modal.Footer>
                    </Modal>




                    <br />








                    <div className="form-group">
                        <label>
                            E_SignRequired:
                            <input
                                type="checkbox"
                                name="esignrequired"
                                checked={batchData.esignrequired === "yes"} // Use "yes" or any other value you want to represent true
                                onChange={handleCheckboxChange1}
                                required
                            />
                        </label>
                    </div>
                    <br />




                </div>
















                <div className="col-md-6">
                    <div className="form-group">
                        <label>Threshold:</label>
                        <input
                            type="number"
                            className="form-control"
                            name="threshold"
                            value={batchData.threshold === 0 ? '' : batchData.threshold}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                            title="Only 10 numbers allowed"
                            onKeyDown={(e) => {
                                // Restrict the input to only allow up to 10 digits
                                if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>


                    <div className="form-group">
                        <label>Intake Size:</label>
                        <input
                            type="number"
                            className="form-control"
                            name="intakesize"
                            value={batchData.intakesize === 0 ? '' : batchData.intakesize}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                            title="Only 10 numbers allowed"
                            onKeyDown={(e) => {
                                // Restrict the input to only allow up to 10 digits
                                if (e.target.value.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                                    e.preventDefault();
                                }
                            }}
                        />
                    </div>






                    <div className="form-group">
                        <label>Venue:</label>
                        <select
                            name="venue"
                            className="form-control"
                            value={batchData.venue}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                        >
                            <option value="" disabled>Select a venue</option>
                            {venues.map((venue) => (
                                <option key={venue.id} value={venue.roomno}>
                                    {venue.roomno}
                                </option>
                            ))}
                        </select>
                    </div>




                    <div className="form-group">
                        <label>Status:</label>
                        <select
                            className="form-control"
                            name="status"
                            value={batchData.status}
                            style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="completed">Completed</option>
                            <option value="cancel">Cancel</option>
                        </select>












                    </div>
                    <div style={{}}>
                        <Button
                            type="button"
                            variant="btn-primary "
                            onClick={() => setShowModal(true)}
                            style={{
                                backgroundColor: '1E90OFF',
                                color: 'white', marginBottom: '-10%', fontSize: '13px'
                            }}>




                            <FaPlus /> Add Trainer
                        </Button>




















                    </div>
                </div>
















                <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Trainer/Assessor Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row align-items-center">
                            <div className="col-md-4">
                                <div className="input-group mb-3" >








                                    <span className="input-group-text" >Search</span>
                                    <input
                                        type="text"
                                        id="search"
                                        className="form-control"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ height: " 38px " }}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="input-group mb-3">
                                    <span className="input-group-text">Show</span>
                                    <select
                                        id="itemsPerPage"
                                        className="form-control"
                                        value={itemsPerPage}
                                        onChange={(e) => setItemsPerPage(Number(e.target.value))}
                                        style={{ height: " 38px " }}
                                    >
                                        <option value={5}>5</option>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                    </select>
                                </div>
                            </div>
                        </div>




                        {facilitatorData &&
                            facilitatorData.length > 0 ? (
                            <>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>NRIC</th>
                                            <th>Add Trainer</th>
                                            <th>Add Assessor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facilitatorData
                                            .filter(
                                                (facilitator) =>
                                                    facilitator.name.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    facilitator.email.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    facilitator.nric.toLowerCase().includes(searchText.toLowerCase())
                                            )
                                            .slice(
                                                (currentPage - 1) * itemsPerPage,
                                                currentPage * itemsPerPage
                                            )
                                            .map((facilitator) => (
                                                <tr key={facilitator.id}>
                                                    <td>{facilitator.name}</td>
                                                    <td>{facilitator.email}</td>
                                                    <td>{facilitator.nric}</td>
                                                    <td>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                handleSelectTrainer(
                                                                    facilitator.name +
                                                                    '(' +
                                                                    facilitator.id +
                                                                    ')'
                                                                )
                                                            }
                                                            disabled={selectedTrainers.includes(
                                                                facilitator.name + '(' + facilitator.id + ')'
                                                            )} style={{ width: '85%' }}
                                                        >
                                                            <FaPlus /> Add
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() =>
                                                                handleSelectAssessor(
                                                                    facilitator.name +
                                                                    '(' +
                                                                    facilitator.id +
                                                                    ')'
                                                                )
                                                            }
                                                            disabled={selectedAssessors.includes(
                                                                facilitator.name + '(' + facilitator.id + ')')
                                                            }
                                                        >
                                                            <FaPlus />  Add Assessor
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>




                                </table>








                            </>
                        ) : (
                            <p></p>
                        )}




                    </Modal.Body>
                </Modal>
                <div>
                    {batchData && batchData.trainers && batchData.trainers.length > 0 && (




                        <div>
                            <br />
                            <h4>Selected Trainers:</h4>
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                                {batchData.trainers.map((trainer, index) => {
                                    const match = trainer.match();
                                    const trainerName = match ? trainer.replace(match[0], '').trim() : trainer;
                                    const trainerName1 = trainerName.match(/^[^\d()]*/)[0].trim();




                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                margin: "10px 0",
                                                padding: "10px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div>
                                                {index + 1}. {trainerName1}
                                            </div>
                                            {trainerName && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteTrainer(trainer)}
                                                    style={{
                                                        background: "red",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        padding: "5px 10px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}




                    {batchData && batchData.assessors && batchData.assessors.length > 0 && (
                        <div>
                            <h4>Selected Assessors:</h4>
                            <ul style={{ listStyleType: "none", padding: 0 }}>
                                {batchData.assessors.map((assessor, index) => {
                                    const match = assessor.match();
                                    const assessorName = match ? assessor.replace(match[0], '').trim() : assessor;
                                    const assessorName1 = assessorName.match(/^[^\d()]*/)[0].trim();



                                    return (
                                        <li
                                            key={index}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                margin: "10px 0",
                                                padding: "10px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <div>
                                                {index + 1}. {assessorName1}
                                            </div>
                                            {assessorName && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleDeleteAssessor(assessor)}
                                                    style={{
                                                        background: "red",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "5px",
                                                        padding: "5px 10px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                            <p>
                                Total Selected Facilitators: {batchData.trainers.length + batchData.assessors.length}
                            </p>
                        </div>
                    )}
                    <div>




                        <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                            {days.map((day, dayIndex) => (
                                <div key={dayIndex} className="day-container">
                                    <h3>Day {dayIndex + 1}</h3>




                                    <div className="form">
                                        <label>Date:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={day.batchDate || ''}
                                            onChange={(e) => handleDateChange(dayIndex, e.target.value)}
                                            min={(new Date(new Date().setFullYear(new Date().getFullYear() - 2))).toISOString().split('T')[0]}
                                            max={(new Date(new Date().setFullYear(new Date().getFullYear() + 2))).toISOString().split('T')[0]}
                                        />
                                        {day.batchDate && !isValidDate(day.batchDate) && (
                                            <p style={{ color: 'red' }}>Please select a date within the allowed range.</p>
                                        )}
                                    </div>




                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Morning From:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={(day && day.morningFrom && day.morningFrom[dayIndex + 1]) || ''}
                                                    onChange={(e) =>
                                                        handleTimeChange(dayIndex, 'morningFrom', dayIndex + 1, e.target.value)
                                                    }
                                                />
                                            </div>




                                            <div className="form-group">
                                                <label>Morning To:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={day && day.morningTo && day.morningTo[dayIndex + 1] || ''}
                                                    onChange={(e) =>
                                                        handleTimeChange(dayIndex, 'morningTo', dayIndex + 1, e.target.value)
                                                    }
                                                />
                                            </div>
                                        </div>




                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Evening From:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={day && day.eveningFrom && day.eveningFrom[dayIndex + 1] || ''}
                                                    onChange={(e) =>
                                                        handleTimeChange(dayIndex, 'eveningFrom', dayIndex + 1, e.target.value)
                                                    }
                                                />
                                            </div>




                                            <div className="form-group">
                                                <label>Evening To:</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={day && day.eveningTo && day.eveningTo[dayIndex + 1] || ''}
                                                    onChange={(e) =>
                                                        handleTimeChange(dayIndex, 'eveningTo', dayIndex + 1, e.target.value)
                                                    }
                                                />
                                            </div>




                                            <br />
                                            <div className="form-group">
                                                <label>Assessment:</label>
                                                <input
                                                    type="checkbox"
                                                    checked={day.assessment}
                                                    onChange={() => handleCheckboxChange(dayIndex)}
                                                />








                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        type="button"
                                        variant="dangr"
                                        onClick={() => handleRemoveDay(dayIndex)}
                                        style={{ marginTop: '-4%' }}
                                    >
                                        <FaMinus />   Remove Day
                                    </Button>




                                </div>
                            ))}

                        </div>




                    </div>




                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button type="button" variant="btn-primary " onClick={handleAddDay} style={{ backgroundColor: '1E90OFF', color: 'white', maxWidth: '15.5%', fontSize: '15px' }}>
                        <FaPlus />   Add Day
                    </Button>




                    <Button type="button" variant="primary " onClick={handleUpdate} style={{ maxWidth: '15.5%', backgroundColor: 'green', fontSize: '15px' }}>Submit</Button>




                    <Button variant="dager" type="button" className="btn-cacel" style={{ maxWidth: '15.5%', backgroundColor: 'red', fontSize: '15px' }}>
                        <a href='/Batches' style={{ color: 'white', textDecoration: 'none' }}>  Cancel</a>
                    </Button>








                </div>




            </Form>
            <Modal show={showConfirmationModal} onHide={cancelRemoveDay} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this day?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={cancelRemoveDay}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmRemoveDay}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showUpdateConfirmationModal} onHide={handleCancelUpdate} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Update Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to update this batch?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancelUpdate}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirmUpdate}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer position="bottom-right" />
        </div>
    );












};




const EditBatch = ({ onFormSubmit }) => {
    return (
        <div>
            <Header />
            <EditBatchForm onFormSubmit={onFormSubmit} />
        </div>
    );
};




export default EditBatch;









