import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from '../Axiosinstance';
import { Modal, Button } from 'react-bootstrap';

const DropdownComponent = ({ events, setEvents,batchess }) => {
  const [facilitators, setFacilitators] = useState([]);
  const [selectedFacilitator, setSelectedFacilitator] = useState('');
  const [selectedBatchDetails, setSelectedBatchDetails] = useState(null);

  useEffect(() => {
    axios.get('facilitators')
      .then(response => setFacilitators(response.data))
      .catch(error => console.error('Error fetching facilitators:', error));

  }, []);

  const handleEventClick = event => {
    const clickedEvent = events.find(e => e.start === event.start && e.end === event.end);
    setSelectedBatchDetails(clickedEvent);
  };

  const handleFacilitatorChange = event => {
    const selectedFacilitatorId = event.target.value;
    setSelectedFacilitator(selectedFacilitatorId);

    const batches = batchess; // Assuming batchess is already defined somewhere

    
    const updatedEvents = [];

    batches.forEach(batch => {
        const trainersAndAssessors = [...batch.trainers, ...batch.assessors];

        trainersAndAssessors.forEach(trainerOrAssessor => {
            const [, id] = trainerOrAssessor.match(/\((\d+)\)/) || [];

            if (id && id === selectedFacilitatorId) {
                batch.days.forEach(day => {
                    const morningStart = day.morningFrom ? Object.values(day.morningFrom)[0] : null;
                    const morningEnd = day.morningTo ? Object.values(day.morningTo)[0] : null;
                    const eveningStart = day.eveningFrom ? Object.values(day.eveningFrom)[0] : null;
                    const eveningEnd = day.eveningTo ? Object.values(day.eveningTo)[0] : null;

                    // Add morning event
                    if (morningStart && morningEnd) {
                        const startDateTime = moment(`${day.batchDate} ${morningStart}`, 'YYYY-MM-DD HH:mm');
                        const endDateTime = moment(`${day.batchDate} ${morningEnd}`, 'YYYY-MM-DD HH:mm');

                        updatedEvents.push({
                            title: `${batch.course.replace(/[^a-zA-Z]/g, '')} (${batch.batchcode}) - Morning`,
                            start: startDateTime.toDate(),
                            end: endDateTime.toDate(),
                            allDay: false,
                            courseId: batch.id,
                        });
                    }

                    // Add evening event
                    if (eveningStart && eveningEnd) {
                        const startDateTime = moment(`${day.batchDate} ${eveningStart}`, 'YYYY-MM-DD HH:mm');
                        const endDateTime = moment(`${day.batchDate} ${eveningEnd}`, 'YYYY-MM-DD HH:mm');

                        updatedEvents.push({
                            title: `${batch.course.replace(/[^a-zA-Z]/g, '')} (${batch.batchcode}) - Evening`,
                            start: startDateTime.toDate(),
                            end: endDateTime.toDate(),
                            allDay: false,
                            courseId: batch.id,
                        });
                    }
                });
            }
        });
    });

    setEvents(updatedEvents);
};

  const calendarStyle = {
    height: 740,
    width: '100%', // Set width to 100% to make it responsive
    backgroundColor: '#F5F5F5',
  };

  return (
    
      <div className="col-md-20" style={{position: 'relative', zIndex: '1', borderRadius: '8px', margin: '10px auto', maxWidth: '1300px'}}>
        <h2 style={{ float: 'left' }}>Trainer/Schedule</h2>
        <br />
        <br />
        <select className="form-select" value={selectedFacilitator} onChange={handleFacilitatorChange}>
          <option value="">Select a facilitator</option>
          {facilitators.map(facilitator => (
            <option key={facilitator.id} value={facilitator.id}>
              {facilitator.name}
            </option>
          ))}
        </select>
        <br />
        <div>
          <div
            className="shadow p-3 mb-5 bg-light rounded"
            style={{ display: 'inline-block', border: '2px solid #3498db', borderRadius: '8px', width: '100%' }} // Set width to 100% to match the container
          >
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={calendarStyle}
              onSelectEvent={handleEventClick}
            />
          </div>
          <Modal show={!!selectedBatchDetails} onHide={() => setSelectedBatchDetails(null)}>
            <Modal.Header closeButton>
              <Modal.Title>Batch Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Title: {selectedBatchDetails?.title}</p>
              <p>Start Time: {moment(selectedBatchDetails?.start).format('MMMM Do YYYY, h:mm:ss a')}</p>
              <p>End Time: {moment(selectedBatchDetails?.end).format('MMMM Do YYYY, h:mm:ss a')}</p>
              {/* Add more details as needed */}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setSelectedBatchDetails(null)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    
  );
};

const localizer = momentLocalizer(moment);

const Batch = ( {batchess}) => {
  const [events, setEvents] = useState([]);

  return (
    <div>
      <DropdownComponent events={events} setEvents={setEvents}  batchess={batchess} />
    </div>
  );
};

export default Batch;
