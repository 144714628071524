import React, { useState, useEffect } from 'react';
import axios from '../Axiosinstance';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableContainer, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { logoImage } from '../common'
import logo from './logo.png'
// import check from '../images/check.jpg'

const Manage = ({ batchDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const { batchId } = useParams();
  const courseId = batchDetails.course.match(/\((\d+)\)/)[1];

  useEffect(() => {

    // Fetch data from the API endpoint using Axios
    axios.get('manages')
      .then(response => {

        // Filter data based on the condition id === batch_id
        const filteredData = response.data.filter(item => item.batch_id === parseInt(batchId));

        // Fetch pc_nyc for each student
        const fetchAssessmentData = async () => {
          const updatedData = await Promise.all(filteredData.map(async (student) => {
            try {
              const assessmentResponse = await axios.get(`/api/assessments/sid/${student.sId}/bid/${student.batch_id}`);
              const pc_nyc = assessmentResponse.data.pc_nyc;
              return { ...student, pc_nyc, assessmentDetails: assessmentResponse.data }; // Merge pc_nyc and assessment details into student object
            } catch (error) {
              console.error('Error fetching assessment data:', error);
              return student; // Return student without pc_nyc and assessment details if error occurs
            }
          }));
          setTableData(updatedData);
        };

        fetchAssessmentData();
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [batchId, courseId]);  // Adding courseId as dependency




  // Function to handle downloading the AR PDF
  const handleDownloadAR = (assessmentDetails, studentName) => {
    // Create a new PDF document
    const doc = new jsPDF();

    // Add logo
    doc.addImage(logo, 'png', 10, 10, 50, 20); // Assuming logoImage is PNG format, adjust width and height as needed

    // Add heading
    doc.setFontSize(18);
    doc.text("Newton International College", 70, 20); // Adjust positioning as needed
    doc.setFontSize(14);
    doc.text("Assessment Summary Record", 70, 30); // Adjust positioning as needed

    // Add basic details
    const basicDetails = [
      ["Skill Code", assessmentDetails.skillCode],
      ["Skill", assessmentDetails.skill],
      ["Name of the Participant", studentName],
      ["NRIC", assessmentDetails.sNric],
      ["Name of the Assessors", assessmentDetails.assessors.join(', ')],
      ["Assessment Date", assessmentDetails.assessmentDate]
    ];

    // Add templates
    const templatesData = assessmentDetails.templates.map(template => {
      const templateInfo = [template.type, template.learningOutcome];
      const criteriaInfo = template.totalCriteria.map(criteria => [
        criteria.criteria,
        criteria.label,
        criteria.c_nyc,
        criteria.evidences
      ]);
      return { templateInfo, criteriaInfo };
    });

    // Add PC/NYC checkboxes
    const pc_nyc = "This participant has been assessed to be:\n ☐ Completed\n ☐ NotCompleted";

    // Add signature lines
    const signatureLines = "By signing, the participant agrees to accept the assessment result\n\nAssessor’s Signature: ________________________________\n\nParticipant’s Signature: ________________________________";

    // Combine all content
    const content = [
      { title: "Details", data: basicDetails },
      { title: "Templates", data: templatesData },
      { title: "Completed/Not Completed", data: [[pc_nyc]] },
      { title: "Signatures", data: [[signatureLines]] }
    ];

    // Add content to PDF
    let startY = 40; // Adjust the startY position to leave space for the logo
    content.forEach(({ title, data }) => {
      doc.setFontSize(12);
      doc.text(title, 10, startY);
      if (title === "Templates") {
        data.forEach(({ templateInfo, criteriaInfo }) => {
          doc.text("Type: " + templateInfo[0] + ", Learning Outcome: " + templateInfo[1], 15, startY + 10);
          doc.autoTable({
            startY: startY + 20,
            head: [["Criteria", "Label", "Completed/Not Completed", "Evidences"]],
            body: criteriaInfo
          });
          startY = doc.autoTable.previous.finalY + 10;
        });
      } else {
        doc.autoTable({
          startY: startY + 10,
          head: [["Description", "Value"]],
          body: data
        });
        startY = doc.autoTable.previous.finalY + 20;
      }
    });

    // Save the PDF with the student's name as the file name
    doc.save(`${studentName} report.pdf`);
  };







  return (
    <div className="container" style={{ maxWidth: "1050px" }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'none' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Student Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Completed/Not Completed</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage)
                .map((row, index) => (
                  <TableRow key={index} align="center">
                    <TableCell>{row.sName}</TableCell>
                    <TableCell>{row.pc_nyc === 'c' ? 'Completed' : row.pc_nyc === 'NYC' ? 'Not Completed' : row.pc_nyc}</TableCell>
                    <TableCell>
                      <div>
                        <button
                          style={{
                            backgroundColor: 'skyblue',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s ease',
                            marginRight: '10px',
                          }}
                        >
                          <a style={{
                            textDecoration: 'none',
                            color: 'white',
                          }} href={`updatear/${courseId}/${batchDetails.id}/${row.id}/${row.sId}`}>Update AR</a>
                        </button>
                        {row.pc_nyc && (
                          <button
                            style={{
                              backgroundColor: 'green',
                              padding: '10px 20px',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                              transition: 'background-color 0.3s ease',
                            }}
                            onClick={() => handleDownloadAR(row.assessmentDetails, row.sName)}
                          >
                            Download AR
                          </button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={dataPerPage}
          labelDisplayedRows={({ from, to }) => `${from}-${to} of ${tableData.length}`}
          page={currentPage - 1}
          onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
          onRowsPerPageChange={(event) => {
            setCurrentPage(1);
            setDataPerPage(event.target.value);
          }}
        />
      </Paper>
    </div>
  );
};

export default Manage;
