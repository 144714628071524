import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Genee from './Set/Genee';
import Blo from './Set/Blo';
import Aa from './Set/Aa';
import Feedback from '../Batchescourserun/Invoiceclick/Feedback';
import Roasr from './Set/Roasr';
import Invoicesett from './Set/Invoicesett';
import Activ from './Set/Activ';
import Paym from './Set/Paym';
// import Mainta from './Set/Mainta';
import Head from '../components/Header';


function Setting() {
  const [tabValue, setTabValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    localStorage.setItem('activeSettingTab', newValue);
  };


  const handleDropdownChange = (event) => {
    setTabValue(event.target.value);
    localStorage.setItem('activeSettingTab', event.target.value);
  };


  const handleResize = () => {
    if (window.innerWidth <= 812) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };


  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    // Retrieve active tab index from localStorage on page load
    const storedTab = localStorage.getItem('activeSettingTab');
    if (storedTab !== null) {
      setTabValue(parseInt(storedTab)); // Parse storedTab to integer
    }
  }, []);


  return (
    <div>
      <div>
        <Head />
        <br />
        <br/>
      </div>
 
      {isMobileView ? (


      <div >
        <div style={{margin:'1% 5%'}}>
          <Select value={tabValue} onChange={handleDropdownChange} displayEmpty>
            <MenuItem value="" disabled>
              Select an option
            </MenuItem>
            <MenuItem value={0}>General Settings</MenuItem>
            {/* <MenuItem value={1}>Roaster</MenuItem> */}
            {/* <MenuItem value={2}>Invoice Setting</MenuItem> */}
            <MenuItem value={1}>Feedback Question</MenuItem>
            {/* <MenuItem value={2}>Blogs</MenuItem> */}
            {/* <MenuItem value={5}>Activity Log</MenuItem> */}
            <MenuItem value={2}>Api Doc</MenuItem>
            {/* <MenuItem value={7}>Payment</MenuItem> */}
            {/* <MenuItem value={8}>Maintenance</MenuItem> */}
          </Select>
          <div>
            <div style={{margin:'1%',marginBottom:'4%'}}>
            {tabValue === 0 && <Genee />}
            {/* {tabValue === 1 && <Roasr />} */}
            {/* {tabValue === 2 && <Invoicesett />} */}
            {tabValue === 1 && <Feedback />}
            {/* {tabValue === 2 && <Blo />} */}
            {/* {tabValue === 5 && <Activ />} */}
            {tabValue === 2 && <Aa />}
            {/* {tabValue === 7 && <Paym />} */}
            {/* {tabValue === 8 && <Mainta />} */}
            </div>
          </div>
          </div>
        </div>
      ) : (
        <div >
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="settings-tabs" variant="fullWidth"
               sx={{
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                "& button": {
                  display: 'flex',
                  justifyContent: 'center',
                  "& span": {
                    fontWeight: 'bold', // Make the font bold
                  },
                },
                "& button:hover": {
                  backgroundColor: "#eee",
                },
                "& button:active": {
                  backgroundColor: "#ddd",
                },
                "& button.Mui-selected": {
                  backgroundColor: "#007bff",
                  color: "#fff",
                },
            }}
          >
            <Tab label="General Settings"  sx={{ minWidth: '320px' }}  />
            {/* <Tab label="Roaster" /> */}
            {/* <Tab label="Invoice Setting" /> */}
            <Tab label="Feedback Question" sx={{ minWidth: '300px' }} />
            {/* <Tab label="Blogs" /> */}
            {/* <Tab label="Activity Log" /> */}
            <Tab label="Api Doc" sx={{ minWidth: '300px' }} />
            {/* <Tab label="Payment" /> */}
            {/* <Tab label="Maintenance" /> */}
          </Tabs>
          </div>
          <div >
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            margin:'1%',
            marginBottom:'4%',
            justifyContent: 'center'
          }}>
            {tabValue === 0 && <Genee />}
            {/* {tabValue === 1 && <Roasr />} */}
            {/* {tabValue === 2 && <Invoicesett />} */}
            {tabValue === 1 && <Feedback />}
            {/* {tabValue === 2 && <Blo />} */}
            {/* {tabValue === 5 && <Activ />} */}
            {tabValue === 2 && <Aa />}
            {/* {tabValue === 7 && <Paym />} */}
            {/* {tabValue === 8 && <Mainta />} */}
          </div>
        </div>
        </div>
      )}
    </div>
  );
 
}


export default Setting;





