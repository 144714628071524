import React, { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Header from '../../components/Header';
import axios from '../../Axiosinstance'
import { useParams } from 'react-router-dom';
import { Table, Button, Typography, Box, Paper, Grid } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTrash } from 'react-icons/fa';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Loader from '../../Loader'
import { FaRegTrashAlt } from "react-icons/fa";
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const App = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] = useState({ id: '', name: '' });
  const [curriculumData, setCurriculumData] = useState([]);
  const { curriculumid } = useParams();
  const [selectedFileType, setSelectedFileType] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [selectedPublicPreview, setSelectedPublicPreview] = useState('');
  const [selectedFor, setSelectedFor] = useState('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [curriculumName, setCurriculumName] = useState('');
  const [isNameValid, setIsNameValid] = useState(true);
  const [uploadStatus, setUploadStatus] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // State to control modal visibility
  const [curriculumIdToDelete, setCurriculumIdToDelete] = useState(null);

  const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dyjwwi8ea/raw/upload';
  const CLOUDINARY_UPLOAD_PRESET = 'vldqgwk8';


  const jwtToken = localStorage.getItem('jwtToken');

  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};

  // const handleUpload = () => {
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
  //     formData.append('public_id', 'LMS/curriculum/' + file.name);


  //     fetch(CLOUDINARY_URL, {
  //       method: 'POST',
  //       body: formData,
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         if (data.secure_url !== '') {
  //           const uploadedFileUrl = data.secure_url;
  //           // console.log('File uploaded successfully:', uploadedFileUrl); // take the uploadedFileUrl and assign to i mention place
  //           setUploadStatus('File uploaded successfully.');
  //         }
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         setUploadStatus('An error occurred during upload.');
  //       });
  //   } else {
  //     setUploadStatus('Please select a file before uploading.');
  //   }
  // };










  const handleNewButtonClick = () => {
    setShowPopup(true);
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };


  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const handleSavePopup = (e) => {

    e.preventDefault();
    if (curriculumName.trim() === '') {
      // Display a warning message or prevent saving
      setIsNameValid(false);
      return;
    }
    const newCurriculumName = document.getElementById('curriculumInput').value;


    const pathSegments = window.location.pathname.split('/');
    const courseid = pathSegments[pathSegments.length - 1];
    const curriculumData = {
      name: newCurriculumName,
      courseid: parseInt(courseid),
      createdAt: new Date().toISOString(),
    };


    axios.post('curriculum', curriculumData, { headers })
      .then((response) => {
        // console.log('Curriculum saved successfully:', response.data);


        // After successfully saving, fetch the latest curriculum data
        axios.get('curriculum', { headers })
          .then((response) => {
            const filteredData = response.data.filter(curriculum => curriculum.courseid === parseInt(curriculumid));
            setCurriculumData(filteredData);
          })
          .catch((error) => {
            console.error('Error fetching curriculum data:', error);
          });
      })
      .catch((error) => {
        console.error('Error saving curriculum:', error);
      });
    setCurriculumName('');
    setShowPopup(false); // Close the popup after saving
  };






  useEffect(() => {
    // Fetch curriculum data from the API
    axios.get('curriculum', { headers })
      .then((response) => {
        // Filter the curriculum data based on the ID
        const filteredData = response.data.filter(curriculum => curriculum.courseid === parseInt(curriculumid));
        // Update the state with the filtered curriculum data


        setCurriculumData(filteredData);
      })
      .catch((error) => {
        console.error('Error fetching curriculum data:', error);
      });
  }, [curriculumid]);


  const handleFileChange = (e) => {
    const fileToUpload = e.target.files[0];


    if (fileToUpload) {
      setIsUploading(true);


      const formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      formData.append('public_id', 'LMS/curriculum/' + fileToUpload.name);


      fetch(CLOUDINARY_URL, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.secure_url !== '') {
            const uploadedFileUrl = data.secure_url;
            setUploadedFileUrl(uploadedFileUrl);
            setIsUploading(false);
            // console.log('File uploaded successfully:', uploadedFileUrl);
          }
        })
        .catch((err) => {
          console.error(err);
          setUploadStatus('An error occurred during upload');
          setIsUploading(false);
        });


      // Set the selected file type based on the accepted formats
      const acceptTypes = getAcceptTypes();
      for (const type in validFileTypes) {
        if (acceptTypes.includes(validFileTypes[type])) {
          setSelectedFileType(type);
          break;
        }
      }
    }
  };


  const handleSubmitButtonHover = (e) => {
    e.target.style.backgroundColor = '#28a745';
  };


  const handleSubmitButtonLeave = (e) => {
    e.target.style.backgroundColor = 'green';
  };










  const handleDelete = (id) => {
    setCurriculumIdToDelete(id);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    // Perform the deletion logic here using curriculumIdToDelete
    axios.delete(`curriculum/${curriculumIdToDelete}`, { headers })
      .then(response => {
        if (response.status === 200) {
          const updatedCourses = curriculumData.filter((curriculum) => curriculum.id !== curriculumIdToDelete);
          setCurriculumData(updatedCourses);
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
        // You can log the error or display an error message here.
      })
      .finally(() => {
        setShowConfirmationModal(false);
        setCurriculumIdToDelete(null);
      });
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setCurriculumIdToDelete(null);
  };


  const validFileTypes = {
    ppt: '.ppt, .pptx',
    pdf: '.pdf',
    audio: '.mp3',
    video: '.mp4, .mov, .avi', // Add more video file extensions if needed
  };


  const getAcceptTypes = () => {
    if (selectedFileType === 'ppt') {
      return validFileTypes.ppt;
    } else if (selectedFileType === 'pdf') {
      return validFileTypes.pdf;
    } else if (selectedFileType === 'video') {
      return validFileTypes.video;
    }
    else if (selectedFileType === 'audio') {
      return validFileTypes.audio;
    } else {
      return '';
    }
  };










  const renderInputField = () => {
    if (selectedFileType === 'youtube link') {
      return (
        <div className="mb-3">
          <label htmlFor="youtubeLink" className="form-label">
            Paste YouTube Link
          </label>
          <input
            type="text"
            id="youtubeLink"
            className="form-control"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </div>
      );
    } else if (selectedFileType === 'text') {
      return (
        <div className="mb-3">
          <label htmlFor="textInput" className="form-label">
            Enter Text
          </label>
          <ReactQuill
            value={inputValue}
            onChange={setInputValue}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['bold', 'italic', 'underline'],
                ['link'],
                [{ 'align': [] }],
              ],
            }}
            formats={[
              'header',
              'font',
              'list',
              'bullet',
              'bold',
              'italic',
              'underline',
              'link',
              'align',
            ]}
          />
        </div>
      );
    }
    return null;
  };





  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Initialize an object to store error messages
    const errors = {};

    // Validate form fields
    const studentName = document.getElementById('name').value;
    const selectedCurriculumId = selectedCurriculum.id;
    const selectedPublicPreviewValue = selectedPublicPreview;
    const selectedForValue = selectedFor;
    const selectedFileTypeValue = selectedFileType;

    if (!studentName) {
      errors.studentName = 'Please enter the student name.';
    }

    if (!selectedCurriculumId) {
      errors.selectedCurriculum = 'Please select a curriculum.';
    }

    if (!selectedPublicPreviewValue) {
      errors.selectedPublicPreview = 'Please select an option for public preview.';
    }

    if (!selectedForValue) {
      errors.selectedFor = 'Please select an option for "For".';
    }

    if (!selectedFileTypeValue) {
      errors.selectedFileType = 'Please select a file type.';
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      // If there are errors, update the state or display the errors in a way that suits your UI
      console.log('Form validation errors:', errors);
      return;
    }

    // Rest of your code for form submission

    // Prepare the data object
    const formData = {
      sectionName: studentName,
      selectCurriculum: selectedCurriculum.name,
      for_access: document.getElementById('for').value,
      type: selectedFileType,
      publicReview: selectedPublicPreview === 'Type 1',
      curriculum_id: selectedCurriculumId,
      course_id: curriculumid,
      fileUrl: (selectedFileType === 'youtube link' || selectedFileType === 'text') ? inputValue : uploadedFileUrl,
    };

    try {
      // Send a POST request to the API endpoint
      const response = await axios.post('uploadcurriculum', formData, { headers });

      // Handle the response, for example, show a success message to the user
      console.log('Form submitted successfully!', response.data);

      // Reset the form fields if needed
      document.getElementById('name').value = '';
      setSelectedCurriculum({ id: '', name: '' });
      document.getElementById('for').value = '';
      setSelectedFileType('');
      setInputValue('');

      // Clear the uploaded file field and reset the upload status
      setUploadedFileUrl('');
      setUploadStatus('');

      // Clear the "For Public Preview" and "For" select fields
      setSelectedPublicPreview('');
      setSelectedFor('');
      window.location.reload();
    } catch (error) {
      // Handle errors, for example, show an error message to the user
      console.error('Error submitting the form:', error);
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setCurriculumName(value);
    setIsNameValid(value.trim() !== ''); // Simple validation: non-empty input is considered valid
  };




  return (
    <div>
      <div className="containerw mx-auto mt-4" style={{ maxWidth: '1000px' }}>
        <br />   <Link to="/Allcourse">
          <Button variant="contained" color="primary">
            All Courses
          </Button>
        </Link>

        <br />   <br />
        <Box
          sx={{
            backgroundColor: '#fff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '20px',
            borderRadius: '8px',
          }}
        >


          <div className="mb-4">
            <h3>New Curriculum</h3>
            <Button onClick={handleNewButtonClick} variant="primary" style={{ background: '#03396c', color: 'white' }} >
              <span className="me-2">
                <i className="bi bi-file-earmark-plus"></i>
              </span>
              New
            </Button>
          </div>
          {showPopup && (
            <div className=" ">
              {/* <h3 className="mb-3">New Curriculum</h3> */}
              <div className="input-group" style={{ boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', padding: '16px' }}>
                <input
                  type="text"
                  id="curriculumInput"
                  placeholder="Enter curriculum name"
                  className={`form-control ${isNameValid ? '' : 'is-invalid'}`}
                  style={{ marginRight: '8px' }}
                  value={curriculumName}
                  onChange={handleInputChange}
                  required
                />
                <div className="invalid-feedback" style={{ display: isNameValid ? 'none' : 'block' }}>
                  Please enter a valid curriculum name.
                </div>
                <div className="d-flex flex-column">
                  <Button onClick={handleSavePopup} variant="success" style={{ background: '#03396c', marginBottom: '8px', backgroundColor: 'green', color: 'white' }}>
                    Save
                  </Button>
                  <Button onClick={handleClosePopup} variant="secondary" style={{ backgroundColor: 'red', color: 'white' }}>
                    Cancel
                  </Button>
                </div>
              </div>


            </div>

          )}


          <div className="responsive">

            <h3>Curriculum List</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  {/* <th>Course ID</th>
              <th>Created At</th> */}
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {curriculumData.map((curriculum) => (
                  <tr key={curriculum.id}>
                    {/* <td>{curriculum.id}</td> */}
                    <td>{curriculum.name}</td>
                    {/* <td>{curriculum.courseid}</td>
                <td>{curriculum.createdAt}</td> */}
                    <td>    <button className="btn btn-danger" onClick={() => handleDelete(curriculum.id)}>
                      <FaRegTrashAlt />
                    </button> </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal show={showConfirmationModal} onHide={handleCancelDelete}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete this curriculum?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCancelDelete} style={{ backgroundColor: 'grey', color: 'white', marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleConfirmDelete} style={{ backgroundColor: 'red', color: 'white' }}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>




          <div className=" ">
            <h3 className="mb-3">Upload Curriculum</h3>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Section Name
                </label>
                <input required type="text" id="name" className="form-control" placeholder="Enter name" style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="curriculum" className="form-label">
                  Select Curriculum
                </label>
                <select
                  required
                  id="curriculum"
                  className="form-select"
                  value={selectedCurriculum.id}
                  onChange={(e) => {
                    const curriculumName = e.target.options[e.target.selectedIndex].text;
                    setSelectedCurriculum({ id: e.target.value, name: curriculumName });
                  }}
                  style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}


                >
                  <option value="">Select a curriculum</option>
                  {curriculumData.map((curriculum) => (
                    <option key={curriculum.id} value={curriculum.id}>
                      {curriculum.name}
                    </option>
                  ))}
                </select>




              </div>


              <div className="row">
                <div className="col-md-6 mb-3">
                  <label htmlFor="publicPreview" className="form-label">
                    For Public Preview
                  </label>
                  <select
                    required
                    id="publicPreview"
                    className="form-select"
                    value={selectedPublicPreview}
                    onChange={(e) => setSelectedPublicPreview(e.target.value)}
                    style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}


                  >
                    <option value="">Select an option</option>
                    <option value="Type 1">Yes</option>
                    <option value="Type 2">No</option>
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label htmlFor="for" className="form-label">
                    For
                  </label>
                  <select
                    required
                    id="for"
                    className="form-select"
                    value={selectedFor}
                    onChange={(e) => setSelectedFor(e.target.value)}
                    style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}


                  >
                    <option value="">Select an option</option>
                    <option value="Student">Student</option>
                    <option value="Trainer">Trainer</option>
                  </select>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  required
                  id="type"
                  className="form-select"
                  value={selectedFileType}
                  onChange={(e) => {
                    setSelectedFileType(e.target.value);
                  }}
                  style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}


                >
                  <option value="">Select a type</option>
                  <option value="ppt">PPT</option>
                  <option value="pdf">PDF</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                  <option value="youtube link">Youtube Link</option>
                  <option value="text">Text</option>
                  {/* Add other options as needed */}
                </select>
              </div>


              {/* {selectedFileType && (
        <div className="mb-3">
          <label htmlFor="file" className="form-label">
            Choose {selectedFileType.toUpperCase()} file
          </label>
          <input
            type="file"
            id="file"
            className="form-control"
            accept={getAcceptTypes()}
            onChange={handleFileChange}
          />
        </div>
      )} */}


              {renderInputField()}


              {selectedFileType && selectedFileType !== 'youtube link' && selectedFileType !== 'text' && (




                <div className="mb-3">
                  <label htmlFor="file" className="form-label">
                    Choose {selectedFileType.toUpperCase()} file
                  </label>
                  <input
                    required
                    type="file"
                    id="file"
                    className="form-control"
                    accept={getAcceptTypes()}
                    onChange={handleFileChange}
                  />
                  {isUploading && <p>Uploading...</p>}
                </div>








              )}




              <button
                className="btn btn-success btn-sm"
                onMouseOver={handleSubmitButtonHover}
                onMouseLeave={handleSubmitButtonLeave}
                type="submit"
                style={{
                  backgroundColor: '#03396c',
                  color: 'white',
                  border: 'none',
                  padding: '8px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                disabled={isUploading || !selectedFileType}
              >
                Submit
              </button>








            </form>
          </div>
        </Box>
      </div>
    </div>
  );
};


const extractYouTubeVideoId = (url) => {
  // Extract the video ID from the YouTube URL
  const videoId = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=|embed\/watch\?feature=player_embedded&v=))([^&\n?#]+)/);
  return videoId ? videoId[1] : null;
};


const Key = () => {
  const { curriculumid } = useParams();
  const [curriculumData, setCurriculumData] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState(null);
  const [selectedContentUrl, setSelectedContentUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalContent(null);
    setShowModal(false);
  };

  const jwtToken = localStorage.getItem('jwtToken');

  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};

  useEffect(() => {
    // Assuming curriculumid is defined elsewhere
    axios.get('uploadcurriculums', { headers })
      .then((response) => {
        setCurriculumData(response.data);
        setLoading(false); // Set loading to false when data is loaded
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, [curriculumid]);


  const filteredData = curriculumData.filter(
    (curriculum) => curriculum.course_id === parseInt(curriculumid, 10)
  );






  const confirmDelete = (curriculum) => {
    if (window.confirm('Are you sure you want to delete this curriculum?')) {
      axios
        .delete(`uploadcurriculum/${curriculum.id}`, { headers })
        .then(() => {
          alert('Curriculum deleted successfully');
          // Reset selected content after a successful delete
          setSelectedContent(null);
          setSelectedContentType(null);
          setSelectedContentUrl(null);


          // Call the callback function to reload data
          reloadCurriculumData();



        })
        .catch((error) => {
          alert('Error deleting curriculum: ' + error);
        });
    }
  };


  const reloadCurriculumData = () => {
    axios.get('uploadcurriculums', { headers })
      .then((response) => {
        setCurriculumData(response.data);
        // You might want to reset other state variables if needed
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  };


  const displayContent = (content) => {
    setSelectedContent(content);
    setSelectedContentType(content.type);
    setSelectedContentUrl(content.fileUrl);
  };

  const renderModalContent = (content) => {
    if (!content) {
      return <p>No content to display</p>;
    }

    switch (content.type) {
      case 'pdf':
        return (
          <div>
            <iframe src={content.fileUrl} style={{ width: '100%', height: '100%' }} title="PDF Viewer"></iframe>
            {/* Add any additional components or buttons you need */}
          </div>
        );
      case 'ppt':
      case 'docx':
        return (
          <div>
            <iframe
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(content.fileUrl)}`}
              width="100%"
              height="100%"
            ></iframe>
            {/* Add any additional components or buttons you need */}
          </div>
        );
      case 'video':
        return (
          <div>
            <video width="100%" height="100%" controls>
              <source src={content.fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* Add any additional components or buttons you need */}
          </div>
        );
      case 'audio':
        return (
          <div>
            <audio controls>
              <source src={content.fileUrl} type="audio/mpeg" />
              Your browser does not support the audio tag.
            </audio>
            {/* Add any additional components or buttons you need */}
          </div>
        );
      case 'text':
        return (
          <div>
            {/* Render HTML content with formatting */}
            <Typography variant="body1" dangerouslySetInnerHTML={{ __html: content.fileUrl }} />
            {/* Add any additional components or buttons you need */}
          </div>
        );
      default:
        return (
          <div>
            <iframe
              src={`https://www.youtube.com/embed/${extractYouTubeVideoId(content.fileUrl)}`}
              title="YouTube Video"
              width="100%"
              height="100%"
            ></iframe>
            {/* YouTube link - No download button */}
          </div>
        );
    }
  };




  return (
    <div className='containerw mx-auto' style={{ maxWidth: '1000px' }}>     
     <Box mt={2} maxWidth="1000px">
      <Paper elevation={3}>
        {loading ? (
          <Loader /> // Show loader while data is being fetched
        ) : (
          <Grid container>
            <Grid item xs={3}>
              <Box p={3}>
                <h3>Curriculum</h3>
                <table class="table table-bordered" style={{ marginTop: '2%' }}>
                  <tbody>
                    {filteredData.map((curriculum) => (
                      <tr
                        key={curriculum.id}
                        onClick={() => handleShowModal(curriculum)}
                        style={{
                          cursor: 'pointer',
                          background: selectedContent && selectedContent.id === curriculum.id ? '#0789A9' : '',
                          lineHeight: '1.5',
                          marginBottom: '5px',
                          borderRadius: '50px'
                        }}
                      >
                        <td style={{ fontSize: '20px' }}>{curriculum.sectionName}</td>
                        <td>
                          <FaTrash
                            style={{ cursor: 'pointer', marginLeft: '5px', color: 'red' }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the modal from opening when deleting
                              confirmDelete(curriculum);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                    <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-fullscreen">
                      <Modal.Header closeButton>
                        <Modal.Title>{modalContent ? modalContent.sectionName : 'Modal Title'}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {renderModalContent(modalContent)}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type='Button'
                          className='btn btn-primary'
                          style={{ padding: '7px', maxWidth: '100px', width: '100%', fontSize: '12px' }} onClick={handleCloseModal}>
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>

                  </tbody>
                </table>

              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={3} ml={2}>
                {selectedContent && (
                  <div>
                    {selectedContent.type === 'pdf' ? (
                      <div>
                        <iframe src={selectedContent.fileUrl} width="700" height="400"></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'ppt' || selectedContent.type === 'docx' ? (
                      <div>
                        <iframe
                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                            selectedContent.fileUrl
                          )}`}
                          width="700"
                          height="400"
                        ></iframe>
                        {renderDownloadButton(selectedContent)}
                      </div>
                    ) : selectedContent.type === 'video' ? (
                      <div>
                        <video width="700" height="400" controls>
                          <source src={selectedContent.fileUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        {renderDownloadButton(selectedContent, 'video')}
                      </div>
                    ) : selectedContent.type === 'audio' ? (
                      <div>
                        <audio controls>
                          <source src={selectedContent.fileUrl} type="audio/mpeg" />
                          Your browser does not support the audio tag.
                        </audio>
                        {renderDownloadButton(selectedContent, 'audio')}
                      </div>
                    ) : selectedContent.type === 'text' ? (
                      <div>
                        {/* Render HTML content with formatting */}
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: selectedContent.fileUrl }} />
                        {renderDownloadButton(selectedContent, 'text')}
                      </div>
                    ) : (
                      <div>
                        <iframe
                          src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                            selectedContent.fileUrl
                          )}`}
                          title="YouTube Video"
                          width="700"
                          height="400"
                        ></iframe>
                        {/* YouTube link - No download button */}
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Box>
      <div style={{ height: '40px' }}>

      </div>
    </div>

  );
};


const Managec = () => {
  return (
    <div >
      <Header />
      <App />
      <div>
        <Key />
      </div>


    </div>
  );
};


export default Managec;


function renderDownloadButton(content, type) {
  // Check if the content type is not a YouTube link and type is defined
  if (type && type !== 'youtube') {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleDownload(content.fileUrl, type)}>
        Download {type.toUpperCase()}
      </Button>
    );
  }


  // No download button for YouTube links or if type is undefined
  return null;
}


function handleDownload(fileUrl, type) {
  // Implement download logic here
  if (type === 'text') {
    downloadTextFile(fileUrl, 'download.txt');
  } else if (type === 'pdf' || type === 'ppt') {
    downloadFile(fileUrl, `download.${type}`);
  } else {
    window.location.href = fileUrl; // This is a simple example; improve based on your requirements
  }
}




function downloadTextFile(htmlContent, fileName) {
  const element = document.createElement('a');


  // Convert HTML content to formatted plain text
  const plainTextContent = convertHtmlToPlainText(htmlContent);


  const file = new Blob([plainTextContent], { type: 'text/plain' });


  element.href = URL.createObjectURL(file);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
}


function convertHtmlToPlainText(htmlContent) {
  const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
  const textContent = doc.body.textContent || '';
  return decodeHtmlEntities(textContent);
}


function decodeHtmlEntities(text) {
  const element = document.createElement('div');
  element.innerHTML = text;
  return element.innerText;
}


function downloadFile(fileUrl, fileName) {
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const element = document.createElement('a');
      const file = new Blob([blob], { type: 'application/octet-stream' });


      element.href = URL.createObjectURL(file);
      element.download = fileName;
      document.body.appendChild(element);
      element.click();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}



