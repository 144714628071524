import axios from "axios";

const instance = axios.create({
  // baseURL: 'http://localhost:8080/', 
  //  baseURL: 'http://api.impulz-lms.com:8080/', 
      // baseURL: 'https://lb.impulz-lms-backend.net/', 
      // baseURL: 'http://ec2-3-111-200-106.ap-south-1.compute.amazonaws.com:8080/', 
      // baseURL:'https://newtonimpulzserver.df.r.appspot.com/',
      
     baseURL:'https://evident-knowing-tuna.ngrok-free.app',

    headers: {
      "Content-type": "application/json",
      'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
      "ngrok-skip-browser-warning": "69420",
    }
  });
  
  export default instance;

  export const logout = () => {
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("usertype");
    localStorage.removeItem("studentteacherid");
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');
    localStorage.removeItem('activeSettingTab');
    localStorage.removeItem('calendarTab');
    localStorage.removeItem('activeTab');


    document.title = "LMS-impluz";
  };