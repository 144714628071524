import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../Axiosinstance';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Header from '../components/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import { BsPencilFill } from 'react-icons/bs';
import CreateAsse from './CreateAsse';
import { toast, ToastContainer } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';








import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Questiobank from './Questiobank';
















const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'course', label: 'Course', minWidth: 100 },
  { id: 'batch', label: 'Batch', minWidth: 170 },
  { id: 'questions', label: 'Questions', minWidth: 170 },
  { id: 'duration', label: 'Duration', minWidth: 170 },
  { id: 'validation', label: 'Validation', minWidth: 170 },
  { id: 'actions', label: 'Actions', minWidth: 170 },
];
















const Screen = () => {
  const [assessments, setAssessments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);












  const [tabValue, setTabValue] = useState(0);
  const [assessmentsPerPage, setAssessmentsPerPage] = useState(10);
















  const [searchQuery, setSearchQuery] = useState('');
  const [filteredAssessments, setFilteredAssessments] = useState([]);
  const [editingAssessment, setEditingAssessment] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);




  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
















  useEffect(() => {
    // Fetch assessment data from the API
    axios
      .get('assessments')
      .then((response) => {
        const data = response.data;
        setAssessments(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);




























  useEffect(() => {
    // Update filtered assessments when the search query changes
    const filtered = assessments.filter((assessment) =>
  assessment.name && assessment.name.toLowerCase().includes(searchQuery.toLowerCase())
);
    setFilteredAssessments(filtered);
  }, [searchQuery, assessments, currentPage, pageSize]);








  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };












  const handleDelete = async (id) => {
    try {
      const confirmation = window.confirm('Are you sure you want to delete this assessment?');
















      if (confirmation) {
        const response = await axios.delete(`assessment/${id}`);
















        if (response.status === 200) {
          const updatedAssessments = assessments.filter((assessment) => assessment.id !== id);
          setAssessments(updatedAssessments);
          toast.success('Assessment deleted successfully');
        } else {
          console.error('Failed to delete assessment');
        }
      }
    } catch (error) {
      console.error('Error deleting assessment:', error);
    }
  };
















  function handleViewValidate() {
    return <h6>View & Validate</h6>;
  }
















  const handleEditAssessment = (assessment) => {
    setEditingAssessment(assessment);
    setEditModalOpen(true);
  };
















  const handleCloseEditModal = () => {
    setEditModalOpen(false);




  };
















  const handleSaveEdit = async (editedAssessment) => {
    try {




      const confirmation = window.confirm('Are you sure you want to update this assessment?');


      if (confirmation) {
        const response = await axios.put(`assessment/${editedAssessment.id}`, editedAssessment);


        if (response.status === 200) {
          const updatedAssessments = assessments.map((assessment) => (assessment.id === editedAssessment.id ? editedAssessment : assessment));
          setAssessments(updatedAssessments);
          setEditingAssessment(null);
          setEditModalOpen(false);
          toast.success('Assessment updated successfully');
        } else {
          throw new Error('Failed to update assessment');
        }
      }
    } catch (error) {
      console.error('Error updating assessment:', error);
      toast.error(error.message);
    }
  };








  const handleCancelEdit = () => {
    setEditModalOpen(false);
    setEditingAssessment(null);
  };




  return (
    <div>
      <div className="container" style={{ maxWidth: '1200px' }}>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchQuery}
            onChange={handleSearch}
            style={{
              padding: '8px 12px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              float: 'left',
              fontSize: '16px',
            }}
          />




          <TableContainer
            sx={{
              overflowX: 'auto',
              maxWidth: '100%',
              '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                overflowX: 'unset', // Enable horizontal scroll
              },
            }}
          >








<Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="left"
                      style={{ fontWeight: 'bold' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAssessments // Use filteredAssessments instead of assessments




                  .slice((currentPage - 1) * assessmentsPerPage, currentPage * assessmentsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.course}</TableCell>
                      <TableCell>{row.batch}</TableCell>
                      <TableCell>{row.questions}</TableCell>
                      <TableCell>{row.duration}</TableCell>
                      <TableCell>
                      <a href='/Validate' style={{ textDecoration: 'none', marginTop: '90' }}>
                        {row.validation}
                     
                      {handleViewValidate()} </a>
                      </TableCell>
                      <TableCell>
                        {editingAssessment && editingAssessment.id === row.id ? (
                          <div>
                            {/* The EditModal component */}
                            <EditModal
                              assessment={editingAssessment}
                              onSave={handleSaveEdit}
                              onCancel={handleCancelEdit}
                            />




                          </div>
                        ) : (
                          <BsPencilFill onClick={() => handleEditAssessment(row)} />
                        )}
                        <Tab
                          label={
                           
                              <DeleteRoundedIcon style={{ color: 'red'}} />
                         
                          }
                          onClick={() => handleDelete(row.id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={assessments.length}
            rowsPerPage={assessmentsPerPage}
            page={currentPage - 1}
            labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={(event) => {
              setCurrentPage(1); // Reset the page to the first page when changing rows per page
              setAssessmentsPerPage(event.target.value);
            }}
          />
        </Paper>
      </div>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />








    </div>
  );
};




const EditModal = ({ assessment, onSave, onCancel }) => {
  const [editedAssessment, setEditedAssessment] = useState(assessment);








  // Define handleInputChange function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedAssessment({
      ...editedAssessment,
      [name]: value,
    });
  };


  const handleFormClick = (e) => {
    e.stopPropagation(); // Prevent click event from propagating to the Dialog
  };


  return (
    <Dialog open={true} onClick={onCancel}>
      <DialogTitle>Edit Assessment</DialogTitle>
      <DialogContent>
        <form onClick={handleFormClick}>
          <TextField
            label="Course"
            name="course"
            value={editedAssessment.course}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required


          />




          <TextField
            label="Batch"
            name="batch"
            value={editedAssessment.batch}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required


          />
          <TextField
            label="Name"
            name="name"
            value={editedAssessment.name}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, ''); // Allow only alphabets and spaces
              const truncatedValue = inputValue.substring(0, 25); // Limit the input to 25 characters
              setEditedAssessment({
                ...editedAssessment,
                name: truncatedValue,
              });
            }}
            fullWidth
            margin="normal"
            required
          />




          <TextField
            label="Duration (in minutes)"
            type="number"
            name="duration"
            value={editedAssessment.duration}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, '').substring(0, 3); // Allow only digits and limit to 8 characters
              setEditedAssessment({
                ...editedAssessment,
                duration: inputValue,
              });
            }}
            fullWidth
            margin="normal"
            required
          />




          <label htmlFor="Start Date">Start Date</label>
          <TextField
            type="date"
            name="startDate"
            value={editedAssessment.startDate}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />
         
          <label htmlFor="End Date">End Date</label>
          <TextField
            type="date"
            name="endDate"
            value={editedAssessment.endDate}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
          />




          <ReactQuill
            aria-label="Editor Content"
            minRows={3}
            value={editedAssessment.description}
            onChange={(content) => handleInputChange({ target: { name: 'description', value: content } })}
            placeholder="Editor Content"
            fullWidth
            required


          />


        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(editedAssessment)} variant="contained" color="primary" style={{ backgroundColor: "green" }}>
          update
        </Button>
        <Button onClick={onCancel} variant="contained" color="secondary" style={{ backgroundColor: "red" }}>
          Cancel
        </Button>
      </DialogActions>




    </Dialog>






  );
};
















const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);








  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };








  const openModal = () => {
    setShowModal(true);
  };








  const closeModal = () => {
    setShowModal(false);
  };








  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };




  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));




  return (
    <div>
      <Header />
      <br></br>
      <div className="d-flex justify-content-center mt-4">




        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Assessment-tabs"
          variant="fullWidth"
          className="d-md-flex justify-content-md-center"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              display: 'flex',
              justifyContent: 'center',
              "& span": {
                fontWeight: 'bold', // Make the font bold
              },
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none',
            },
            // Adjust width for mobile view
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="Assessment" sx={{ minWidth: isMobile ? '20%' : '390px' }} />
          <Tab label="Create New Assessment" sx={{ minWidth: isMobile ? '20%' : '390px' }} />
          {/* <Tab label="Question Bank" sx={{ minWidth: isMobile ? '20%' : '390px' }} /> */}
        </Tabs>
      </div>
      <br />
      <div>
        {tabValue === 0 && <Screen />}
        {tabValue === 1 && <CreateAsse onFormSubmit={() => handleTabChange(0)} />}
        {/* {tabValue === 2 && <Questiobank />} */}
      </div>
    </div>
  );
};








export default New;






