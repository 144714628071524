import React, { useState, useEffect } from 'react';
import axios from '../Axiosinstance';
import { Modal, Button, Form, ButtonGroup } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { baseURLdyte, Password, Username } from '../common'


const BatchForm = ({ onFormSubmit }) => {

  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [selectedAssessors, setSelectedAssessors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [facilitatorData, setFacilitatorData] = useState(null);




  const [searchText, setSearchText] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const initialFieldErrors = {
    course: '',
    courseId: '',
    batchCode: '',
    online: '',
    attendance: '',
    status: '',
    intakeSize: '',
    threshold: '',
    venue: '',
  };


  useEffect(() => {
    axios.get('courses')
      .then(response => setCourses(response.data))
      .catch(error => console.error('Error fetching course data:', error));
  }, []);


  useEffect(() => {
    axios.get('venues')
      .then(response => setVenues(response.data))
      .catch(error => console.error('Error fetching venue data:', error));
  }, []);


  const handleCloseModal = () => {
    setShowModal(false);
  };




  const handleSelectTrainer = (trainer) => {
    setSelectedTrainers((prevTrainers) => [...prevTrainers, trainer]);
  };



  const handleSelectAssessor = (assessor) => {
    setSelectedAssessors((prevAssessors) => [...prevAssessors, assessor]);
  };




  const handleDeleteTrainer = (trainer) => {
    setSelectedTrainers((prevTrainers) =>
      prevTrainers.filter((t) => t !== trainer)
    );
  };




  const handleDeleteAssessor = (assessor) => {
    setSelectedAssessors((prevAssessors) =>
      prevAssessors.filter((a) => a !== assessor)
    );
  };








  // Function to fetch facilitator details from the API
  const fetchFacilitatorDetails = () => {
    axios.get('facilitators')
      .then(response => setFacilitatorData(response.data))
      .catch(error => console.error('Error fetching facilitator data:', error));
  };




  useEffect(() => {
    fetchFacilitatorDetails();
  }, []);



  useEffect(() => {
    fetchFacilitatorDetails();
  }, []);




  const handleAddTrainer = (e) => {
    e.preventDefault();
    fetchFacilitatorDetails();
    setShowModal(true);
  };



  const handleAddAssessor = (e) => {
    e.preventDefault();
    fetchFacilitatorDetails();
    setShowModal(true);
  };




  const [formModified, setFormModified] = useState(false);



  useEffect(() => {
    const handleFormModification = () => {
      if (!formModified) {
        setFormModified(true);
      }
    };



    window.addEventListener('input', handleFormModification);


    return () => {
      window.removeEventListener('input', handleFormModification);
    };
  }, [formModified]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formModified) {
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };


    window.onbeforeunload = handleBeforeUnload;



    return () => {
      window.onbeforeunload = null;
    };
  }, [formModified]);





  const [courses, setCourses] = useState([]);
  const [venues, setVenues] = useState([]);




  const [batchData, setBatchData] = useState({
    course: '',
    batchcode: '',
    onlineclasslink: '',
    attendanceqrlink: '',
    esignrequired: '',
    threshold: 0,
    intakesize: 0,
    venue: '',
    status: '',
    courseId: '',





    trainers: facilitatorData,
    assessors: facilitatorData,
    days: [
      // {
      //   morningFrom: {},
      //   morningTo: {},
      //   eveningFrom: {},
      //   eveningTo: {},
      //   batchDate: '',
      //   assessment: false,  this for remove default date 
      // },
    ],
  });




  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('courses');
        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };




    const fetchVenues = async () => {
      try {
        const response = await axios.get('venues');
        setVenues(response.data);
      } catch (error) {
        console.error('Error fetching venues:', error);
      }
    };




    fetchCourses();
    fetchVenues();
  }, []);




  const handleInputChange = (e, dayIndex) => {
    const { name, value, type, checked } = e.target;




    if (name === 'course') {
      const selectedCourse = courses.find((course) => course.name === value);
      setBatchData((prevData) => ({
        ...prevData,
        course: value,
        courseId: selectedCourse ? selectedCourse.id : '', // Set courseId
      }));
    } else if (type === 'checkbox' && name === 'assessment') {
      setBatchData((prevData) => ({
        ...prevData,
        days: prevData.days.map((day, index) =>
          index === dayIndex ? { ...day, [name]: checked } : day
        ),
      }));
    } else {
      setBatchData((prevData) => ({ ...prevData, [name]: value }));
    }
  };




  const handleAddDay = () => {
    setBatchData((prevData) => ({
      ...prevData,
      days: [
        ...prevData.days,
        {
          morningFrom: {},
          morningTo: {},
          eveningFrom: {},
          eveningTo: {},
          batchDate: '',
        },
      ],
    }));
  };




  const handleTimeChange = (dayIndex, timeType, timeKey, value) => {
    setBatchData((prevData) => {
      const updatedDays = [...prevData.days];
      updatedDays[dayIndex][timeType][timeKey] = value;
      return { ...prevData, days: updatedDays };
    });
  };




  const handleDateChange = (dayIndex, value) => {
    setBatchData((prevData) => {
      const updatedDays = [...prevData.days];
      updatedDays[dayIndex].batchDate = value;
      return { ...prevData, days: updatedDays };
    });
  };




  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const trainerNames = selectedTrainers.map((trainer) => trainer);
  const assessorNames = selectedAssessors.map((assessor) => assessor);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Extract facilitator names from selected trainers and assessors


    try {
      setShowConfirmationModal(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleConfirmSubmit = async () => {
    // Update batchData with facilitator names
    const updatedBatchData = {
      ...batchData,
      trainers: trainerNames,
      assessors: assessorNames,
    };

    try {
      const response = await axios.post('batch', updatedBatchData);

      if (response.status === 200) {
        console.log('Batch data submitted successfully!');
        onFormSubmit();
      } else {
        console.error('Failed to submit batch data.');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const handleCancelSubmit = () => {
    setShowConfirmationModal(false);
  };


  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);

  const handleCancel = () => {
    setShowCancelConfirmationModal(true);
  };

  const handleConfirmCancel = () => {
    onFormSubmit();
    // Additional cancel logic if needed
    setShowCancelConfirmationModal(false);
  };

  const handleCancelCancel = () => {
    setShowCancelConfirmationModal(false);
  };

  const containerStyle = {
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '20px',
  };




  const handleCheckboxChange = (e) => {
    const value = e.target.checked ? "yes" : "no";
    setBatchData({ ...batchData, esignrequired: value });
  };




  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dayIndexToDelete, setDayIndexToDelete] = useState(null);

  const handleRemoveDay = (dayIndex) => {
    setDayIndexToDelete(dayIndex);
    setShowConfirmation(true);

  };

  const confirmRemoveDay = () => {

    // Delete the day when the user confirms
    setBatchData((prevData) => {
      const updatedDays = [...prevData.days];
      updatedDays.splice(dayIndexToDelete, 1);
      return { ...prevData, days: updatedDays };
    });

    // Close the confirmation modal
    setShowConfirmation(false);
    toast.success('Day removed successfully!', {
      position: 'bottom-right',
    });

  };

  const closeConfirmation = () => {
    // Close the confirmation modal without deleting the day
    setShowConfirmation(false);
  };

  const [meetingDetails, setMeetingDetails] = useState({});
  const [meetingLink, setMeetingLink] = useState('');
  const username = Username;
  const password = Password;
  const baseURL = baseURLdyte;
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');

  const handleShowMeetingModal = () => {
    setShowMeetingModal(true);
  };

  const handleCloseMeetingModal = () => {
    setShowMeetingModal(false);
  };

  const handleMeetingTitleChange = (e) => {
    setMeetingTitle(e.target.value);
  };

  const handleCreateMeeting = async () => {
    try {
      const response = await axios.post(
        `${baseURL}/meetings`,
        {
          title: meetingTitle,
          preferred_region: 'ap-south-1',
          record_on_start: false,
          live_stream_on_start: false,
          recording_config: {
            max_seconds: 60,
            file_name_prefix: 'string',
            video_config: {
              codec: 'H264',
              width: 1280,
              height: 720,
              watermark: {
                url: 'http://example.com',
                size: { width: 1, height: 1 },
                position: 'left top',
              },
            },
            audio_config: { codec: 'AAC', channel: 'stereo' },
            storage_config: {
              type: 'aws',
              access_key: 'string',
              secret: 'string',
              bucket: 'string',
              region: 'us-east-1',
              path: 'string',
              auth_method: 'KEY',
              username: 'string',
              password: 'string',
              host: 'string',
              port: 0,
              private_key: 'string',
            },
            dyte_bucket_config: { enabled: true },
            live_streaming_config: { rtmp_url: 'rtmp://a.rtmp.youtube.com/live' },
          },
        },
        {
          auth: {
            username: username,
            password: password,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response && response.data) {
        // Extract the meeting ID from the response
        const meetingId = response.data.data.id;

        // Omit participant addition in this part of the code

        // Construct the complete meeting link using base link, id, and authToken
        const completeMeetingLink = `${baseURL}/meeting?id=${meetingId}`;

        // Update batchData with the meeting link
        setBatchData((prevData) => ({
          ...prevData,
          onlineclasslink: completeMeetingLink,
        }));

        // Close the meeting modal
        handleCloseMeetingModal();
      } else {
        console.error('Invalid response from Dyte API');
      }
    } catch (error) {
      console.error('Error creating meeting:', error);
    }
  };

  const isValidDate = (dateString) => {
    const selectedDate = new Date(dateString);
    const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 2));
    return selectedDate >= minDate && selectedDate <= maxDate;
  };





  return (
    <div className="batch-form-container">


      <Modal show={showConfirmationModal} onHide={handleCancelSubmit} centered>
        <Modal.Header closeButton>
          <Modal.Title>Save Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to save this batch?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSubmit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <form onSubmit={handleSubmit} className="row" style={containerStyle}>
        <h2>Batch Form  (Newton International College)</h2>
        < br />
        < br />
        < br />

        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="batchCode">Batch Code:</label>
            <input
              type="text"
              id="batchCode"
              className="form-control"
              name="batchcode"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              value={batchData.batchcode}
              onChange={handleInputChange}
              placeholder="Enter batch code"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="name">Course:</label>
            <select
              name="course"
              value={batchData.course}
              className="form"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              onChange={handleInputChange}
              required // Add the required attribute here
            >
              <option value="" disabled>
                Select a course
              </option>
              {courses.map((course) => (
                <option key={course.id} value={`${course.name}(${course.id})`}>
                  {course.name}
                </option>
              ))}
            </select>
          </div>






          {/* <div className="form-group">
            <label htmlFor=" attendanceqrlink" >Attendance Link:</label>
            <input
              type="text"
              className="form-control"
              name="attendanceqrlink"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              value={batchData.attendanceqrlink}
              onChange={handleInputChange}
              required
            />
          </div> */}



          <div className="form-group">
            <label>Online Class Link:</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="onlineclasslink"
                value={batchData.onlineclasslink}
                onChange={handleInputChange}
                placeholder="Enter online class link"
                style={{ height: '42px', borderRadius: '8px 0 0 6px', border: '1px solid #ced4da' }} // Adjusted styles
                required
              />
              <button
                type="button"

                onClick={handleShowMeetingModal}
                style={{
                  background: "rgb(30, 144, 255)",
                  color: "white",
                  border: "none",
                  borderRadius: '0 8px 8px 0',
                  padding: "5px 10px",
                  cursor: "pointer",
                }}
              >
                +
              </button>
            </div>
          </div>




          {/* Displaying the Dyte meeting link */}
          {/* {meetingLink && (
        <div>
          <h2>Dyte Meeting Link:</h2>
          <a href={meetingLink} target="_blank" rel="noopener noreferrer">
            {meetingLink}
          </a>
        </div>
      )} */}

          <Modal show={showMeetingModal} onHide={handleCloseMeetingModal}>
            <Modal.Header closeButton>
              <Modal.Title>Enter Meeting Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                placeholder="Enter meeting title"
                value={meetingTitle}
                onChange={handleMeetingTitleChange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseMeetingModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleCreateMeeting}>
                Create
              </Button>
            </Modal.Footer>
          </Modal>
          <br />



          <div className="form-group">

            <label>
              E Sign_Required:
              <input
                type="checkbox"
                name="esignrequired"
                checked={batchData.esignrequired === "yes"} // Use "yes" or any other value you want to represent true
                onChange={handleCheckboxChange}
                style={{ verticalAlign: '-10%' }}
              />
            </label>
          </div>


          <br />




        </div>
        <div className="col-md-6">



          <div className="form-group">
            <label>Intake Size</label>
            <input
              type="number"
              pattern="[0-9]*" // Allow only numeric input
              className="form-control"
              name="intakesize"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              value={batchData.intakesize}
              maxLength={3}
              onChange={(e) => {
                const inputNumber = e.target.value.slice(0, 3); // Restrict input to first 3 characters
                setBatchData((prevState) => ({
                  ...prevState,
                  intakesize: inputNumber === '' ? 0 : parseInt(inputNumber, 10),
                }));
              }}
              title='3 Numbers Only'
              required
            />
          </div>


          <div className="form-group">
            <label>Threshold:</label>
            <input
              type="number"
              className="form-control"
              name="threshold"
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              value={batchData.threshold !== 0 ? batchData.threshold : ''}
              onChange={(e) => {
                const inputNumber = e.target.value.slice(0, 3); // Restrict input to first 3 characters
                const newThreshold = inputNumber === '' ? 0 : parseInt(inputNumber, 10);


                // Enforce the condition: threshold should be less than or equal to intake size
                if (newThreshold <= batchData.intakesize) {
                  setBatchData((prevState) => ({
                    ...prevState,
                    threshold: newThreshold,
                  }));
                } else {
                  // Display a warning or handle the condition when threshold is greater than intake size
                  console.warn('Threshold should be less than or equal to Intake Size');
                }
              }}
              title='3 Numbers Only'
              required
            />
          </div>





          <div className="form-group">
            <label>Venue:</label>
            <select
              name="venue"
              className="form-control"
              value={batchData.venue}
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>Select a venue</option>
              {venues.map((venue) => (
                <option key={venue.id} value={venue.roomno}>
                  {venue.roomno}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Status:</label>
            <select
              className="form-control"
              name="status"
              value={batchData.status}
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Status</option>
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="cancel">Cancel</option>
            </select>


          </div>
          <br />
          <button variant="btn-primary " onClick={handleAddTrainer}
            style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px', fontSize: '13px' }}
          >
            <FaPlus /> Add Trainer
          </button>

        </div>


        <div>


          <div>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Trainer/Assessor Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div className="input-group mb-3" >


                      <span className="input-group-text" style={{ height: '45px' }}>Search</span>
                      <input
                        type="text"
                        id="search"
                        className="form-control"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ height: " 45px " }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <span className="input-group-text">Show</span>
                      <select
                        id="itemsPerPage"
                        className="form-control"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(Number(e.target.value))}
                        style={{ height: " 45px " }}
                      >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                      </select>



                    </div>
                  </div>
                </div>


                {facilitatorData &&
                  facilitatorData.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>NRIC</th>
                            <th>Add Trainer</th>
                            <th>Add Assessor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {facilitatorData
                            .filter(
                              (facilitator) =>
                                facilitator.name.toLowerCase().includes(searchText.toLowerCase()) ||
                                facilitator.email.toLowerCase().includes(searchText.toLowerCase()) ||
                                facilitator.nric.toLowerCase().includes(searchText.toLowerCase())
                            )
                            .slice(
                              (currentPage - 1) * itemsPerPage,
                              currentPage * itemsPerPage
                            )
                            .map((facilitator) => (
                              <tr key={facilitator.id}>
                                <td>{facilitator.name}</td>
                                <td>{facilitator.email}</td>
                                <td>{facilitator.nric}</td>
                                <td>
                                  <button
                                    className="primary"
                                    type='button'
                                    onClick={() =>
                                      handleSelectTrainer(
                                        facilitator.name +
                                        '(' +
                                        facilitator.id +
                                        ')'
                                      )
                                    }
                                    disabled={selectedTrainers.includes(
                                      facilitator.name + '(' + facilitator.id + ')'
                                    )} style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px' }}
                                  >
                                    <FaPlus /> Add
                                  </button>
                                </td>
                                <td>
                                  <button
                                    className="primary"
                                    onClick={() =>
                                      handleSelectAssessor(
                                        facilitator.name +
                                        '(' +
                                        facilitator.id +
                                        ')'
                                      )
                                    }
                                    disabled={selectedAssessors.includes(
                                      facilitator.name + '(' + facilitator.id + ')')
                                    }
                                    style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px' }}
                                  >
                                    <FaPlus /> Add
                                  </button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'end', float: 'inline-end' }}  >
                      <div className="pagination" style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() =>
                            setCurrentPage((prevPage) =>
                              Math.max(prevPage - 1, 1)
                            )
                          }
                          disabled={currentPage === 1}
                          style={{ width: '100px', marginRight: '10px' }} // Adjust width as needed
                        >
                          <span aria-hidden="true">&laquo;</span>
                        </button>
                        <span className="mx-2">Page {currentPage}</span>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() =>
                            setCurrentPage((prevPage) =>
                              Math.min(
                                prevPage + 1,
                                Math.ceil(facilitatorData.length / itemsPerPage)
                              )
                            )
                          }
                          disabled={
                            currentPage ===
                            Math.ceil(facilitatorData.length / itemsPerPage)
                          }
                          style={{ width: '100px' }} // Adjust width as needed
                        >
                          <span aria-hidden="true">&raquo;</span>
                        </button>
                      </div>



                    </div>
                  </>
                ) : (
                  <p>No facilitator data found.</p>
                )}

              </Modal.Body>
            </Modal>




            <div>
              {selectedTrainers.length > 0 && (
                <div>
                  <h3>Selected Trainers:</h3>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {selectedTrainers.map((trainer, index) => {
                      // Use regular expression to extract the trainer name
                      const trainerName = trainer.match(/^[^\d()]*/)[0].trim();
                      return (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "10px 0",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <div>
                            {index + 1}. {trainerName}
                          </div>
                          <button
                            className="primary"
                            onClick={() => handleDeleteTrainer(trainer)}
                            style={{ backgroundColor: 'red', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px' }}
                          >
                            Remove
                          </button>
                        </li>
                      );
                    })}
                  </ul>




                  {/* <p>
        Total Selected Trainers: {selectedTrainers.length}
      </p> */}
                </div>
              )}
            </div>


            <div>
              {selectedAssessors.length > 0 && (
                <div>
                  <h3>Selected Assessors:</h3>
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {selectedAssessors.map((assessor, index) => {
                      // Use regular expression to extract the assessor name
                      const assessorName = assessor.match(/^[^\d()]*/)[0].trim();




                      return (
                        <li
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: "10px 0",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <div>
                            {index + 1}. {assessorName}
                          </div>
                          <button
                            className="primary"
                            onClick={() => handleDeleteAssessor(assessor)}
                            style={{ backgroundColor: 'red', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px' }}

                          >
                            Remove
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                  <p>
                    Total Selected Facilitators: {selectedTrainers.length + selectedAssessors.length}
                  </p>
                  {/* <p>
        Total Selected Assessors: {selectedAssessors.length}
      </p> */}
                </div>
              )}
            </div>




          </div>
        </div>
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
          {batchData.days.map((day, dayIndex) => (
            <div key={dayIndex} className="day-container">
              <h3>Day {dayIndex + 1}</h3>


              <div className="form">
                <label>Date:</label>
                <input
                  type="date"
                  className="form-control"
                  value={day.batchDate || ''}
                  onChange={(e) => handleDateChange(dayIndex, e.target.value)}
                  min={(new Date(new Date().setFullYear(new Date().getFullYear() - 2))).toISOString().split('T')[0]}
                  max={(new Date(new Date().setFullYear(new Date().getFullYear() + 2))).toISOString().split('T')[0]}
                />
                {day.batchDate && !isValidDate(day.batchDate) && (
                  <p style={{ color: 'red' }}>Please select a date within the allowed range.</p>
                )}
              </div>






              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Morning From:</label>
                    <input
                      type="time"
                      className="form-control"
                      value={day.morningFrom[dayIndex + 1] || ''}
                      onChange={(e) =>
                        handleTimeChange(dayIndex, 'morningFrom', dayIndex + 1, e.target.value)
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Morning To:</label>
                    <input
                      type="time"
                      className="form-control"
                      value={day.morningTo[dayIndex + 1] || ''}
                      onChange={(e) =>
                        handleTimeChange(dayIndex, 'morningTo', dayIndex + 1, e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>Evening From:</label>
                    <input
                      type="time"
                      className="form-control"
                      value={day.eveningFrom[dayIndex + 1] || ''}
                      onChange={(e) =>
                        handleTimeChange(dayIndex, 'eveningFrom', dayIndex + 1, e.target.value)
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Evening To:</label>
                    <input
                      type="time"
                      className="form-control"
                      value={day.eveningTo[dayIndex + 1] || ''}
                      onChange={(e) =>
                        handleTimeChange(dayIndex, 'eveningTo', dayIndex + 1, e.target.value)
                      }
                    />
                  </div>
                  <br />
                  <div className="form-group">
                    <label>Assessment:</label>
                    <input
                      type="checkbox"
                      checked={day.assessment}
                      onChange={(e) => handleInputChange(e, dayIndex)}
                      name="assessment"
                    />
                  </div>
                </div>
              </div>
              <Button
                type="button"
                className="primary"
                onClick={() => handleRemoveDay(dayIndex)}
                style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '7px', marginTop: '-4%', fontSize: '13px' }}

              >
                <FaMinus />  Remove Day
              </Button>


            </div>
          ))}
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2%' }}>
            <Button type="button" className="primary" onClick={handleAddDay} style={{ backgroundColor: '#1E90FF', color: 'white', padding: '5px', maxWidth: '100px', width: '100%', fontSize: '12px' }}>
              Add Day
            </Button>
            <Button type="submit" className="primary" style={{ backgroundColor: 'green', padding: '5px', maxWidth: '100px', width: '100%', fontSize: '12px' }} >
              Submit
            </Button>
            <Button type="button" className="btn-cancel" onClick={handleCancel} style={{
              backgroundColor: 'red', fontSize: '12px',
              padding: '5px', maxWidth: '100px', width: '100%', fontSize: '12px'
            }} >
              Cancel
            </Button>
          </div>
        </div>
      </form>
      {showConfirmation && (
        <Modal show={showConfirmation} onHide={closeConfirmation} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove this day?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeConfirmation} style={{ backgroundColor: 'grey' }}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmRemoveDay} style={{ backgroundColor: 'green' }}   >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>

      )}
      <Modal show={showCancelConfirmationModal} onHide={handleCancelCancel} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelCancel}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirmCancel}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer position="bottom-right" />
    </div>
  );
};




const New = ({ onFormSubmit }) => {
  return (
    <div>


      <BatchForm onFormSubmit={onFormSubmit} />
    </div>
  );
};




export default New;



