import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from '../Axiosinstance';
import Header from './Header';
import { Modal, Button  } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function NewFacilitator({ onFormSubmit }) {
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [facilitator, setFacilitator] = useState({
    salutation: '',
    nric: '',
    email: '',
    phone: '',
    educational_level: '',
    qualification: '',
    domain: '',
    gender: '',
    password: '',
    name: '',
    usertype1: '',
  });
  const [error, setError] = useState('');




  const [errors, setErrors] = useState({
    salutation: '',
    nric: '',
    email: '',
    phone: '',
    educational_level: '',
    qualification: '',
    domain: '',
    gender: '',
    password: '',
    name: '',
    usertype1: '',
  });




  const {
    salutation,
    nric,
    email,
    phone,
    educational_level,
    qualification,
    domain,
    gender,
    password,
    name,
    usertype1
  } = facilitator;




  const onInputChange = (e) => {
    const { name, value } = e.target;




    // Update the facilitator state
    setFacilitator({ ...facilitator, [name]: value });




    // Clear the error message for the specific field
    setErrors({ ...errors, [name]: '' });
  };
 
  const onSubmit = (e) => {
    e.preventDefault();
 
    // Validate the form fields
    const validationErrors = {};
 
    if (!salutation) {
      validationErrors.salutation = 'Salutation is required';
    }
 
    if (!name) {
      validationErrors.name = 'Name is required';
    }
 
    if (!usertype1) {
      validationErrors.usertype1 = 'User Type is required';
    }
 
    if (!nric) {
      validationErrors.nric = 'NRIC is required';
    } else if (nric.length > 30) {
      validationErrors.nric = 'NRIC should be 30 characters maximum';
    }
 
    if (!email) {
      validationErrors.email = 'Email is required';
    }
 
    if (!phone) {
      validationErrors.phone = 'Phone is required';
    }
 
    if (!educational_level) {
      validationErrors.educational_level = 'Educational Level is required';
    } else if (educational_level.length > 30) {
      validationErrors.educational_level = 'Educational Level should be 30 characters maximum';
    }
 
    if (!qualification) {
      validationErrors.qualification = 'Qualification is required';
    } else if (qualification.length > 30) {
      validationErrors.qualification = 'Qualification should be 30 characters maximum';
    }
 
    if (!domain) {
      validationErrors.domain = 'Domain is required';
    } else if (domain.length > 30) {
      validationErrors.domain = 'Domain should be 30 characters maximum';
    }
 
    if (!gender) {
      validationErrors.gender = 'Gender is required';
    }
 
    if (!password) {
      validationErrors.password = 'Password is required';
    }
 
    // If there are validation errors, update the state and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
 
    {
      // Your logic for adding a facilitator here
   
      // Displaying the toast message
      toast.success("Facilitator added successfully!", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
     




   
    axios
      .get('facilitators', {
        params: {
          name: name,
          email: email,
        },
      })
      .then((checkResponse) => {
        // If a facilitator with the same name and email is found, show an error message
        if (checkResponse.data.some(facilitator => facilitator.email === email)) {
          setError('Facilitator with the same email already exists.');
        }
     
        else {
          // If no matching facilitator is found, proceed to create a new facilitator
          axios
            .post('facilitator', facilitator)
            .then((response) => {
              const newFacilitatorId = response.data.id;




              // Now, use the 'user' API endpoint to add user information
              const userData = {
                email: email,
                name:name,
                username: name,
                password,
                usertype: 2,
                sfid: newFacilitatorId,
              };




              // Make a POST request to the 'user' endpoint
              axios
                .post('auth/create-user', userData)
                .then((userResponse) => {
                  // console.log('User added successfully', userResponse.data);
                  navigate('/facilitor');
                  onFormSubmit();




                })
                .catch((userError) => {
                  console.error('Error adding user:', userError);
                  setError('Error adding user');
                });
            })
            .catch((error) => {
              console.error(error);
              setError('Error creating facilitator');
            });
        }
     
      })
      .catch((checkError) => {
        console.error(checkError);
        setError('Error checking facilitator');
      });
 




  }
  const [isFormDirty, setIsFormDirty] = useState(false);




  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };








  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };








    window.addEventListener('beforeunload', handleBeforeUnload);








    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);












 




  const handleCancel = () => {
   
     
   {
      onFormSubmit();
    }
  };




  const handleConfirmSave = () => {
    setShowConfirmationModal(false);
    onFormSubmit();
  };




  const handleCancelSave = () => {
    setShowConfirmationModal(false);
  };








  return (
    <div className="containerw mx-auto" style={{
      maxWidth: '1000px',
      background: '#ffffff',
      padding: '20px',
      boxShadow: '0px 0px 2px 0px #000000',
      borderRadius: '10px',
    }}>
      <h4> New Facilitator (Newton International College)</h4>
      <hr style={{ marginTop: '1%' }} />
      {error && <div className="text-danger">{error}</div>}
      <form onSubmit={onSubmit} onChange={handleInputChange} style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ marginRight: '20px', flex: 1 }}>
          <div className="form-group">
            <label>Salutation</label>
            <select
              className="form-control"
              name="salutation"
              value={facilitator.salutation}
              onChange={onInputChange}
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}              required
            >
              <option value="">Select Salutation</option>
              <option value="Mr.">Mr.</option>
              <option value="Mrs.">Mrs.</option>
              <option value="Miss">Miss</option>
            </select>
            {errors.salutation && (
              <div className="text-danger">{errors.salutation}</div>
            )}
          </div>




          <div className="form-group">
  <label htmlFor="name">Name</label>
  <input
    type="text"
    className="form-control"
    name="name"
    value={facilitator.name}
    required
   
    onChange={(e) => {
      const inputValue = e.target.value;
      const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces




      if ((inputValue.length <= 30 && onlyLetters.test(inputValue)) || inputValue === "") {
        onInputChange(e);
      }
    }}
    title={facilitator.name.length >= 30 ? "Maximum 30 characters only" : ""}
    style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}  />
  {(errors.name || !facilitator.name) && <div className="text-danger">{errors.name }</div>}
</div>








          <div className="form-group">
            <label>User Type</label>
            <select
              className="form-control"
              name="usertype1" // Use the correct name here
              value={facilitator.usertype1} // Use the correct name here
              onChange={onInputChange}
              required
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}            >
              <option value="">Select User Type</option>
              <option value="new">New</option>
              <option value="existing">Existing</option>
            </select>
            {errors.usertype1 && (
              <div className="text-danger">{errors.usertype1}</div>
            )}
          </div>








          <div className="form-group">
            <label htmlFor="nric">NRIC/FIN</label>
            <input
              type="text" // Change the type to text to allow both characters and numbers
              className="form-control"
              name="nric"
              value={facilitator.nric}
             
              onChange={(e) => {
                const inputValue = e.target.value;




                if (inputValue.length <= 9) {
                  onInputChange(e);
                }
              }}
              required
              title={facilitator.nric.length >= 12 ? "maximum 12 characters " : ""}
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}            />
             {(errors.nric || !facilitator.nric) && <div className="text-danger">{errors.nric  }</div>}
          </div>








          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={facilitator.email}
              onChange={onInputChange}
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}              required
            />
            {(errors.email || !facilitator.email) && <div className="text-danger">{errors.email  }</div>}
          </div>




          <div className="form-group">
            <label htmlFor="phone">Phone</label>
            <input
              type="number"
              id="phone"
              className="form-control"
              name="phone"
              value={facilitator.phone}
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}


              onChange={(e) => {
                const inputText = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                const formattedText = inputText.substring(0, 8);
               
                // Update the facilitator state
                setFacilitator({ ...facilitator, phone: formattedText });
              }}
              required
            />
            {(errors.phone || !facilitator.phone) && <div className="text-danger">{errors.phone  }</div>}
          </div>
        </div>
        <div style={{ flex: 1 }}>








       
        <div className="form-group">
            <label htmlFor="educational_level">Educational Level</label>
            <input
              type="text"
              className="form-control"
              name="educational_level"
              value={facilitator.educational_level}
              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z0-9\s]+$/;// Regular expression for letters and spaces




                // If the input matches the regular expression or is empty, update the state
                if ((inputValue.length <= 20 && onlyLetters.test(inputValue)) || inputValue === "") {
                  setFacilitator((prevFacilitator) => ({
                    ...prevFacilitator,
                    educational_level: inputValue,
                  }));
                }
              }}
              required
              title={
                facilitator.educational_level.length >= 20 ? "20 characters maximum" : "Only letters and spaces allowed"
              }
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}            />




            {(errors.educational_level || !facilitator.educational_level) && <div className="text-danger">{errors.educational_level  }</div>}
          </div>




         
          <div className="form-group">
            <label htmlFor="qualification">Qualification</label>
            <input
              type="text"
              className="form-control"
              name="qualification"
              value={facilitator.qualification}
              onChange={(e) => {
                const inputValue = e.target.value;
                const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces




                // If the input matches the regular expression or is empty, update the state
                if ((inputValue.length <= 20 && onlyLetters.test(inputValue)) || inputValue === "") {
                  setFacilitator((prevFacilitator) => ({
                    ...prevFacilitator,
                    qualification: inputValue,
                  }));
                }
              }}
              required
              title={
                facilitator.qualification.length >= 20 ? "20 characters maximum" : "Only letters and spaces allowed"
              }
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}            />




            {(errors.qualification || !facilitator.qualification) && <div className="text-danger">{errors.qualification  }</div>}
          </div>








         
          <div className="form-group">
            <label htmlFor="domain">Domain</label>
            <input
              type="text"
              className="form-control"
              name="domain"
              value={facilitator.domain}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphanumericRegex = /^[a-zA-Z0-9\s]*$/; // Regular expression for alphanumeric and spaces




                // If the input matches the regular expression or is empty, update the state
                if ((inputValue.length <= 20 && alphanumericRegex.test(inputValue)) || inputValue === "") {
                  onInputChange(e);
                }
              }}
              required
              title={
                facilitator.domain.length >= 20 ? "20 characters maximum" : "Only alphanumeric characters and spaces allowed"
              }
              style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' ,width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}            />




            {(errors.domain || !facilitator.domain) && <div className="text-danger">{errors.domain }</div>}
          </div>








         




          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={facilitator.password}
              style={{ width: '100%', height: '42px', borderRadius: '6px', border: '1px solid #ced4da' }}
              onChange={onInputChange}
              autoComplete="new-password"
            />
           {(errors.password || !facilitator.password) && <div className="text-danger">{errors.password || ""}</div>}
          </div>


          <div className="form-group">
            <label>Gender</label>
            <div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="gender"
                  value="Male"
                  checked={facilitator.gender === 'Male'}
                  onChange={onInputChange}
                  required
                />
                <label className="form-check-label">Male</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="gender"
                  value="Female"
                  checked={facilitator.gender === 'Female'}
                  onChange={onInputChange}
                  required
                />
                <label className="form-check-label">Female</label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  name="gender"
                  value="Other"
                  checked={facilitator.gender === 'Other'}
                  onChange={onInputChange}
                  required
                />
                <label className="form-check-label">Other</label>
              </div>
            </div>
            {(errors.gender || !facilitator.gender) && <div className="text-danger">{errors.gender }</div>}
          </div>
          <>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <button
      type="button"
      className="primary"
      onClick={onSubmit}
      style={{
        backgroundColor: 'green',
        fontSize: '13px',
        color: 'white',
        border: 'none',
        padding: '8px 15px',
        borderRadius: '7px'
      }}
    >
      <FaPlus /> Add Facilitator
    </button>
    {/* Add this div to render the toast messages */}
    <div style={{ marginLeft: '1%', marginTop: '2%' }}>
      <ToastContainer />
    </div>
   
    <button
      type="button"
      className="primary"
      onClick={handleCancel}
      style={{
        background: 'red',
        fontSize: '13px',
        color: 'white',
        border: 'none',
        padding: '8px 30px',
        borderRadius: '7px'
      }}
    >
      Cancel
    </button>
  </div>
</>




      {/* Confirmation Modal */}
      {/* <Modal
  show={showConfirmationModal}




  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '400px', // Adjust this value to move the modal down
  }}
> */}




        {/* <Modal.Header closeButton>
          <Modal.Title>Confirm Save</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to save changes?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSave} style={{ backgroundColor: 'red', color: 'white' }}>
 
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCancel} style={{ backgroundColor: 'green', color: 'white' }}>
 
            sure
          </Button>
        </Modal.Footer>
      </Modal> */}
        </div>
       
      </form>




    </div>
  );
}




const Batchess = ({ onFormSubmit }) => {
  return (
    <div>




      <NewFacilitator onFormSubmit={onFormSubmit} />
    </div>
  );
};








export default Batchess;


















