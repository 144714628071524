import React, { useState, useEffect } from 'react';
import { Categories } from './Individual';
import { Last } from './Homepage';
import { useParams } from 'react-router-dom';
import axios from '../Axiosinstance'
import './Courses.css'; // Import the CSS file
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Loader from '../Loader';
import { useNavigate } from 'react-router-dom';
import {logoImage} from '../common'


function Category() {
  const [category, setCategory] = useState({});
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`category/${categoryId}`);
        setCategory(response.data);
        setLoading(false); // Set loading to false when data is received
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, [categoryId]);

  

  return (
    <div>
       {loading ? (
        <Loader /> // Show loader while waiting for API response
      ) : (
        <>
      <Nav />
      <div className="banner">
        <img
          src={category.url}
          alt="Banner Image"
          className="banner-image"
        />
        <div className="banner-content">
          <h1 className="banner-heading">{category.name}</h1>
        </div>
      </div>
      <Courses />
      <Categories />
      <Last />
      </>
      )}
    </div>
  );
}


const Nav = () => {
  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue); // Handle change for Tabs component
  };

  const toggleHamburger = () => {
    setHamburgerActive((prevState) => !prevState);
    setNavActive((prevState) => !prevState);
  }
  return (
    <>
      <div >
        <div style={{height:'200px'}}>
          <nav className="navbar" style={{ backgroundColor: '#fff', height: '90px' }}>
            <img
              src={logoImage}
              alt="Impluz Logo"
              style={{ width: '170px', height: '60px', position: 'absolute', top: '10px', left: '10px' }}
            />

            <div className="logo" id="MainSection">
              <div className='Impluz'></div>
            </div>
            <ul className={`nav-list ${isNavActive ? 'active' : ''}`}>
              <li><a className="nav-link" href="#MainSection">Home</a></li>
              <li><a className="nav-link" href="#Feature">Features</a></li>
              {value !== 0 && <li><a className="nav-link" href="#ssg">SSG-WSQ</a></li>}
              <li><a className="nav-link" href="#Course">Courses</a></li>
              <li><a className="nav-link" href="/login">Demo Login</a></li>
              <li><a className="nav-link" href="#Blogs">Blogs</a></li>
              <li><a className="nav-link" href="#Last-section">Contact Us</a></li>
            </ul>
            <div className={`hamburger ${isHamburgerActive ? 'active' : ''}`} onClick={toggleHamburger}>
              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

const Courses = () => {
  const { categoryId } = useParams();
  const [matchedCourses, setMatchedCourses] = useState([]);
  const [zindex, setZIndex] = useState(10); // State for zindex
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [studentName, setStudentName] = useState('');
  const [studentEmail, setStudentEmail] = useState('');
  const [showModalStudentDetails, setShowModalStudentDetails] = useState(false);
  const [showModal1, setShowModal1] = useState(false); // State for Modal 1
  const [showModal2, setShowModal2] = useState(false);
  const navigate = useNavigate(); // Get the navigate function from React Router

  const handleShowModal = () => {
    setShowModal(true); // Shows the modal
    setShowModalStudentDetails(true); // Shows the student details section
  };
  useEffect(() => {
    axios.get('courses')
      .then(response => {
        const data = response.data;
        const coursesMatchingCategory = data.filter(course => categoryId == course.categoryId);
        setMatchedCourses(coursesMatchingCategory);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, [categoryId]);

  const toggleCard = (e, card, course) => {
    e.preventDefault();

    let isShowing = false;
    if (card.classList.contains('show')) {
      isShowing = true;
    }

    const allCards = document.querySelectorAll('.card');
    allCards.forEach(c => c.classList.remove('show'));

    const allCardContainers = document.querySelectorAll('.cards');
    allCardContainers.forEach(c => c.classList.remove('showing'));

    if (!isShowing) {
      card.style.zIndex = zindex;
      card.classList.add('show');
      card.parentElement.classList.add('showing');
      setZIndex(zindex + 1);
      setSelectedCourse(course);
      setShowModal(true);
    }
    setShowModal(true);
    setShowModal1(true);
  };


  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');


  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStudentDetailsSubmit = () => {
    // Handle the submission of student details, e.g., API call or state management
    // For simplicity, log the details in the console for demonstration purposes
    console.log("Student Name:", studentName);
    console.log("Student Email:", studentEmail);
    handleClose();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const handleShowModalStudentDetails = () => {
    setShowModal2(true); // Show Modal 2
    setFormData({
      ...formData,
      batch: selectedCourse.id, // Assuming `selectedCourse.id` holds the course ID
      batch_id: selectedCourse.name // Assuming `selectedCourse.name` holds the course name
    });
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };


  const handleCloseModal = () => {
    setShowModal(false);
  };







  const CLOUDINARY_URL = baseURL;
  const CLOUDINARY_UPLOAD = CLOUDINARY_UPLOAD_PRESET;

  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const [formData, setFormData] = useState({
    fullname: '',
    nric: '',
    email: '',
    phone: '',
    gender: '',
    dob: '',
    password: '',
    address: '',
    postalcode: '',
    race: '',
    education: '',
    employmentstatus: '',
    salary: '',
    companyname: '',
    corporatecompanysponsored: '',
    designation: '',
    status: false,
    profileUrl: '',
    batch: '',
    batch_id: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'gender') {
      setFormData({
        ...formData,
        gender: value
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };







  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isUploading) {
      try {
        const formDataWithImage = new FormData();

        // Append all form data fields
        Object.keys(formData).forEach((key) => {
          formDataWithImage.append(key, formData[key]);
        });

        if (selectedFile) {
          // Append the file data if an image is selected
          formDataWithImage.append('file', selectedFile);
          formDataWithImage.append('upload_preset', CLOUDINARY_UPLOAD);
          formDataWithImage.append('public_id', 'LMS/RequestStudent/' + selectedFile.name);

          setIsUploading(true);
          try {
            const confirmed = window.confirm('Are you sure you want to request?');
            if (!confirmed) {
              return; // If not confirmed, exit the function
            }
            const response = await fetch(CLOUDINARY_URL, {
              method: 'POST',
              body: formDataWithImage,
            });
            const data = await response.json();
            if (data.secure_url !== '') {
              const url = data.secure_url;
              formDataWithImage.set('profileUrl', url);
              setIsUploading(false);
            }
          } catch (err) {
            console.error(err);
            setIsUploading(false);
          }
        }
        if (selectedCourse) {
          // Set batch_id and batch using selectedCourse
          formDataWithImage.set('batch_id', selectedCourse.id);
          formDataWithImage.set('batch', selectedCourse.name);
        }
        // Send formDataWithImage (with image and form data) to the server
        const response = await axios.post('requeststudent', formDataWithImage);

        console.log('Data sent:', response.data);

        // Clear the form
        setFormData({
          fullname: '',
          nric: '',
          email: '',
          phone: '',
          gender: '',
          dob: '',
          password: '',
          address: '',
          postalcode: '',
          race: '',
          education: '',
          employmentstatus: '',
          salary: '',
          companyname: '',
          corporatecompanysponsored: '',
          designation: '',
          status: '',
          profileUrl: '',
          batch: '',
          batch_id: ''
        });
        setSelectedFile(null); // Clear the selected file
        navigate('/');
      } catch (error) {
        console.error('Error:', error);
      }
    }


  };


  const clearForm = () => {
    setFormData({
      fullname: '',
      nric: '',
      email: '',
      phone: '',
      gender: '',
      dob: '',
      password: '',
      address: '',
      postalcode: '',
      race: '',
      education: '',
      employmentstatus: '',
      salary: '',
      companyname: '',
      corporatecompanysponsored: '',
      designation: '',
      status: '',
      profileUrl: '',
      batch: '',
      batch_id: ''
    });

    setSelectedFile(null); // Clear the selected file
  };


  const truncateDescription = (description) => {
    const maxWords = 20;
    const words = description.split(' ');

    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...'; // Truncate description
    }
    return description;
  };


  return (
    <div className="cards-container">
{matchedCourses.map((course) => (
  <div className="" key={course.reference}>
    
    <div className="card hover-effect" onClick={(e) => toggleCard(e, e.currentTarget, course)}>
      <div className="card__image-holder">
        <img className="card__image" src={course.url} alt={course.name} />
      </div>
      <div className="card-content">
        <h2>{course.name}</h2>
        <div className="card-description">
          <div dangerouslySetInnerHTML={{ __html: truncateDescription(course.description) }}></div>
        </div>
        <div className="card-actions">
          <Button variant="contained" color="primary" className="btn">Read more</Button>
        </div>
      </div>
    </div>
  </div>
))}

      {selectedCourse && (
        <Modal show={showModal1} onHide={handleCloseModal1} size="fullscreen">
          <Modal.Header closeButton>
            <Modal.Title>{selectedCourse.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{ maxWidth: '1600px', margin: '0 auto', padding: '0 20px' }}>
      <div className="course-details">
      <div className="course-image-container">
  <img
    src={selectedCourse.url}
    alt={selectedCourse.name}
    className="course-image"
    style={{ width: '100%', height: 'auto' }}
  />
</div>

        <div className="course-details-right">
          <h2 className="course-name">{selectedCourse.name}</h2>
          <div className="entry-requirements">
            <label className="label fw-bold">Entry Requirements:</label>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: selectedCourse.description }}
            ></div>
          </div>
          <div className="course-info">
            <div className="fee">
              <span className="label fw-bold">Course fee:</span><br/>
              <span className="value">{selectedCourse.fee}</span>
            </div>
            <div className="duration">
              <span className="label fw-bold">Course duration:</span><br/>
              <span className="value">{selectedCourse.duration} </span>
            </div><br/>
            <Button variant="contained" color="primary" onClick={handleShowModalStudentDetails}>
              For Enquiry
            </Button>
          </div>
        </div>
      </div>
      <div className="course-additional-info">
        <div className="teacher-student-ratio">
          <label className="label fw-bold">Teacher and Students ratio:</label>
          <div
            className="outcome"
            dangerouslySetInnerHTML={{ __html: selectedCourse.outcome }}
          ></div>
        </div>
        <div className="graduation-requirement">
          <label className="label fw-bold">Graduation requirement:</label>
          <div
            className="requirement"
            dangerouslySetInnerHTML={{ __html: selectedCourse.requirement }}
          ></div>
        </div>

  

                {showModal1 && showModal2 && (
                  <Modal show={showModal2} onHide={handleCloseModal2} dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Student Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* <div>
                        <TextField
                          label="Name"
                          variant="outlined"
                          value={studentName}
                          onChange={(e) => setStudentName(e.target.value)}
                          fullWidth
                          margin="normal"
                          autoFocus
                        />
                        <TextField
                          label="Email"
                          variant="outlined"
                          value={studentEmail}
                          onChange={(e) => setStudentEmail(e.target.value)}
                          fullWidth
                          margin="normal"
                        />
                      </div> */}
                      <div className="container mt-8">
                        <h2>Request Student Form</h2>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="fullname" className="form-label">Full Name</label>
                              <input type="text" required name="fullname" value={formData.fullname} onChange={handleInputChange} placeholder="Full Name" className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="nric" className="form-label">NRIC</label>
                              <input
                                type="password"
                                required
                                name="nric"
                                value={formData.nric}
                                onChange={handleInputChange}
                                placeholder="NRIC"
                                autoComplete="off"
                                className="form-control"
                              />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="email" className="form-label">Email</label>
                              <input type="email" required name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" className="form-control" autocomplete="off" />
                            </div>
                          </div>
                          <div className="row mb-3">

                            <div className="col-md-4">
                              <label htmlFor="phone" className="form-label">Phone</label>
                              <input type="number" required name="phone" value={formData.phone} onChange={handleInputChange} placeholder="Phone" className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="gender" className="form-label">Gender</label>
                              <select
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                className="form-control"
                              >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>


                            </div>
                            <div className="col-md-4">
                              <label htmlFor="dob" className="form-label">Date of Birth</label>
                              <input type="date" required name="dob" value={formData.dob} onChange={handleInputChange} placeholder="Date of Birth" className="form-control" />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="password" className="form-label">Password</label>
                              <input type="password" required name="password" value={formData.password} onChange={handleInputChange} placeholder="Password" className="form-control" autocomplete="off" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="address" className="form-label">Address</label>
                              <input type="text" required name="address" value={formData.address} onChange={handleInputChange} placeholder="Address" className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="postalcode" className="form-label">Postal Code</label>
                              <input
                                type="text" required name="postalcode" value={formData.postalcode} onChange={handleInputChange} placeholder="Postal Code" className="form-control"
                              />
                            </div>
                          </div>
                          <div className="row mb-3">

                            <div className="col-md-4">
                              <label htmlFor="race" className="form-label">Race</label>
                              <input type="text" required name="race" value={formData.race} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="education" className="form-label">Education</label>
                              <input type="text" required name="education" value={formData.education} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="employmentstatus" className="form-label">Employment Status</label>
                              <input type="text" required name="employmentstatus" value={formData.employmentstatus} onChange={handleInputChange} className="form-control" />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-md-4">
                              <label htmlFor="salary" className="form-label">Salary</label>
                              <input type="number" name="salary" value={formData.salary} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="companyname" className="form-label">Company Name</label>
                              <input type="text" name="companyname" value={formData.companyname} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="corporatecompanysponsored" className="form-label">Company Sponsored</label>
                              <input type="text" name="corporatecompanysponsored" value={formData.corporatecompanysponsored} onChange={handleInputChange} className="form-control" />
                            </div>
                          </div>
                          <div className="row mb-3">

                            <div className="col-md-4">
                              <label htmlFor="designation" className="form-label">Designation</label>
                              <input type="text" name="designation" value={formData.designation} onChange={handleInputChange} className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="profileUrl" className="form-label">Profile URL</label>
                              <input type="file" name="file" onChange={handleFileChange}
                                className="form-control" />
                              {selectedFile && <div>Selected File: {selectedFile.name}</div>}

                            </div>
                          </div>


                          {/* <div className="col-md-6">
                        <label htmlFor="batch" className="form-label">Batch</label>
                        <input type="text" name="batch" value={formData.batch} onChange={handleInputChange} className="form-control" />
                    </div> */}

                          {/* <div className="row mb-3">
                    <div className="col-md-6">
                        <label htmlFor="batch_id" className="form-label">Batch ID</label>
                        <input type="text" name="batch_id" value={formData.batch_id} onChange={handleInputChange} className="form-control" />
                    </div>
                    
                </div> */}
                          <div className="row mb-3" style={{ display: 'flex', justifyContent: 'end' }}>
                            <div className="col-md-2">
                              {selectedFile && isUploading ? (
                                <Button variant="contained" color="primary" type="submit" disabled>
                                  Uploading Image...
                                </Button>
                              ) : (
                                <Button variant="contained" color="primary" type="submit">
                                  Submit
                                </Button>
                              )}
                            </div>
                            <div className="col-md-1"></div> {/* Add an empty column for spacing */}
                            <div className="col-md-2">
                              <Button variant="contained" color="secondary" onClick={clearForm}>
                                Clear
                              </Button>
                            </div>
                          </div>


                        </form>
                      </div>
                    </Modal.Body>
                    {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal2}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleStudentDetailsSubmit}>
                        Submit
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
                )}
              </div>
            </div>
            <Last />
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal1}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

    </div>
  );
};




export default Category;