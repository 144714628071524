import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TeachHeader from './TeachHeader'
import axios from '../Axiosinstance';
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableContainer, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { logoImage } from '../common'

const getOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return `${day}th`;
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return `${day}st`;
    case 2:
      return `${day}nd`;
    case 3:
      return `${day}rd`;
    default:
      return `${day}th`;
  }
};

const Container = () => {

  const [tabValue, setTabValue] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const { bid: batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);
  const [showAllDates, setShowAllDates] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDropdownChange = (event) => {
    setTabValue(event.target.value);
  };

  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch batch details
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      }
    };

    fetchBatchDetails();
  }, [batchId]);

  if (!batchDetails) {
    return <p>Loading...</p>;
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-us', { month: 'short' });
    const year = date.getFullYear();
    const ordinalDay = getOrdinalSuffix(day);


    return `${ordinalDay} ${month} ${year}`;
  };

  const formattedDates = batchDetails.days.map(day => formatDate(day.batchDate));

  const visibleDates = showAllDates ? formattedDates : formattedDates.slice(0, 2);



  return (
    <div className="container-fluid" style={{ maxWidth: '1050px', marginTop: '-1%' }}>

      <table className="table" style={{ margin: 'auto' }}>
        <tbody>
          <tr>
            <td colSpan="2" className="col-md-6">
              <div className="container-content" style={{ justifyContent: 'space-between' }}>
                <p className="paragraph">Course<span style={{ marginLeft: '50px' }}>:</span> {batchDetails.course.split('(')[0]}</p>
                <div className="course-container">
                  {batchDetails.days.length > 0 && (
                    <div className="course-details">
                      <p className="paragraph">Course Dates<span style={{ marginLeft: '8px' }}>:</span> {visibleDates.join(', ')}</p>
                      {!showAllDates && <button className="see-all-btn" onClick={() => setShowAllDates(true)}>See More</button>}
                      {showAllDates && <button className="see-less-btn" onClick={() => setShowAllDates(false)}>See Less</button>}
                    </div>
                  )}
                </div>              <p className="paragraph">Trainers<span style={{ marginLeft: '47px' }}>:</span>  {batchDetails.trainers.map(trainer => trainer.split('(')[0]).join(', ')}</p>
              </div>
            </td>
            <td colSpan="2" className="col-md-6">
              <div className="container-content">
                <p className="paragraph">Batch <span style={{ marginLeft: '85px' }}>:</span> {batchDetails.batchcode}</p>
                <p className="paragraph">Student Enrolled<span style={{ marginLeft: '10px' }}>:</span> {batchDetails.intakesize}</p>
                <p className="paragraph">Assessors<span style={{ marginLeft: '60px' }}>:</span> {batchDetails.assessors.map(assessor => assessor.split('(')[0]).join(', ')}</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


const Manage = ({ batchDetails }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(5);
  const [tableData, setTableData] = useState([]);
  const { bid: batchId } = useParams();
  const courseId = batchDetails.course.match(/\((\d+)\)/)[1];

  useEffect(() => {

    // Fetch data from the API endpoint using Axios
    axios.get('manages')
      .then(response => {

        // Filter data based on the condition id === batch_id
        const filteredData = response.data.filter(item => item.batch_id === parseInt(batchId));

        // Fetch pc_nyc for each student
        const fetchAssessmentData = async () => {
          const updatedData = await Promise.all(filteredData.map(async (student) => {
            try {
              const assessmentResponse = await axios.get(`/api/assessments/sid/${student.sId}/bid/${student.batch_id}`);
              const pc_nyc = assessmentResponse.data.pc_nyc;
              return { ...student, pc_nyc, assessmentDetails: assessmentResponse.data }; // Merge pc_nyc and assessment details into student object
            } catch (error) {
              console.error('Error fetching assessment data:', error);
              return student; // Return student without pc_nyc and assessment details if error occurs
            }
          }));
          setTableData(updatedData);
        };

        fetchAssessmentData();
      })
      .catch(error => console.error('Error fetching data:', error));
  }, [batchId, courseId]);  // Adding courseId as dependency




  // Function to handle downloading the AR PDF
  const handleDownloadAR = (assessmentDetails, studentName) => {
    // Create a new PDF document
    const doc = new jsPDF();

    // Add logo
    doc.addImage(logoImage, 'PNG', 10, 10, 50, 20); // Assuming logoImage is PNG format, adjust width and height as needed

    // Add heading
    doc.setFontSize(18);
    doc.text("Newton International College", 70, 20); // Adjust positioning as needed
    doc.setFontSize(14);
    doc.text("Assessment Summary Record", 70, 30); // Adjust positioning as needed

    // Add basic details
    const basicDetails = [
      ["Skill Code", assessmentDetails.skillCode],
      ["Skill", assessmentDetails.skill],
      ["Name of the Participant", studentName],
      ["NRIC", assessmentDetails.sNric],
      ["Name of the Assessors", assessmentDetails.assessors.join(', ')],
      ["Assessment Date", assessmentDetails.assessmentDate]
    ];

    // Add templates
    const templatesData = assessmentDetails.templates.map(template => {
      const templateInfo = [template.type, template.learningOutcome];
      const criteriaInfo = template.totalCriteria.map(criteria => [
        criteria.criteria,
        criteria.label,
        criteria.c_nyc,
        criteria.evidences
      ]);
      return { templateInfo, criteriaInfo };
    });

    // Add PC/NYC checkboxes
    const pc_nyc = "This participant has been assessed to be:\n☐ C\n☐ NYC";

    // Add signature lines
    const signatureLines = "By signing, the participant agrees to accept the assessment result\n\nAssessor’s Signature: ________________________________\n\nParticipant’s Signature: ________________________________";

    // Combine all content
    const content = [
      { title: "Details", data: basicDetails },
      { title: "Templates", data: templatesData },
      { title: "C/NYC", data: [[pc_nyc]] },
      { title: "Signatures", data: [[signatureLines]] }
    ];

    // Add content to PDF
    let startY = 40; // Adjust the startY position to leave space for the logo
    content.forEach(({ title, data }) => {
      doc.setFontSize(12);
      doc.text(title, 10, startY);
      if (title === "Templates") {
        data.forEach(({ templateInfo, criteriaInfo }) => {
          doc.text("Type: " + templateInfo[0] + ", Learning Outcome: " + templateInfo[1], 15, startY + 10);
          doc.autoTable({
            startY: startY + 20,
            head: [["Criteria", "Label", "C/NYC", "Evidences"]],
            body: criteriaInfo
          });
          startY = doc.autoTable.previous.finalY + 10;
        });
      } else {
        doc.autoTable({
          startY: startY + 10,
          head: [["Description", "Value"]],
          body: data
        });
        startY = doc.autoTable.previous.finalY + 20;
      }
    });

    // Save the PDF with the student's name as the file name
    doc.save(`${studentName} report.pdf`);
  };







  return (
    <div className="container" style={{ maxWidth: "1050px" }}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'none' }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Student Name</TableCell>
                <TableCell>C/NYC</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage)
                .map((row, index) => (
                  <TableRow key={index} align="center">
                    <TableCell>{row.sName}</TableCell>
                    <TableCell>{row.pc_nyc}</TableCell>
                    <TableCell>
                      <div>
                        <button
                          style={{
                            backgroundColor: 'skyblue',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s ease',
                            marginRight: '10px',
                          }}
                        >
                          <a style={{
                            textDecoration: 'none',
                            color: 'white',
                          }} href={`/Updaate/${courseId}/${batchDetails.id}/${row.id}/${row.sId}`}>Update AR</a>
                        </button>
                        {row.pc_nyc && (
                          <button
                            style={{
                              backgroundColor: 'green',
                              padding: '10px 20px',
                              border: 'none',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                              transition: 'background-color 0.3s ease',
                            }}
                            onClick={() => handleDownloadAR(row.assessmentDetails, row.sName)}
                          >
                            Download AR
                          </button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={tableData.length}
          rowsPerPage={dataPerPage}
          labelDisplayedRows={({ from, to }) => `${from}-${to} of ${tableData.length}`}
          page={currentPage - 1}
          onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
          onRowsPerPageChange={(event) => {
            setCurrentPage(1);
            setDataPerPage(event.target.value);
          }}
        />
      </Paper>
    </div>
  );
};

const View = () => {


  const [isMobileView, setIsMobileView] = useState(false);
  const { bid: batchId } = useParams();
  const [batchDetails, setBatchDetails] = useState(null);



  const handleResize = () => {
    if (window.innerWidth <= 600) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Fetch batch details
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      }
    };

    fetchBatchDetails();
  }, [batchId]);

  if (!batchDetails) {
    return <p>Loading...</p>;
  }


  return (
    <div >
      <TeachHeader />
      <br />
      <br />
      <Container batchDetails={batchDetails} />
      <Manage batchDetails={batchDetails} />
    </div>
  );
};

export default View;
