import React from 'react';

import TeachHeader from './TeachHeader'
import  { useState } from 'react';

const Container = () => {
  return (
    <div className="containerw">
      <div className="row">
        <div className="col-md-4  mt-4">
          <div className="container-content">
            <p className="paragraph">Course:</p>
            <p className="paragraph">Course Date:</p>
            <p className="paragraph">Trainers:</p>
          </div>
        </div>

        <div className="col-md-4  mt-4">
          <div className="container-content">
            <p className="paragraph">Batch:</p>
            <p className="paragraph">Student Enrolled:</p>
            <p className="paragraph">Assessors:</p>
          </div>
        </div>
      </div>
    </div>
  );
};


const Table = () => {
const [data, setData] = useState([
  {
    name: 'John Doe',
    id: 1,
    email: 'john@example.com',
    phone: '123-456-7890',
    gender: 'Male',
    dob: '1990-01-01',
    address: '123 Main St, City, Country',
  },
  {
    name: 'Jane Smith',
    id: 2,
    email: 'jane@example.com',
    phone: '987-654-3210',
    gender: 'Female',
    dob: '1995-05-15',
    address: '456 Park Ave, Town, Country',
  },
  // Add more dummy data here as needed
]);
const styles = {
  tableContainer: {
    // Add your container styles here
  },
  searchContainer: {
    // Add your search container styles here
  },
  tableHeader: {
    // Add your table header styles here
  },
  tableCell: {
    // Add your table cell styles here
    border: '1px solid black',
    padding: '8px',
  },
};


  return (
    <div>
      <TeachHeader/>
      <Container />

      <div style={{ width: '100%', overflowX: 'auto' }}>
    <table style={{ width: '54%', tableLayout: 'fixed' , marginLeft: '18%' }}>
          {/* Add search input or any other elements here */}
      
      
          <thead>
            <tr style={styles.tableHeader}>
              <th style={styles.tableCell}>Name</th>
              <th style={styles.tableCell}>Id</th>
              <th style={styles.tableCell}>Email</th>
              <th style={styles.tableCell}>Phone</th>
              <th style={styles.tableCell}>Gender</th>
              <th style={styles.tableCell}>DOB</th>
              <th style={styles.tableCell}>Address</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td style={styles.tableCell}>{row.name}</td>
                <td style={styles.tableCell}>{row.id}</td>
                <td style={styles.tableCell}>{row.email}</td>
                <td style={styles.tableCell}>{row.phone}</td>
                <td style={styles.tableCell}>{row.gender}</td>
                <td style={styles.tableCell}>{row.dob}</td>
                <td style={styles.tableCell}>{row.address}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div
          className=""
          style={{ display: 'flex', justifyContent: 'flex-end' , translate: '-26%' , marginTop: '-2%' }}
        >
          <button
            className=""
            style={{
              backgroundColor: '#03396c',
              color: '#fff',
              marginRight: '2%',
              border: 'none',
              padding: '8px 40px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
              marginTop: '3%',
            }}
          >
            Previous
          </button>

          <button
            className=""
            style={{
              backgroundColor: '#03396c',
              color: '#fff',
              border: 'none',
              marginRight: '2%',
              padding: '8px 50px',
              borderRadius: '4px',
              cursor: 'pointer',
              fontSize: '14px',
              marginLeft: '10px',
              marginTop: '3%',
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
