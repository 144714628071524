import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Headstu from './Headstu';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';





const ContactForm = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    comment: '',
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Your form submission logic here
    // You can access the form data using 'formData' state
    console.log(formData);
  };


  return (
    <div className="containerw contact">
      <div className="row">
        <div className="col-md-3">
          <div className="contact-info">
            <img src="https://image.ibb.co/kUASdV/contact-image.png" alt="image" />
            <h2>Contact Us</h2>
            <h4>We would love to hear from you !</h4>
          </div>
        </div>
        <div className="col-md-9">
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="fname">
                  First Name:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="fname"
                    placeholder="Enter First Name"
                    name="fname"
                    value={formData.fname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="lname">
                  Last Name:
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    id="lname"
                    placeholder="Enter Last Name"
                    name="lname"
                    value={formData.lname}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="email">
                  Email:
                </label>
                <div className="col-sm-10">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-sm-2" htmlFor="comment">
                  Comment:
                </label>
                <div className="col-sm-10">
                  <textarea
                    className="form-control"
                    rows="5"
                    id="comment"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <br/><br/>
              <div className="form-group">
                <div className="col-sm-offset-2 col-sm-10">
                  <button type="submit" className="btn btn-default">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};










const NewbatchPage = () => {
  return (
    <div>
      <Headstu/>
      <div className="container-xl px-4 mt-4">
    
        <ContactForm />
      </div>
    </div>
  );
};


export default NewbatchPage;



