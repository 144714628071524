import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Header from '../components/Header';
 
 
import Allas from './Allas';
import CreateAsse from './CreateAsse';
import Questiobank from './Questiobank';


const New = () => {
  const [tabValue, setTabValue] = useState(0);
  const [showModal, setShowModal] = useState(false);
 


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const openModal = () => {
    setShowModal(true);
  };


  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div>
      <Header />
<br></br>
     
      <Tabs
  value={tabValue}
  onChange={handleChange} // Change from handleChangeTab to handleChange
  aria-label="assessment-tabs"
  variant="fullWidth"
>
            <Tab label="All Assessment" />
          <Tab label="Create Assessment" />
          <Tab label="Question Bank" />
          </Tabs>
     


      {/* Add a horizontal line separator */}


      <div>
        {tabValue === 0 && <Allas />}
        {tabValue === 1 && <CreateAsse/>}
       
        {tabValue === 2 && <Questiobank />}
      </div>
    </div>
  );
};


export default New;





