import React ,{useState} from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
 
const columns = [
  { id: 'batch', label: 'Batch', minWidth: 100 },
  { id: 'date', label: 'Date', minWidth: 170 },
  { id: 'course', label: 'Course', minWidth: 170 },
  { id: 'trainer', label: 'Trainer', minWidth: 170 },
  { id: 'numComponents', label: 'Invoice Total', minWidth: 170, align: 'right' },
  { id: 'numComments', label: 'Paid Total', minWidth: 170, align: 'right' },
  { id: 'passing', label: 'Passing Total', minWidth: 170, align: 'right' },
  { id: 'remaining', label: 'Remaining', minWidth: 170, align: 'right' },
];


const createData = (batch, date, course, trainer, numComponents, numComments, passing) => {
  const remaining = parseInt(numComponents) - parseInt(numComments);
  return { batch, date, course, trainer, numComponents, numComments, passing, remaining };
};


const rows = [
  createData('Batch 1', '2023-06-26', 'React Basics', 'John Doe', '5', '10', 'Pass'),
  createData('Batch 2', '2023-06-27', 'React Hooks', 'Jane Smith', '7', '8', 'Fail'),
  // Add more rows as needed
];


const MaterialUITable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const filteredRows = rows
  .filter((row) => {
    const values = Object.values(row).map((value) =>
      typeof value === 'string' ? value.toLowerCase() : value.toString()
    );
    return values.some((value) => value.includes(searchQuery.toLowerCase()));
  })
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
     


    <div className="container" style={{ maxWidth: '1000px', marginTop: '-20px' }}>
     
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <input
        type="text"
        placeholder="Search by Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          padding: '8px 12px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          float: 'left',
          fontSize: '16px',
        }}
      />
      <TableContainer
    sx={{
      overflowX: 'auto',
      maxWidth: '100%',
      '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
        overflowX: 'unset', // Enable horizontal scroll
      },
    }}
  >      
 <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
    </div>
  );
};


export default MaterialUITable;





