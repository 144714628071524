import React, { useState } from 'react';
import './Home.css';
import Individual from './Individual';
import Business from './Business';
import Universities from './Universities';
import L from './images/lider.png';
import ImpluzImage from '.././All/images/logo1.png';
import { Tabs, Tab } from '@mui/material';


export const Navbar = () => {
  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue); // Handle change for Tabs component
  };

  const toggleHamburger = () => {
    setHamburgerActive((prevState) => !prevState);
    setNavActive((prevState) => !prevState);
  };

  return (
    <div>
      <div>
        <nav className="navbar" style={{ backgroundColor: '#fff', height: '90px' }}>
          <img
            src={ImpluzImage}
            alt="Impluz Logo"
            style={{ width: '170px', height: '60px', position: 'absolute', top: '10px', left: '10px' }}
          />
  
          <div className="logo" id="MainSection">
            <div className='Impluz'></div>
          </div>
          <ul className={`nav-list ${isNavActive ? 'active' : ''}`}>
            <li><a className="nav-link" href="#MainSection">Home</a></li>
            <li><a className="nav-link" href="#Feature">Features</a></li>
            {value !== 0 && <li><a className="nav-link" href="#ssg">SSG-WSQ</a></li>}
            <li><a className="nav-link" href="#heading">Courses</a></li>
            <li><a className="nav-link" href="/login">Demo Login</a></li>
            <li><a className="nav-link" href="#Blogs">Blogs</a></li>
            <li><a className="nav-link" href="#Last-section">Contact Us</a></li>
          </ul>
          <div className={`hamburger ${isHamburgerActive ? 'active' : ''}`} onClick={toggleHamburger}>
            <div className="line line-1"></div>
            <div className="line line-2"></div>
            <div className="line line-3"></div>
          </div>
  
          <Tabs value={value} onChange={handleChange} aria-label="icon tabs example" style={{ display: 'flex', alignContent: 'flex-end' }}>
            <Tab label="Individuals" aria-label="individuals" />
            <Tab label="Businesses" aria-label="businesses" />
            <Tab label="Institutes" aria-label="Institutes" />
          </Tabs>
        </nav>
        
        {/* Add a horizontal line separator */}
        {/* <hr style={{ width: '100%', height: '71px', backgroundColor: 'gray' }} /> */}
  
        <div>
          {value === 0 && <Individual />}
          {value === 1 && <Business />}
          {value === 2 && <Universities />}
        </div>
      </div>
    </div>
  );
  
};

export const Last = () => {

  return (
    <footer className="footer">
      <div className="section" id="1">
        <img src={L} alt="" className="logo" />
        <img src={ImpluzImage} alt="" className="logo1" />
        <h6 className="heading" style={{ backgroundColor: 'white' }}>LIDER Technology Solutions</h6>
        <p className="text">
          <i className="fa fa-map-marker"></i>
          <span>Rgd No: 381, T.H. Road, Thiruvottiyur, Chennai - 600 019, Tamil Nadu, INDIA</span>
          <br />
          <i className="fa fa-phone"></i>
          
          <span>+91- 44 35013145</span>
        </p>
        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a href="mailto:lidertechnologysolutions@gmail.com" className="email-link">lidertechnologysolutions@gmail.com</a>
          </p>
        </div>
      </div>
      <div className="section" id="2">
        <div className="nav">
          <a href="#MainSection" className="nav-link">Home</a>
          <a href="#Feature" className="nav-link">Features</a>
          <a href="#" className="nav-link">Blogs</a>
          <a href="#ssg" className="nav-link">SSG-WSQ</a>
          <a href="#Last-section" className="nav-link">Contact Us</a>
        </div>
      </div>
      <div className="section" id="3">
        <p className="about">
          <span style={{ fontWeight: 'bolder', fontSize: '25px' }}>About the company</span><br /><br /> LIDER technology is an IT Services Company based in India and Singapore, focused on providing a good environment and Managed IT Infrastructure Services. We build customized applications and websites as per user requirements and add significant value to our clients' business operations. Our expertise in mobility spans from developing mobile applications on native platforms for Android, Apple iOS, Windows Mobile to developing multi-platform enterprise applications.
        </p>
      </div>
    </footer>
  );

};

const Homepage = () => {
  return (
    <div>
      <Navbar />
      <Last />
    </div>
  );  
};

export default Homepage;

