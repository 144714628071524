import React, { useEffect, useState } from 'react';
import axios from '../Axiosinstance';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Loader from '../Loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import {baseURLdyte, Password,Username} from '../common'


const Recoedings = () => {
    const { batchId } = useParams();
    const [batchDetails, setBatchDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [matchingSessions, setMatchingSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchBatchDetails = async () => {
      try {
        const response = await axios.get(`batch/${batchId}`);
        setBatchDetails(response.data);
      } catch (error) {
        console.error('Error fetching batch details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBatchDetails();
  }, [batchId]);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const orgId =  Username;
        const apiKey = Password;
        const authHeader = `Basic ${btoa(`${orgId}:${apiKey}`)}`;

        const sessionsResponse = await axios.get(`${baseURLdyte}/recordings`, {
          headers: {
            Authorization: authHeader,
          },
        });

        const sessions = sessionsResponse.data.data;

        if (batchDetails && sessions) {
          const url = batchDetails.onlineclasslink;
          const match = url.match(/id=([a-f\d-]+)/i);

          if (match && match[1]) {
            const meetingId = match[1];

            const matchingSessions = sessions.filter(
              (session) => session.meeting_id === meetingId
            );

            setMatchingSessions(matchingSessions);
          } else {
            console.warn('Meeting ID not found in onlineclasslink');
          }
        }
      } catch (error) {
        console.error('Error fetching sessions:', error);
      }
    };

    fetchSessions();
  }, [batchDetails]);

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleRowClick = (session) => {
    setSelectedSession(session);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedSession(null);
    setShowModal(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of sessions per page

  // Calculate the indexes for the current page
  const indexOfLastSession = (page + 1) * rowsPerPage;
  const indexOfFirstSession = indexOfLastSession - rowsPerPage;
  const currentSessions = matchingSessions.slice(indexOfFirstSession, indexOfLastSession);

  // Function to handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Function to handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className='container-fluid' style={{ maxWidth: '1200px'}} >
      {loading && <Loader />}
      {!loading && (
        <TableContainer>
          <Table striped bordered hover responsive>
            <TableHead>
              <TableRow>
                <TableCell>S.no</TableCell>
                <TableCell>Meeting  Name</TableCell>
                <TableCell>Recording Duration</TableCell>
                <TableCell>Starting At</TableCell>
                <TableCell>Ending At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchingSessions.map((session, index) => (
                <TableRow key={session.id} onClick={() => handleRowClick(session)}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{session.meeting.title}</TableCell>
                  <TableCell>{session.recording_duration} seconds</TableCell>
                  <TableCell>{formatDate(session.started_time)}</TableCell>
                  <TableCell>{formatDate(session.stopped_time)}</TableCell>
                  <TableCell>
                    <Button variant="info">View Video</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Modal */}
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
  <Modal.Header closeButton>
    <Modal.Title>Recording Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedSession && (
      <div>
        <h5 className="mb-3">Meeting Name: {selectedSession.meeting.title}</h5>
        <p>Recording Duration: {selectedSession.recording_duration} seconds</p>
        <p>Started At: {formatDate(selectedSession.started_time)}</p>
        <p>Ended At: {formatDate(selectedSession.stopped_time)}</p>

        <div className="embed-responsive embed-responsive-16by9 mb-3">
          <video className="embed-responsive-item" controls width="100%">
            <source src={selectedSession.download_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <p>
          <a
            href={selectedSession.download_url}
            download={selectedSession.output_file_name}
            className="btn btn-primary"
          >
            Download
          </a>
        </p>
      </div>
    )}
  </Modal.Body>
</Modal>

          <TablePagination
        component="div"
        count={matchingSessions.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        </TableContainer>
      )}
    </div>
  );

};

export default Recoedings;
