import React, { useState, useEffect } from 'react';








import axios from '../../Axiosinstance';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../../common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '@mui/material';




const GeneralSettingsForm = () => {
  const CLOUDINARY_URL = baseURL;
  const CLOUDINARY_UPLOAD = CLOUDINARY_UPLOAD_PRESET;
  const [isFormDirty, setIsFormDirty] = useState(false);








  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    ssgserver: '',
    organization: '',
    clientID: '',
    clientSecret: '',
    url: ''
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [dataId, setDataId] = useState(null);








  useEffect(() => {
    axios.get('/tests')
      .then(response => {
        const data = response.data[0];
        setDataId(data.id);
        setFormData({
          ...data,
        });








        setSelectedImage(data.url);
        // console.log(data.id);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);








  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };
















  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);




    // Create a FileReader to read and display the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedFileUrl(reader.result);
    };




    if (file) {
      reader.readAsDataURL(file);
    }
  };












  const handleSubmit = async (e) => {
    e.preventDefault();
    const confirmed = window.confirm('Are you sure you want to save the changes?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }




    const formDataToSend = new FormData();








    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }








    // Check if a new image is selected for upload
    if (selectedImage) {
      // Upload the image to Cloudinary
      const fileData = new FormData();
      fileData.append('file', selectedImage);
      fileData.append('upload_preset', CLOUDINARY_UPLOAD);
      fileData.append('public_id', 'LMS/General_settings/' + selectedImage.name);








      try {
        const response = await fetch(CLOUDINARY_URL, {
          method: 'POST',
          body: fileData,
        });








        if (response.ok) {
          const imageData = await response.json();
          const imageUrl = imageData.secure_url;
          formDataToSend.set('url', imageUrl);








          // Display the image URL in the console
          // console.log('Image URL:', imageUrl);
        } else {
          console.error('Error uploading file to Cloudinary');
        }
      } catch (err) {
        console.error(err);
      }
    }








    // Make the API call with updated form data
    axios
      .put(`/test/${dataId}`, formDataToSend)
      .then((response) => {
        toast.success('General settings updated successfully!', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });




        // console.log('Data updated successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error updating data:', error);
        toast.error('Error updating General Settings. Please try again.', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };
















  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };




    window.addEventListener('beforeunload', handleBeforeUnload);




    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);








  return (
    <div>




      <div
        className="containerd mx-auto"
        style={{
          maxWidth: "800px",
          padding: "20px 20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          marginTop: "2%",
        }}
      >
        <h2 style={{ color: "#000" }}>General Settings</h2>
        <br />
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleInputChange}
                maxLength={20} // Set the maximum character length to 20
                title="20 characters only" // Tooltip message
                required
              />
            </div>


            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="companyName">Company Name:</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                placeholder="Enter your company name"
                value={formData.companyName}
                onChange={handleInputChange}
                maxLength={20} // Set the maximum character length to 20
                title="20 characters only" // Tooltip message
                required
              />
            </div>
          </div>
          <br />



          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                maxLength={20} // Set the maximum character length to 20
                title="20 characters only" // Tooltip message
                required
                autoComplete="off"
              />
            </div>


            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                id="phone"
                name="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  if (numericValue.length <= 10) {
                    setFormData((prevState) => ({
                      ...prevState,
                      phone: numericValue,
                    }));
                  }
                }}
                required
              />
            </div>
          </div>




          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="SSGServer">SSG Server:</label>
              <input
                type="text"
                className="form-control"
                id="SSGServer"
                name="ssgserver"
                placeholder="Enter SSG server"
                value={formData.ssgserver}
                onChange={handleInputChange}
                required
              />
            </div>


            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="organization">Organization:</label>
              <input
                type="text"
                className="form-control"
                id="organization"
                name="organization"
                placeholder="Enter organization"
                value={formData.organization}
                onChange={handleInputChange}
                maxLength={20}
                title="20 characters only"
                required
              />
            </div>
          </div>


          <div style={{ display: "flex", gap: "20px" }}>
            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="clientId">Client ID:</label>
              <input
                type="text"
                className="form-control"
                id="clientID"
                name="clientID"
                placeholder="Enter client ID"
                value={formData.clientID}
                onChange={handleInputChange}
                maxLength={20}
                title="20 characters only"
                required
              />
            </div>


            <div className="form-group" style={{ flex: 1 }}>
              <label htmlFor="clientSecret">Client Secret:</label>
              <input
                type="password"
                className="form-control"
                id="clientSecret"
                name="clientSecret"
                placeholder="Enter client secret"
                value={formData.clientSecret}
                onChange={handleInputChange}
                required
                autoComplete="off"
              />
            </div>
          </div>
          <br />
          <div>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            <img
              style={{ width: "130px", height: "70px" }}
              src={uploadedFileUrl || selectedImage}
              alt="Uploaded"
            />
          </div>




          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              color: "white",
              marginTop: "2%",
            }}
          >
            Save
          </Button>
        </form>
        <ToastContainer />
      </div>


    </div>
  );
};
























const SmtpConfig = () => {
  const [smtpHost, setSmtpHost] = useState('');
  const [smtpUsername, setSmtpUsername] = useState('');
  const [smtpPassword, setSmtpPassword] = useState('');
  const [smtpPort, setSmtpPort] = useState('');
  const [smtpId, setSmtpId] = useState('');








  useEffect(() => {
    axios.get('smtps')
      .then(response => {
        const smtpConfig = response.data[0];
        setSmtpHost(smtpConfig.host);
        setSmtpUsername(smtpConfig.username);
        setSmtpPassword(smtpConfig.password);
        setSmtpPort(smtpConfig.port);
        setSmtpId(smtpConfig.id);
      })
      .catch(error => {
        console.error('Error fetching SMTP configuration:', error);
      });
  }, []);








  const handleSave = () => {
    // Prepare the updated SMTP configuration object
    const confirmed = window.confirm('Are you sure you want to save the changes?');
    if (!confirmed) {
      return; // If not confirmed, exit the function
    }
    const updatedConfig = {
      host: smtpHost,
      username: smtpUsername,
      password: smtpPassword,
      port: smtpPort,
    };








    // Update the SMTP configuration using the API
    axios.put(`smtp/${smtpId}`, updatedConfig)
      .then(response => {
        toast.success('SMTP updated successfully!', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch(error => {
        // console.error('Error updating SMTP configuration:', error);
        toast.error('Error updating SMTP. Please try again.', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };








  const [isFormDirty, setIsFormDirty] = useState(false);
















  const handleInputChange = () => {
    if (!isFormDirty) {
      setIsFormDirty(true);
    }
  };








  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };








    window.addEventListener('beforeunload', handleBeforeUnload);








    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormDirty]);












  return (
    <div className="containerw" style={{
      maxWidth: '700px', backgroundColor: "#f9f9f9", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", marginTop: '2%',
      padding: "20px 20px",
      border: "1px solid #ddd",
      borderRadius: "8px"
    }}>
      <h2 style={{ color: '#000' }}  >SMTP Configuration</h2>
      <br />
      <form onChange={handleInputChange}>
        <div className="form-group">
          <label htmlFor="smtpHost">SMTP Host</label>
          <input
            type="text"
            className="form-control"
            id="smtpHost"
            placeholder="Enter SMTP Host"
            value={smtpHost}
            onChange={(e) => setSmtpHost(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="smtpUsername">SMTP Username</label>
          <input
            type="text"
            className="form-control"
            id="smtpUsername"
            placeholder="Enter SMTP Username"
            autocomplete="off"
            maxLength={20}
            title="20 characters only"
            value={smtpUsername}
            onChange={(e) => setSmtpUsername(e.target.value)}








          />
        </div>
        <div className="form-group">
          <label htmlFor="smtpPassword">SMTP Password</label>
          <input
            type="password"
            className="form-control"
            id="smtpPassword"
            placeholder="Enter SMTP Password"
            autocomplete="off"
            value={smtpPassword}
            onChange={(e) => setSmtpPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="smtpPort">SMTP Port</label>
          <input
            type="text"
            className="form-control"
            id="smtpPort"
            placeholder="Enter SMTP Port"
            value={smtpPort}
            onChange={(e) => setSmtpPort(e.target.value)}
          />
        </div>
        <br />




        {smtpId && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSave}
            style={{
              marginTop: '-3%',
              color: 'white',

            }}
          >
            Save
          </Button>
        )}








      </form>
    </div>
  );
};








const Bar = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>


      <div style={{ width: '800px' }}>
        <GeneralSettingsForm />
      </div>


      {/*<div style={{ marginTop:'2%',width: '500px' }}>
      <SmtpConfig />
    </div> */}
    </div>
  );
};








export default Bar;











