import React, { useState, useEffect } from 'react';
import './Hs.css';
import axios from '../Axiosinstance'
import { logoImage } from '../common';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBook,
  faCalendar,
  faGraduationCap,
  faCog,
  faQuestionCircle,
  faFolder,
  faUser,
  faComment,
  faDesktop,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isResourcesOpen, setResourcesOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleResources = () => {
    setResourcesOpen(!isResourcesOpen);
    setHelpOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening resources dropdown
  };

  const toggleHelp = () => {
    setHelpOpen(!isHelpOpen);
    setResourcesOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening help dropdown
  };

  const toggleAccount = () => {
    setAccountOpen(!isAccountOpen);
    setResourcesOpen(false);
    setHelpOpen(false); // Close resources and help dropdowns when opening account dropdown
  };

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const handleLogout = () => {
    // Remove user data from local storage
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("usertype");
    localStorage.removeItem("studentteacherid");
    document.title = "LMS-impluz";

    // Navigate to the login page
    navigate("/login");
  };

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      // axios.get(`home/user/${userId}`)
      //   .then((response) => {
      //     if (response.status === 200) {
      //       const data = response.data;
      //       localStorage.setItem("studentteacherid", data.sfid);
      //       document.title = `impluz - ${data.username}`;
      //       setStudentName(data.name);
      //       setProfileUrl(data.profile_Url)
      //         // console.log(data.profile_Url)
      //         // const studentteacherid = data.sfid;
      //         // axios.get(`student/${studentteacherid}`)
      //         // .then((studentResponse) => {
      //         // if (studentResponse.status === 200) {
      //         //   const studentData = studentResponse.data;
      //         // setStudentName(studentData.fullname);

      //         // Set the document title with the student's name
      //         //   document.title = `impluz-${studentData.fullname}`;
      //         // } else {
      //         //   throw new Error("Failed to fetch student name");
      //         // }
      //         // })
      //         .catch((studentError) => {
      //           console.error(studentError);
      //         });
      //     } else {
      //       throw new Error("Failed to fetch sfid");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });

      //new better error handling 
      axios.get(`home/user/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data;
            localStorage.setItem("studentteacherid", data.sfid);
            document.title = `impluz - ${data.username}`;
            setStudentName(data.name);
            setProfileUrl(data.profile_Url);
          } else {
            throw new Error("Failed to fetch sfid");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });

    }
  }, []);


  const toggleHamburger = () => {
    setHamburgerActive((prevState) => !prevState);
    setNavActive((prevState) => !prevState);
  };


  return (
    <div className={`App ${isNavOpen ? 'opened' : ''}`}>
      <nav>
        <div className="logo">
          <img
            src={logoImage}
            alt="logoImage"
            style={{ width: '180px', height: '60px', }}
          />        </div>
        <button className="btn-hamburger" onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul className={`nav-menu ${isNavOpen ? 'opened' : ''}`}>
          <li className="nav-item"><Link to="/Dashboard"><FontAwesomeIcon icon={faHome} /> Home </Link></li>
          <li className="nav-item"><Link to="/StudentCourese"><FontAwesomeIcon icon={faHome} /> Courses </Link></li>
          {/* <li className="nav-item"><a href="/MyCourses"><FontAwesomeIcon icon={faHome} /> My Courses </a></li> */}


          {/* <li className={`nav-item dropdown ${isHelpOpen ? 'show' : ''}`}>
            <Link to={'/services'} onClick={toggleHelp}> <FontAwesomeIcon icon={faQuestionCircle} />  Help</Link>
            <div className={`dropdown-menu ${isHelpOpen ? 'show' : ''}`}>
              <Link className="dropdown-item" to={'/Contactstu'}>Contact us</Link>
              <Link className="dropdown-item" to={'/services/option3'}>Live Chat</Link>
              <Link className="dropdown-item" to={'/services/option3'}>Remote Desktop</Link>
            </div>
          </li> */}
          <li className={`nav-item dropdown ${isAccountOpen ? 'show' : ''}`}>
            <Link to={''} onClick={toggleAccount}>{profileUrl ? (
              <img
                src={profileUrl}
                alt="Profile"
                style={{ marginLeft: '5px', borderRadius: '50%', width: '50px', height: '50px' }}
              />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}     {studentName}</Link>
            <div className={`dropdown-menu ${isAccountOpen ? 'show' : ''}`}>
              <Link className="dropdown-item" to={'/Profilestu'}>Profile</Link>
              <Link className="dropdown-item" to={'/studentchat'}>Contact us</Link>
              <Link onClick={handleLogout} className="dropdown-item" to={'/'}><FontAwesomeIcon icon={faSignOutAlt} /> Log out</Link>
            </div>
          </li>
        </ul>
      </nav>
      <div className="container">

      </div>
    </div>
  );
}
export default App;
