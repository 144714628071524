import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';

import Header from '../../components/Header'
import ReactQuill from 'react-quill';
import Heading  from './Heading';


const InvoiceForm = () => {
  const [invoiceNo, setInvoiceNo] = useState('');
  const [editorContent, setEditorContent] = useState('');

  const handleInvoiceNoChange = (e) => {
    setInvoiceNo(e.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSave = () => {
    // Handle save logic here
    console.log('Invoice number:', invoiceNo);
    console.log('Editor content:', editorContent);
    // Reset the form
    setInvoiceNo('');
    setEditorContent('');
  };

  return (
    <div className="invoice-form" style={{ padding:'3px 15px' , border: '1px solid #ccc', borderRadius: '20px' , height: '680spx' , marginBottom: '20px',}} >
      <h4 style={{ marginLeft: '20px' }}>Invoice Basic Settings</h4>
      <div className="form-container" style={{ padding: ' 5px  20px', marginRight:'1500%', width: '100%'}}>
        <form>
        <div className="form-group">
  <label htmlFor="invoiceNo">Invoice Basic Prefix:</label>
  <input
    type="text"
    id="invoiceNo"
    className="form-control"
    placeholder=""
    value={invoiceNo}
    onChange={handleInvoiceNoChange}
  
  />
</div>
   
          <div className="form-group">
            <label htmlFor="invoiceNo">Invoice Starting No:</label>
            <input
              type="text"
              id="invoiceNo"
              className="form-control"
              placeholder=""
              value={invoiceNo}
              onChange={handleInvoiceNoChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="invoiceNo">Payment Terms</label>
            <input
              type="text"
              id="invoiceNo"
              className="form-control"
              placeholder="Cash Cheque"
              value={invoiceNo}
              onChange={handleInvoiceNoChange}
            />
          </div>
        
          
        </form>
      </div>
      <div className="instructions-container"  style={{ padding:'5px 20px', lineheight: '8',marginRight:'5%' }}>
        {/* Content goes here */}
        <h4>Instruction(Shown at bottom of the Page)</h4>
        <ReactQuill
          value={editorContent}
          onChange={handleEditorChange}
          placeholder="Enter your text"
          style={{ padding:'10px 20px',height: '100px'}}
        />
        
      </div>
      <div className="container">
  <button
    type="button"
    className="btn btn-primary"
    onClick={handleSave}
    style={{ background: '#03396c' , textalign: 'right' ,  padding: '10px 54px',marginTop:'6%'}}
  >
    Save
  </button>
</div>
    </div>
  );
};

const App = () => {
  const [name, setName] = useState('');
  const [calculation, setCalculation] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleCalculationChange = (e) => {
    setCalculation(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform submit logic here
    console.log('Name:', name);
    console.log('Calculation:', calculation);
  };

  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSave = () => {
    // Handle save logic here
    console.log('Invoice number:', );
    // Reset the form
  };

  return (
    
    <div className="invoice-form"  style={{ padding:'3px 15px' , border: '1px solid #ccc', borderRadius: '20px' , height: '260px' }}>
    <h4 style={{ marginLeft: '20px' , marginBottom: '10px'}}>At New Particular</h4>
    <div className="form-container" style={{ padding: ' 5px  20px', marginRight:'80%', width: '100%'}}>
      <form>
        <div className="form-group">
          <label htmlFor="invoiceNo">Name:</label>
          <input
            type="text"
            id="invoiceNo"
            className="form-control"
            placeholder=""
          
          />
        </div>
        <div className="form-group">
          <label htmlFor="invoiceNo">Calculator:</label>
          <input
            type="text"
            id="invoiceNo"
            className="form-control"
            placeholder=""
        
          />
        </div>
 
      
        
      </form>
    </div>
  
    <div className="container">
<button
  type="button"
  className="btn btn-primary"
  onClick={handleSave}
  style={{ background: '#03396c' , textalign: 'right' ,  padding: '10px 54px'}}
>
  Save
</button>
</div>
  </div>
  
  );
};const tableData = [
    { id: 1, name: 'Item 1', type: 'Type A', mode: 'Manual', value: 10, isEditing: false },
    { id: 2, name: 'Item 2', type: 'Type B', mode: 'Automatic', value: 20, isEditing: false },
    { id: 3, name: 'Item 3', type: 'Type A', mode: 'Manual', value: 15, isEditing: false },
    { id: 4, name: 'Item 4', type: 'Type C', mode: 'Automatic', value: 25, isEditing: false },
    { id: 5, name: 'Item 5', type: 'Type B', mode: 'Manual', value: 18, isEditing: false },
  ];
  
  const ResponsiveTable = () => {
    const [editableItemId, setEditableItemId] = useState(null);
    const [editedData, setEditedData] = useState({});
  
    const handleEdit = (itemId, column) => {
      setEditableItemId(itemId);
      const item = tableData.find((item) => item.id === itemId);
      setEditedData({ ...item, column });
    };
  
    const handleSave = () => {
      // Perform save operation with the updated data
      const itemIndex = tableData.findIndex((item) => item.id === editableItemId);
      if (itemIndex !== -1) {
        tableData[itemIndex] = { ...editedData };
      }
  
      setEditableItemId(null);
      setEditedData({});
    };
  
    const handleDelete = (itemId) => {
      console.log(`Delete item with ID ${itemId}`);
    };
  
    return (
      <div className="table-container" style={{ border: '1px solid #ccc', borderRadius: '12px',width:'50%', marginLeft:'27%' , width: '48%' }}>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Calculation Mode</th>
              <th>Value</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={editedData.name || item.name}
                      onChange={(e) =>
                        setEditedData({ ...editedData, name: e.target.value })
                      }
                    />
                  ) : (
                    item.name
                  )}
                </td>
                <td>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={editedData.type || item.type}
                      onChange={(e) =>
                        setEditedData({ ...editedData, type: e.target.value })
                      }
                    />
                  ) : (
                    item.type
                  )}
                </td>
                <td>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={editedData.mode || item.mode}
                      onChange={(e) =>
                        setEditedData({ ...editedData, mode: e.target.value })
                      }
                    />
                  ) : (
                    item.mode
                  )}
                </td>
                <td>
                  {editableItemId === item.id ? (
                    <input
                      type="text"
                      value={editedData.value || item.value}
                      onChange={(e) =>
                        setEditedData({ ...editedData, value: e.target.value })
                      }
                    />
                  ) : (
                    item.value
                  )}
                </td>
                <td>
                  {editableItemId === item.id ? (
                    <div className="btn-group" role="group">
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div className="btn-group" role="group" >
  <button
    type="button"
    className="btn btn-primary btn-sm"
    onClick={() => handleEdit(item.id, null)}
    style={{ padding: '1px 10px', backgroundColor: '#03396c', color: 'white' }}>
    Edit
  </button>
</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  
const EmailPreview = () => {
  return (
    <div className="container" >
      <h1>Email Preview</h1>
      <div className="table-responsive" >
        <table className="table table-bordered" >
          <thead>
            <tr>
              <th>Column 1</th>
              <th>Column 2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Row 1, Cell 1</td>
              <td>Row 1, Cell 2</td>
            </tr>
            <tr>
              <td>Row 2, Cell 1</td>
              <td>Row 2, Cell 2</td>
            </tr>
            <tr>
              <td>Row 3, Cell 1</td>
              <td>Row 3, Cell 2</td>
            </tr>
            <tr>
              <td>Row 4, Cell 1</td>
              <td>Row 4, Cell 2</td>
            </tr>
            <tr>
              <td>Row 5, Cell 1</td>
              <td>Row 5, Cell 2</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button className="btn btn-primary">Login</button>
    </div>
  );
};
const Invoicesetting = () => {
    return (
      <div>
      <Header/>
        <Heading />
     
       
        <div className="container">
        <div className="row">
          <div className="col-md-18 ">
          <InvoiceForm />
         
          </div>
          
          <div className="col-md-18">
          <App/>
          </div>
        </div>
      </div>
      <div className="col-md-120"  style={{ marginLeft: '-1%' }} >
            {/* Move the ResponsiveTable component to the right by 20% */}
            <ResponsiveTable />
          </div>
      </div>
    );
  };
  
  export default Invoicesetting;