import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
 
import { Form, Button, Modal} from 'react-bootstrap';
import './N.css';
import axios from '../Axiosinstance';






const NewVenue = ({ onFormSubmit }) => {
  const navigate = useNavigate();


  const jwtToken = localStorage.getItem('jwtToken');
  
  // Set the Authorization header if the token exists
  const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};

  const [formErrors, setFormErrors] = useState({});
  const [formModified, setFormModified] = useState(false);




  const [showModal, setShowModal] = useState(false);




  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);




  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);


    const errors = {};
    if (!formData.get('roomno')) {
      errors.roomno = 'Room No is required';
    }
    if (!formData.get('unit')) {
      errors.unit = 'Unit is required';
    }
    if (!formData.get('block')) {
      errors.block = 'Block is required';
    }
    if (!formData.get('floor')) {
      errors.floor = 'Floor is required';
    }
    if (!formData.get('street')) {
      errors.street = 'Street is required';
    }
    if (!formData.get('building')) {
      errors.building = 'Building is required';
    }
    if (!formData.get('postalcode')) {
      errors.postalcode = 'Postal Code is required';
    }


    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }


    const wheelchair = form.wheelchair.checked ? 'yes' : 'no';


    try {
      const confirmation = window.confirm('Are you sure you want to add this venue?');
      if (confirmation) {
      const response = await axios.post('venue', {
        ...Object.fromEntries(formData),
        wheelchair,
      }, { headers });


      if (response.status === 200) {
        onFormSubmit();
      } else {
        throw new Error('Failed to add venue');
      }
    }
    } catch (error) {
      console.error(error);
    }
  };


  const handleCancel = () => {
    handleCloseModal();
    // navigate('/Adhome'); // Replace this with the route to navigate when canceled
    window.location.reload();
  };
   
















  useEffect(() => {
    const handleFormModification = () => {
      if (!formModified) {
        setFormModified(true);
      }
    };
















    window.addEventListener('input', handleFormModification);
















    return () => {
      window.removeEventListener('input', handleFormModification);
    };
  }, [formModified]);
















  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (formModified) {
        event.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      }
    };
















    window.onbeforeunload = handleBeforeUnload;
















    return () => {
      window.onbeforeunload = null;
    };
  }, [formModified]);




  const handleInputChange = (e) => {
    const inputName = e.target.name;
    let inputValue = e.target.value;
 
    // If the input is roomno, unit, block, floor, or postalcode, restrict to digits and 8 characters
    if (['roomno', 'unit', 'floor', 'postalcode'].includes(inputName)) {
      inputValue = inputValue.replace(/\D/g, '').substring(0, 8); // Allow only digits and limit to 8 characters
    } else if (['block'].includes(inputName)) {
      inputValue = inputValue. substring(0, 20);
    }
 
    // Update the input value in the form
    e.target.value = inputValue;
  };




  const styles = {
    container: {
      maxWidth: "800px",
      margin: "0 auto",
      padding: "40px",
      border: "1px solid #ddd",
      borderRadius: "8px",
      backgroundColor: "#f9f9f9",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },




  };




 








return (
 
  <div className="container-fluid" >
  <br></br>
  <div style={styles.container}>
       
      <h4 style={{ marginTop: '-1%'}}>New Venue  (Newton International College)</h4>








<hr/>
      <Form onSubmit={handleSubmit}>
        {/* Room No */}
        <Form.Group controlId="roomNo">
          <Form.Label>Room No<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Room No"
            name="roomno"
            required
            onChange={handleInputChange}




            isInvalid={!!formErrors.roomno}
            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}




          />
          <Form.Control.Feedback type="invalid">
            {formErrors.roomno}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Unit */}
        <Form.Group controlId="unit">
          <Form.Label>Unit<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Unit"
            name="unit"
            required
            onChange={handleInputChange}
            isInvalid={!!formErrors.unit}
            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}




          />
          <Form.Control.Feedback type="invalid">
            {formErrors.unit}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Block */}
        <Form.Group controlId="block">
          <Form.Label>Block<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Block"
            name="block"
            required
            onChange={handleInputChange}
            isInvalid={!!formErrors.block}
           




          />
          <Form.Control.Feedback type="invalid">
            {formErrors.block}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Floor */}
        <Form.Group controlId="floor">
          <Form.Label>Floor<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter Floor"
            name="floor"
            required
            onChange={handleInputChange}
            isInvalid={!!formErrors.floor}
            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}




          />
          <Form.Control.Feedback type="invalid">
            {formErrors.floor}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Street */}
        <Form.Group controlId="street">
          <Form.Label>Street<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Street"
            name="street"
            required
           
            isInvalid={!!formErrors.street}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.street}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Building */}
        <Form.Group controlId="building">
          <Form.Label>Building<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Building"
            name="building"
            required
           
            isInvalid={!!formErrors.building}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.building}
          </Form.Control.Feedback>
        </Form.Group>
















        {/* Postal Code */}
        <Form.Group controlId="postalcode">
          <Form.Label>Postal Code<span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="number"
            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            placeholder="Enter Postal Code"
            name="postalcode"
            required
            onChange={handleInputChange}
            isInvalid={!!formErrors.postalcode}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.postalcode}
          </Form.Control.Feedback>
        </Form.Group>








<br></br>




        {/* Wheelchair access */}
        <Form.Group controlId="checkbox">
       
          <Form.Check type="checkbox" label="Wheelchair access" name="wheelchair" />
         
        </Form.Group>
<br/>
<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button  type="submit" className="btn-submit" style={{
        marginRight: '1%'  ,
         fontSize: '15px' ,
         border: 'none' , padding: '8px 35px' , borderRadius: '7px',
        backgroundColor: 'green',
        color: 'white'
      }} >
          Submit
        </button>
             <button
           
            type="button"
            className="btn-cancel"
            onClick={handleShowModal}
            style={{
              backgroundColor: 'red',
              color: 'white',  
               background:'red' , fontSize: '15px' , color: 'white' , border: 'none' ,
              padding: '8px 35px' , borderRadius: '7px'
            }}
          >
            Cancel
          </button>
        </div>
      </Form>
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            No
          </Button>
          <Button variant="danger" onClick={handleCancel} style={{ backgroundColor: 'red', color: 'white' }}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    </div>
  );
};






const New = ({ onFormSubmit }) => {
  return (
    <div>
       
     <br></br>
       <NewVenue onFormSubmit={onFormSubmit} />
    </div>
  );
};



export default New;

