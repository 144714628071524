import React, { useState, useEffect } from 'react';

import axios, { AxiosError } from '../Axiosinstance'
import { logoImage } from '../common';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBook,
  faCalendar,
  faGraduationCap,
  faCog,
  faQuestionCircle,
  faFolder,
  faUser,
  faComment,
  faDesktop,
  faSignOutAlt,
  faChevronDown
} from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';
import { Modal, Button } from 'react-bootstrap';


function App() {
  const [isResourcesOpen, setResourcesOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);


  const openModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const toggleResources = (e) => {
    console.log("lalchand", e)
    setResourcesOpen(!isResourcesOpen);
    setHelpOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening resources dropdown
  };




  const toggleHelp = () => {
    setHelpOpen(!isHelpOpen);
    setResourcesOpen(false);
    setAccountOpen(false); // Close the account dropdown when opening help dropdown
  };




  const toggleAccount = () => {
    setAccountOpen(!isAccountOpen);
    setResourcesOpen(false);
    setHelpOpen(false); // Close resources and help dropdowns when opening account dropdown
  };





  const [isHamburgerActive, setHamburgerActive] = useState(false);
  const [isNavActive, setNavActive] = useState(false);
  const navigate = useNavigate();
  const [studentName, setStudentName] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const handleLogout = () => {
    // Remove user data from local storage
    localStorage.removeItem("loggedInUserId");
    localStorage.removeItem("usertype");
    localStorage.removeItem("studentteacherid");
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('username');

    document.title = "LMS-impluz";

    // Navigate to the login page
    // navigate("/");

    openModal();
  };



  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios.get(`home/user/${userId}`)
        .then((response) => {
          if (response.status === 200) {
            // alert(response.status);
            const data = response.data;
            localStorage.setItem("studentteacherid", data.sfid);
            document.title = `impluz - ${data.name}`;
            setStudentName(data.name);
            setProfileUrl(data.profile_Url);
          }

          else {
            throw new Error("Failed to fetch sfid");
          }
        })
        .catch((error) => {
          console.error(error.response);
          if (error == "AxiosError: Network Error")
            handleLogout()
          // alert(error)
          // Handle any errors that occur during the API call
        });
    }
  }, []);


  const toggleHamburger = () => {
    setHamburgerActive((prevState) => !prevState);
    setNavActive((prevState) => !prevState);
  };

  const toggleNav = () => {
    console.log('Toggling navigation menu');
    setNavOpen(!isNavOpen);
  };


  return (
    <div className={`App ${isNavOpen ? 'opened' : ''}`}>
      <nav>
        <div className="logo">
          <Link to='/adhome'>
            <img
              id='imglogo'
              src={logoImage}
              alt="logoImage"

            /> </Link>       </div>
        <button className={`btn-hamburger ${isNavOpen ? 'opened' : ''}`} onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>




        <ul className={`nav-menu ${isNavOpen ? 'opened' : ''}`} >
          <li className="nav-item"><Link to="/Adhome"><FontAwesomeIcon icon={faHome} /> Home </Link></li>
          <li className="nav-item"><Link to="/Batches"> <FontAwesomeIcon icon={faBook} /> Course Run </Link></li>
          <li className="nav-item"><Link to="/Batch"><FontAwesomeIcon icon={faCalendar} />  Roaster </Link></li>
          {/* <li className="nav-item"><a href="/Sswcommon"><FontAwesomeIcon icon={faGraduationCap} /> SSG_WSQ</a></li> */}


          {/* {console.log("isResourcesOpen", isResourcesOpen)} */}

          <li style={{ color: 'black' }} className={`nav-item dropdown ${isResourcesOpen ? 'show' : ''}`}>
            <a to={''} onClick={toggleResources}> <FontAwesomeIcon icon={faFolder} />  Resources <FontAwesomeIcon />  </a>
            {isResourcesOpen ? <div className={`dropdown-menu ${isResourcesOpen ? 'show' : ''}`}>
              <Link className="dropdown-item" to='/CourseCatagories'>  <FontAwesomeIcon icon={faFolder} />  Course Categories</Link>
              <Link className="dropdown-item" to='/Allcourse' > <FontAwesomeIcon icon={faBook} />  Course</Link>
              <Link className="dropdown-item" to='/Allstudent' >  <FontAwesomeIcon icon={faUser} />  Student</Link>
              {/* <Link className="dropdown-item" to={'/Allcourse'}>       <FontAwesomeIcon icon={faUser} /> Facilitators</Link> */}
              <Link className="dropdown-item" to='/Facilitor' > <FontAwesomeIcon icon={faUser} />  Facilitators</Link>
              <Link className="dropdown-item" to='/All' ><FontAwesomeIcon icon={faBook} />  Venues</Link>
              <Link className="dropdown-item" to='/certificate' ><FontAwesomeIcon icon={faBook} />  Certificate</Link>

              {/* <a className="dropdown-item" href='/Allas' ><FontAwesomeIcon icon={faBook} />  Assessment Setup</a> */}
              {/* <a className="dropdown-item" href='/Acadamic' ><FontAwesomeIcon icon={faBook} />  General Report</a> */}
            </div> : null}
          </li>
          <li className="nav-item">
            <Link to='/Setting' >  <FontAwesomeIcon icon={faCog} /> Settings</Link></li>




          {/* <li style={{ color: 'black' }} className={`nav-item dropdown ${isHelpOpen ? 'show' : ''}`}>
            <a to={''} onClick={toggleHelp}> <FontAwesomeIcon icon={faQuestionCircle} /> Help <FontAwesomeIcon /></a>
            <div className={`dropdown-menu ${isHelpOpen ? 'show' : ''}`}>
              <Link className="dropdown-item" to='/Chat' >Contact us</Link>
              {/* <a className="dropdown-item" to={''}>Live Chat</a> */}
          {/* <a className="dropdown-item" to={''}>Remote Desktop</a> */}
          {/* </div> */}
          {/* </li>  */}

          <li style={{ color: 'black' }} className={`nav-item dropdown ${isHelpOpen ? 'show' : ''}`}>
            <a to={''} onClick={toggleHelp}> <FontAwesomeIcon icon={faQuestionCircle} /> Help <FontAwesomeIcon /></a>
            <div className={`dropdown-menu ${isHelpOpen ? 'show' : ''}`}>
              <Link className="dropdown-item" to='/Chat' >Contact us</Link>
              {/* <a className="dropdown-item" to={''}>Live Chat</a> */}
              {/* <a className="dropdown-item" to={''}>Remote Desktop</a> */}
            </div>
          </li>


          <span style={{ width: '40px' }}>
            <li className={`nav-item dropdown ${isAccountOpen ? 'show' : ''}`}>
              <a to={''} onClick={toggleAccount}>
                {profileUrl ? (
                  <img
                    src={profileUrl}
                    alt="Profile"
                    style={{ marginLeft: '5px', borderRadius: '50%', width: '50px', height: '50px' }}
                  />
                ) : (
                  <span
                    style={{
                      marginLeft: '5px',
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                      backgroundColor: 'gray',
                    }}
                  ></span>
                )}
                {studentName ? (

                  studentName
                ) : (
                  <span className="placeholder-text" >Loading</span>
                )}{' '}
                <FontAwesomeIcon />
              </a>

              <div className={`dropdown-menu ${isAccountOpen ? 'show' : ''}`}>
                <Link className="dropdown-item" to="/Profile">
                  Profile
                </Link>
                <a onClick={handleLogout} className="dropdown-item" to={'/'}>
                  <FontAwesomeIcon icon={faSignOutAlt} /> Log out
                </a>
              </div>
            </li>
          </span>


        </ul>
      </nav>
      <div className="container">

      </div>

      <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
        <Modal.Body>
          <p>Your session has been closed.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { navigate("/login") }}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}




export default App;




