import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';

const NavigationButtons = () => {
  return (
    <div className="navigation-buttons">
       <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Genera' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
      </button>
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Roaste' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
      </button>
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='Invoicesettin' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Feedbac' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Blog' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Activit' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Ap' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
      </button>
     
      <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Paymen' style={{ textDecoration: 'none', color: 'white' }}>Tms usage&Payment</a>
      </button>
      <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
        <a href='/Maintanc' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
      </button>
    </div>
  );
};

const Pay = () => {
    useEffect(() => {
      // Load the Google Pay API library
      const loadGooglePayApi = () => {
        const script = document.createElement("script");
        script.src = "https://pay.google.com/gp/p/js/pay.js";
        script.onload = initializeGooglePay;
        document.body.appendChild(script);
      };
  
      // Initialize Google Pay
      const initializeGooglePay = () => {
        if (window.google && window.google.payments && window.google.payments.api) {
          const paymentsClient = new window.google.payments.api.PaymentsClient({
            environment: "TEST", // Use "PRODUCTION" for production environment
          });
  
          const paymentDataRequest = {
            // Rest of the code...
          };
  
          const button = paymentsClient.createButton({
            onClick: handleGooglePayPayment,
          });
  
          document.getElementById("googlePayButton").appendChild(button);
  
          function handleGooglePayPayment() {
            paymentsClient.loadPaymentData(paymentDataRequest).then((paymentData) => {
              // Handle the payment data returned by Google Pay
              console.log(paymentData);
            });
          }
        } else {
          console.error("Google Pay API is not available.");
        }
      };
  
      loadGooglePayApi();
    }, []);

  const handlePayPalPayment = () => {
    // Implement logic for PayPal integration
  };

  const handleCreditCardPayment = () => {
    // Implement logic for credit card payment
  };

  return (
    <div>
      <div>
        {/* Placeholder buttons for payment options */}
        <button onClick={handlePayPalPayment}>Pay with PayPal</button>
        <button onClick={handleCreditCardPayment}>Pay with Credit Card</button>
      </div>

      <div id="googlePayButton"></div>
    </div>
  );
};

const Payment = () => {
  return (
    <div>
      <Header />
      <NavigationButtons />
      <Pay />
    </div>
  );
};

export default Payment;
