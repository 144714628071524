import React, { useEffect, useState } from 'react';
import axios from '../Axiosinstance';
import { Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import TeachHeader from './TeachHeader';


function NotificationTable() {
  const [notifications, setNotifications] = useState([]);
  const studentTeacherId = localStorage.getItem('studentteacherid');


  useEffect(() => {
    axios.get('notifications')
      .then((response) => {
        const filteredNotifications = response.data.filter((notification) =>
          notification.teacherIds.includes(Number(studentTeacherId))
        );


        filteredNotifications.sort((a, b) => b.id - a.id);
        setNotifications(filteredNotifications);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [studentTeacherId]);


  return (
    <Container>
      <TeachHeader />
      <br />
      <br />
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Notifications
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }}>S.No</TableCell>
              <TableCell style={{ width: '20%' }}>Subject</TableCell>
              <TableCell style={{ width: '30%' }}>Message</TableCell>
              {/* <TableCell style={{ width: '15%' }}>Date</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications.map((notification, index) => (
              <TableRow key={notification.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{notification.subject}</TableCell>
                <TableCell>{notification.message}</TableCell>
                {/* <TableCell>{new Date(notification.date).toLocaleDateString()}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}


export default NotificationTable;





