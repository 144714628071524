import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import Headstu from '../StudentLogin/Headstu';
import axios from '../Axiosinstance';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Prostu.css'

const Newbatch = () => {
  const [userData, setUserData] = useState({
    id: '',
    username: '',
    password: '',
    email: '',
    usertype: 0,
    sfid: 0,
    profile_Url: '',
    name:'',
  });

  const [uploadedFileUrl, setUploadedFileUrl] = useState('');

  useEffect(() => {
    // Retrieve the id from local storage
    const id = localStorage.getItem('userId');

    if (id) {
      // Make an API request to get user data
      axios.get(`home/user/${id}`)
        .then((response) => {
          const userDataResponse = response.data;
          // Update the state with the response data
          setUserData(userDataResponse);
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      formData.append('public_id', 'LMS/profile/' + file.name);

      try {
        const response = await fetch(baseURL, {
          method: 'POST',
          body: formData,
        });
        const data = await response.json();
        if (data.secure_url !== '') {
          const url = data.secure_url;
          setUploadedFileUrl(url);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  // Function to handle saving changes
  const handleSaveChanges = () => {
    // Make a PUT request to update user details
    axios
    .put(`/home/user/${userData.userId}`, {
      ...userData,
        profile_Url: uploadedFileUrl || userData.profile_Url, // Use the uploadedFileUrl if available, else retain the existing URL
      })
      .then((response) => {
        // Handle success, e.g., show a success message
        console.log('User details updated:', response.data);
        toast.success('User details updated successfully!', {
          position: 'bottom-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message
        console.error('Error updating user details:', error);
        toast.error('Error updating user details. Please try again.', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };

  return (
    <div className="new-batch">
  <div className="container-xxl px-4 mt-4">
        <h2>
          
            Profile
          
        </h2>
        <div className="row">
          <div className="col-xl-8">
            <div className="card mb-4">
            <div className="card-header bg-primary text-white">Account Details</div>
              <div className="card-body">
                <form>
                  <div className="mb-3">
                    <label htmlFor="inputUsername" className="form-label">
                      Username
                    </label>
                    <input
                      className="form-control"
                      id="inputUsername"
                      name="name"
                      type="text"
                      placeholder="Enter your name"
                      value={userData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputEmail" className="form-label">
                      Email
                    </label>
                    <input
                      className="form-control"
                      id="inputEmail"
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      value={userData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="inputPassword" className="form-label">
                      Password
                    </label>
                    <input
                      className="form-control"
                      id="inputPassword"
                      name="password"
                      type="password"
                      placeholder="Enter your password"
                      value={userData.password}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="fileupload" className="form-label">
                      Profile Image
                    </label>
                    <input type="file" id="fileupload" onChange={handleFileUpload} />
                    {userData.profile_Url && (
                      <div>
                        <p>Profile Image:</p>
                        <img style={{ width: '100%', maxHeight: '150px', objectFit: 'cover', borderRadius:'5%' }} src={userData.profile_Url} alt="Profile" />
                      </div>
                    )}
                  </div>
                  <br />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSaveChanges}
                  >
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

const NewbatchPage = () => {
  return (
    <div>
      <Headstu />
      <div className="container-xl px-4 mt-4">
        <div className="row">
          <div className="col-xl-4"></div>
          <div className="col-xl-8">
            <Newbatch />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewbatchPage;
