import React, { useState, useEffect } from 'react';
import axios from '../../Axiosinstance';
import {
 
  Button
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';


const App = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [calculator, setCalculator] = useState('');
  const [value, setValue] = useState('');
  const [errors, setErrors] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [tableData, setTableData] = useState([]); // State variable to store data from the API


  useEffect(() => {
    // Fetch data from the 'invoicesettings' API endpoint
    axios
      .get('invoicesettings')
      .then((response) => {
        setTableData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });


     
  }, [refresh]);
 


  const handleNameChange = (e) => {
    setName(e.target.value);
  };


  const handleTypeChange = (e) => {
    setType(e.target.value);
  };


  const handleCalculatorChange = (e) => {
    setCalculator(e.target.value);
  };


  const handleValueChange = (e) => {
    setValue(e.target.value);
  };


  const validateForm = () => {
    const errors = {};


    if (name.trim() === '') {
      errors.name = 'Name cannot be empty';
    } else if (tableData.some((item) => item.name === name)) {
      errors.name = 'Name already exists';
    }


    if (type === '') {
      errors.type = 'Please select a type';
    } else if (!['add', 'deduct'].includes(type)) {
      errors.type = 'Type must be either "add" or "deduct"';
    }


    if (calculator === '') {
      errors.calculator = 'Please select a calculator';
    } else if (!['percentage', 'fixed'].includes(calculator)) {
      errors.calculator = 'Calculator must be either "percentage" or "fixed"';
    }


    if (isNaN(parseFloat(value))) {
      errors.value = 'Invalid value';
    }


    setErrors(errors);


    return Object.keys(errors).length === 0;
  };


  const handleSave = () => {
    if (validateForm()) {
      const formData = {
        name: name,
        type: type,
        calculator: calculator,
        value: value,
      };


      axios
        .post('invoicesettings', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          console.log('Data successfully posted:', response.data);
          setName('');
          setType('');
          setCalculator('');
          setValue('');
          setRefresh(!refresh);
          toast.success('Particular added successfully');


        })
        .catch((error) => {
          console.error('Error posting data:', error);
        });
         
    }
  };


  return (
    <div className="container-fluid" style={{  borderRadius: '5px' ,
    padding: "15px ",
    border: "1px solid #ddd", backgroundColor: "#f9f9f9", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",   maxWidth: '1200px', marginTop: '1%', background: 'white', boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'}}>
      <h5 style={{ marginLeft: '20px' }}>Add New Particular</h5>
      <div className="form-container" style={{ padding: '5px 20px', width: '100%' }}>
        <form>
        <div className="form-group">
  <label htmlFor="invoiceName">Name:</label>
  <input
    type="text"
    id="invoiceName"
    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
    placeholder="Enter name"
    value={name}
    onChange={(e) => {
      const inputValue = e.target.value;
      const onlyLetters = /^[A-Za-z\s]+$/; // Regular expression for letters and spaces


      if (onlyLetters.test(inputValue) || inputValue === "") {
        handleNameChange(e);
      }
    }}
  />
  {errors.name && <div className="invalid-feedback">{errors.name}</div>}
</div>
          <div className="form-group">
            <label htmlFor="invoiceType">Type:</label>
            <select
              id="invoiceType"
              className={`form-control ${errors.type ? 'is-invalid' : ''}`}
              value={type}
              onChange={handleTypeChange}
            >
              <option value="" disabled>Select Type</option>
              <option value="add">Add</option>
              <option value="deduct">Deduct</option>
            </select>
            {errors.type && <div className="invalid-feedback">{errors.type}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="invoiceCalculator">Calculator:</label>
            <select
              id="invoiceCalculator"
              className={`form-control ${errors.calculator ? 'is-invalid' : ''}`}
              value={calculator}
              onChange={handleCalculatorChange}
            >
              <option value="" disabled>Select Calculator</option>
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed</option>
            </select>
            {errors.calculator && <div className="invalid-feedback">{errors.calculator}</div>}
          </div>
          <div className="form-group">
            <label htmlFor="invoiceValue">Value:</label>
            <input
              type="number"
              id="invoiceValue"
              className={`form-control ${errors.value ? 'is-invalid' : ''}`}
              placeholder="Enter value"
              value={value}
              onChange={handleValueChange}
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}


            />
            {errors.value && <div className="invalid-feedback">{errors.value}</div>}
       
       
          </div>


     
        <Button
          variant="contained"
    color="primary"
          onClick={handleSave}
         style={{ marginTop: '1%'}}
        >
          Save
        </Button>
     
        </form>
      </div>
   
    </div>
  );
};


export default App;



