import { FaPlus } from 'react-icons/fa';
import React from 'react';
import './Update.css'; // Import your custom CSS file if needed
import Header from '../../components/Header';
import  { useState ,Link} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS


const App = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <button className="btn btn-primary custom-button"style={{ backgroundColor: "#03396c", color: 'white', marginBottom: '2%' ,padding:'5% 15%' }}>All course </button>
        </div>
        <div className="col-md-2">
          <button className="btn btn-secondary custom-button" style={{ backgroundColor: "#03396c", color: 'white', marginBottom: '2%',padding:'5% 5%' }}><FaPlus/>New Course</button>
        </div>
        <div className="col-md-2">
         <a href='/A'><button className="btn btn-secondary custom-button" style={{ backgroundColor: "#03396c", color: 'white', marginBottom: '2%' ,padding:'6% 21%',marginLeft:'270%'}}>Cancel</button></a> 
        </div>
        <div className="col-md-2">
          <button className="btn btn-secondary custom-button" style={{ backgroundColor: "#03396c", color: 'white', marginBottom: '2%',padding:'5% 15%',marginLeft:'270%' }}>Submit</button>
        </div>
        
      </div>
    </div>
  );
};


const Cree = () => {
    return (
      <div className="container" style={{background:'white'}} >
        <div className="table-responsive">
          <table className="table table-bordered custom-table">
            <thead align='center'>
              <tr>
                <th>Column 1</th>
                <th>Column 2</th>
                <th>Column 3</th>
                <th>Column 4</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Row 1, Row 1,Column 1</td>
                <td>Row 1, Row 1,Column 2</td>
                <td>Row 1,Row 1, Column 3</td>
                <td>Row 1, Row 1,Column 4</td>
              </tr>
              <tr>
                <td>Row 2,Row 1, Column 1</td>
                <td>Row 2,Row 1, Column 2</td>
                <td>Row 2, Row 1,Column 3</td>
                <td>Row 2,Row 1, Column 4</td>
              </tr>
              <tr>
                <td>Row 3,Row 1, Column 1</td>
                <td>Row 3,Row 1, Column 2</td>
                <td>Row 3,Row 1, Column 3</td>
                <td>Row 3, Row 1,Column 4</td>
              </tr>
              <tr>
                <td>Row 4,Row 1, Column 1</td>
                <td>Row 4,Row 1, Column 2</td>
                <td>Row 4, Row 1,Column 3</td>
                <td>Row 4, Row 1,Column 4</td>
              </tr>
            </tbody>
          </table>
        </div>
      
      </div>
    );
  };
  

  const New= () => {
    const [columnContent, setColumnContent] = useState(['Learing OUtcome', 'Criteria', 'Evidences']);
  
 
     
    return (
      <div className="container" style={{background:'white'}}>
      <h3><label>Assesment Record Role Play
      </label></h3>
        <div className="row">
          {columnContent.map((content, index) => (
            <div className="col" key={index}>
              <div className="column-content">
                <input
                  type="text"
                
                 
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  
  const Oral= () => {
    const [columnContent, setColumnContent] = useState(['Learing OUtcome', 'Criteria', 'Evidences']);
  
 
     
    return (
      <div className="container" style={{background:'white'}}>
      <h3><label>Assesment Record for Oral
      </label></h3>
        <div className="row">
          {columnContent.map((content, index) => (
            <div className="col" key={index}>
              <div className="column-content">
                <input
                  type="text"
                
                 
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  const MyComponent = () => {
    const [showModal, setShowModal] = useState(false);
  
    const handleButtonClick = () => {
      setShowModal(true);
    };
  
    const handleModalClose = () => {
      setShowModal(false);
    };
  
    return (
        <div className="container" style={{ background: 'white' }}>
        <div className="row">
          
        </div>
        <div className="row">
          <label>The participant has been assessed to be:</label>
          <div className="col">
            <div className="column-content">
              <label style={{marginRight:'8%'}}>
                <input type="checkbox" />
                C
              </label>
             
              <label>
                <input type="checkbox" />
                NYC
              </label>
              
            </div>
          </div>
          <div className="col">
            <div className="column-content">
              <input type="text" placeholder="Feedback" />
            </div>
          </div>
          <div className="col">
            <div className="column-content">
              <button  onClick={handleButtonClick}>
                Signature
              </button>
            </div>
          </div>
        </div>
  
        
      </div>
    );
  };
  

  const MyComponen = () => {
    const [showModal, setShowModal] = useState(false);
  
    const handleButtonClick = () => {
      setShowModal(true);
    };
  

    return (
        <div className="col">
        <div className="column-content" style={{background:"none"}}>
          <button className="btn btn-primaryy" onClick={handleButtonClick} style={{background:"skyblue"}} >
           submit
          </button>
        </div>
      </div>

);
};

const Componen = () => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = () => {
    setShowModal(true);
  };


  return (
      <div className="col">
      <div className="column-content" style={{background:"none"}}>
        <button className="btn btn-primaryy" onClick={handleButtonClick} style={{background:"skyblue"}} >
         submit
        </button>
      </div>
    </div>

);
};



const Update = () => {
    return (
      <div >
      
    <Header/>
  <div  style={{ maxWidth: "1050px",marginLeft:'18%' , maxWidth: "1050px",marginLeft:'18%' ,  border: '1px solid #ccc', borderRadius: '2px', borderWidth: '1px 2px' ,marginTop:'2%' }}>
    <Cree  />
    <New/>
    <Oral/>
    <MyComponent/>

<App  />
</div>
      </div>
    );
  };
  
export default Update;


