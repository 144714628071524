const NavigationBar = () => {
    return (
      <div className="navigation-buttons" style={{ marginLeft: '20%',marginRight:'10%',marginTop:'1%' }}>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 10px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/General' style={{ textDecoration: 'none', color: 'white' }}>General Settings</a>
    </button>
    <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Roaster' style={{ textDecoration: 'none', color: 'white' }}>Roaster</a>
    </button>
    <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='Invoicesetting' style={{ textDecoration: 'none', color: 'white' }}>Invoice Setting</a>
    </button>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 5px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Feedback' style={{ textDecoration: 'none', color: 'white' }}>Feedback Question</a>
    </button>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 40px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Blogs' style={{ textDecoration: 'none', color: 'white' }}>Blogs</a>
    </button>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 10px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Activity' style={{ textDecoration: 'none', color: 'white' }}>Activity Log</a>
    </button>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Api' style={{ textDecoration: 'none', color: 'white' }}>Api Doc</a>
    </button>
    <button style={{ backgroundColor:'#03396c', color: 'white', border: 'none', padding: '10px 20px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Payment' style={{ textDecoration: 'none', color: 'white' }}>Payment</a>
    </button>
    <button style={{ backgroundColor: '#03396c', color: 'white', border: 'none', padding: '10px 30px', borderRadius: '4px', margin: '5px', textDecoration: 'none', cursor: 'pointer' }}>
      <a href='/Maintance' style={{ textDecoration: 'none', color: 'white' }}>Maintance</a>
    </button>
  </div>
    );
  };
  
  
  
  const 

   Heading = () => {
    return (
      <div>
     <NavigationBar/>
      </div>
    );
  };
  
  export default Heading;