import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../Axiosinstance';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Header from '../components/Header';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { BsPencilFill, BsTrashFill } from 'react-icons/bs';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast, ToastContainer } from 'react-toastify';
import { CLOUDINARY_UPLOAD_PRESET, baseURL } from '../common'
import Newstudent from './Newstudent'
import Loader from '../Loader';
import Modal from 'react-bootstrap/Modal';
import { InputLabel, FormControl } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const columns = [
  { id: 'id', label: 'ID', minWidth: 170 },
  { id: 'fullname', label: 'Name', minWidth: 170 },
  { id: 'image', label: 'Image', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'phone', label: 'Phone', minWidth: 170 },
  { id: 'gender', label: 'Gender', minWidth: 170 },

  { id: 'actions', label: 'Actions', minWidth: 170 },
];


const Screen = () => {
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]); // Define filteredStudents
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterGender, setFilterGender] = useState('');
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editedStudent, setEditedStudent] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const CLOUDINARY_URL = baseURL;
  const CLOUDINARY_UPLOAD = CLOUDINARY_UPLOAD_PRESET;
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(true);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    fetchStudents();
  }, []);


  const fetchStudents = async () => {
    try {
      setLoading(true); // Set loading to true when starting to fetch data
      const response = await axios.get('students');
      setStudents(response.data.reverse());
      setFilteredStudents(response.data);
      setLoading(false); // Set loading to false when data is loaded
    } catch (error) {
      console.error('Error fetching students:', error);
      setLoading(false); // Set loading to false in case of an error
    }
  };


  useEffect(() => {
    const filtered = students.filter((student) => {
      if (filterGender) {
        return student.gender.toLowerCase() === filterGender.toLowerCase();
      }
      return true;
    });


    const searchFiltered = filtered.filter((student) =>
      student.fullname.toLowerCase().includes(searchQuery.toLowerCase())
    );


    setFilteredStudents(searchFiltered);
  }, [searchQuery, filterGender, students, currentPage, pageSize]);


  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const handleFilterGender = (gender) => {
    setFilterGender(gender);
    setCurrentPage(1); // Reset to the first page when applying filters
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingStudentId, setDeletingStudentId] = useState(null);

  const DeleteConfirmationModal = ({ show, handleClose, handleConfirm }) => {
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this student?</Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: 'grey', color: 'white' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button style={{ backgroundColor: 'red', color: 'white', marginLeft: '10px' }} onClick={() => handleConfirm(deletingStudentId)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };


  const openDetailsModal = (student, isEdit) => {
    setEditedStudent(student);
    setIsReadOnly(!isEdit); // Set read-only mode to true if it's not an edit
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    setDeletingStudentId(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await axios.delete(`student/${id}`);

      if (response.status === 200) {
        const updatedStudents = students.filter((student) => student.id !== id);
        setStudents(updatedStudents);
        setFilteredStudents(updatedStudents);
        toast.success('Student deleted successfully');
      } else {
        console.error('Failed to delete student');
      }
    } catch (error) {
      console.error('Error deleting student:', error);
    } finally {
      handleCloseDeleteModal();
    }
  };


  const openEditModal = (student) => {
    setEditedStudent(student);
    setEditModalOpen(true);
  };


  const closeEditModal = () => {
    setEditModalOpen(false);
  };


  const handleSaveEdit = async (e) => {
    e.preventDefault();

    try {

      if (
        !editedStudent.fullname ||
        !editedStudent.email ||
        !editedStudent.nric ||
        !editedStudent.phone ||
        !editedStudent.gender ||
        !editedStudent.dob ||
        !editedStudent.password ||
        !editedStudent.address ||
        !editedStudent.postalcode ||
        !editedStudent.race ||
        !editedStudent.eduction ||
        !editedStudent.employmentstatus ||
        !editedStudent.studentId
        // !editedStudent.salary ||
        // !editedStudent.companyname ||
        // !editedStudent.corporatecompanysponsored ||
        // !editedStudent.designation
      ) {
        toast.error('Please fill in all required fields.');
        return;
      }

      const confirmation = window.confirm('Are you sure you want to update this student?');

      if (!confirmation) {
        return; // Cancel the update if the user clicks "Cancel" in the confirmation alert
      }

      let imageUrl = editedStudent.profileUrl;
      // If a new image is selected, upload it to Cloudinary
      if (selectedFile) {
        const timestamp = Date.now();
        const fileExtension = selectedFile.name.split('.').pop();
        const fileNameWithTimestamp = `${timestamp}.${fileExtension}`;

        const formDataWithImage = new FormData();
        formDataWithImage.append('file', selectedFile, fileNameWithTimestamp);
        formDataWithImage.append('upload_preset', CLOUDINARY_UPLOAD);
        formDataWithImage.append('public_id', `LMS/Student/${fileNameWithTimestamp}`);

        const imageResponse = await fetch(CLOUDINARY_URL, {
          method: 'POST',
          body: formDataWithImage,
        });

        const imageData = await imageResponse.json();
        // console.log('Image Response:', imageData);

        if (imageData.secure_url !== '') {
          imageUrl = imageData.secure_url;
          setUploadedImageUrl(imageData.secure_url); // Set uploaded image URL

        }


      }



      // Update other student information
      const updatedStudent = {
        ...editedStudent,
        profileUrl: imageUrl, // Assign the imageUrl here
      };

      const response = await axios.put(`student/${editedStudent.id}`, updatedStudent);

      if (response.status === 200) {
        const updatedStudents = students.map((student) =>
          student.id === editedStudent.id ? updatedStudent : student
        );
        setStudents(updatedStudents);
        closeEditModal();
        toast.success('Student updated successfully');
      } else {
        console.error('Failed to update student');
      }
    } catch (error) {
      console.error('Error updating student:', error);
    }
  };


  const handleBulkDownload = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredStudents);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Students');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });


    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'students.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const [isFocused, setIsFocused] = useState(false);


  return (
    <div>
      <div className="container" style={{ maxWidth: '1200px' }}>
        {loading ? (
          <Loader />
        ) : (
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    placeholder="Search by Name"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="form-control"
                  />
                  <select
                    value={filterGender}
                    onChange={(e) => setFilterGender(e.target.value)}
                    style={{
                      padding: '8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                      fontSize: '16px',
                      marginLeft: '10px',
                    }}
                  >
                    <option value="">All</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>

                  </select>


                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleBulkDownload}
                    style={{ marginLeft: '20px', borderRadius: '5%' }}
                  >
                    Bulk Download
                  </Button>
                </div>
              </div>
            </div>
            <TableContainer
              sx={{
                overflowX: 'auto',
                maxWidth: '100%',
                '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
                  overflowX: 'unset', // Enable horizontal scroll
                },
              }}
            >
              <div style={{ maxHeight: '600px', overflowY: 'auto' }}>

                <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align="left"
                          style={{ fontWeight: 'bold' }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredStudents
                      .slice((currentPage - 1) * studentsPerPage, currentPage * studentsPerPage)
                      .map((student) => (
                        <TableRow key={student.id}>
                          <TableCell>{student.studentId}</TableCell>
                          <TableCell>
                            <span

                              title={`${student.fullname} details`}

                              style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                              onClick={() => openDetailsModal(student, false)} // Pass 'false' for read-only mode
                            >
                              {student.fullname}
                            </span>
                          </TableCell>
                          <TableCell>
                            <img
                              src={student.profileUrl ? student.profileUrl :
                                student.gender === 'Male' ?
                                  'https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/default-profile-picture-male-icon.png' :
                                  student.gender === 'Female' ?
                                    'https://cdn3.iconfinder.com/data/icons/business-vol-26/100/Artboard_2-512.png' :
                                    'https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg'}
                              alt="Profile"
                              style={{ width: '150px', height: '100px' }}
                            />
                          </TableCell>
                          <TableCell>{student.email}</TableCell>
                          <TableCell>{student.phone}</TableCell>
                          <TableCell>{student.gender}</TableCell>

                          <TableCell style={{ width: '10%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <BsPencilFill
                                style={{ marginRight: 10 }}
                                onClick={() => openDetailsModal(student, true)} // Pass 'true' for edit mode
                              />                    <DeleteRoundedIcon
                                style={{ color: 'red', marginLeft: 10 }}
                                onClick={() => handleDelete(student.id)}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredStudents.length}
              rowsPerPage={studentsPerPage}
              page={currentPage - 1}
              labelDisplayedRows={({ from, to }) => `${from}-${to} Page No ${currentPage}`}
              onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
              onRowsPerPageChange={(event) => {
                setCurrentPage(1);
                setStudentsPerPage(event.target.value);
              }}
            />
          </Paper>
        )}
      </div>
      <Dialog open={isEditModalOpen} onClose={closeEditModal}>
        <DialogTitle>
          {isReadOnly ? 'Student Details' : 'Edit Student (Newton International College)'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSaveEdit}>
            <div >
              <TextField
                label="Full Name*"
                name="fullname"
                required
                value={editedStudent ? editedStudent.fullname : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, '');
                    const truncatedValue = inputValue.substring(0, 25);
                    if (truncatedValue.trim() !== '' || truncatedValue.length === 0) {
                      setEditedStudent({
                        ...editedStudent,
                        fullname: truncatedValue,
                      });
                    }
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />


              <TextField
                label="studentId*"
                name="studentId"
                value={editedStudent ? editedStudent.studentId : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    setEditedStudent({
                      ...editedStudent,
                      studentId: e.target.value,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />


              <TextField
                label="Email*"
                name="email"
                value={editedStudent ? editedStudent.email : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    setEditedStudent({
                      ...editedStudent,
                      email: e.target.value,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />



              <TextField
                label="NRIC*"
                name="nric"
                value={editedStudent ? editedStudent.nric : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only alphanumeric characters and limit to 12 characters
                    const inputValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 12);
                    setEditedStudent({
                      ...editedStudent,
                      nric: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />




              <TextField
                label="Phone*"
                name="phone"
                value={editedStudent ? editedStudent.phone : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only numeric characters and limit to 10 characters
                    const inputValue = e.target.value.replace(/[^0-9]/g, '').substring(0, 8);
                    setEditedStudent({
                      ...editedStudent,
                      phone: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />




              <div className="form-group">
                <label>Gender*</label>
                <Select
                  className="form-control"
                  name="gender"
                  style={{
                    width: '100%',
                    height: '49px',
                    borderRadius: '6px',
                    border: `1px solid ${isFocused ? '#007bff' : '#ced4da'}`,
                    fontSize: '14px',
                    padding: '8px',
                    backgroundColor: '#ffffff',
                    color: '#495057',
                    outline: 'none',
                    boxShadow: isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
                  }}
                  value={editedStudent ? editedStudent.gender : ''}
                  onChange={(e) => {
                    // Allow editing only if not in read-only mode
                    if (!isReadOnly) {
                      setEditedStudent({
                        ...editedStudent,
                        gender: e.target.value,
                      });
                    }
                  }}
                  fullWidth
                  margin="normal"
                  inputProps={{ readOnly: isReadOnly }} // Set Select as read-only based on isReadOnly
                >
                  <MenuItem value="">Select Gender</MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </div>

              <div className="form-group">
                <label>Date of Birth*</label>
                <input
                  type="date"

                  label="Date of Birth"
                  style={{ width: '100%', height: '49px', borderRadius: '6px', border: '1px solid #ced4da' }}
                  className={`form-control`}

                  name="dob"
                  value={editedStudent ? editedStudent.dob : '2000-01-01'} // Default value is set to '2000-01-01'
                  onChange={(e) => {
                    // Allow editing only if not in read-only mode
                    if (!isReadOnly) {
                      setEditedStudent({
                        ...editedStudent,
                        dob: e.target.value,
                      });
                    }
                  }}
                  max="2012-12-31" // Set the maximum date
                  min="1920-01-01" // Set the minimum date
                  readOnly={isReadOnly} // Set input as read-only based on isReadOnly
                />
              </div>



              <TextField
                label="Password*"
                name="password"
                value={editedStudent ? editedStudent.password : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    setEditedStudent({
                      ...editedStudent,
                      password: e.target.value,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />

              <TextField
                label="Address*"
                name="address"
                value={editedStudent ? editedStudent.address : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    setEditedStudent({
                      ...editedStudent,
                      address: e.target.value,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />



              <TextField
                label="Postal Code*"
                name="postalcode"
                value={editedStudent ? editedStudent.postalcode : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only alphanumeric characters and limit to 10 characters
                    const inputValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 10);
                    setEditedStudent({
                      ...editedStudent,
                      postalcode: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />



              <TextField
                label="Race*"
                name="race"
                value={editedStudent ? editedStudent.race : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    setEditedStudent({
                      ...editedStudent,
                      race: e.target.value,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />


              <TextField
                label="Education*"
                name="eduction"
                value={editedStudent ? editedStudent.eduction : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only letters and limit to 30 characters
                    const inputValue = e.target.value.replace(/[^A-Za-z]/g, '').substring(0, 30);
                    setEditedStudent({
                      ...editedStudent,
                      eduction: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />



              <FormControl fullWidth margin="normal">
                <label>Employment Status*</label>
                <Select
                  value={editedStudent ? editedStudent.employmentstatus : ''}
                  onChange={(e) => {
                    // Allow editing only if not in read-only mode
                    if (!isReadOnly) {
                      setEditedStudent({
                        ...editedStudent,
                        employmentstatus: e.target.value,
                      });
                    }
                  }}
                  inputProps={{ readOnly: isReadOnly }}
                >
                  <MenuItem value="">Select Employment Status</MenuItem>
                  <MenuItem value="employed">Employed</MenuItem>
                  <MenuItem value="unemployed">Unemployed</MenuItem>
                  <MenuItem value="student">Student</MenuItem>
                </Select>
              </FormControl>


              <TextField
                label="Salary"
                name="salary"
                value={editedStudent ? editedStudent.salary : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only numbers and limit to 10 digits
                    const inputValue = e.target.value.replace(/\D/g, '').substring(0, 10);
                    setEditedStudent({
                      ...editedStudent,
                      salary: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />




              <TextField
                label="Company Name"
                name="companyname"
                value={editedStudent ? editedStudent.companyname : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow letters and numbers, limit to 30 characters
                    const inputValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 30);
                    setEditedStudent({
                      ...editedStudent,
                      companyname: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />





              <TextField
                label="Corporate Company Sponsored"
                name="corporatecompanysponsored"
                value={editedStudent ? editedStudent.corporatecompanysponsored : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow letters and numbers, limit to 30 characters
                    const inputValue = e.target.value.replace(/[^A-Za-z0-9]/g, '').substring(0, 30);
                    setEditedStudent({
                      ...editedStudent,
                      corporatecompanysponsored: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />




              <TextField
                label="Designation"
                name="designation"
                value={editedStudent ? editedStudent.designation : ''}
                onChange={(e) => {
                  // Allow editing only if not in read-only mode
                  if (!isReadOnly) {
                    // Validate input to allow only letters
                    const inputValue = e.target.value.replace(/[^A-Za-z]/g, '');
                    setEditedStudent({
                      ...editedStudent,
                      designation: inputValue,
                    });
                  }
                }}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: isReadOnly }} // Set TextField as read-only based on isReadOnly
              />




              <div className="col-md-6">
                <label htmlFor="file" className="form-label">Profile image*</label>

                {isReadOnly ? null : (
                  <input
                    type="file"
                    name="file"
                    onChange={handleFileChange}
                    className="form-control"
                    disabled={isReadOnly}
                  />
                )}

                {selectedFile && <div>Selected File: {selectedFile.name}</div>}
              </div>


              {/* Display the uploaded image */}
              <div>
                <p>Image:</p>
                {selectedFile ? (
                  <img style={{ width: '150px', height: '100px' }} src={URL.createObjectURL(selectedFile)} alt="Uploaded" />
                ) : (
                  <img style={{ width: '150px', height: '100px' }} src={uploadedImageUrl || editedStudent.profileUrl} alt="Existing" />
                )}
              </div>


            </div>    </form>
        </DialogContent>
        <DialogActions>
          {!isReadOnly && ( // Conditionally render the Update button based on read-only mode
            <Button onClick={handleSaveEdit} variant="contained" color="primary" style={{ backgroundColor: 'green', color: 'white' }}>
              Update
            </Button>
          )}
          <Button onClick={closeEditModal} variant="contained" color="secondary" style={{ backgroundColor: 'red', color: 'white' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleConfirm={handleConfirmDelete}
      />
    </div>
  );
};


const New = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };



  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Header />
      <br />
      <div className="d-flex justify-content-center mt-4">
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Students-tabs"
          variant="fullWidth"
          sx={{
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
            "& button": {
              borderRadius: 10,
              color: "#555",

              margin: 'auto',
              display: 'block'
            },
            "& button:hover": {
              backgroundColor: "#eee",
            },
            "& button:active": {
              backgroundColor: "#ddd",
            },
            "& button.Mui-selected": {
              backgroundColor: "#007bff",
              color: "#fff",
            },
            "& .MuiTabs-indicator": {
              display: 'none', // Remove the underline
            },
            ...(isMobile && {
              flexDirection: 'column', // Stack tabs vertically
              "& button": {
                width: '100%', // Set tabs to full width on mobile
              },
            }),
          }}
        >
          <Tab label="All Students" sx={{ minWidth: isMobile ? '50%' : '620px' }} />
          <Tab label="New Student" sx={{ minWidth: isMobile ? '50%' : '620px' }} />
        </Tabs>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {tabValue === 0 && <Screen />}
        {tabValue === 1 && <Newstudent onFormSubmit={() => handleTabChange(0)} />}
      </div>
    </div>
  );



};

export default New;