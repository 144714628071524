import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-quill/dist/quill.snow.css';
import Heading from './Heading';

const Pay = () => {
  return (
    <div className="min-w-screen min-h-screen bg-gray-200 flex items-center justify-center px-5 pb-10 pt-16">
      <div className="w-full mx-auto rounded-lg bg-white shadow-lg p-5 text-gray-700" style={{ maxWidth: '1100px', marginTop: '2%' }}>
        <div className="w-full pt-1 pb-5">
          <div className="bg-indigo-500 text-white overflow-hidden rounded-full w-20 h-20 -mt-16 mx-auto shadow-lg flex justify-center items-center">
            <i className="mdi mdi-credit-card-outline text-3xl"></i>
          </div>
        </div>
        <div className="mb-">
          <h1 className="text-center font-bold text-xl uppercase">Secure payment info</h1>
        </div>
        <div className="mb-3 flex -mx-2">
  <div className="mb-3 flex -mx-2">
  <div className="px-2">
  <label htmlFor="type1" className="flex items-center cursor-pointer">
    <input type="radio" className="form-radio h-5 w-5 text-indigo-500" name="type" id="type1" checked />
    <img src="https://leadershipmemphis.org/wp-content/uploads/2020/08/780370.png" style={{ height: "100px", width: "500px", marginLeft: "5px" }} />
  </label>
</div>
<div className="px-2">
  <label htmlFor="type2" className="flex items-center cursor-pointer">
    <input type="radio" className="form-radio h-5 w-5 text-indigo-500" name="type" id="type2" />
    <img src="https://www.sketchappsources.com/resources/source-image/PayPalCard.png" style={{ height: "100px", width: "200px", marginLeft: "5px" }} />
  </label>
</div>

</div>
</div>
        <div className="mb-3">
          <label className="font-bold text-sm mb-2 ml-1">Name on card</label>
          <div>
            <input
              className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
              placeholder=""
              type="text"
            />
          </div>
        </div>
        <div className="mb-3">
          <label className="font-bold text-sm mb-2 ml-1">Card number</label>
          <div>
            <input
              className="w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
              placeholder="0000 0000 0000 0000"
              type="text"
            />
          </div>
        </div>
        <div className="mb-3 -mx-2 flex items-end">
          <div className="px-2 w-1/2">
            <label className="font-bold text-sm mb-2 ml-1">Expiration date</label>
            <div>
              <select
                className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
              >
                <option value="01">01 - January</option>
                <option value="02">02 - February</option>
                {/* ... other options ... */}
              </select>
            </div>
          </div>
          <div className="px-2 w-1/2">
            <select
              className="form-select w-full px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
            >
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              {/* ... other options ... */}
            </select>
          </div>
        </div>
        <div className="mb-10">
          <label className="font-bold text-sm mb-2 ml-1">Security code</label>
          <div>
            <input
              className="w-32 px-3 py-2 mb-1 border-2 border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
              placeholder=""
              type="text"
            />
          </div>
        </div>
        <div>
        <button
  style={{
    backgroundColor: '#03396c',
    color: '#fff',
    border: 'none',
    padding: '10px 26px',
    borderRadius: '4px',
    cursor: 'pointer',
  }}
  className="block w-full max-w-xs mx-auto font-semibold"
>
  <i className="mdi mdi-lock-outline mr-1"></i> PAY NOW
</button>

          
        </div>
      </div>
    </div>
  );
};
 
const Payment = () => {
  return (
    <div>
      <Header />
      <Heading/>
      <Pay />
    </div>
  );
};

export default Payment;
