import React, { useState, useEffect, useLayoutEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../components/Ad.css';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faPlus, faClipboardList, faUserPlus, faChalkboardUser, faCalendar, faCalendarWeek, faCalendarAlt, faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import 'react-calendar/dist/Calendar.css'
import 'bootstrap/dist/css/bootstrap.min.css';
// import LearnImage from '../images/Learn.jpeg'
import LearnImage from '../images/learn.jpg'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from '../Axiosinstance';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Loader from '../Loader';
import { faUserGraduate, faUserTie, faLayerGroup, faChalkboard, faBook } from '@fortawesome/free-solid-svg-icons';
import Studentreq from './Studentreq'
import { Tabs, Tab,      TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { faTasks, faProjectDiagram, faBell, faTicketAlt, faBullhorn, faTable } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Ap = () => {


  return (
    <div className="container" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif', padding: '30px'  , marginTop: '-2%'}}>
      <h1 className="welcome-heading" style={{ color: '#2186C8', fontSize: '2.4rem', fontWeight: 'bold', marginBottom: '8px', fontStyle: 'italic' }}>
        <span style={{ color: '#2186C8' }}>Newton International College</span>
      </h1>
      <h4 className="welcome-heading" style={{ color: '#97903F', fontSize: '1.4rem', fontWeight: 'bold', marginBottom: '10px' }}>
        {/* <span style={{ color: 'gold' , fontSize: '2rem' }}>i</span>
      <span style={{ color: 'gold' }}>mpulz</span>  */}
        Learning Management System
      </h4>
    </div>
  );
};


const WelcomeBoxes = () => {
  const [hoveredBatch, setHoveredBatch] = useState(false);
  const [hoveredCourse, setHoveredCourse] = useState(false);
  // Add similar state variables for other boxes
  const [hoveredFacilitators, setHoveredFacilitators] = useState(false);
  const [hoveredStudent, setHoveredStudent] = useState(false);
  const [hoveredRegistration, setHoveredRegistration] = useState(false);
  const [hoveredStudentRequest, setHoveredStudentRequest] = useState(false);
  const [count, setCount] = useState();
  const [countStudent, setCountStudent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showStudentReq, setShowStudentReq] = useState(false);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get('batch/count');
        // const response2 = await axios.get('requeststudents/count');
                const response2 = await axios.get('exisitingstudentrequest/countFalseStatus');

        setCount(response1.data);
        setCountStudent(response2.data);
        // console.log(response2.data);

      } catch (error) {
        console.error('Error fetching batch count:', error);
      }
    };

    fetchData();
  }, []);


  const handleHoverBatch = () => {
    setHoveredBatch(!hoveredBatch);
  };

  const handleHoverCourse = () => {
    setHoveredCourse(!hoveredCourse);
  };
  // Add similar functions for other boxes
  const handleHoverFacilitators = () => {
    setHoveredFacilitators(!hoveredFacilitators);
  };




  const handleHoverStudent = () => {
    setHoveredStudent(!hoveredStudent);
  };




  const handleHoverRegistration = () => {
    setHoveredRegistration(!hoveredRegistration);
  };




  const handleHoverStudentRequest = () => {
    setHoveredStudentRequest(!hoveredStudentRequest);
  };

  const openModal = () => {
    setShowModal(true);
   

  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleOpenStudentReq = () => {
    setShowStudentReq(true);
  };
  
  const handleCloseStudentReq = () => {
    setShowStudentReq(false);
  };
  

  return (
<>
 {/* <div className="col-12 col-md-4">
          <a href="/batches" style={{ textDecoration: 'none' }}>
            <div
              className="box blue-card"
              style={{
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                padding: '20px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                transform: hoveredRegistration ? 'scale(1.05)' : 'scale(1)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer' // Add pointer cursor for better UX
              }}
              onMouseEnter={handleHoverRegistration}
              onMouseLeave={handleHoverRegistration}
            >
              <div style={{ marginBottom: '15px' }}>
                <FontAwesomeIcon
                  icon={faBook}
                  size="3x"
                  style={{
                    color: '#373ABB',
                  }}
                />
              </div>
              <h3 style={{ color: '#373ABB', fontWeight: 'bold', marginBottom: '10px', fontSize: '22px' }}>Total Batches</h3>
              {typeof count === 'undefined' ? (
                <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>Loading...</p>
              ) : (
                <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>{count} batches available</p>
              )}
              <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>Click here to view batch details.</p>
            </div>
          </a>
        </div> */}


    <div className="container" style={{ maxWidth: '1550px' }}>
      <div className="row">

       


        <div className="col-12 col-md-6"  onClick={handleOpenStudentReq} >
            <div
              className="box green-card"
              style={{
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                padding: '20px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                transform: hoveredStudentRequest ? 'scale(1.05)' : 'scale(1)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                cursor: 'pointer' // Add pointer cursor for better UX
              }}
              onMouseEnter={handleHoverStudentRequest}
              onMouseLeave={handleHoverStudentRequest}
            >
              <div style={{ marginBottom: '15px' }}>
                <FontAwesomeIcon
                  icon={faClipboardList}
                  size="3x"
                  style={{
                    color: '#373ABB',
                  }}
                />
              </div>
              <h3 style={{ color: '#373ABB', fontWeight: 'bold', marginBottom: '10px', fontSize: '22px' }}>Student Requests</h3>
              {/* Placeholder text while countStudent is being fetched */}
              {typeof countStudent === 'undefined' ? (
                <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>Loading...</p>
              ) : (
                <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>{countStudent} requests pending</p>
              )}
              <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>View and check the upcoming course details.</p>
            </div>
        
        </div>


        <div className="col-12 col-md-6"  onClick={openModal}>
            <div
                     

              className="box green-card"
              style={{
                borderRadius: '8px',
                backgroundColor: '#ffffff',
                padding: '20px',
                textAlign: 'center',
                transition: 'transform 0.3s ease',
                transform: hoveredStudent ? 'scale(1.05)' : 'scale(1)',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
              onMouseEnter={handleHoverStudent}
              onMouseLeave={handleHoverFacilitators}
            >
              <FontAwesomeIcon
                icon={faLayerGroup}
                size="3x"
                style={{
                  marginBottom: '15px',
                  color: '#373ABB',
                }}
              />
              <h3 style={{ color: '#373ABB', fontWeight: 'bold', marginBottom: '10px', fontSize: '22px' }}>New Registration</h3>
              <p style={{ color: '#373ABB', fontSize: '16px', margin: '10px 0' }}>View and New Registration</p>
            </div>
    
        </div>

      </div>
<Modal show={showModal} onHide={closeModal} dialogClassName="modal-fullscreen">
  <Modal.Header closeButton>
    <Modal.Title>New Registration</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Tablerow />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
  </Modal.Footer>
</Modal>

<Modal show={showStudentReq} onHide={handleCloseStudentReq} dialogClassName="modal-fullscreen">
  <Modal.Header closeButton>
    <Modal.Title>Student Requests</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Studentreq />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseStudentReq}>Cancel</Button>
  </Modal.Footer>
</Modal>





    </div>

    </>
  );
  
};

const Dashboard = () => {
  const [studentdata1, setstudentdata1] = useState(0);
  const [staffdata2, setstaffdata2] = useState(0);
  const [batchdata3, setbatchdata3] = useState(0);
  const [coursesdata4, setcoursesdata4] = useState(0);
  // const [categorydata5, setcategorydata5] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response1, response2, response3, response4] = await Promise.all([
          axios.get('student/count'),
          axios.get('facilitator/count'),
          axios.get('batch/count'),
          axios.get('course/count')
        
          // axios.get('category/count'),
        ]);
        setstudentdata1(response1.data);
        setstaffdata2(response2.data);
        setbatchdata3(response3.data);
        setcoursesdata4(response4.data);


        // console.log(studentdata1);
        // console.log(staffdata2);
        // console.log(batchdata3);
        // console.log(coursesdata4);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  // Sample data for total counts
  const totals = [
    { name: 'Total Students', count: studentdata1, icon: faUserGraduate, path: '/Allstudent' },
    { name: 'Total Staff', count: staffdata2, icon: faUserTie, path: '/Facilitor' },
    { name: 'Total Courses', count: coursesdata4, icon: faChalkboard, path: '/AllCourse' },
    { name: 'Batches', count: batchdata3, icon: faLayerGroup, path: '/Batches' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
  };

    // { name: 'Total Batches', count: batchdata3, icon: faChalkboard }


  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        backgroundImage: `url(${LearnImage})`, // Set background image
        backgroundSize: 'cover', // Cover the entire container
        backgroundPosition: 'center calc(50% + 10px)', // Move the image down from half of the container
        maxWidth: '1700px', // Set a maximum width to reduce the container width
        margin: '0 auto', // Center the container horizontally
        borderRadius: '15px',
        flexWrap: 'wrap', // Allow items to wrap to the next line
      }}
    >
      {totals.map((item, index) => (
        <div
          key={index}
          onClick={() => handleNavigation(item.path)}
          style={{
            width: '350px',
            height: '120px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Updated box shadow
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px', // Increased padding for better spacing
            margin: '8px', // Added margin for spacing between items
            cursor: 'pointer',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out', // Added transform transition for hover effect
            backgroundColor: hoverIndex === index ? '#F3F59F' : '#ffffff', // Updated background color
          }}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          <div style={{ flex: 1, textAlign: 'left' }}>
            <div style={{ fontSize: '32px', fontWeight: 'bold', marginBottom: '5px', color: '#005656' }}>{item.count}</div>
            <div style={{ fontSize: '22px', fontWeight: 'bold', color: '#373ABB' }}>{item.name}</div>
          </div>
          <div style={{ width: '50px', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <FontAwesomeIcon icon={item.icon} style={{ fontSize: '42px', color: '#373ABB' }} />

          </div>
        </div>
      ))}
    </div>



  );
};



const Tablerow = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const [hoveredStudentDetails, setHoveredStudentDetails] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    axios.get('requeststudent')
      .then(response => {
        setStudents(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);






  const [selectedRow, setSelectedRow] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);




  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };




  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };










  const handleApprove = (id) => {
    setSelectedStudentId(id);
    setShowConfirmation(true);
  };




  const confirmApprove = () => {
    if (selectedStudentId) {
      axios.get(`requeststudents/${selectedStudentId}`)
        .then(response => {
          const studentData = response.data;


          // Update only the "status" field
          studentData.status = true;


          axios.put(`requeststudents/${selectedStudentId}`, studentData)
            .then(() => {
              // If the 'status' turns true, update the 'student' endpoint with the same data
              if (studentData.status === true) {
                const studentToUpdate = { ...studentData };


                axios.post('student', studentToUpdate)
                  .then(response => {
                    const sfid = response.data.id;


                    const userData = {
                      username: studentData.fullname,
                      password: studentData.password,
                      email: studentData.email,
                      usertype: 1, // Set the usertype as needed
                      
                      profile_Url: studentData.profileUrl,
                      sfid: sfid // Assuming sfid comes from the 'student' response
                      // Other necessary fields
                    };


                    axios
                      .post('auth/create-user', userData)
                      .then(() => {
                        // Perform any additional actions or UI updates


                        // Fetch the updated list of students after approval
                        axios.get('requeststudent')
                          .then(response => {
                            setStudents(response.data); // Update the students state


                            // Show a success toast notification
                            toast.success('Student approved successfully', {
                              position: 'bottom-right',
                              autoClose: 3000, // Close the notification after 3 seconds
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                            });
                          })
                          .catch(error => {
                            console.error('Error fetching updated student list:', error);
                            // Handle error scenarios for fetching updated students
                          });
                      })
                      .catch(error => {
                        console.error('Error updating user data:', error);
                        // Handle error scenarios for 'user' endpoint
                      });
                  })
                  .catch(error => {
                    console.error('Error updating student data:', error);
                    // Handle error scenarios for 'student' endpoint
                  });
              }
            })
            .catch(error => {
              console.error('Error updating student data in requeststudents endpoint:', error);
              // Handle error scenarios for 'requeststudents' endpoint
            });
        })
        .catch(error => {
          console.error('Error fetching student data:', error);
          // Handle error scenarios for fetching student data
        });
    }


    setShowConfirmation(false);
    setSelectedStudentId(null);
  };


  const cancelApprove = () => {
    setShowConfirmation(false);
    setSelectedStudentId(null);
  };



  const handleDelete = (id) => {
    // Show a confirmation dialog or prompt the user for confirmation
    const confirmDelete = window.confirm("Are you sure you want to delete this student?");


    if (confirmDelete) {
      // Assuming axios is imported in your file
      axios.delete(`requeststudents/${id}`)
        .then(() => {
          // If the deletion is successful, remove the student from the list
          const updatedStudents = students.filter(student => student.id !== id);
          setStudents(updatedStudents);
        })
        .catch(error => {
          console.error('Error deleting student:', error);
          // Handle error scenarios (e.g., showing an error message)
        });
    }
  };




  const handleCancel = () => {
    setSelectedRow(null);
    setShowConfirmation(false);
  };








  const handleHover = (studentDetails) => {
    setHoveredStudentDetails(studentDetails);
  };








  const handleLeave = () => {
    setHoveredStudentDetails(null);
  };


  return (
    <div className="container-responsive" style={{marginRight:'10%', marginLeft:'10%', marginBottom:'0px'}}>

      {loading ? ( // Render loader if loading is true
        <Loader /> // Replace Loader with your loader component
      ) : (
        <>
          <ToastContainer />
          {showConfirmation && (
            <div className="confirmation-overlay">
              <div className="confirmation-message">
                <p>Approving Student</p>
                <p>Are you sure to approve this student for this batch</p>
                <div className="confirmation-buttons">
                  <button
                    className=""
                    onClick={confirmApprove} // Trigger the approve action


                    style={{
                      backgroundColor: '#28a745',
                      color: '#fff',
                      border: 'none',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className=""
                    onClick={cancelApprove} // Cancel the action
                    style={{
                      backgroundColor: '#dc3545',
                      color: '#fff',
                      border: 'none',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                  >
                    Cancel
                  </button>


                </div>
              </div>
            </div>
          )}
          <h2 className="heading" align="center" style={{ color: '  #FFFFFF', fontSize: '28px' }}>New Registration</h2>
          <TableContainer sx={{ maxHeight: 'none' }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell style={{ minWidth: 60, borderBottom: '1px solid #ccc' }}>S.No</TableCell>
                  <TableCell style={{ minWidth: 170, borderBottom: '1px solid #ccc' }}>Name</TableCell>
                  <TableCell style={{ minWidth: 150, borderBottom: '1px solid #ccc' }}>Phone No</TableCell>
                  <TableCell style={{ minWidth: 120, borderBottom: '1px solid #ccc' }}>Course</TableCell>
                  <TableCell style={{ minWidth: 120, borderBottom: '1px solid #ccc' }}>Status</TableCell>
                  <TableCell style={{ minWidth: 120, borderBottom: '1px solid #ccc' }}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {students
                  .filter((student) => student.status === 'false')
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((student, index) => (
                    <TableRow key={student.id} onMouseEnter={() => handleHover(student)} onMouseLeave={handleLeave}>
                      <TableCell style={{ minWidth: 60, borderRight: '1px solid #ccc' }}>{index + 1}</TableCell>
                      <TableCell style={{ minWidth: 170, borderRight: '1px solid #ccc' }}>
                        <Tooltip
                          title={
                            hoveredStudentDetails ? (
                              <div>
                                <p>Name: {hoveredStudentDetails.fullname}</p>
                                <p>Email: {hoveredStudentDetails.email}</p>
                                <p>Phone: {hoveredStudentDetails.phone}</p>
                                <p>Address: {hoveredStudentDetails.address}</p>
                              </div>
                            ) : (
                              ''
                            )
                          }
                          arrow
                        >
                          <span>{student.fullname}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ minWidth: 150, borderRight: '1px solid #ccc' }}>{student.phone}</TableCell>
                      <TableCell style={{ minWidth: 120, borderRight: '1px solid #ccc' }}>{student.batch ? student.batch : 'General'}</TableCell>
                      <TableCell style={{ minWidth: 120, borderRight: '1px solid #ccc' }}>{student.status === 'false' ? 'Pending' : student.status}</TableCell>
                      <TableCell >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <IconButton onClick={() => handleApprove(student.id)} style={{ backgroundColor: '#28a745', color: '#fff' }}>
                            <CheckIcon />
                          </IconButton>
                          <div style={{ width: '1px', height: '24px', backgroundColor: '#ccc', margin: '0 10px' }}></div>
                          <IconButton onClick={() => handleDelete(student.id)} style={{ backgroundColor: '#dc3545', color: '#fff' }}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={students.filter((student) => student.status === 'false').length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}

            />
          </TableContainer>

        </>
      )}
    </div>
  );
};


const tabData = [
  {
    id: 'tab1',
    title: 'Students',
    icon: faTasks,
    tableHeaders: ['ID', 'Name', 'Image', 'Email', 'Phone', 'Gender'],
    data: [
      { id: 1, name: 'John Doe', image: 'img1.jpg', email: 'john@example.com', phone: '123-456-7890', gender: 'Male' },
      { id: 2, name: 'Jane Doe', image: 'img2.jpg', email: 'jane@example.com', phone: '987-654-3210', gender: 'Female' },
      { id: 3, name: 'Michael Smith', image: 'img3.jpg', email: 'michael@example.com', phone: '456-789-0123', gender: 'Male' },
    ]
  },
  {
    id: 'tab2',
    title: 'Diploma',
    icon: faProjectDiagram,
    tableHeaders: ['ID', 'Name', 'Image', 'Email', 'Phone', 'Gender'],
    data: [
      { id: 1, name: 'Emily Johnson', image: 'img4.jpg', email: 'emily@example.com', phone: '111-222-3333', gender: 'Female' },
      { id: 2, name: 'Daniel Williams', image: 'img5.jpg', email: 'daniel@example.com', phone: '444-555-6666', gender: 'Male' },
      { id: 3, name: 'Olivia Brown', image: 'img6.jpg', email: 'olivia@example.com', phone: '777-888-9999', gender: 'Female' },
    ]
  },
  {
    id: 'tab3',
    title: 'WSQ',
    icon: faBell,
    tableHeaders: ['ID', 'Name', 'Image', 'Email', 'Phone', 'Gender'],
    data: [
      { id: 1, name: 'William Wilson', image: 'img7.jpg', email: 'william@example.com', phone: '111-222-3333', gender: 'Male' },
      { id: 2, name: 'Sophia Anderson', image: 'img8.jpg', email: 'sophia@example.com', phone: '444-555-6666', gender: 'Female' },
      { id: 3, name: 'Alexander Martinez', image: 'img9.jpg', email: 'alexander@example.com', phone: '777-888-9999', gender: 'Male' },
    ]
  },
  {
    id: 'tab4',
    title: 'Staff Details',
    icon: faTicketAlt,
    tableHeaders: ['ID', 'Name', 'Image', 'Email', 'Phone', 'Gender'],
    data: [
      { id: 1, name: 'Emma Garcia', image: 'img10.jpg', email: 'emma@example.com', phone: '111-222-3333', gender: 'Female' },
      { id: 2, name: 'James Lopez', image: 'img11.jpg', email: 'james@example.com', phone: '444-555-6666', gender: 'Male' },
      { id: 3, name: 'Ella Rodriguez', image: 'img12.jpg', email: 'ella@example.com', phone: '777-888-9999', gender: 'Female' },
    ]
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #bdc3c9',
    paddingBottom: '10px',
    marginBottom: '20px',
    fontSize: '20px',
  },
  customNavLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));


const Adhome = () => {
  
  return (
    <div style={{ backgroundColor: '#FDFCE8', minHeight: '100vh' }}>
      <Header />
      <br /> <br />
      <div style={{ flexDirection: 'column', alignItems: 'center' }}>
        <Ap />
        < Dashboard />
        <WelcomeBoxes />
        {/* <Tablerow /> */}
      </div>
    </div>
  );
};




export default Adhome;  