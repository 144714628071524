import React ,{useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
 
import { Container, Row, Col, Form } from 'react-bootstrap';


const columns = [
  { id: 'Batch', label: 'Batch', minWidth: 170 },
  { id: 'Date', label: 'Date', minWidth: 100 },
  {
    id: 'Course',
    label: 'Course',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'Trainer',
    label: 'Trainer',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'NoofCompentent',
    label: 'No of Compentent',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'NoofNotYetCompentent',
    label: 'No of Not Yet Compentent',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'PassingPercentage',
    label: 'Passing Percentage',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];


function createData(Batch, Date, Course, Trainer, NoofCompentent, NoofNotYetCompentent, PassingPercentage) {
  return { Batch, Date, Course, Trainer, NoofCompentent, NoofNotYetCompentent, PassingPercentage };
}


const rows = [
  createData('Batch1', '21-07-2023', 'java', 'ram', 2, 12, 25),
  createData('Batch2', '09-04-2023', 'reactjs', 'moorthy', 2, 12, 25),
  createData('Batch3', '11-02-2023', 'python', 'raju', 2, 12, 25),
  createData('Batch4', '16-05-2023', 'dotnet', 'sheela', 2, 12, 25),
  createData('Batch5', '23-09-2023', 'angular', 'suganya', 2, 12, 25),
  // Add more rows as needed
];


const StickyHeadTable = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQuery, setSearchQuery] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const filteredRows = rows
  .filter((row) => {
    const values = Object.values(row).map((value) =>
      typeof value === 'string' ? value.toLowerCase() : value.toString()
    );
    return values.some((value) => value.includes(searchQuery.toLowerCase()));
  })
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  return (
    <div className="containerw" style={{ maxWidth: '1000px', marginTop: '-20px' }}>
       
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <input
        type="text"
        placeholder="Search by Name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{
          padding: '8px 12px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          float: 'left',
          fontSize: '16px',
        }}
      />
      <TableContainer
    sx={{
      overflowX: 'auto',
      maxWidth: '100%',
      '@media (min-width: 768px)': { // Apply styles for screens smaller than 768px (mobile)
        overflowX: 'unset', // Enable horizontal scroll
      },
    }}
  >      
 <Table aria-label="sticky table">
                  <TableHead style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};


const AssessmentForm = () => {
  return (
    <div className="containerw" style={{ maxWidth: '1000px', marginTop: '-35px'  }}>
       <br></br>
       <br></br>
      <Form>
        <Row>
          <Col sm={12} md={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                No of Trained Assets
              </Form.Label>
              <Col sm={6}>
                {/* Input field for No of Trained Assets */}
              </Col>
            </Form.Group>


            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                No of Components
              </Form.Label>
              <Col sm={6}>
                {/* Input field for No of Components */}
              </Col>
            </Form.Group>
          </Col>


          <Col sm={12} md={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                Passing Rate
              </Form.Label>
              <Col sm={6}>
                {/* Input field for Passing Rate */}
              </Col>
            </Form.Group>


            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                Target Passing Rate
              </Form.Label>
              <Col sm={6}>
                {/* Input field for Target Passing Rate */}
              </Col>
            </Form.Group>
          </Col>
        </Row>


        <Row>
          <Col sm={12} md={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                No of Trainer
              </Form.Label>
              <Col sm={6}>
                {/* Input field for No of Trainer */}
              </Col>
            </Form.Group>


            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                No of Fallout
              </Form.Label>
              <Col sm={6}>
                {/* Input field for No of Fallout */}
              </Col>
            </Form.Group>
          </Col>


          <Col sm={12} md={6}>
            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                Attrition Rate
              </Form.Label>
              <Col sm={6}>
                {/* Input field for Attrition Rate */}
              </Col>
            </Form.Group>


            <Form.Group as={Row}>
              <Form.Label column sm={6}>
                Target Attrition Rate
              </Form.Label>
              <Col sm={6}>
                {/* Input field for Target Attrition Rate */}
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};


 


const TableForm = () => {
  return (
    <div>
       
      <AssessmentForm />
      <br></br>
      <StickyHeadTable />
    </div>
  );
};


export default TableForm;





